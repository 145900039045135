import * as Yup from "yup";
export const initial_values = {
  id: 0,
  bankName: "",
  accountNumber: "",
  accountHolderName: "",
  cardNumber: "",
  date: "",
  cvv: "",
};
export const validationSchema = Yup.object().shape({
  bankName: Yup.string().required("BankName is Required").label("bankName"),
  accountNumber: Yup.string().required("Account Number is Required").label("accountNumber"),
  accountHolderName: Yup.string().required("Account Holder Name is Required").label("accountHolderName"),
  cardNumber: Yup.string().required("Card Number is Required").label("cardNumber"),
  date: Yup.string().required("Date is Required").label("date"),
  cvv: Yup.string().required("CVV is Required").label("cvv"),
  
});
