import React from "react";
import HeroImage from "../sections/ServiceCostCal/HeroImage";
import EstimatedHighToLowCostGraph from "../sections/ServiceCostCal/EstimatedHighToLowCostGraph";
import ServicesOnMap from "../sections/ServiceCostCal/ServicesOnMap";
import Tips from "../sections/ServiceCostCal/Tips";
import FAQs from "../sections/ServiceCostCal/FAQs";
import DoubleLineSlider from "../sections/ServiceCostCal/DoubleLineSlider";
import FullLengthSlider from "../sections/AllServicesSlider/FullLengthSlider";

const ServiceCostCal = () => {
  return (
    <>
      <HeroImage />
      <EstimatedHighToLowCostGraph />
      <DoubleLineSlider />
      <ServicesOnMap />
      <Tips />
      <FAQs />
      <FullLengthSlider />
    </>
  );
};

export default ServiceCostCal;
