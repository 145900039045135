import React from "react";
import Heading from "../../../components/common/Typography/Heading";
import BarChart from "../../../components/Analytics/BarChart";

const FollowingChart = () => {
  const data1 = [500, 1500, 1800];
  const data2 = [400, 1200, 1900];
  const labels = ["January", "March", "April"];
  return (
    <>
      <div className="rounded-3xl h-[450px] 2xl:h-[500px] w-full sm:w-1/2 bg-white pt-5 px-5 pb-10 relative">
        <Heading variant={"h6"} className={"text-offblack"}>
          Followers
        </Heading>
        <div className="flex items-end justify-center mx-auto mt-60 xl:mt-[190px] 3xl:mt-40 2k:mt-28">
          <BarChart data1={data1} data2={data2} labels={labels} height={500} />
        </div>
      </div>
    </>
  );
};

export default FollowingChart;
