import React, { useState } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import TextArea from "../../../components/common/InputField/TextArea";
import { Dropdown } from "primereact/dropdown";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
import SpecialText from "../../../components/common/Typography/SpecialText";

const BusinessDeactivation = () => {
  const [selectedReason, setSelectedReason] = useState(null);
  const reasons = [
    { name: "Business needs have changed." },
    { name: "Found services through another platform." },
    { name: "Expectations not met by Trades Poster." },
    { name: "Experienced difficulties with platform usability." },
    { name: "Concerns about business data privacy." },
    { name: "Others" },
  ];

  return (
    <form>
      <div className="grid grid-cols-12 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto">
        <div className="col-span-12">
          <label className={`block mb-2`}>
            <AuxiliaryText variant={"FieldLabel"}>
              Reason for deactivation
            </AuxiliaryText>
          </label>
          <div className="custom-dropdown">
            <Dropdown
              value={selectedReason}
              onChange={(e) => setSelectedReason(e.value)}
              options={reasons}
              optionLabel="name"
              placeholder="Select the primary reason for deactivation"
              panelClassName="custom-dropdown-panel"
              className="custom-dropdown-field p-dropdown-trigger-custom text-offblack flex items-center font-ibmplex font-normal normal-case text-[12px] leading-[16px] tracking-[0.70px] sm:text-[12px] sm:leading-[16px] sm:tracking-[0.70px] md:text-[12px] md:leading-[16px] md:tracking-[0.80px] lg:text-[12px] lg:leading-[16px] lg:tracking-[0.90px] xl:text-[13px] xl:leading-[16px] xl:tracking-[1px] 2xl:text-[13px] 2xl:leading-[16px] 2xl:tracking-[1px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px]"
            />
            <i className="fi fi-rs-angle-down text-sm text-offblack hover:cursor-pointer custom-dropdown-icon"></i>
          </div>
          <div className="mt-5">
            <TextArea
              className={"bg-pastalblue"}
              variant={"general"}
              label={"Additional feedback"}
              placeholder={
                "Your feedback is crucial to us. If you have any additional thoughts about your experience, challenges faced, ideas for platform improvement, or positive aspects you'd like to mention, we welcome your input."
              }
            />
          </div>
          <div className="mt-10">
            <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px]">
              <div className="flex items-center">
                <label
                  className="relative flex items-center rounded-full cursor-pointer mr-3"
                  htmlFor="link"
                >
                  <input
                    type="checkbox"
                    className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-[4px] border border-mediumgray transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primaryblue checked:bg-primaryblue checked:before:bg-primaryblue"
                    id="link"
                  />
                  <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-[40%] left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <i className="fi fi-rs-check text-[10px]"></i>
                  </span>
                </label>
                <SpecialText variant={"FootNoteDisclaimer"}>
                  Account deactivation confirmation
                </SpecialText>
              </div>
              <SpecialText
                variant={"FootNoteDisclaimer"}
                className={"w-full pl-7 mt-2"}
              >
                Confirm your request to deactivate your business account. This
                will cease all current engagements with service providers on
                Trades Poster, and all saved preferences or business listings
                will be permanently removed.
              </SpecialText>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-10">
        <MainButton variant={"specialredbuttonmedium"}>Deactivate</MainButton>
      </div>
    </form>
  );
};
export default BusinessDeactivation;
