import React, { useState } from "react";
import Heading from "../../components/common/Typography/Heading";
import signUp from "../../assets/img/P1.jpeg";
import profile from "../../assets/img/P2.jpeg";
import serviceList from "../../assets/img/P3.jpeg";
import customerConn from "../../assets/img/P4.jpeg";

const WhiteBg = () => {
  const data = [
    {
      id: 1,
      heading: "Connect with customers",
      para: "Use the platform to engage directly with clients, respond to inquiries, and build relationships.",
      pic: customerConn,
    },

    {
      id: 2,
      heading: "List your services",
      para: "Add clear descriptions, images, and categories to attract clients.",
      pic: serviceList,
    },
    {
      id: 3,
      heading: "Create your profile",
      para: "Set up a detailed business profile with comprehensive service listings.",
      pic: profile,
    },
    {
      id: 4,
      heading: "Sign up",
      para: "Register with your email or Google account quickly and easily.",
      pic: signUp,
    },
  ];
  const [step, setStep] = useState(4);
  const [selectedStep, setSelectedStep] = useState(4);
  const handleStepClick = (stepId) => {
    setSelectedStep(stepId);
  };

  // Filter out the selected step from the data array
  const remainingSteps = data.filter((step) => step.id !== selectedStep);

  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Using Trades Poster
        </Heading>
      </div>
      <div className="mx-[5%] sm:mx-auto sm:w-[600px] md:w-[700px] lg:w-[980px] xl:w-[1200px] 2xl:w-[1748px] 3xl:w-[1850px] 2k:w-[2000px] 4k:w-[2150px] pb-10 pt-20 xl:pb-10 2xl:pb-[120px] xl:pt-20 2xl:pt-[140px] bg-primaryblue rounded-3xl mt-10 shrink-0">
        {remainingSteps.map((e, index) => {
          return (
            <>
              <div
                key={index}
                className={
                  index === 0
                    ? "mx-[11%] sm:mx-auto sm:w-[430px] md:w-[530px] lg:w-[780px] xl:w-[980px] 2xl:w-[1084px] 3xl:w-[1220px] 2k:w-[1420px] 4k:w-[1620px] hover:cursor-pointer"
                    : index === 1
                    ? "mx-[9%] sm:mx-auto sm:w-[470px] md:w-[570px] lg:w-[820px] xl:w-[1020px] 2xl:w-[1144px] 3xl:w-[1280px] 2k:w-[1480px] 4k:w-[1680px] hover:cursor-pointer"
                    : "mx-[7%] sm:mx-auto sm:w-[510px] md:w-[610px] lg:w-[860px] xl:w-[1060px] 2xl:w-[1204px] 3xl:w-[1340px] 2k:w-[1540px] 4k:w-[1740px] hover:cursor-pointer"
                }
                onClick={() => {
                  setStep(e.id);
                  handleStepClick(e.id);
                }}
              >
                <div className="p-3 w-full flex items-center bg-white rounded-t-3xl">
                  <Heading variant={"h6"} className={"text-offblack"}>
                    {e.heading}
                  </Heading>
                </div>
                <img src={e.pic} className="w-full h-[10px]" alt="" />
              </div>
            </>
          );
        })}
        {step === 1 && (
          <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
            <div className="p-3 w-full flex items-center bg-white rounded-t-3xl">
              <Heading variant={"h6"} className={"text-offblack"}>
                Connect with customers
              </Heading>
            </div>
            <div className="h-auto sm:h-[400px] md:h-[450px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px] 3xl:h-[800px] 2k:h-[900px] 4k:h-[1000px] spect-[1264/700]">
              <img
                src={profile}
                className="w-full h-full object-cover rounded-b-3xl"
                alt=""
              />
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
            <div className="p-3 w-full flex items-center bg-white rounded-t-3xl">
              <Heading variant={"h6"} className={"text-offblack"}>
                List your services
              </Heading>
            </div>
            <div className="h-auto sm:h-[400px] md:h-[450px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px] 3xl:h-[800px] 2k:h-[900px] 4k:h-[1000px] spect-[1264/700]">
              <img
                src={profile}
                className="w-full h-full object-cover rounded-b-3xl"
                alt=""
              />
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
            <div className="p-3 w-full flex items-center bg-white rounded-t-3xl">
              <Heading variant={"h6"} className={"text-offblack"}>
                Create your profile
              </Heading>
            </div>
            <div className="h-auto sm:h-[400px] md:h-[450px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px] 3xl:h-[800px] 2k:h-[900px] 4k:h-[1000px] spect-[1264/700]">
              <img
                src={profile}
                className="w-full h-full object-cover rounded-b-3xl"
                alt=""
              />
            </div>
          </div>
         
        )}
        {step === 4 && (
          <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
            <div className="p-3 w-full flex items-center bg-white rounded-t-3xl">
              <Heading variant={"h6"} className={"text-offblack"}>
                Sign up
              </Heading>
            </div>
            <div className="h-auto sm:h-[400px] md:h-[450px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px] 3xl:h-[800px] 2k:h-[900px] 4k:h-[1000px] spect-[1264/700]">
              <img
                src={profile}
                className="w-full h-full object-cover rounded-b-3xl"
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WhiteBg;
