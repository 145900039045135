import React from "react";
import NotificationsLayout from "../../../sections/BusinessDashboard/Inbox/Notifications/NotificationsLayout";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
import SpecialText from "../../../components/common/Typography/SpecialText";

const Notifications = () => {
  return (
    <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
      <Heading variant={"h6"} className={"text-center text-offblack"}>
        Alerts and notifications
      </Heading>

      <div className="mx-auto mt-[60px] min-h-screen">
        <NotificationsLayout />
        <SpecialText
          variant={"FootNoteDisclaimer"}
          className={"mt-20 text-center text-lightgray"}
        >
          You've reached the end of the list.
        </SpecialText>
      </div>
    </DashboardMainContainer>
  );
};

export default Notifications;
