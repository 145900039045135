/* eslint-disable no-lone-blocks */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { usePosterServices } from "../../Hooks/useListing";
import AdsCard from "../../components/common/AdsCard/AdsCard";
import DefaultImage from "../../assets/img/default-image.jpg";
import CircularButton from "../../components/common/MainButton/CircularButton";
import useAuth from "../../Hooks/useAuth";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useRef } from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";

const UserGigs = () => {
  const { auth, setAuth } = useAuth();
  function useParams() {
    return new URLSearchParams(useLocation().search);
  }
  const params = useParams();
  const userId = params.get("id");
  const { data: sellerRelatedData, isLoading } = usePosterServices(userId);
  // console.log("Data", sellerRelatedData);
  const swiperRef = useRef();
  return (
    <>
      <div className={"ml-5 md:ml-0"}>
        <SecondaryHeading
          variant={"MainParagraphHeading"}
          className={"text-offblack"}
        >
          Posts
        </SecondaryHeading>
      </div>
      {sellerRelatedData?.data?.$values?.length === 0 ||
      sellerRelatedData?.data?.$values?.length === undefined ? (
        <>
          <div className="flex items-center justify-center mt-5">
            <div className="mt-5 w-[350px] sm:w-[378px] 2xl:w-[413px] h-[310px]">
              <Link to={"/business-dashboard/posts/create-post"}>
                <div className="flex flex-col justify-center items-center gap-2 py-5 rounded-xl bg-white text-offblack w-[378px] 2xl:w-[413px] h-[310px]">
                  <i className="fi fi-rs-add text-[100px] hover:text-primaryblue"></i>
                  <AuxiliaryText variant={"FieldLabel"}>
                    Create new post
                  </AuxiliaryText>
                </div>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <Swiper
              className="w-full lg:w-[378px] xl:w-[788px] 2xl:w-[858px] 3xl:w-full mt-5"
              breakpoints={{
                200: {
                  slidesPerView: 'auto',
                },
                1024: {
                  slidesPerView: 1,
                },
                1280: {
                  slidesPerView: 2,
                },
                1920: {
                  slidesPerView: 'auto',
                },
              }}
              // slidesPerView={2}
              speed={1000}
              spaceBetween={32}
              modules={[Pagination, Navigation]}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              <>
                <SwiperSlide className="w-fit">
                  <div>
                    <Link to={"/business-dashboard/posts/create-post"}>
                      <div className="flex flex-col justify-center items-center gap-2 py-5 rounded-xl bg-white text-offblack w-[350px] sm:w-[378px] 2xl:w-[413px] h-[310px]">
                        <i className="fi fi-rs-add text-[100px] hover:text-primaryblue"></i>
                        <AuxiliaryText variant={"FieldLabel"}>
                          Create new post
                        </AuxiliaryText>
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
                {React.Children.toArray(
                  sellerRelatedData?.data?.$values.map((e, index) => {
                    {
                      const ListImage = JSON.parse(e?.pictures);
                      return (
                        <>
                          <SwiperSlide className="swiper-slide w-fit" key={index}>
                            <AdsCard
                              key={index}
                              serviceName={e?.listingTitle}
                              serviceDescription={e?.description}
                              category={
                                e?.category ??
                                e?.listingCategory?.businessCategoryName
                              }
                              type={e?.listType}
                              Images={
                                ListImage?.length > 0
                                  ? ListImage
                                  : [DefaultImage]
                              }
                              rating={e?.listRating}
                              companyName={
                                e?.companyName ??
                                e?.userProfile?.company?.companyName
                              }
                              companyLogo={
                                e?.companyLogo ??
                                e?.userProfile?.company?.companyLogo
                              }
                              serviceId={e?.listingId ?? e.id}
                              companyId={
                                e?.companyId ?? e?.userProfile?.company?.id
                              }
                              reaction={e?.listReaction}
                            />
                          </SwiperSlide>
                        </>
                      );
                    }
                  })
                )}
              </>
          {sellerRelatedData?.data?.$values?.length > 1 && (
            <div className="flex items-center justify-end gap-x-2 mt-5">
              <CircularButton
                variant={"prev30"}
                onClick={() => swiperRef.current?.slideNext()}
              ></CircularButton>
              <CircularButton
                variant={"next30"}
                onClick={() => swiperRef.current?.slidePrev()}
              ></CircularButton>
            </div>
          )}
            </Swiper>
          </div>

        </>
      )}
    </>
  );
};

export default UserGigs;
