import React from "react";
import MainButton from "../MainButton/MainButton";
import TextFiled from "../InputField/TextFiled";
import TextArea from "../InputField/TextArea";
import { useState } from "react";
import Heading from "../Typography/Heading";
import Paragraph from "../Typography/Paragraph";
import AuxiliaryText from "../Typography/AuxiliaryText";
const StoriesForm = ({ closePopup }) => {
  const [step, setStep] = useState(1);
  // const [selectedReason, setSelectedReason] = useState(null);
  const reasons = [
    { name: "Poor Customer Service" },
    { name: "Fraud or Deceptive Practices" },
    { name: "Subpar Quality of Work" },
    { name: "Licensing or Certification Issues" },
    { name: "False or Misleading Reviews" },
    { name: "Safety Concerns or Hazards" },
    { name: "Misleading Advertising or Misrepresentation" },
    { name: "Communication Breakdown" },
    { name: "Appointment No-Shows or Cancellations" },
    { name: "Other (Please specify)" },
  ];

  //   const filesData = [...files];
  //   setImageList((state) => {
  //     return [
  //       ...state,
  //       ...filesData.map((file) => ({
  //         file: file,
  //         url: URL.createObjectURL(file),
  //       })),
  //     ];
  //   });
  // };
  const completion = 76;
  return (
    <>
      <>
        {step === 1 && (
          <>
            <div className="bg-pastalblue">
              <Heading
                variant={"h6"}
                className={
                  "flex items-center justify-center text-offblack mt-20 lg:mt-20 xl:mt-28"
                }
              >
                Create your success story
              </Heading>
              <div className="h-[700px] overflow-auto pt-10 pb-60 hidden-scrollbar">
                <div className="w-full md:w-[450px] lg:w-[500px] xl:w-[500px] mx-auto">
                  <div>
                    <div>
                      <TextFiled
                        variant={"general"}
                        label={"Business name"}
                        placeholder={"Please enter your business name"}
                      />
                    </div>
                    <div className="mt-5">
                      <TextFiled
                        variant={"general"}
                        label={"Business owner name"}
                        placeholder={"Please enter your business owner name"}
                      />
                    </div>
                    <div className="mt-5">
                      <TextArea
                        variant={"general"}
                        label={"About business"}
                        placeholder={"Provide business detail"}
                      />
                    </div>
                    <div className="mt-5">
                      <TextArea
                        variant={"general"}
                        label={"Comments for Trades Poster"}
                        placeholder={"Comment your experience with Trades Poster"}
                      />
                    </div>
                    <div>
                      <div className="mt-5">
                        <label className={`block mb-2`}>
                          <AuxiliaryText variant={"FieldLabel"}>
                            Business Portfolio Images
                          </AuxiliaryText>
                        </label>
                        <div className="flex items-center justify-center w-full sm:w-[350px] md:w-[450px] lg:w-[500px] xl:w-[500px] 2xl:w-[500px]">
                          <input
                            type="file"
                            id="imageslist"
                            // onChange={handleSelect}
                            accept="image/*"
                            className="cursor-pointer hidden"
                            multiple
                          />
                          <label
                            role="button"
                            htmlFor="imageslist"
                            className="h-[140px] w-full sm:w-[350px] md:w-[450px] lg:w-[500px] xl:w-[500px] 2xl:w-[500px] rounded-lg flex justify-center bg-white"
                          >
                            <div className="my-auto">
                              <div className="flex justify-center">
                                <div className="flex justify-center items-center">
                                  <i className="fi fi-rs-inbox-out text-base text-offblack"></i>
                                </div>
                              </div>
                              <div className="flex justify-center">
                                <AuxiliaryText
                                  variant={"Placeholder"}
                                  className={"mt-1 text-primaryblue"}
                                >
                                  Click to upload
                                </AuxiliaryText>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className="mt-10 w-full">
                          <div className="flex gap-3 w-full">
                            <i className="fi fi-br-picture text-[50px] text-primaryblue"></i>
                            <div className="w-full flex flex-col gap-2 mt-5">
                              <div className="flex items-center justify-between w-full">
                                <AuxiliaryText variant={"FieldLabel"}>
                                  myprofile.png
                                </AuxiliaryText>

                                <AuxiliaryText variant={"FieldLabel"}>
                                  {completion}%
                                </AuxiliaryText>
                              </div>
                              <div className="w-full h-1.5 bg-white rounded-full">
                                <div
                                  className={`h-full bg-primaryblue rounded-full`}
                                  style={{ width: `${completion}%` }}
                                ></div>
                              </div>
                            </div>
                            <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
                          </div>
                          <div className="flex gap-3 w-full mt-5">
                            <i className="fi fi-br-picture text-[50px] text-primaryblue"></i>
                            <div className="w-full flex flex-col gap-2 mt-5">
                              <div className="flex items-center justify-between w-full">
                                <AuxiliaryText variant={"FieldLabel"}>
                                  myprofile.png
                                </AuxiliaryText>

                                <AuxiliaryText variant={"FieldLabel"}>
                                  {completion}%
                                </AuxiliaryText>
                              </div>
                              <div className="w-full h-1.5 bg-white rounded-full">
                                <div
                                  className={`h-full bg-primaryblue rounded-full`}
                                  style={{ width: `${completion}%` }}
                                ></div>
                              </div>
                            </div>
                            <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                    <div>
                      <div className="flex items-center justify-end mt-10">
                        <MainButton
                          variant={"small"}
                          onClick={() => {
                            setStep(2);
                            setTimeout(closePopup, 3000);
                          }}
                        >
                          Submit
                        </MainButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="bg-pastalblue">
              <Heading
                variant={"h6"}
                className={
                  "flex items-center justify-center text-offblack mt-10 lg:mt-20 xl:mt-28"
                }
              >
                Submission confirmed
              </Heading>
              <div className="flex w-[350px] sm:w-[350px] md:w-[380px] lg:w-[500px] xl:w-[500px] 2xl:w-[500px] mx-auto rounded-lg">
                <div className="w-full">
                  <div>
                    <div className="w-full">
                      <Paragraph variant={"MainParagraph"} className={"mt-10"}>
                        Thank you for taking the time to submit your complaint.
                        We at Trades Poster take your concerns seriously and are
                        dedicated to maintaining a trustworthy environment for
                        all users. Each complaint is carefully reviewed, and
                        should we require further information, we will reach out
                        to you. Please keep an eye on your email for updates
                        regarding the investigation. Your patience and
                        cooperation in this process are greatly appreciated.
                      </Paragraph>
                    </div>
                  </div>
                  <div className="flex justify-center my-10">
                    <i className="fi fi-ss-badge-check text-[4.5rem] text-primaryblue"></i>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default StoriesForm;
