import React from "react";
import Heading from "../../components/common/Typography/Heading";
import Paragraph from "../../components/common/Typography/Paragraph";

const LeftText = ({ heading, text }) => {
  return (
    <>
      <div>
        <div className="mx-auto flex flex-col lg:flex-row items-center gap-x-16 w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-40">
          <div className="mt-10 lg:my-auto order-2 lg:order-1">
            <div>
              <Heading variant={"h2"} className={"text-offblack"}>
                {heading}
              </Heading>
              <Paragraph variant={"MainParagraph"} className={"mt-3"}>
                {text}
              </Paragraph>
            </div>
          </div>
          <div className="order-1 lg:order-2 w-[350px] h-auto sm:w-[550px] md:w-[650px] lg:w-1/2 lg:h-auto xl:w-1/2 aspect-square shrink-0">
            <img
              className="object-cover w-full h-full rounded-3xl"
              src={require("../../assets/img/TradesPosterSpeciality/businesses.jpg")}
              alt="trade-poster"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftText;
