import React from "react";
import { Link } from "react-router-dom";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";
import Title from "../../components/common/Title/Title";

const BusinessContact = () => {
  const data = [
    {
      heading: "Mobile",
      value: (
        <Title text={"0412 345 678"} className={"bg-white"}>
          <Link
            to={"tel:0412 345 678"}
            // title=" 0412 345 678"
          >
            <Paragraph variant={"MainParagraph"}> 0412 345 678</Paragraph>
          </Link>
        </Title>
      ),
    },
    {
      heading: "Office",
      value: (
        <Title text={"03 1234 5678"} className={"bg-white"}>
          <Link
            to={"tel:03 1234 5678"}
            // title="03 1234 5678"
          >
            <Paragraph variant={"MainParagraph"}>03 1234 5678</Paragraph>
          </Link>
        </Title>
      ),
    },
    {
      heading: "Email",
      value: (
        <Title text={"Info@tradesposter.com"} className={"bg-white"}>
          <Paragraph
            variant={"MainParagraph"}
            className={"text-primaryblue cursor-pointer"}
            // title="Info@tradesposter.com"
            onClick={() => (window.location = "mailto:Info@tradesposter.com")}
          >
            www.tradespo...
          </Paragraph>
        </Title>
      ),
    },
    {
      heading: "Website",
      value: (
        <Title text={"https://www.tradesposter.com"} className={"bg-white"}>
          <Link
            to={"https://www.tradesposter.com"}
            target="_blank"
            className="text-primaryblue "
            // title="www.tradesposter.com"
          >
            <Paragraph variant={"MainParagraph"}> www.tradespo...</Paragraph>
          </Link>
        </Title>
      ),
    },
  ];
  return (
    <>
      <SecondaryHeading
        variant={"MainParagraphHeading"}
        className={"flex justify-center text-offblack"}
      >
        Business contact
      </SecondaryHeading>
      <div className="mt-5 ml-10 mb-9 flex justify-center">
        <div>
          {data?.map((item) => {
            return (
              <>
                <div className="grid grid-cols-3 mb-1 gap-7">
                  <div className="col-span-1">
                    <Paragraph
                      variant={"MainParagraph"}
                    >
                      {item.heading}
                    </Paragraph>
                  </div>
                  <div className="col-span-2">{item.value}</div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BusinessContact;
