import React from "react";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const MeetTheOrigin = () => {
  return (
    <>
      <div className="w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mx-auto mt-10 lg:mt-40 xl:mt-40 2xl:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Meet the Origin
        </Heading>
        {/* 1st row */}
        <div className="flex gap-8 w-full mt-20">
          <div className="w-1/2 ">
            <div>
              <img
                src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
                className="w-full md:h-[400px] lg:h-[500px] xl:h-[700px] rounded-3xl object-cover"
                alt="Tradesposters"
              />
            </div>
          </div>
          <div className="w-1/2">
            <img
              src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
              className="w-full md:h-[400px] lg:h-[500px] xl:h-[700px] rounded-3xl object-cover"
              alt="Tradesposters"
            />
          </div>
        </div>
        {/* Second row */}
        <div className="flex gap-8 mt-8 w-full">
          <div className="w-1/2 flex flex-col">
            <div className="h-[400px] flex flex-col justify-center">
              <div className="flex items-center gap-x-4 justify-start">
                <div className="w-[50px] h-[30px]">
                  <img
                    src={require("../../assets/img/Australian_Aboriginal.png")}
                    className="w-full h-full"
                    alt="Australian National - Aboriginal"
                  />
                </div>
                <div className="w-[50px] h-[30px]">
                  <img
                    src={require("../../assets/img/Torres_Strait_Islanders.png")}
                    className="w-full h-full"
                    alt="Torres Strait - Islander"
                  />
                </div>
              </div>
              <div className="mt-5">
                <SecondaryHeading variant={"MainParagraphHeading"}>
                  Acknowledgement of Traditional Custodians
                </SecondaryHeading>
                <Paragraph variant={"MainParagraph"} className={"mt-3"}>
                  TradesPoster recognises the Aboriginal and Torres Strait
                  Islander peoples as the first inhabitants of Australia. We
                  acknowledge the Bunurong and Wadawurrung Peoples as the
                  Traditional Owners of the lands where our business thrives.
                  The Werribee Yalook (river), its creeks, stars, hills, and red
                  clay are enduring testaments to their deep connection with
                  this Country.
                </Paragraph>
                <Paragraph variant={"MainParagraph"} className={"mt-2"}>
                  We pay respect to their Ancestors and Elders, past, present,
                  and emerging, who have always cared for this land and
                  community, and will continue to do so for future generations.
                </Paragraph>
              </div>
            </div>
            <div>
              <img
                src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
                className="w-full h-[300px] xl:h-[500px] rounded-3xl object-cover"
                alt="Tradesposters"
              />
            </div>
          </div>
          <div className="w-1/2">
            <div>
              <img
                src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
                className="w-full h-[700px] xl:h-[900px] rounded-3xl object-cover"
                alt="Tradesposters"
              />
            </div>
          </div>
        </div>
        {/* 3rd row */}
        <div className="flex mt-8 w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
          <img
            src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
            className="w-full h-[350px] md:h-[400px] lg:h-[500px] xl:h-[700px] rounded-3xl object-cover"
            alt="Tradesposters"
          />
        </div>
        {/* 4th row */}
        <div className="flex gap-8 mt-8 w-full">
          <div className="w-1/2 flex flex-col">
            <div className="h-[450px] lg:h-[600px] xl:h-[800px] flex flex-col justify-center">
              <img
                src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
                className="w-full h-[450px] lg:h-[600px] xl:h-[800px] rounded-3xl object-cover"
                alt="Tradesposters"
              />
            </div>
          </div>
          <div className="flex flex-col gap-8 w-1/2">
            <div>
              <img
                src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
                className="w-full h-[209px] lg:h-[284px] xl:h-[384px] rounded-3xl object-cover"
                alt="Tradesposters"
              />
            </div>
            <div>
              <img
                src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
                className="w-full h-[209px] lg:h-[284px] xl:h-[384px] rounded-3xl object-cover"
                alt="Tradesposters"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetTheOrigin;
