import React, { useRef } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CircularButton from "../../../components/common/MainButton/CircularButton";
import { businessCategories } from "../../Home/BusinessCategories";
import Card from "../../../components/common/ReviewCard/Card";

const Reviews = () => {
  const swiperRef = useRef();
  return (
    <>
      <div className="w-full 2xl:w-full mt-5">
        <Swiper
          className="w-full"
          slidesPerView={"auto"}
          // centeredSlides={true}
          //   spaceBetween={32}
          initialSlide={1}
          speed={1000}
          // loop
          modules={[Pagination, Navigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          <>
            {businessCategories?.map((e) => {
              return (
                <SwiperSlide className="w-[400px] h-[200px] 2xl:w-[400px] 2xl:h-[200px] mr-8">
                  <Card />
                </SwiperSlide>
              );
            })}
          </>
        </Swiper>

        <div className="flex items-center justify-end gap-x-2 mt-5">
          <CircularButton
            variant={"prev30"}
            onClick={() => swiperRef.current?.slideNext()}
          ></CircularButton>
          <CircularButton
            variant={"next30"}
            onClick={() => swiperRef.current?.slidePrev()}
          ></CircularButton>
        </div>
      </div>
    </>
  );
};

export default Reviews;
