import React from "react";
import ProfileMainContainer from "../sections/PosterProfile/ProfileMainContainer";

const PosterProfile = () => {
  return (
    <>
      <ProfileMainContainer />
    </>
  );
};

export default PosterProfile;
