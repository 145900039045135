import React from 'react'

const DashboardMainContainer = ({children,className}) => {
  return (
    <>
    <div className ={`bg-white w-full rounded-3xl ${className}`}>
    {children}
    </div>
    </>
  )
}

export default DashboardMainContainer