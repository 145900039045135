import React from "react";
import SpecialText from "../../components/common/Typography/SpecialText";
import Paragraph from "../../components/common/Typography/Paragraph";
import TextArea from "../../components/common/InputField/TextArea";
import MainButton from "../../components/common/MainButton/MainButton";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import { Link } from "react-router-dom";

const MessageLayout = ({
  moveBack,
  customerprofileImage,
  customermessenger,
  customerconversationText,
  profileImage,
  businessProfileLink,
  messenger,
  sender,
  timing,
  conversationText,
}) => {
  return (
    <>
      {/* First line */}
      <div className="flex items-center gap-2 mb-[60px]">
        <div onClick={moveBack}>
          <SecondaryHeading
            variant={"MainParagraphHeading"}
            className={"hover:text-primaryblue cursor-pointer"}
          >
            My inbox
          </SecondaryHeading>
        </div>
        <div className="pt-[2px]">
          <i className="fi fi-rs-angle-right text-xs"></i>
        </div>
        <div className="inline">
          <SecondaryHeading
            variant={"MainParagraphHeading"}
            className={"inline"}
          >
            Conversation with {" "}
          </SecondaryHeading>
          {businessProfileLink ? (
            <Link to={"/poster-profile"}>
              <SecondaryHeading
                variant={"MainParagraphHeading"}
                className={"inline text-offblack hover:text-primaryblue"}
              >
                Aaqib Javaid
              </SecondaryHeading>
            </Link>
          ) : (
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className={"inline"}
            >
              Saim sahi
            </SecondaryHeading>
          )}
        </div>
      </div>
      <div className="max-w-fit">
        {/* customer Profile Section */}
        <div className="pb-10 border-b border-b-mediumgray">
          <div className="flex items-center gap-3">
            <Link to={"/poster-profile"} className="shrink-0">
              <img
                src={customerprofileImage}
                alt={customermessenger}
                className="w-12 h-12 rounded-full shrink-0"
              />
            </Link>
            <div className="flex items-center justify-between w-full">
              <SpecialText variant={"ProfileName"} className={"text-offblack"}>
                {customermessenger}
              </SpecialText>
              <SpecialText variant={"DateStamp"} className={"text-offblack"}>
                {timing}
              </SpecialText>
            </div>
          </div>

          {/* Message */}
          <div className="mt-5 w-[300px] sm:w-[400px] md:w-[500px] sm:ml-14 shrink-0">
            <Paragraph variant={"MainParagraph"}>
              {customerconversationText}
            </Paragraph>
          </div>
        </div>
        {/* Business Profile section */}
        <div className="pb-10 border-b border-b-mediumgray mt-10">
          <div className="flex items-center gap-3">
            <img
              src={profileImage}
              alt={messenger}
              className="w-12 h-12 rounded-full shrink-0"
            />
            <div className="flex items-center justify-between w-full">
              <SpecialText variant={"ProfileName"} className={"text-offblack"}>
                {messenger}
              </SpecialText>
              <SpecialText variant={"DateStamp"} className={"text-offblack"}>
                {timing}
              </SpecialText>
            </div>
          </div>

          {/* Message */}
          <div className="mt-5 w-[300px] sm:w-[400px] md:w-[500px] sm:ml-14 shrink-0">
            <Paragraph variant={"MainParagraph"}>{conversationText}</Paragraph>
          </div>
        </div>
        {/* Message input section */}
        <div className="mt-10 flex flex-col sm:flex-row gap-x-5 w-[300px] sm:w-[468px] md:w-[568px] 2xl:w-[568px]">
          <div className="shrink-0">
            <img
              src={profileImage}
              alt={messenger}
              className="w-12 h-12 rounded-full"
            />
          </div>
          <div className="w-full">
            <TextArea
              variant={"general"}
              className={"bg-pastalblue"}
              placeholder={"Write your reply here"}
            />
            <div className="flex justify-end mt-5">
              <MainButton variant={"small"}>Reply</MainButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageLayout;
