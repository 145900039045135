import React from "react";
import Heading from "../../components/common/Typography/Heading";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import { Link } from "react-router-dom";
import Paragraph from "../../components/common/Typography/Paragraph";
import SpecialText from "../../components/common/Typography/SpecialText";
import BlogDesign from "../../components/common/Blog/BlogDesign";
const BlogsLayout = () => {
  const data = [
    {
      id: 1,
      item: "Interviews",
      link: "/blogs/category-name",
    },
    {
      id: 2,
      item: "Go green",
      link: "/blogs/category-name",
    },
    {
      id: 3,
      item: "DIY",
      link: "/blogs/category-name",
    },
    {
      id: 4,
      item: "Hang time",
      link: "/blogs/category-name",
    },
    {
      id: 5,
      item: "Trades update",
      link: "/blogs/category-name",
    },
  ];
  const bloggs = [
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
  ];
  return (
    <>
      <div className="sm:mx-auto w-full sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
        <div className="flex flex-col sm:flex-row gap-16 mt-10">
          <div className="w-full flex flex-col items-start mt-[75px]">
            <AuxiliaryText
              variant={"MenuDropdown"}
              className={
                "mb-5 text-offblack border-b border-b-mediumgray w-full pb-4"
              }
            >
              All Categories
            </AuxiliaryText>
            {data.map((e) => {
              return (
                <Link to={e.link}>
                  <AuxiliaryText
                    variant={"SubMenuDropdown"}
                    className={"mb-2 hover:text-primaryblue"}
                  >
                    {e.item}
                  </AuxiliaryText>
                </Link>
              );
            })}
          </div>
          <div className="sm:w-[400px] md:w-[450px] lg:w-[700px] xl:w-[900px] 3xl:w-[1050px] 2k:w-[1200px] 4k:w-[1400px] shrink-0">
            <Heading variant={"h5"} className={"text-offblack"}>
              8 tips for developing motion design principles
            </Heading>
            <div className="h-auto sm:w-[400px] md:w-[450px] lg:w-[700px] xl:w-[900px] 3xl:w-[1050px] 2k:w-[1200px] 4k:w-[1400px] sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[600px] 3xl:h-[700px] 2k:h-[800px] 4k:h-[900px] aspect-[900/600] mt-10">
            <Link to={"/blogs/article-id-will-be-here"}>
              <img
                src={require("../../../src/assets/img/default-image.jpg")}
                className="w-full h-full shrink-0 rounded-3xl"
                alt=""
              />
            </Link>

            </div>
            <Paragraph
              variant={"MainParagraph"}
              className={"mt-5 text-offblack w-full"}
            >
              A casual space for the community to share their off-the-clock
              activities and informal gatherings. This category celebrates the
              lighter side of the trades community, from hobbies and interests
              to relaxation techniques, promoting a balanced lifestyle.
            </Paragraph>
            <div className="mt-5 mb-20">
              <SpecialText variant={"DateStamp"} className={"text-offblack"}>
                7 days ago
              </SpecialText>
            </div>

            {bloggs.map((e, index) => {
              return (
                <BlogDesign
                  key={index}
                  heading={e.title}
                  para={e.text}
                  timing={e.time}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogsLayout;
