import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Facebook from "../../assets/img/social/facebook.svg";
import Instagram from "../../assets/img/social/instagram.svg";
import Linkiedin from "../../assets/img/social/linkedin.svg";
import Twitter from "../../assets/img/social/threads.svg";
import Tiktok from "../../assets/img/social/tiktok.svg";
import Pinterest from "../../assets/img/social/pinterest.svg";
import Paragraph from "../../components/common/Typography/Paragraph";

const Social = () => {
  const data = [
    {
      platform: "Facebook",
      logo: Facebook,
    },
    {
      platform: "Instagram",
      logo: Instagram,
    },
    {
      platform: "Linkiedin",
      logo: Linkiedin,
    },
    {
      platform: "Twitter",
      logo: Twitter,
    },
    {
      platform: "Tiktok",
      logo: Tiktok,
    },
    {
      platform: "Pinterest",
      logo: Pinterest,
    },
  ];
  return (
    <>
      <SecondaryHeading
        variant={"MainParagraphHeading"}
        className={"flex justify-center text-offblack"}
      >
        Social profiles
      </SecondaryHeading>
      <div className="grid grid-cols-3 md:grid-cols-2 gap-y-5 xl:mx-10 mt-5">
        {data.map((e, index) => {
          return (
            <>
              <div
                key={index}
                className="flex flex-col items-center justify-center"
              >
                <img
                  className="w-[50px] h-auto cursor-pointer"
                  src={e.logo}
                  alt={e.platform}
                />
                <div>
                  <Paragraph variant={"MainParagraph"} className={"text-offblack"}>{e.platform}</Paragraph>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Social;
