import React from "react";
import TradingHours from "../../../sections/BusinessDashboard/Settings/TradingHours";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";

const Tradinghours = () => {
  return (
    <>
      <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center text-offblack"}>
          Trading hours
        </Heading>
        <div className="mt-[60px] min-h-screen">
          <TradingHours />
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default Tradinghours;
