import React, { useState } from "react";
import TextFiled from "../../../components/common/InputField/TextFiled";
import TextArea from "../../../components/common/InputField/TextArea";
import MainButton from "../../../components/common/MainButton/MainButton";
import Paragraph from "../../../components/common/Typography/Paragraph";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
import Heading from "../../../components/common/Typography/Heading";
import { useNavigate } from "react-router-dom";

const GeneralInquiry = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const completion = 79.32;
  return (
    <>
      {step === 1 && (
        <>
          <div>
            <TextFiled
              variant={"general"}
              label={"First name"}
              placeholder={"Enter your first name"}
              star={false}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              variant={"general"}
              label={"Last name"}
              placeholder={"Enter your last name"}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              variant={"general"}
              label={"Email address"}
              placeholder={"Enter your email address"}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              variant={"general"}
              label={"Tradesposter username"}
              placeholder={"Enter your Tradesposter username (optional)"}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              variant={"general"}
              label={"Subject"}
              placeholder={"Enter your quiry subject"}
            />
          </div>
          <div className="mt-5">
            <TextArea
              variant={"general"}
              label={"Message"}
              placeholder={"Please briefly describe your quiry"}
            />
          </div>
          <div className="mt-5">
            <label
              htmlFor="addyourtags"
              className="block mb-2 mt-5 max-w-[350px] mx-auto sm:mx-0 sm:max-w-full"
            >
              <AuxiliaryText variant={"FieldLabel"}>Attatchment</AuxiliaryText>
            </label>
            <div className="flex items-center justify-center w-full ">
              <input
                type="file"
                id="imageslist"
                // onChange={handleSelect}
                accept="image/*"
                className="cursor-pointer hidden"
                multiple
              />
              <label
                role="button"
                htmlFor="imageslist"
                className="h-[112px] w-full rounded-lg flex justify-center bg-white"
              >
                <div className="my-auto">
                  <div className="flex justify-center">
                    <div className="flex justify-center items-center">
                      <i className="fi fi-rs-inbox-out text-[16px] text-offblack"></i>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <AuxiliaryText
                      variant={"Placeholder"}
                      className={"mt-1 text-primaryblue"}
                    >
                      Click to upload
                    </AuxiliaryText>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className="mt-10 w-full">
            <div className="flex gap-3 w-full">
              <i className="fi fi-br-picture text-[30px] lg:text-[50px] text-primaryblue mt-auto"></i>
              <div className="w-full flex flex-col gap-2 mt-5">
                <div className="flex items-center justify-between w-full">
                  <AuxiliaryText variant={"FieldLabel"}>
                    myprofile.png
                  </AuxiliaryText>

                  <AuxiliaryText variant={"FieldLabel"}>
                    {completion}%
                  </AuxiliaryText>
                </div>
                <div className="w-full h-1.5 bg-pastalblue rounded-full">
                  <div
                    className={`h-full bg-primaryblue rounded-full`}
                    style={{ width: `${completion}%` }}
                  ></div>
                </div>
              </div>
              <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
            </div>
          </div>
          <div className="flex justify-end mt-10">
            <MainButton
              variant={"small"}
              onClick={() => {
                setStep(2);
                setTimeout(() => navigate("/HelpCentre"), 3000);
              }}
            >
              Submit
            </MainButton>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div className="w-fit mx-auto mt-20">
            <Heading variant={"h6"} className={"text-offblack"}>
              Submission received - thank you!
            </Heading>
            <Paragraph
              variant={"MainParagraph"}
              className={"text-offblack mt-3"}
            >
              Your submission has been successfully received.
            </Paragraph>
          </div>
        </>
      )}
    </>
  );
};

export default GeneralInquiry;
