import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "./Style.css";
import photos from "./photos";
import Popup from "../../components/popup/Popup";
import { PhotoAlbum } from "react-photo-album";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import CircularButton from "../../components/common/MainButton/CircularButton";

const Portfolios = () => {
  const [ShowPreview, setShowPreview] = useState(false);
  const [ImageIndex, setImageIndex] = useState("0");

  const ImagePreviewHandler = (e, index) => {
    console.log("index", index);
    // var imag  =  e.target.currentSrc
    setImageIndex(index);
    setShowPreview(!ShowPreview);
  };
  useEffect(() => {
    if (ShowPreview) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [ShowPreview]);

  return (
    <>
      <div className={"text-center md:text-start"}>
        <SecondaryHeading
          variant={"MainParagraphHeading"}
          className={"text-offblack"}
        >
          Portfolio
        </SecondaryHeading>
      </div>
      {ShowPreview && (
        <ImagePreview
          currentIndex={ImageIndex}
          photos={photos}
          setShowPreview={setShowPreview}
        />
      )}
      <div className="mt-5">
        <PhotoAlbum
          // style= {}
          layout="columns"
          photos={photos}
          onClick={(e) => ImagePreviewHandler(e, e?.index)}
        />
      </div>
    </>
  );
};

export default Portfolios;

const ImagePreview = ({ photos, currentIndex, setShowPreview }) => {
  const [showSlider, setShowSlider] = useState(true);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    setShowSlider(false);
    setTimeout(() => {
      setShowSlider(true);
    }, 50);
  }, [currentIndex]);
  const swiperRef = useRef();
  return (
    <>
      {showSlider && (
        <Popup setTrigger={setShowPreview} ShowCrossButton={ShowCrossButton}>
          <>
          <div className="flex items-center justify-center mt-10 rounded-3xl overflow-y-auto hidden-scrollbar pt-[270px] md:pt-0 pb-[850px] md:pb-0 mx-auto w-[350px] sm:w-[550px] h-screen md:h-[80vh] lg:w-[80vw] lg:h-[80vh] xl:w-[150vh] xl:h-[80vh] 3xl:w-[160vh] 3xl:h-[90vh] md:my-auto">
              <div>
                <Swiper
                  initialSlide={currentIndex}
                  navigation={false}
                  loop
                  speed={1000}
                  className="product-images-slider rounded-3xl mx-auto w-[300px] h-[400px] sm:w-[550px] sm:h-[650px] md:w-[90vh] md:h-[80vh] lg:w-[80vw] lg:h-[70vh] xl:w-[150vh] xl:h-[70vh] 3xl:w-[160vh] 3xl:h-[75vh]"
                  spaceBetween={32}
                  modules={[Pagination, Navigation]}
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {photos?.map((item, index) => {
                    return (
                      <>
                        <SwiperSlide className="swiper-slide">
                          <img
                            className="cursor-pointer rounded-3xl object-cover w-full h-full"
                            src={item?.src}
                            alt="product images"
                          />
                        </SwiperSlide>
                      </>
                    );
                  })}
                </Swiper>
                <div className="flex items-center justify-center gap-x-2 mt-5">
                  <CircularButton
                    variant={"prev"}
                    onClick={() => swiperRef.current?.slideNext()}
                  ></CircularButton>
                  <CircularButton
                    variant={"next"}
                    onClick={() => swiperRef.current?.slidePrev()}
                  ></CircularButton>
                </div>
              </div>
            </div>
          </>
        </Popup>
      )}
    </>
  );
};
