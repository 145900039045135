import React from "react";
import SecondaryHeading from "../../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../../components/common/Typography/Paragraph";

const CustNotificationsSettings = () => {
  const Notificationdata = [
    {
      heading: "Account security",
      description:
        "Notifications for login alerts, security updates, and password changes.",
      status: "Enabled",
    },
    {
      heading: "Interaction alerts",
      description:
        "Updates about activity on your interactions with businesses and services.",
      status: "Enabled",
    },
    {
      heading: "Business messages",
      description: "Alerts for messages and replies from businesses.",
      status: "Enabled",
    },
    {
      heading: "New content and services",
      description:
        "Stay updated on new services and content from businesses you follow.",
      status: "Enabled",
    },
    {
      heading: "Support updates",
      description: "Quick updates on your support inquiries.",
      status: "Enabled",
    },
    {
      heading: "Review feedback",
      description: "Feedback on your reviews from businesses.",
      status: "Enabled",
    },
    {
      heading: "Service recommendations",
      description:
        "Personalized suggestions based on your interests.",
      status: "Enabled",
    },
    {
      heading: "Platform updates",
      description: "News, features, and important announcements from trades poster.",
      status: "Enabled",
    },
    {
      heading: "Privacy notifications",
      description:
        "Information about changes to your privacy settings and data rights.",
      status: "Enabled",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-12 w-[300px] sm:w-[450px] md:w-[500px] 2xl:w-[600px] 3xl:w-[700px] 2k:w-[800px] 4k:w-[900px] mx-auto">
        <div className="col-span-12">
          {Notificationdata.map((item, index) => {
            return (
              <>
                <div className="flex justify-between gap-x-5 mb-10">
                  <div>
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      {item.heading}
                    </SecondaryHeading>
                    <Paragraph variant={"MainParagraph"} className={"mt-2"}>
                      {item.description}
                    </Paragraph>
                  </div>
                  <div>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer ml-5"
                      />
                      <div className="w-9 h-5 bg-lightgray rounded-full peer-checked:after:translate-x-full after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-lightgray after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-primaryblue"></div>
                    </label>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CustNotificationsSettings;
