import React from "react";
import SpecialText from "../Typography/SpecialText";

const Title = ({className,text, children, width }) => {
  return (
    <>
      <div className="group/title">
        <div className="hidden group-hover/title:block absolute ">
          <div className="flex">
            <div
              className={`bg-offblack text-white ml-2 md:ml-8 -mt-5 flex items-center justify-center w-[${width}px] px-2 py-1 z-10`}
            >
              <SpecialText variant={"FootNoteDisclaimer"}>{text}</SpecialText>
            </div>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};

export default Title;
