import React from "react";
import Account from "../../../sections/BusinessDashboard/Settings/Account";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";

const Accounts = () => {
  return (
    <>
      <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center text-offblack"}>
          Manage account
        </Heading>
        <div className="flex justify-center mt-[60px] min-h-screen">
          <Account />
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default Accounts;
