import React, { useEffect } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import useAuth from "../../../Hooks/useAuth";
import VerificationPopUp from "../../VerificationPop/VerificationPopUp";
import Popups from "../../../components/popup/Popups";
import TextFiled from "../../../components/common/InputField/TextFiled";

const BusinessCont = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [verificationmobile, setVerificationmobile] = useState(false);
  const [verificationemail, setVerificationemail] = useState(false);
  const ServieTagList = useSelector((tags) => tags.ServiceTagsReducer);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  const ListCategory = useSelector((cat) => cat.BusinessCategoryReducer)?.map(
    (item) => {
      return {
        value: item.id,
        label: item.businessCategoryName,
      };
    }
  );
  const BusinessServiceHandler = (e) => {
    let list = [];
    for (let i = 0; i < e.target.value.length; i++) {
      let tags = ServieTagList?.filter(
        (item) => item.businessCategoryId === e.target.value[i]
      )?.map((d) => {
        return d.tagName;
      });

      list = [...list, ...tags];
    }
  };
  useEffect(() => {
    if (verificationmobile || verificationemail ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [verificationmobile, verificationemail]);
  return (
    <>
      {/* Mobile Number */}

      <div className="grid grid-cols-12 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto">
        <div className="col-span-12 ">
          <div>
            <TextFiled
              className={"bg-pastalblue"}
              variant={"large"}
              label={"Mobile number"}
              placeholder={"Mobile number"}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              className={"bg-pastalblue"}
              variant={"large"}
              label={"New mobile number"}
              placeholder={"Enter new mobile number"}
            />
          </div>
          <div className="flex items-center justify-end mt-10">
            <MainButton
              variant={"small"}
              onClick={() => setVerificationmobile(!verificationmobile)}
            >
              Verify
            </MainButton>
          </div>
          {verificationmobile && (
            <Popups
              setTrigger={() => setVerificationmobile(false)}
              ShowCrossButton={ShowCrossButton}
              popupWidth={"w-[350px] h-[500px] sm:w-[600px] sm:h-[500px] 2xl:w-[600px] 2xl:h-[500px] pb-10"}
              crossPosition={
                "flex justify-end w-[16px] absolute left-[320px] sm:left-[565px] top-3"
              }
            >
              <VerificationPopUp
                closePopup={() => setVerificationmobile(false)}
                firstMainHeading={"Verify your business mobile number"}
                label={"Varification code"}
                placeholder={"Input the 5-digit code here."}
                firstP={
                  "To enhance the security of your business account, please verify your mobile number."
                }
                secondMainHeading={"Mobile number verified"}
                secondP={
                  "Congratulations! Your business mobile number is now verified, ensuring secure communication and account integrity. This verification is essential for prompt notifications and updates. Thank you for completing this vital step."
                }
                smallPara={
                  "Verifying your mobile number is a crucial step in securing your business account and ensuring smooth communication."
                }
                Resend={"Send a new code"}
                SecondButton={"Verify your number"}
              />
            </Popups>
          )}
        </div>
      </div>

      {/* Office Number */}

      <div className="grid grid-cols-12 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-10">
        <div className="col-span-12 ">
          <div>
            <TextFiled
              className={"bg-pastalblue"}
              variant={"large"}
              label={"Office number"}
              placeholder={"Office number"}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              className={"bg-pastalblue"}
              variant={"large"}
              label={"New office number"}
              placeholder={"Enter new office number"}
            />
          </div>
          <div className="flex items-center justify-end mt-10 ">
            <MainButton variant={"small"}>Save</MainButton>
          </div>
        </div>
      </div>

      {/* Business email */}

      <div className="grid grid-cols-12 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-10">
        <div className="col-span-12 ">
          <div>
            <TextFiled
              className={"bg-pastalblue"}
              variant={"large"}
              label={"Business email"}
              placeholder={"Business email"}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              className={"bg-pastalblue"}
              variant={"large"}
              label={"New business email"}
              placeholder={"Enter new business email"}
            />
          </div>
          <div className="flex items-center justify-end mt-10">
            <MainButton
              variant={"small"}
              onClick={() => setVerificationemail(!verificationemail)}
            >
              Verify
            </MainButton>
          </div>
          {verificationemail && (
            <Popups
              setTrigger={() => setVerificationemail(false)}
              ShowCrossButton={ShowCrossButton}
              popupWidth={"w-[350px] h-[500px] sm:w-[600px] sm:h-[500px] 2xl:w-[600px] 2xl:h-[500px] pb-10"}
              crossPosition={
                "flex justify-end w-[16px] absolute left-[320px] sm:left-[565px] top-3"
              }
            >
              <VerificationPopUp
                closePopup={() => setVerificationemail(false)}
                firstMainHeading={"Confirm your business email address"}
                label={"Varification code"}
                placeholder={"Enter the code here."}
                firstP={
                  "Please verify your business email to ensure you receive all important communications and updates."
                }
                secondMainHeading={"Email address confirmed"}
                secondP={
                  "Great job! Your business email address has been successfully verified. Now you're all set to receive timely updates and alerts directly in your inbox."
                }
                smallPara={
                  "Email verification helps maintain your account's security and allows you to recover your account if needed."
                }
                Resend={"Get a new verification code"}
              />
            </Popups>
          )}
        </div>
      </div>

      {/* Business Website */}

      <div className="grid grid-cols-12 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-10">
        <div className="col-span-12 ">
          <div>
            <TextFiled
              className={"bg-pastalblue"}
              variant={"large"}
              label={"Website"}
              placeholder={"Website.com"}
            />
          </div>
          <div className="mt-5">
            <TextFiled
              className={"bg-pastalblue"}
              variant={"large"}
              label={"New website"}
              placeholder={"Enter new website "}
            />
          </div>
          <div className="flex items-center justify-end mt-10">
            <MainButton variant={"small"}>Save</MainButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessCont;
