import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import InteractiveText from "../../components/common/Typography/InteractiveText";

const FAQs = () => {
  const faqs = [
    {
      question: "What is trades poster? ",
      answer:
        "Trades Poster is a platform that connects customers with trades businesses. It offers only trades services with more than 38 different trades categories, allowing customers to easily find the service they need, while prioritizing the connection of customers with local businesses. Trades Poster's platform features advanced algorithms that filter out and display the most relevant and suitable trades service providers in a customer's local area which is default 25km from your approximate location. ",
    },
    {
      question: "Do I need to pay trades poster to create account? ",
      answer:
        "Signing up is free for all Customer and businesses. You just need to be over 18 and comply with Terms and Conditions. Our sign-up process is quick and secure. You can sign up using your email address, or with exciting Facebook and Google account. ",
    },
    {
      question: "How to Contact Trades Poster? ",
      answer:
        "  If you have any questions or concerns, the easiest way to do so is by visiting our contact us page on the Trades Poster website.  ",
    },
    {
      question: "How to signup business account on trades poster? ",
      answer:
        "To sign up for a business account on Trades Poster, please visit the sign up page on website ",
    },
    {
      question:
        "Are my business or personal details are secured on trades poster? ",
      answer:
        "Yes, your business and personal details are secured on trades poster. We use industry-standard security measures to protect your information and ensure that it is kept confidential. ",
    },
    {
      question: "Do I get a tax invoice? ",
      answer:
        "Yes, you will receive a tax invoice for your payment with Trades Poster on each subscription billing cycle. This invoice will be sent to the email address associated with your account. ",
    },
  ];
  return (
    <>
      <div>
        <SecondaryHeading
          variant={"MainParagraphHeading"}
          className={"flex justify-start text-offblack"}
        >
          Business FAQs
        </SecondaryHeading>
        <div>
          {faqs.map((item) => {
            return (
              <>
                <div className="mt-5">
                  <div>
                    <InteractiveText
                      variant={"FAQHeader"}
                      className={"text-offblack"}
                    >
                      {item.question}
                    </InteractiveText>
                  </div>
                  <div className="mt-2">
                    <InteractiveText
                      variant={"FAQAnswer"}
                      className={"text-offblack"}
                    >
                      {item.answer}
                    </InteractiveText>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FAQs;
