import React from "react";
import { useSelector } from "react-redux";
import Heading from "../../components/common/Typography/Heading";
import PricingSlider from "../BusinessDashboard/Subscriptions/PricingPlans/PricingSlider";
import PriceCard from "../../components/common/PriceCard/PriceCard";

const HeroImage = () => {
  const getFreePackageDetail = useSelector((plan) =>
    plan?.PricingPlanReducer.find((item) => item.name === "FREE")
  );
  const getBusinessPackageDetail = useSelector((plan) =>
    plan?.PricingPlanReducer.find((item) => item.name === "BUSINESS")
  );
  const getPremiumPackageDetail = useSelector((plan) =>
    plan?.PricingPlanReducer.find((item) => item.name === "PREMIUM")
  );
  return (
    <>
      <div className="bg-about bg-no-repeat bg-center object-cover bg-cover h-[1000px] lg:h-screen">
        <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] pt-20 2xl:pt-28">
          <Heading
            variant={"h1"}
            className={"text-center text-white"}
          >
            Choose Your Perfect Plan
          </Heading>
        </div>
        <div className="flex justify-center lg:hidden mt-10 md:mt-20 mx-auto w-full sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px]">
          <PricingSlider />
        </div>
        <div className="hidden lg:grid grid-cols-3 gap-8 w-fit mx-auto lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] mt-10 xl:mt-20">
          {/* Free Card */}
          <div className="col-span-3 md:col-span-1 lg:col-span-1">
            <PriceCard
              bottomLine={"border-b border-b-lightgray pb-5"}
              MainDiveClasses={
                "bg-white text-offblack w-full h-[500px] xl:h-[500px] 2xl:w-[400px] 2xl:h-[600px] 3xl:w-[450px]"
              }
              pricingColor={"text-offblack"}
              tagging={true}
              label={"Free"}
              price={"0"}
              duration={1}
              description={
                "Perfect for new ventures ready to connect with customers. Start with Trades Poster, create your profile, no fees attached."
              }
              buttonText={"Get started"}
              listItems={[
                `Business Description`,
                `Profile logo`,
                `Live chat`,
                // `Business ABN details`,
                // `Cover picture`,
                `1 Regular post`,
                `1 Keyword`,
              ]}
              // listItemsValue={[
              //   `${getPremiumPackageDetail?.regularListing}`,
              //   `${getPremiumPackageDetail?.featureListing}`,
              //   `${getPremiumPackageDetail?.noOfImages}`,
              // ]}
            />
          </div>
          {/* Standard Card */}
          <div className="col-span-3 md:col-span-1 lg:col-span-1">
            <PriceCard
              bottomLine={"border-b border-b-lightgray pb-5"}
              MainDiveClasses={
                "bg-white text-offblack w-full h-[500px] xl:h-[500px] 2xl:w-[400px] 2xl:h-[600px] 3xl:w-[450px]"
              }
              pricingColor={"text-offblack"}
              tagging={true}
              label={"Standard"}
              price={"0"}
              duration={1}
              description={
                "Best for businesses wanting more visibility and trust. The Standard plan adds powerful features to engage and grow."
              }
              buttonText={"Get started"}
              listItems={[
                `Includes all free plan features`,
                `Portfolio gallery`,
                // `multiple category selection`,
                // `Business associations`,
                `5 Keywords`,
                `5 Featured posts`,
                // `8 Portfolio pictures`,
                // `3 Post pictures`,
                `Unlimited regular posts`,
              ]}
              // listItemsValue={[
              //   `${getPremiumPackageDetail?.regularListing}`,
              //   `${getPremiumPackageDetail?.featureListing}`,
              //   `${getPremiumPackageDetail?.noOfImages}`,
              // ]}
            />
          </div>
          {/* Premium Card */}
          <div className="col-span-3 md:col-span-1 lg:col-span-1">
            <PriceCard
              bottomLine={"border-b border-b-lightgray pb-5"}
              MainDiveClasses={
                "bg-white text-offblack w-full h-[500px] xl:h-[500px] 2xl:w-[400px] 2xl:h-[600px] 3xl:w-[450px]"
              }
              pricingColor={"text-offblack"}
              label={"Premium"}
              price={getPremiumPackageDetail?.price ?? ""}
              duration={1}
              description={
                "Ideal for businesses seeking to lead and expand reach. The Premium plan offers complete solutions for dynamic growth."
              }
              buttonText={"Get started"}
              listItems={[
                `Includes all standard plan features`,
                // `Business Trading Hours`,
                `Business contact details`,
                // `highlighted services`,
                // `accepted payment methods`,
                // `social profile link`,
                // `business website link`,
                // `personalized business FAQs`,
                `10 Keywords`,
                `20 Featured posts`,
                // `20 portfolio pictures`,
                // `8 post pictures`,
                `Post metrics`,
                `Unlimited regular posts`,
              ]}
              // listItemsValue={[
              //   `${getPremiumPackageDetail?.regularListing}`,
              //   `${getPremiumPackageDetail?.featureListing}`,
              //   `${getPremiumPackageDetail?.noOfImages}`,
              // ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroImage;
