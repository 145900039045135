import React from "react";
import blogs from "../../../assets/img/default-image.jpg";
import { Link } from "react-router-dom";
import SecondaryHeading from "../Typography/SecondaryHeading";
import Paragraph from "../Typography/Paragraph";
import ButtonText from "../Typography/ButtonText";

const BlogCard = ({ text, blogName, link }) => {
  return (
    <>
      <div className={"w-[350px] sm:w-full md:w-[300px] lg:w-full xl:w-[345px] 2xl:w-[400px] 3xl:w-full relative"}>
        <img
          className="cursor-pointer object-center w-full h-[420px] lg:h-[400px] xl:h-[450px] 2xl:h-[550px] 4k:h-[600px] rounded-3xl"
          src={blogs}
          alt="product images"
        />
        <div className="text-offblack bg-transparent">
          <SecondaryHeading variant={"MainParagraphHeading"} className={"mt-5"}>
            {blogName}
          </SecondaryHeading>
          <Paragraph
            variant={"MainParagraph"}
            className={"h-auto overflow-hidden text-ellipsis mt-3"}
          >
            {text}
          </Paragraph>
          <Link
            to={"/blogs/article-id-will-be-here"}
            // to={link}
          >
            <div class="group relative border-b-2 border-lightgray cursor-pointer w-[67px] lg:w-[70px] xl:w-[70px] 2xl:w-[74px] mt-4">
              <ButtonText variant={"SecondaryText"} className={"-mb-5"}>
                Read more
              </ButtonText>
              <span class="absolute -bottom-[2px] left-0 w-0 border-b-2 border-primaryblue transition-all duration-100 group-hover:w-[68px] lg:group-hover:w-[71px] xl:group-hover:w-[72px] 2xl:group-hover:w-[75px]" />
                
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
