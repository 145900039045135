import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DefaultImage from "../../assets/img/default-image.jpg";
import AdsCard from "../../components/common/AdsCard/AdsCard";
// import MainButton from "../../components/common/MainButton/MainButton";
import useAuth from "../../Hooks/useAuth";
const ServiceCards = ({ addType, Services }) => {
  function useParams() {
    return new URLSearchParams(useLocation().search);
  }
  const params = useParams();
  const CategoryId = params.get("id");

  const [FilterServices, setFilterServices] = useState(Services);
  const { auth } = useAuth();
  useEffect(() => {
    if (!auth) {
      let FilterServices = Services?.filter((e) => e?.categoryId?.toString())
      setFilterServices(FilterServices);
    } else {
      let FilterServices = Services?.filter((e) =>
        e?.listingCategory?.id?.toString()
      );
      setFilterServices(FilterServices);
    }
  }, [CategoryId, Services, auth]);

  return (
    <>
      {FilterServices?.map((e, index) => {
        const ListImage = JSON.parse(e?.pictures);
        return (
          <AdsCard
            key={index}
            serviceName={e?.listingTitle}
            serviceDescription={e?.description}
            category={e?.category ?? e?.listingCategory?.businessCategoryName}
            type={e?.listType}
            Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
            rating={e?.listRating}
            companyName={e?.companyName ?? e?.userProfile?.company?.companyName}
            companyLogo={e?.companyLogo ?? e?.userProfile?.company?.companyLogo}
            serviceId={e?.listingId ?? e.id}
            companyId={e?.companyId ?? e?.userProfile?.company?.id}
            reaction={e?.listReaction}
          />
        );
      })}

      {/* {FilterServices?.length > 15 && (
          <div className="flex justify-center items-center">
            <MainButton
              variant={"filled"}
              className="min-h-[50px] min-w-[300px] text-4xl"
            >
              Load More
            </MainButton>
          </div>
        )} */}
    </>
  );
};

export default ServiceCards;
