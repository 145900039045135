import HeroImage from "../sections/Pricing/HeroImage";
import PackageDetailsTable from "../sections/Pricing/PackageDetailsTable";

const Pricing = () => {
  return (
    <>
      <HeroImage />
      <PackageDetailsTable />
    </>
  );
};

export default Pricing;
