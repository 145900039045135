import React from "react";
import Heading from "../../components/common/Typography/Heading";
import Paragraph from "../../components/common/Typography/Paragraph";
import Percentaging from "./Percentaging";

const RighText = ({ heading, text }) => {
  return (
    <>
      <div className="mt-40 xl:mt-40 2xl:mt-40">
        <div className="mx-auto flex flex-col lg:flex-row items-center gap-x-16 w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
          <div className="order-1 shrink-0 w-full sm:w-[550px] md:w-[650px] lg:w-1/2">
            <img
              className="object-cover w-full h-auto aspect-square rounded-3xl shrink-0"
              src={require("../../assets/img/TradesPosterSpeciality/businesses.jpg")}
              alt="trade-poster"
            />
            <div className="hidden lg:block">
              <Percentaging
                number={70}
                textColor={"text-softpurple"}
                text={"More profile visits"}
              />
            </div>
          </div>
          <div className="mt-10 lg:my-auto order-2">
            <div>
              <Heading variant={"h2"} className={"text-offblack"}>
                {heading}
              </Heading>
              <Paragraph
                variant={"MainParagraph"}
                className={"mt-3 text-offblack"}
              >
                {text}
              </Paragraph>
              <div className="lg:hidden">
                <Percentaging
                  number={70}
                  textColor={"text-softpurple"}
                  text={"More profile visits"}
                />
              </div>
              <Percentaging
                number={50}
                textColor={"text-freshgreen"}
                text={"More messages from customers"}
              />
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RighText;
