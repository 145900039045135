import useAuth from "../../Hooks/useAuth";

function generateRandomNumber() {
  return Math.random() * 99999;
}
const useSidebarData = (props) => {
  const { setAuth } = useAuth();
  return [
    {
      icon: (
        <i
          className="fi fi-rs-chart-user text-base cursor-pointer hover:text-primaryblue"
        
        ></i>
      ),
      linkName: "Profile overview",
      link: "/poster-profile",
    },
    {
      icon: (
        <i className="fi fi-rs-dashboard text-base hover:text-primaryblue"></i>
      ),
      iconSelected: (
        <i className="fi fi-rs-dashboard text-base text-primaryblue"></i>
      ),
      linkName: "Dashboard",
      link: "/business-dashboard/dashboard",
    },
    {
      icon: (
        <i className="fi fi-rs-rectangle-list text-base hover:text-primaryblue"></i>
      ),
      iconSelected: (
        <i className="fi fi-rs-rectangle-list text-base text-primaryblue"></i>
      ),
      linkName: "Post",
      subMenu: [
        {
          icon: (
            <i className="fi fi-rs-add-document text-base hover:text-primaryblue"></i>
          ),
          linkName: "Create post",
          link: "/business-dashboard/posts/create-post",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-code-pull-request-draft text-base hover:text-primaryblue"></i>
          ),
          linkName: "Drafts",
          link: "/business-dashboard/posts/drafts",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-shop text-base hover:text-primaryblue"></i>
          ),
          linkName: "Published",
          link: "/business-dashboard/posts/published",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-trash-clock text-base hover:text-primaryblue"></i>
          ),
          linkName: "Bin",
          link: "/business-dashboard/posts/bin",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      icon: (
        <i className="fi fi-rs-box-up text-base hover:text-primaryblue"></i>
      ),
      iconSelected: (
        <i className="fi fi-rs-box-up text-base text-primaryblue"></i>
      ),
      linkName: "Subscription",
      subMenu: [
        {
          icon: (
            <i className="fi fi-rs-tags text-base hover:text-primaryblue"></i>
          ),
          linkName: "Pricing plans",
          link: "/business-dashboard/subscriptions/pricing-plans",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-file-invoice-dollar text-base hover:text-primaryblue"></i>
          ),
          linkName: "Billing",
          link: "/business-dashboard/subscriptions/billing-details",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      icon: (
        <i className="fi fi-rs-settings text-base hover:text-primaryblue"></i>
        
      ),
      iconSelected: (
        <i className="fi fi-rs-settings text-base text-primaryblue"></i>
        
      ),
      linkName: "Settings",
      subMenu: [
        {
          icon: (
            <i className="fi fi-rs-user text-base hover:text-primaryblue"></i>
          ),
          linkName: "Account",
          link: "/business-dashboard/settings/account",
          id: generateRandomNumber(),
        },
        {
          icon: (
        
            <i className="fi fi-rs-briefcase text-base hover:text-primaryblue"></i>
          ),
          linkName: "Portfolio",
          link: "/business-dashboard/settings/manage-portfolio",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-diploma text-base hover:text-primaryblue"></i>
          ),
          linkName: "Certifications",
          link: "/business-dashboard/settings/certifications",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-animated-icon text-base hover:text-primaryblue"></i>
          ),
          linkName: "Associations",
          link: "/business-dashboard/settings/associations",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-rss text-base hover:text-primaryblue"></i>
          ),
          linkName: "Social profiles",
          link: "/business-dashboard/settings/social-profiles",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-form text-base hover:text-primaryblue"></i>
          ),
          linkName: "Business contact",
          link: "/business-dashboard/settings/business-contact",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-calendar-clock text-base hover:text-primaryblue"></i>
          ),
          linkName: "Trading hours ",
          link: "/business-dashboard/settings/trading-hours",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-bank text-base hover:text-primaryblue"></i>
          ),
          linkName: "Payment method",
          link: "/business-dashboard/settings/payment-options",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-bell text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Notifications",
          link: "/business-dashboard/settings/notification-preferences",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-key-skeleton-left-right text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Reset password",
          link: "/business-dashboard/settings/reset-password",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      icon: (
        <i className="fi fi-rs-comment-alt-dots text-base  hover:text-primaryblue"></i>
      ),
      iconSelected: (
        <i className="fi fi-rs-comment-alt-dots text-base text-primaryblue"></i>
      ),
      linkName: "Inbox",
      subMenu: [
        {
          icon: (
            <i className="fi fi-rs-comment-alt-dots text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Messages",
          link: "/business-dashboard/inbox/chats",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-bell text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Notifications",
          link: "/business-dashboard/inbox/notifications",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-star text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Reviews",
          link: "/business-dashboard/inbox/reviews",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      icon: (
        <i className="fi fi-rs-exclamation text-base hover:text-primaryblue"></i>
      ),
      iconSelected: (
        <i className="fi fi-rs-exclamation text-base text-primaryblue"></i>
      ),
      linkName: "Support",
      subMenu: [
        {
          icon: <i className="fi fi-rs-info text-base hover:primaryblue"></i>,
          linkName: "About",
          link: "/about-us",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-blog-pencil text-base hover:text-primaryblue"></i>
          ),
          linkName: "Blog",
          link: "/Blogs",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-life-ring text-base hover:text-primaryblue"></i>
          ),
          linkName: "Help centre",
          link: "/HelpCentre",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-time-fast text-base hover:text-primaryblue"></i>
          ),
          linkName: "Terms & Conditions",
          link: "/terms-&-conditions",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-incognito text-base hover:text-primaryblue"></i>
          ),
          linkName: "Privacy Policy",
          link: "/privacy-policy",
          id: generateRandomNumber(),
        },
      ],
    },

    {
      icon: <i className="fi fi-rs-exit rotate-180 text-base hover:text-primaryblue"></i>,
      linkName: "Log out",
      link: "/",
      onClick: () => {
        localStorage.removeItem("LoginSession");
        if (props?.toggleDrawer) {
          props.toggleDrawer();
        }
        setAuth({
          user: null,
          roles: null,
          accessToken: "",
        });
      },
      id: generateRandomNumber(),
    },
  ];
};

export default useSidebarData;
