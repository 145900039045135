import React from "react";
import MainContainer from "../sections/HelpCenter/MainContainer";

const HelpCenter = () => {
  return (
    <>
      <MainContainer />
    </>
  );
};

export default HelpCenter;
