import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainButton from "../../components/common/MainButton/MainButton";
import RatingStars from "../../components/common/RatingStars/RatingStars";
import useAuth from "../../Hooks/useAuth";
// import { useServiceReviews } from "../../Hooks/useServices";
import Certifications from "./Certifications";
import Associations from "./Associations";
import Social from "./Social";
import Payment from "./Payment";
import TradingHours from "./TradingHours";
import BusinessContact from "./BusinessContact";
import ServicesTags from "./ServicesTags";
import PosterReviews from "./PosterReviews";
import UserGigs from "./UserGigs";
import UserDescription from "./UserDescription";
import Portfolios from "../Portfolio/Portfolios";
import Popup from "../../components/popup/Popup";
import BusinessReport from "../../components/common/ReportForm/BusinessReport";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import ProfileComponent from "../../components/common/Profile/ProfileComponent";
import Heading from "../../components/common/Typography/Heading";
import RatingOverview from "./RatingOverview";
import ServiceHighlights from "./ServiceHighlights";
import FAQs from "./FAQs";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import Title from "../../components/common/Title/Title";
import QoutingForms from "../ServiceDetails/QouteForms/QoutingForms";
import Popups from "../../components/popup/Popups";
import SpecialText from "../../components/common/Typography/SpecialText";

const ProfileMainContainer = () =>
  // {
  // data,
  // ListingId,
  // AuthnicateData,
  // listRating,
  // tagsList,
  // showMeReply,
  // }
  {
    const [report, setReport] = useState(false);
    const [ShowCrossButton, setShowCrossButton] = useState(true);
    const [qouting, setQouting] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const handleFileInputChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setSelectedFile(file);

        // Generate a preview URL for the selected image
        const reader = new FileReader();
        reader.onload = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    // const getReactionInfo = AuthnicateData?.data?.$values.find(
    //   (e) => e.id?.toString() === ListingId.toString()
    // );
    // const listingInfo = data;
    // const [heartClick, setheartClick] = useState(getReactionInfo?.listReaction);
    // const [SignUpState, setSignUpState] = React.useState(false);
    // const [SignIn, setSignIn] = React.useState(false);
    // const ListImage = JSON?.parse(listingInfo.pictures);
    // const Images = ListImage?.map((e) => {
    //   return { original: e, thumbnail: e };
    // });
    // const { data: reviewsData, status } = useServiceReviews(ListingId);
    // const [ReviewsList, setReviewsList] = useState();
    // useEffect(() => {
    //   const sortOrderByDescbyRating = (List) => {
    //     let sortedDsc = List?.sort((a, b) => {
    //       return parseInt(b.starRating) - parseInt(a.starRating);
    //     });
    //     return sortedDsc;
    //   };
    //   setReviewsList(sortOrderByDescbyRating(reviewsData?.data?.key?.$values));
    // }, [ListingId, reviewsData]);

    // const ReviewHandler = () => {
    //   if (!auth?.user) {
    //     setSignIn(true);
    //   } else {
    //     navigate({
    //       pathname: "/WriteReview/",
    //       search: `?${createSearchParams({
    //         id: ListingId,
    //       })}`,
    //     });
    //   }
    // };
    const navigate = useNavigate();
    const { auth } = useAuth();
    const ReviewItems = [
      {
        text: "Communication",
      },
      {
        text: "Reliability",
      },
      {
        text: "Satisfaction",
      },
      {
        text: "Recommend",
      },
      {
        text: "Punctuality",
      },
    ];
    useEffect(() => {
      if (report || qouting) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
        document.body.style.width = `auto`;
      }
    }, [report, qouting]);
    return (
      <>
        <div className="mx-auto w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] pt-24">
          <div className="w-[350px] h-[300px] sm:w-[550px] sm:h-[400px] md:w-[650px] md:h-[450px] lg:w-[900px] lg:h-[500px] xl:w-[1100px] xl:h-[600px] 2xl:w-[1264px] 2xl:h-[700px] 3xl:w-[1400px] 3xl:h-[800px] 2k:w-[1600px] 2k:h-[900px] 4k:w-[1800px] 4k:h-[1000px] mx-auto group relative">
            {auth?.user && (
              <div className="hidden group-hover:block absolute z-20 top-2 right-2 w-fit h-fit">
                <div className="flex items-center justify-center bg-primaryblue w-[30px] h-[30px] rounded-full z-10">
                  <input
                    type="file"
                    accept="image/*" // Accept only image files
                    onChange={handleFileInputChange}
                    id="imageslist"
                    className="cursor-pointer hidden"
                  />
                  <label
                    role="button"
                    htmlFor="imageslist"
                    className="flex justify-center bg-transparent"
                  >
                    <Title text={"Edit"} width={"50"} className={"bg-white"}>
                      <i className="fi fi-rs-pen-circle text-xs text-white"></i>
                    </Title>
                  </label>
                </div>
              </div>
            )}
            {selectedFile && (
              <img
                src={imagePreview || URL.createObjectURL(selectedFile)}
                className="w-full h-full object-cover rounded-3xl"
                alt="tradesposter"
              />
            )}
            {!selectedFile && (
              <img
                src={require("../../assets/img/P5.jpeg")}
                className="w-full h-full object-cover rounded-3xl"
                alt="tradesposter"
              />
            )}
          </div>
          <div className="flex justify-center md:justify-between -mt-12 px-[30px]">
            <div className="w-fit md:w-[300px]">
              <ProfileComponent
                reporting={() => setReport(true)}
                heading={"ABC Company PVT LTD"}
              />
              {report && (
                <Popup setTrigger={setReport} ShowCrossButton={ShowCrossButton}>
                  <BusinessReport closePopup={() => setReport(false)} />
                </Popup>
              )}
            </div>
            <div className="hidden lg:block">
              <RatingOverview />
            </div>
          </div>
          <div className="mt-5 flex items-center justify-center md:hidden">
            <MainButton
              variant={"outlinelarge"}
              type="button"
              onClick={() => {
                auth?.user ? setQouting(true) : navigate("/login");
              }}
            >
              Get in touch
            </MainButton>
            {qouting && (
              <Popups
                setTrigger={setQouting}
                ShowCrossButton={ShowCrossButton}
                popupWidth={
                  "w-[350px] sm:w-[550px] md:w-[550px] md:h-[600px] 2xl:w-[550px] 2xl:h-[650px] pb-10"
                }
                crossPosition={
                  "flex justify-end w-4 absolute left-[320px] sm:left-[520px] top-2"
                }
              >
                <QoutingForms closePopup={() => setQouting(false)} />
              </Popups>
            )}
          </div>
          <div className="mt-3 mb-10 flex items-center justify-center md:hidden">
            <MainButton variant={"large"}>ABN&nbsp;12 456 789 532</MainButton>
          </div>
          <div className="grid grid-cols-12 mt-10">
            <div className="md:col-start-1 col-span-12 md:col-span-3 w-[350px] sm:w-[550px] md:w-[200px] lg:w-[200px] xl:w-[300px] order-2 md:order-1 mt-20 md:mt-0">
              <div className="flex flex-col">
                <div className="md:flex justify-center mb-5 hidden">
                  <MainButton
                    variant={"large"}
                    type="button"
                    onClick={() => {
                      auth?.user ? setQouting(true) : navigate("/login");
                    }}
                  >
                    Get in touch
                  </MainButton>
                  {qouting && (
                    <Popups
                      setTrigger={setQouting}
                      ShowCrossButton={ShowCrossButton}
                      popupWidth={
                        "w-[350px] sm:w-[550px] md:w-[550px] md:h-[600px] 2xl:w-[550px] 2xl:h-[650px] pb-10"
                      }
                      crossPosition={
                        "flex justify-end w-4 absolute left-[320px] sm:left-[520px] top-2"
                      }
                    >
                      <QoutingForms closePopup={() => setQouting(false)} />
                    </Popups>
                  )}
                </div>
                <div className="md:flex justify-center mb-10 hidden">
                  <MainButton variant={"large"}>
                    ABN&nbsp;12 456 789 532
                  </MainButton>
                </div>
                <Certifications />
                <BusinessContact />
                <TradingHours />
                <Associations />
                <Social />
                <Payment />
                <div className="mt-10 md:mt-20 md:hidden">
                  <div className="bg-white text-offblack rounded-3xl px-10 sm:px-[30%] md:px-20 py-10">
                    <div className="flex justify-between mb-5">
                      <div>
                        <AuxiliaryText variant={"FieldLabel"}>
                          Overall rating
                        </AuxiliaryText>
                      </div>
                      <div>
                        <div className="flex items-center mr-1 gap-3 h-[20px]">
                          <SpecialText variant={"LargeNumber"}>4.3</SpecialText>
                        </div>
                      </div>
                    </div>
                    {ReviewItems?.map((item) => {
                      return (
                        <>
                          <div className="flex items-center justify-between mt-1">
                            <AuxiliaryText variant={"Placeholder"}>
                              {item?.text}
                            </AuxiliaryText>
                            <RatingStars rating={3} />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="md:hidden">
                  <PosterReviews showMeReply={true} showMeReport={true} />
                </div>
              </div>
            </div>
            <div className="md:ml-[60px] 2xl:ml-[90px] col-span-12 md:col-start-4 md:col-span-full order-1 md:order-2">
              <UserDescription />
              <div className="mt-10 md:mt-20">
                <UserGigs />
              </div>
              <div className="mt-10 md:mt-20">
                <Portfolios />
              </div>
              <div className="mt-10 md:mt-20">
                <ServiceHighlights />
              </div>
              <div className="mt-10 md:mt-20">
                <FAQs />
              </div>
              <div className="mt-10 md:mt-20">
                <ServicesTags />
              </div>
              <div className="hidden md:block">
                <PosterReviews
                  showMeReply={true}
                  showMeReport={true}
                  DropdownClasses={"bg-white"}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
export default ProfileMainContainer;
