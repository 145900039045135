import React from "react";
import MapView from "../../components/common/Map/MapView";
import Heading from "../../components/common/Typography/Heading";

const ServicesOnMap = () => {
  return (
    <>
      <div className="mx-auto w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-40 lg:mt-40">
        <Heading
          variant={"h2"}
          className={"flex justify-start text-start text-offblack"}
        >
        Explore other services in your area
         
        </Heading>
      </div>
      <div className="w-[350px] sm:w-[600px] md:w-[700px] lg:w-[980px] xl:w-[1200px] 2xl:w-[1748px] 3xl:w-[1850px] 2k:w-[2000px] 4k:w-[2150px] h-[500px] sm:h-[500px] md:h-[600px] lg:h-[700px] xl:h-[800px] 2xl:h-[1000px] 3xl:h-[1100px] 2k:h-[1200px] 4k:h-[1300px] mt-10 mx-auto">
        <MapView />
      </div>
    </>
  );
};

export default ServicesOnMap;
