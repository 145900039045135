import React from "react";
import Heading from "../../components/common/Typography/Heading";
import SpecialStars from "../../components/common/RatingStars/SpecailStars";
import ProgressBar from "../../components/common/ProgressBar/ProgressBar";
import SpecialText from "../../components/common/Typography/SpecialText";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText"

const RatingOverview = () => {
  const data = [
    {
      number: 5,
      counter: 12,
    },
    {
      number: 4,
      counter: 25,
    },
    {
      number: 3,
      counter: 69,
    },
    {
      number: 2,
      counter: 25,
    },
    {
      number: 1,
      counter: 85,
    },
  ];
  return (
    <>
      <div className="w-[370px] h-[370px] bg-white p-[30px] pt-[25px] rounded-xl z-20 relative">
        <Heading variant={"h6"} className={"text-center text-offblack"}>
          Rating overview
        </Heading>
        <div>
          <div>
            <div className="flex justify-center my-5">
              <SpecialText variant={"LargeNumber"} className={"text-offblack"}>4.3</SpecialText>
              <SpecialText variant={"Counter"} className={"mt-5 ml-1 text-offblack"}>
                /5
              </SpecialText>
            </div>
            <div className="flex justify-center">
              <SpecialStars rating={5} className={"size-4"} />
            </div>
            <div className="flex justify-center mt-3">
              <AuxiliaryText variant={"Placeholder"} className={"text-center text-offblack"}>
                2k Reviews
              </AuxiliaryText>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          {data.map((item) => {
            return (
              <>
              <div className="mb-2">
                <ProgressBar
                  number={item.number}
                  percent={"25"}
                  stars={true}
                  counter={item.counter}
                  className={"h-2"}
                />

              </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RatingOverview;
