import React from "react";
import AllServicesLayout from "../sections/AllServices/AllServicesLayout";

const AllServices = () => {
  return (
    <>
      <AllServicesLayout />
    </>
  );
};

export default AllServices;
