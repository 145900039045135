import React, { useState, useEffect } from "react";

import SecondaryHeading from "../../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../../components/common/Typography/Paragraph";
import MainButton from "../../../components/common/MainButton/MainButton";
import Form from "../Settings/CertificationForm/Form";
import { General, Licensed, Tickets } from "./CertificationData";
import Popups from "../../../components/popup/Popups";

const CertificationLayout = () => {
  return (
    <>
      <div className="mb-5 flex flex-col w-full">
        <SecondaryHeading
          variant={"MainParagraphHeading"}
          className={
            " text-offblack w-[300px] sm:w-[500px] md:w-[600px] lg:w-[600px] xl:w-[680px] 2xl:w-[680px] 2k:w-[1000px] 4k:w-[1200px] mx-auto pb-2 border-b border-b-mediumgray"
          }
        >
          General
        </SecondaryHeading>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2k:grid-cols-6 4k:grid-cols-7 gap-16 w-fit mx-auto mt-10">
          {General?.map((item, index) => {
            return (
              <>
                <CertificationsDesign
                  key={index}
                  heading={item.heading}
                  // description={item.description}
                  image={item.image}
                  status={item.status}
                />
              </>
            );
          })}
        </div>
        <div className={"mt-5"}>
          <SecondaryHeading
            variant={"MainParagraphHeading"}
            className={
              " text-offblack w-[300px] sm:w-[500px] md:w-[600px] lg:w-[600px] xl:w-[680px] 2xl:w-[680px] 2k:w-[1000px] 4k:w-[1200px] mx-auto pb-2 border-b border-b-mediumgray mt-10"
            }
          >
            Licensed
          </SecondaryHeading>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2k:grid-cols-6 4k:grid-cols-7 gap-16 w-fit mx-auto mt-10">
          {Licensed?.map((item, index) => {
            return (
              <>
                <CertificationsDesign
                  key={index}
                  heading={item.heading}
                  // description={item.description}
                  image={item.image}
                  status={item.status}
                />
              </>
            );
          })}
        </div>
        <SecondaryHeading
          variant={"MainParagraphHeading"}
          className={
            " text-offblack w-[300px] sm:w-[500px] md:w-[600px] lg:w-[600px] xl:w-[680px] 2xl:w-[680px] 2k:w-[1000px] 4k:w-[1200px] mx-auto pb-2 border-b border-b-mediumgray mt-10"
          }
        >
          Tickets
        </SecondaryHeading>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2k:grid-cols-6 4k:grid-cols-7 gap-16 w-fit mx-auto mt-10">
          {Tickets?.map((item, index) => {
            return (
              <>
                <CertificationsDesign
                  key={index}
                  heading={item.heading}
                  // description={item.description}
                  image={item.image}
                  status={item.status}
                />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CertificationLayout;

const CertificationsDesign = ({ heading, description, image, status }) => {
  const [form, setForm] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    if (form) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [form]);
  return (
    <>
      <div className="mb-10">
        <div className="flex flex-col items-center justify-center">
          <div className=" flex items-center justify-center">
            <img className="w-[50px] h-[50px]" src={image} alt="badge" />
          </div>
          <div className="my-2 md:my-3">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              {heading}
            </Paragraph>
          </div>
          <div>
            <MainButton
              className={"md:hidden"}
              variant={status === "Remove" ? "outlineextrasmall" : "extrasmall"}
              onClick={() => setForm(!form)}
            >
              {status}
            </MainButton>
            <MainButton
              className={"hidden md:block"}
              variant={status === "Remove" ? "outlineextrasmall" : "extrasmall"}
              onClick={() => setForm(!form)}
            >
              {status}
            </MainButton>
          </div>
        </div>
      </div>
      {form && (
        <>
          <Popups
            setTrigger={() => setForm(false)}
            ShowCrossButton={ShowCrossButton}
            popupWidth={"w-[350px] h-[500px] sm:w-[600px] sm:h-[500px] 2xl:w-[600px] 2xl:h-[500px] pb-10"}
              crossPosition={
                "flex justify-end w-[16px] absolute left-[320px] sm:left-[565px] top-3"
              }
          >
            <Form
              closePopup={() => setForm(false)}
              secondMainHeading={"Credential submission confirmed!"}
              secondP={
                "We've successfully received your licensed trade credentials at Trades Poster. An email notification will be dispatched to you upon completion of the verification process. Please note, our team might take up to 10 business days to review and verify your information. Your understanding and patience are greatly valued."
              }
            />
          </Popups>
        </>
      )}
    </>
  );
};
