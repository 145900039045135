/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Filters from "../../sections/Service/Filters";

const SelectedCity = ({ formik }) => {
  return (
    <>
      <div className="bg-pastalblue">
        <div className="mx-[5%] w-full lg:mx-auto xl:w-[1200px] 2xl:w-[1748px] pt-24">
          <Filters />
        </div>
      </div>
    </>
  );
};

export default SelectedCity;
