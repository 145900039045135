import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = ({ data1, data2, labels }) => {
  const chartRef = useRef();

  useEffect(() => {
    let chartInstance = null;

    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (chartInstance) {
        chartInstance.destroy(); // Destroy any existing chart instances
      }
      chartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Following',
              data: data1,
              backgroundColor: '#2E00FF', // Red color with transparency
              borderColor: '#2E00FF', // Red color
              borderWidth: 1,
            },
            {
              label: 'Unfollow',
              data: data2,
              backgroundColor: '#C0C0C0', // Blue color with transparency
              borderColor: '#C0C0C0', // Blue color
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              display: false, // Remove the vertical y-axis label on the left side
              beginAtZero: true,
              grid: {
                display: false, // Remove the background grid lines
                drawTicks: false, // Remove the bottom horizontal line
              },
            },
            x: {
              grid: {
                display: false, // Remove both vertical and horizontal grid lines
              },
              ticks: {
                borderWidth: 0, // Remove the x-axis line
              },
            },
          },
          plugins: {
            tooltip: {
              backgroundColor: '#E8F0FF', // Set the background color of the tooltip
              bodyColor: '#232A2E', // Set the text color of the tooltip body
              titleColor: '#232A2E', // Set the text color of the tooltip title (heading)
              titleFontFamily: 'IBM Plex Sans Condensed', // Set the font family of the tooltip title
              bodyFontFamily: 'PT Sans', // Set the font family of the tooltip body
              callbacks: {
                label: (context) => {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y;
                  }
                  return label;
                },
              },
            },
            legend: {
              display: false, // Hide the legend
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy(); // Clean up when the component is unmounted
      }
    };
  }, [data1, data2, labels]);

  return (
    <div className='w-full h-full'>
      <canvas ref={chartRef} />
    </div>
  );
};

export default BarChart;
