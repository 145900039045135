import { useState } from "react";
import { Link } from "react-router-dom";
import useSidebarData from "./Data";
import { NavHashLink } from "react-router-hash-link";
import ProfileComponent from "../common/Profile/ProfileComponent";
import AuxiliaryText from "../common/Typography/AuxiliaryText";
export default function Sidebar({ children }) {
  const [open, setOpen] = useState(false);
  const [ClickIndex, setClickIndex] = useState(null);
  const sidebarLinks = useSidebarData();

  const toggleHandler = (index) => {
    if (open === index) {
      return setOpen(null);
    } else {
      setOpen(index);
    }
  };
  return (
    <>
      <div
        id="default-sidebar"
        className={`lg:w-[230px] xl:w-[250px] 2xl:w-[293px] 3xl:w-[300px] 2k:w-[308px] 4k:w-[312px] h-auto bg-white p-5 rounded-xl`}
      >
        <ProfileComponent
          heading={"ABC Plumbing XYZ"}
          profileCompleteion={true}
          actions={true}
          dashboardActions={true}
        />
        <div className="overflow-y-auto text-offblack mt-5 pt-5 border-t border-t-mediumgray">
          <ul>
            <li>
              {sidebarLinks?.map((e, index) => {
                if (e.subMenu) {
                  return (
                    <li>
                      <button
                        type="button"
                        className={
                          open === index
                            ? "flex items-center justify-between px-5 py-3 text-primaryblue w-full"
                            : "flex items-center justify-between px-5 py-3 hover:text-primaryblue w-full"
                        }
                        aria-controls="dropdown-example"
                        data-collapse-toggle="dropdown-example"
                        onClick={() => {
                          toggleHandler(index);
                        }}
                      >
                        <span className="flex items-center gap-3">
                          {e.icon}
                          <span
                            className={
                              open === index
                                ? " text-primaryblue whitespace-nowrap"
                                : " text-offblack hover:text-primaryblue whitespace-nowrap"
                            }
                            sidebar-toggle-item
                          >
                            <AuxiliaryText variant={"MenuDropdown"}>
                              {e.linkName}
                            </AuxiliaryText>
                          </span>
                        </span>
                        <i className="fi fi-rs-angle-down text-sm text-offblack"></i>
                      </button>
                      {open === index && (
                        <ul
                          id="dropdown-example"
                          className="transition duration-150 ease-in-out py-2 space-y-2 flex justify-center flex-col w-full pl-3"
                        >
                          {e.subMenu.map((sub) => (
                            <li>
                              <NavHashLink
                                key={sub.id}
                                to={sub.link}
                                onClick={() => setClickIndex(sub.id)}
                                className={`flex items-center gap-3 hover:text-primaryblue text-offblack w-full px-5 py-2 ${
                                  sub.id === ClickIndex && "text-primaryblue "
                                }`}
                              >
                                {sub.icon}
                                <AuxiliaryText variant={"SubMenuDropdown"}>
                                  {sub.linkName}
                                </AuxiliaryText>
                              </NavHashLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                } else {
                  return (
                    <Link
                      key={index}
                      to={e.link}
                      onClick={() => {
                        setClickIndex(index);
                        setOpen(false);
                        if (e.onClick) {
                          e.onClick();
                        }
                      }}
                      className={`flex items-center px-5 py-3 gap-3 text-offblack hover:text-primaryblue ${
                        index === ClickIndex && "text-primaryblue"
                      }`}
                    >
                      <>{index === ClickIndex ? e.iconSelected : e.icon}</>
                      <AuxiliaryText variant={"MenuDropdown"}>
                        {e.linkName}
                      </AuxiliaryText>
                    </Link>
                  );
                }
              })}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
