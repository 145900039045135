import React, { useState } from "react";
import CertificationLayout from "../../../sections/BusinessDashboard/Certifications/CertificationLayout";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
import Agreement from "./CertificationPopUp/Agreement";

const Certifications = () => {
  const [agreementDocs, setAgreementDocs] = useState(true);
  return (
    <>
      {agreementDocs ? (
        <>
          <Agreement clickHandler={() => setAgreementDocs(false)} />
        </>
      ) : (
        <>
          <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
            <Heading variant={"h6"} className={"text-center text-offblack"}>
              Certifications
            </Heading>
            <div className="flex justify-center mt-[60px] min-h-screen">
              <CertificationLayout />
            </div>
          </DashboardMainContainer>
        </>
      )}
    </>
  );
};

export default Certifications;
