import React from "react";
import Paragraph from "../../components/common/Typography/Paragraph";
import Heading from "../../components/common/Typography/Heading";
import { classNames } from "primereact/utils";
const HeadingsWithParagraphsList = () => {
  const mainSections = [
    {
      headingLavel: "h2",
      heading:
        "Choosing the right plumber: An essential guide for Australians ",
      para: "When it comes to selecting the right plumber in Australia, it's essential to understand the diverse landscape of plumbing services across different states. This guide is designed to provide key insights and considerations to help you choose a plumber who not only meets your needs but also adheres to the highest standards of quality and compliance.",
    },
    {
      headingLavel: "h6",
      heading: "Understanding state-specific plumbing regulations ",
      para: "Plumbing regulations can vary significantly from state to state in Australia. While some regions require plumbers to hold specific licenses or certifications, others might have more general requirements. Always verify that the plumber you consider is licensed in your state, ensuring compliance with local laws, and confirming they have the necessary qualifications and training.",
    },
    {
      headingLavel: "h6",
      heading: "The importance of experience and specialization in plumbing ",
      para: "The ability of a plumber to efficiently handle complex issues often relies on their experience level. Whether you need routine maintenance, emergency repairs, or specialized services like gas fitting or drain laying, it is advisable to choose a plumber with a proven track record in the specific service you require.",
    },
    {
      headingLavel: "h6",
      heading:
        "Evaluating costs and seeking transparency in plumbing services ",
      para: "The cost of plumbing services can vary widely, influenced by the scope of work, materials used, and the timing of the call-out. Emergency and after-hours services may carry higher fees. Always request detailed quotes upfront to avoid hidden charges and compare prices from at least three different plumbers to gauge an average cost.",
    },
    {
      headingLavel: "h6",
      heading: "The significance of insurance and warranties in plumbing work ",
      para: "Ensuring your plumber carries the appropriate insurance is crucial. This protection covers you and your property in case of accidents or unforeseen damages during the plumbing work. Additionally, inquire about warranties and guarantees on the work performed. A reliable plumber will back their service with assurances that any issues related will be addressed promptly and without additional charges.",
    },
    {
      headingLavel: "h6",
      heading: "Assessing plumber reputation through reviews and testimonials ",
      para: "The reputation of a plumber can be gauged through online reviews, testimonials from previous clients, and recommendations from trusted sources. A reputable plumber will have positive feedback and be known for their professionalism and customer service.",
    },
    {
      headingLavel: "h6",
      heading:
        "Making an informed decision: selecting a competent and trustworthy plumber ",
      para: "Selecting the right plumber in Australia involves understanding the specific regulations of your state, assessing the plumber's experience and specialization, considering the cost and transparency of the service, checking for necessary insurance, and evaluating the plumber's reputation. By carefully considering these factors, you can ensure that you choose a competent and trustworthy plumber who can effectively address your plumbing needs.",
    },
  ];

  return (
    <>
      <div className="mt-40 w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mx-auto">
        <ul>
          {mainSections.map((e, index) => {
            return (
              <>
                <li className={classNames({ "mt-5": index > 0 })}>
                  <Heading variant={e.headingLavel} className={"text-offblack"}>
                    {e.heading}
                  </Heading>
                  <Paragraph
                    variant={"MainParagraph"}
                    className={"mt-3 text-offblack"}
                  >
                    {e.para}
                  </Paragraph>
                </li>
              </>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default HeadingsWithParagraphsList;
