import React from "react";
import StoriesLayout from "../sections/BusinessStories/StoriesLayout";

const BusinessStories = () => {
  return (
    <>
      <StoriesLayout />
    </>
  );
};

export default BusinessStories;
