import React from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import Paragraph from "../../../components/common/Typography/Paragraph";

const PaymentComp = ({ method, image, status }) => {
  return (
    <>
     
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <img
              className="w-[50px] h-[50px]"
              src={image}
              alt="badge"
            />
          </div>
          <div className="mt-1">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              {method}
            </Paragraph>
          </div>
          <div className="mt-3">
            <MainButton
              variant={status === "Remove" ? "outlineextrasmall" : "extrasmall"}
            >
              {status}
            </MainButton>
          </div>
        </div>
      
    </>
  );
};
export default PaymentComp;
