import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useMemo } from "react";
import Popup from "../../components/popup/Popup";
import CircularButton from "../../components/common/MainButton/CircularButton";

const PortfolioBanner = () => {
  const [ShowPreview, setShowPreview] = useState(false);
  const [ImageIndex, setImageIndex] = useState("0");
  const id = useMemo(() => Math.round(Math.random() * 1000), []);
  useEffect(() => {
    if (ShowPreview) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [ShowPreview]);
  const Images = [
    {
      original: require("../../assets/img/default-image.jpg"),
      thumbnail: require("../../assets/img/default-image.jpg"),
    },
    {
      original: require("../../assets/img/P1.jpeg"),
      thumbnail: require("../../assets/img/P1.jpeg"),
    },
    {
      original: require("../../assets/img/P2.jpeg"),
      thumbnail: require("../../assets/img/P2.jpeg"),
    },
    {
      original: require("../../assets/img/P3.jpeg"),
      thumbnail: require("../../assets/img/P3.jpeg"),
    },
    {
      original: require("../../assets/img/P4.jpeg"),
      thumbnail: require("../../assets/img/P4.jpeg"),
    },
    {
      original: require("../../assets/img/P5.jpeg"),
      thumbnail: require("../../assets/img/P5.jpeg"),
    },
    {
      original: require("../../assets/img/P77.jpg"),
      thumbnail: require("../../assets/img/P77.jpg"),
    },
  ];

  const ImagePreviewHandler = (e, index) => {
    setImageIndex(index);
    setShowPreview(!ShowPreview);
  };
  return (
    <>
      {ShowPreview && (
        <ImagePreview
          currentIndex={ImageIndex}
          Images={Images}
          setShowPreview={setShowPreview}
        />
      )}
      <div>
        <img
          // onClick={() => {
          //   navigate({
          //     pathname: "/service-detail/",
          //     search: `?${createSearchParams({
          //       id: serviceId,
          //     })}`,
          //   });
          // }}
          className="cursor-pointer rounded-3xl object-cover w-[350px] h-[300px] sm:h-[450px] sm:w-full lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px] 3xl:h-[800px] 2k:h-[900px] 4k:h-[1000px]"
          onClick={(e) => ImagePreviewHandler(e)}
          src={require("../../assets/img/default-image.jpg")}
          alt="product images"
          // onError={(e) => {
          //   e.target.src = require("../../assets/img/P1.jpeg");
          // }}
        />
      </div>
    </>
  );
};

export default PortfolioBanner;

const ImagePreview = ({ Images, currentIndex, setShowPreview }) => {
  const [showSlider, setShowSlider] = useState(true);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    setShowSlider(false);
    setTimeout(() => {
      setShowSlider(true);
    }, 50);
  }, [currentIndex]);
  const swiperRef = useRef();
  return (
    <>
      {showSlider && (
        <Popup setTrigger={setShowPreview} ShowCrossButton={ShowCrossButton}>
          <>
          <div className="flex items-center justify-center mt-10 rounded-3xl overflow-y-auto hidden-scrollbar pt-[270px] md:pt-0 pb-[850px] md:pb-0 mx-auto w-[350px] sm:w-[550px] h-screen md:h-[80vh] lg:w-[80vw] lg:h-[80vh] xl:w-[150vh] xl:h-[80vh] 3xl:w-[160vh] 3xl:h-[90vh] md:my-auto">
              <div>
                <Swiper
                  slidesPerView={1}
                  initialSlide={currentIndex}
                  navigation={false}
                  loop
                  speed={1000}
                   className="product-images-slider rounded-3xl mx-auto w-[300px] h-[400px] sm:w-[550px] sm:h-[650px] md:w-[90vh] md:h-[80vh] lg:w-[80vw] lg:h-[70vh] xl:w-[150vh] xl:h-[70vh] 3xl:w-[160vh] 3xl:h-[75vh]"
                  spaceBetween={32}
                  modules={[Pagination, Navigation]}
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {Images?.map((item, index) => {
                    return (
                      <>
                        <SwiperSlide className="swiper-slide" key={index}>
                          <img
                            className="cursor-pointer rounded-3xl object-cover w-full h-full"
                            src={item?.original}
                            alt="product images"
                          />
                        </SwiperSlide>
                      </>
                    );
                  })}
                </Swiper>
                <div className="flex items-center justify-center gap-x-2 mt-5">
                  <CircularButton
                    variant={"prev"}
                    onClick={() => swiperRef.current?.slideNext()}
                  ></CircularButton>
                  <CircularButton
                    variant={"next"}
                    onClick={() => swiperRef.current?.slidePrev()}
                  ></CircularButton>
                </div>
              </div>
            </div>
          </>
        </Popup>
      )}
    </>
  );
};
