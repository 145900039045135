import React from "react";
import "swiper/css";
import MapView from "../../components/common/Map/MapView";
import Heading from "../../components/common/Typography/Heading";

const ServicesMapView = () => {
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-40">
        <div className="flex items-center justify-between">
          <Heading
            variant={"h2"}
            className={"flex justify-start text-start text-offblack"}
          >
            Discover services available near you
          </Heading>
        </div>
      </div>
      <div className="mx-[5%] sm:mx-auto sm:w-[600px] md:w-[700px] lg:w-[980px] xl:w-[1200px] 2xl:w-[1748px] 3xl:w-[1850px] 2k:w-[2000px] 4k:w-[2150px] h-auto sm:h-[600px] md:h-[700px] lg:h-[700px] xl:h-[800px] 2xl:h-[1000px] 3xl:h-[1100px] 2k:h-[1200px] 4k:h-[1300px] aspect-[1748/1000] mt-10">
        <MapView />
      </div>
    </>
  );
};

export default ServicesMapView;
