import React from "react";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const HireWithConf = () => {
  const data = [
    {
      heading: "No Taskers",
      para: "We feature only professional trade people, ensuring no weekend warriors or taskers.",
    },
    {
      heading: "Trade-Specific",
      para: "Our platform is dedicated exclusively to trade services, designed to meet the specific needs of trade businesses.",
    },
    {
      heading: "Pay your way",
      para: "Our platform is dedicated exclusively to trade services, designed to meet the specific needs of trade businesses.",
    },
    {
      heading: "Privacy Protected",
      para: "Businesses cannot contact customers first, only customers can initiate contact with businesses.",
    },
  ];
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Hire with confidence
        </Heading>
        <div className="sm:h-[350px] md:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px] 3xl:h-[800px] 2k:h-[900px] 4k:h-[1000px] aspect-[1264/700]">
        <img
          src={require("../../assets/img/about-us.jpg")}
          className="w-full h-full rounded-3xl mt-10 object-cover"
          alt=""
        />

        </div>
      </div>
        <div className="flex flex-wrap lg:flex-nowrap gap-8 mt-10 mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
          {data.map((e, index) => {
            return (
              <>
                <div key={index} className="w-full">
                  <SecondaryHeading
                    variant={"MainParagraphHeading"}
                    className={"text-offblack mt-5"}
                  >
                    {e.heading}
                  </SecondaryHeading>
                  <Paragraph
                    variant={"MainParagraph"}
                    className={"text-offblack mt-3"}
                  >
                    {e.para}
                  </Paragraph>
                </div>
              </>
            );
          })}
        </div>
    </>
  );
};

export default HireWithConf;
