import React from "react";
import MainButton from "../MainButton/MainButton";
import TextFiled from "../InputField/TextFiled";
import TextArea from "../InputField/TextArea";
import { useState } from "react";
import Heading from "../Typography/Heading";
import Paragraph from "../Typography/Paragraph";
import AuxiliaryText from "../Typography/AuxiliaryText";
import { Dropdown } from "primereact/dropdown";
import SpecialText from "../Typography/SpecialText";
const BusinessReport = ({ closePopup }) => {
  const [step, setStep] = useState(1);
  const [selectedReason, setSelectedReason] = useState(null);
  const reasons = [
    { name: "Poor Customer Service" },
    { name: "Fraud or Deceptive Practices" },
    { name: "Subpar Quality of Work" },
    { name: "Licensing or Certification Issues" },
    { name: "False or Misleading Reviews" },
    { name: "Safety Concerns or Hazards" },
    { name: "Misleading Advertising or Misrepresentation" },
    { name: "Communication Breakdown" },
    { name: "Appointment No-Shows or Cancellations" },
    { name: "Other (Please specify)" },
  ];

  //   const filesData = [...files];
  //   setImageList((state) => {
  //     return [
  //       ...state,
  //       ...filesData.map((file) => ({
  //         file: file,
  //         url: URL.createObjectURL(file),
  //       })),
  //     ];
  //   });
  // };
  const completion = 76;
  return (
    <>
      <>
        {step === 1 && (
          <>
            <div className="bg-pastalblue">
              <Heading
                variant={"h6"}
                className={
                  "flex items-center justify-center text-offblack mt-10 lg:mt-20 xl:mt-28"
                }
              >
                Submit a complaint
              </Heading>
              <div className="h-[700px] overflow-auto pt-10 pb-60 hidden-scrollbar">
                <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto">
                  <div>
                    <div>
                      <TextFiled
                        variant={"large"}
                        label={"Name"}
                        placeholder={"Please enter your name"}
                      />
                    </div>
                    <div className={"mt-5"}>
                      <TextFiled
                        variant={"large"}
                        label={"Contact Information (Email/Phone)"}
                        placeholder={"Please enter Email/Phone"}
                      />
                    </div>
                    <div className="mt-5">
                      <label className={`block mb-2`}>
                        <AuxiliaryText variant={"FieldLabel"}>
                          Nature of Complaint
                        </AuxiliaryText>
                      </label>
                      <div className="custom-dropdown">
                        <Dropdown
                          value={selectedReason}
                          onChange={(e) => setSelectedReason(e.value)}
                          options={reasons}
                          optionLabel="name"
                          placeholder="Select the nature of complaint"
                          // panelClassName="custom-dropdown-panel"
                          className="bg-white text-offblack flex items-center placeholder:font-ptsans placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px]"
                          />
                          <i className="fi fi-rs-angle-down text-sm text-offblack hover:cursor-pointer custom-dropdown-icon"></i>
                        </div>
                     
                    </div>
                    <div className="mt-5">
                      <TextArea
                        variant={"large"}
                        label={"Detailed Description"}
                        placeholder={"provide a detailed account of concerns"}
                      />
                    </div>
                    <div>
                      <div className="mt-5">
                        <label className={`block mb-2`}>
                          <AuxiliaryText variant={"FieldLabel"}>
                            Evidence attachment
                          </AuxiliaryText>
                        </label>
                        <div className="flex items-center justify-center w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px]">
                          <input
                            type="file"
                            id="imageslist"
                            // onChange={handleSelect}
                            accept="image/*"
                            className="cursor-pointer hidden"
                            multiple
                          />
                          <label
                            role="button"
                            htmlFor="imageslist"
                            className="h-[140px] w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] rounded-lg flex justify-center bg-white"
                          >
                            <div className="my-auto">
                              <div className="flex justify-center">
                                <div className="flex justify-center items-center">
                                  <i className="fi fi-rs-inbox-out text-base text-offblack"></i>
                                </div>
                              </div>
                              <div className="flex justify-center">
                                <AuxiliaryText
                                  variant={"Placeholder"}
                                  className={"mt-1 text-primaryblue"}
                                >
                                  Click to upload
                                </AuxiliaryText>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className="mt-10 w-full">
                          <div className="flex gap-3 w-full">
                            <i className="fi fi-br-picture text-[50px] text-primaryblue"></i>
                            <div className="w-full flex flex-col gap-2 mt-5">
                              <div className="flex items-center justify-between w-full">
                                <AuxiliaryText variant={"FieldLabel"}>
                                  myprofile.png
                                </AuxiliaryText>

                                <AuxiliaryText variant={"FieldLabel"}>
                                  {completion}%
                                </AuxiliaryText>
                              </div>
                              <div className="w-full h-1.5 bg-white rounded-full">
                                <div
                                  className={`h-full bg-primaryblue rounded-full`}
                                  style={{ width: `${completion}%` }}
                                ></div>
                              </div>
                            </div>
                            <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
                          </div>
                          <div className="flex gap-3 w-full mt-5">
                            <i className="fi fi-br-picture text-[50px] text-primaryblue"></i>
                            <div className="w-full flex flex-col gap-2 mt-5">
                              <div className="flex items-center justify-between w-full">
                                <AuxiliaryText variant={"FieldLabel"}>
                                  myprofile.png
                                </AuxiliaryText>

                                <AuxiliaryText variant={"FieldLabel"}>
                                  {completion}%
                                </AuxiliaryText>
                              </div>
                              <div className="w-full h-1.5 bg-white rounded-full">
                                <div
                                  className={`h-full bg-primaryblue rounded-full`}
                                  style={{ width: `${completion}%` }}
                                ></div>
                              </div>
                            </div>
                            <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mt-10">
                      <div className="flex justify-start mt-5">
                        <SpecialText variant={"FootNoteDisclaimer"}>
                          Privacy and Consent
                        </SpecialText>
                      </div>
                      <SpecialText
                        variant={"FootNoteDisclaimer"}
                        className={"mt-3"}
                      >
                        We respect your privacy. By submitting this complaint,
                        you agree to our processing of your personal data in
                        accordance with our privacy policy. We will use the
                        information provided to address the complaint and may
                        contact you for further information if necessary.
                      </SpecialText>
                    </div>
                    <div className="flex items-center mt-10">
                      <label
                        className="relative flex items-center rounded-full cursor-pointer mr-3"
                        htmlFor="link"
                      >
                        <input
                          type="checkbox"
                          className="before:content[''] peer relative size-4 cursor-pointer appearance-none rounded-[4px] border border-mediumgray transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primaryblue checked:bg-primaryblue checked:before:bg-primaryblue"
                          id="link"
                        />
                        <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-[40%] left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                          <i className="fi fi-rs-check text-[10px]"></i>
                        </span>
                      </label>
                      <SpecialText
                        variant={"FootNoteDisclaimer"}
                        className={"text-offblack"}
                      >
                        I agree to the Privacy Policy and confirm that all
                        information provided is accurate to the best of my
                        knowledge.
                      </SpecialText>
                    </div>
                    <div>
                      <div className="flex items-center justify-end mt-10">
                        <MainButton
                          variant={"small"}
                          onClick={() => {
                            setStep(2);
                            setTimeout(closePopup, 3000);
                          }}
                        >
                          Submit
                        </MainButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="bg-pastalblue">
              <Heading
                variant={"h6"}
                className={
                  "flex items-center justify-center text-offblack mt-10 md:mt-28"
                }
              >
                Submission confirmed
              </Heading>
              <div className="flex w-[300px] sm:w-[350px] md:w-[450px] lg:w-[380px] xl:w-[420px] 2xl:w-[500px] mx-auto rounded-lg">
                <div className="w-full">
                  <div>
                    <div className="w-full">
                      <Paragraph variant={"MainParagraph"} className={"mt-10"}>
                        Thank you for taking the time to submit your complaint.
                        We at Trades Poster take your concerns seriously and are
                        dedicated to maintaining a trustworthy environment for
                        all users. Each complaint is carefully reviewed, and
                        should we require further information, we will reach out
                        to you. Please keep an eye on your email for updates
                        regarding the investigation. Your patience and
                        cooperation in this process are greatly appreciated.
                      </Paragraph>
                    </div>
                  </div>
                  <div className="flex justify-center my-10">
                    <i className="fi fi-ss-badge-check text-[4.5rem] text-primaryblue w-[4.5rem] h-[4.5rem]"></i>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default BusinessReport;
