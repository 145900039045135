import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { GetBusinessCategories } from "../Api/BusinessCategories";
import { GetListingTypes } from "../Api/Listing";
import { GET_BUSINESS_CATEGORY } from "../Redux/Constants";
export function useBusinessCategories() {
  const dispatch = useDispatch();
  return useQuery(["GetBusinessCategories"], GetBusinessCategories, {
    staleTime: 30000,
    onSuccess: (data) => {
      if (data) {
        dispatch({
          type: GET_BUSINESS_CATEGORY,
          payload: data?.data?.key.$values,
        });
      }
    },
  });
}
export function useListingType() {
  return useQuery(["GetListingType"], GetListingTypes);
}
