import React from "react";
import BlogCard from "../../components/common/BlogCard/BlogCard";
import Heading from "../../components/common/Typography/Heading";

const Blogs = [
  {
    link: "",
    blogName: "Top 10 Plumbing Tips Every Homeowner Should Know",
    text: "Explore the boundary between DIY home repairs and tasks that require professional expertise. This guide helps homeowners identify signs that it's time to call in a professional, ensuring safety and efficiency in home maintenance.",
  },
  {
    link: "",
    blogName: "The Environmental Impact of Plumbing: What You Need to Know",
    text: "Discover practical ways to reduce water usage in your home and garden, contributing to environmental conservation and reducing your water bills. From fixing leaks to choosing water-efficient fixtures, learn how small changes can make a significant impact.",
  },
  {
    link: "",
    blogName: "How to Choose Sustainable Plumbing Solutions for Your Home",
    text: "Electrical safety is paramount in any household. This article provides an overview of your home’s electrical system, common hazards to watch for, and tips on maintaining electrical safety, highlighting when to hire a professional electrician.",
  },
];

const RelatedArticles = () => {
  return (
    <>
      <div className="mt-40">
        <div>
          <div className="w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] xl:mx-auto">
            <div>
              <div>
                <Heading variant={"h2"} className={"text-offblack text-left"}>
                  Related articles
                </Heading>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-center w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mx-auto mt-20">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 mx-auto w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] gap-8">
              {Blogs.map((e, index) => {
                return (
                  <BlogCard
                    link={e.link}
                    key={index}
                    blogName={e.blogName.split(/\s+/).slice(0, 15).join(" ")}
                    text={e.text}
                    blogTitle={e.blogTitle}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RelatedArticles;
