import React, { useRef } from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";

import CircularButton from "../../components/common/MainButton/CircularButton";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import picture from "../../assets/img/default-image.jpg";

const FeaturesSlider = () => {
  const swiperRef = useRef();
  const data = [
    {
      text: "Custom Metrics",
      pic: picture,
    },
    {
      text: "No leads or Biding",
      pic: picture,
    },
    {
      text: "Invoice on your terms",
      pic: picture,
    },
    {
      text: "No commission or undercutting",
      pic: picture,
    },
    {
      text: "Custom Metrics",
      pic: picture,
    },
    {
      text: "No leads or Biding",
      pic: picture,
    },
    {
      text: "Invoice on your terms",
      pic: picture,
    },
    {
      text: "No commission or undercutting",
      pic: picture,
    },
  ];
  return (
    <>
      <div className="mt-10 lg:mt-20 xl:mt-20 2xl:mt-20 w-[100%]">
        <Swiper
          className="pr-[6%] lg:pr-[0%] pl-[6%] 2xl:ml-0 3xl:pl-[0%] w-[100%]"
          // breakpoints={{
          //   400: {
          //     slidesPerView: 1,
          //   },
          //   1280: {
          //     slidesPerView: 2,
          //   },
          // }}
          slidesPerView="auto"
          // loop
          // slidesPerView={2}
          speed={1000}
          // centeredSlides={true}
          // allowSlideNext = {false}
          spaceBetween={32}
          modules={[Pagination, Navigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          <>
            <SwiperSlide className="hidden 2xl:block w-[200px] 2xl:w-[270px]"></SwiperSlide>
            {data?.map((e, index) => {
              return (
                <SwiperSlide className="w-[200px] 2xl:w-[270px]">
                  <div
                    key={index}
                    className="w-[200px] h-[250px] 2xl:w-[270px] 2xl:h-[340px] cursor-pointer mb-2"
                  >
                    <img
                      className="w-full h-full object-cover rounded-xl"
                      src={e.pic}
                      alt="/"
                    />
                    <SecondaryHeading
                      variant={"MainParagraphHeading"}
                      className={"mt-3"}
                    >
                      {e.text}
                    </SecondaryHeading>
                  </div>
                </SwiperSlide>
              );
            })}
            <SwiperSlide className="hidden xl:block 2k:hidden w-[250px] 2xl:h-[320px] 2xl:w-[270px] h-[340px] rounded-xl"></SwiperSlide>
            <SwiperSlide className="hidden xl:block 2k:hidden w-[250px] 2xl:h-[320px] 2xl:w-[270px] h-[340px] rounded-xl"></SwiperSlide>
            <SwiperSlide className="hidden xl:block 2k:hidden w-[250px] 2xl:h-[320px] 2xl:w-[270px] h-[340px] rounded-xl"></SwiperSlide>
            <SwiperSlide className="hidden lg:block w-[250px] 2xl:h-[320px] 2xl:w-[270px] h-[340px] rounded-xl"></SwiperSlide>
          </>
        </Swiper>
        <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-10">
          <div className="flex items-center justify-start gap-x-2">
            <CircularButton
              variant={"prev30"}
              onClick={() => swiperRef.current?.slideNext()}
            ></CircularButton>
            <CircularButton
              variant={"next30"}
              onClick={() => swiperRef.current?.slidePrev()}
            ></CircularButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesSlider;
