import * as Yup from "yup";
export const initial_values = {
  id: 0,
  listTitle: "",
  listCategory: 0,
  description: "",
  listingTags: "",
  listingImages: [],
  listingType: "",
  state: "draft",
  noOfImages: 0,
  prevNoOfImages: 0,
};
export const validationSchema = Yup.object().shape({
  listTitle: Yup.string().required("ListTitle is Required").label("ListTitle"),
  listCategory: Yup.number().required("ListCategory is Required").test('len',"ListCategory is Required",(val)=> val !== 0).label("ListCategory"),
  listingTags: Yup.array().required("List Tags is Required").label("ListTag"),
  description: Yup.string()
    .required("Description is Required")
    // .test('len', 'Must be exactly 100 characters', (val) => val?.length >= 100)
    .label("Description"),
});
