import React from "react";
import BusinessCont from "../../../sections/BusinessDashboard/Settings/BusinessCont";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
const BusinessContact = () => {
  return (
    <>
      <>
        <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
          <Heading variant={"h6"} className={"text-center text-offblack"}>
            Update business contact details
          </Heading>
          <div className="mt-[60px] min-h-screen">
            <BusinessCont />
          </div>
        </DashboardMainContainer>
      </>
    </>
  );
};

export default BusinessContact;
