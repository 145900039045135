import React from "react";
import BlogCard from "../../components/common/BlogCard/BlogCard";
import Heading from "../../components/common/Typography/Heading";

const Blogs = [
  {
    link: "",
    blogName: "Fixing a Leaky Tap: A Step-by-Step Guide",
    text: "Discover the simple steps to tighten up that pesky leak yourself.",
  },
  {
    link: "",
    blogName: "When to Call a Plumber: Mixer Repair Edition",
    text: "Learn the signs that indicate it’s time to call in the professionals.",
  },
  {
    link: "",
    blogName: "Choosing the Right Mixer for Your Kitchen",
    text: "A comprehensive guide to selecting a mixer that combines functionality with style.",
  },
];

const Tips = () => {
  return (
    <>
    <div className="mt-40">
      <div>
        <div className="mx-auto w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
          <div>
            <div>
              <Heading variant={"h2"} className={"text-offblack"}>
                Expert tips & <br/> Guides for your home
              </Heading>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex justify-center w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl::w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mx-auto mt-20">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 w-full mx-auto gap-8 pb-1">
            {Blogs.map((e, index) => {
              return (
                <BlogCard
                  link={e.link}
                  key={index}
                  blogName={e.blogName.split(/\s+/).slice(0, 9).join(" ")}
                  text={e.text}
                  blogTitle={e.blogTitle}
                />
              );
            })}
          </div>
        </div>
      </div>
       
    </div>
    </>
  );
};

export default Tips;
