import React from "react";
import { Link } from "react-router-dom";
import BlogCard from "../../components/common/BlogCard/BlogCard";
import InteractiveText from "../../components/common/Typography/InteractiveText";

const Relatedblogs = () => {
  const Blogs = [
    {
      blogName:
        "Blog Name Go through the list of service providers and select the best service on the basis of reviews.",
      text: "Go through the list of service providers and select the best service on the basis of reviews. Connect with the service provider via contact number or email address to get a final quote and avail their best services.",
    },
    {
      blogName:
        "Choose from the best Go through the list of service providers and select the best service on the basis of reviews.",
      text: "Go through the list of service providers and select the best service on the basis of reviews. Connect with the service provider via contact number or email address to get a final quote and avail their best services.",
    },
    {
      blogName:
        "Blog Name Go through the list of service providers and select the best service on the basis of reviews.",
      text: "Go through the list of service providers and select the best service on the basis of reviews. Connect with the service provider via contact number or email address to get a final quote and avail their best services.",
    },
  ];
  return (
    <>
      <div className="flex justify-center pb-20">
        <div>
          <div className="flex items-center justify-end">
            <Link to={"/blogs"}>
              <InteractiveText
                variant={"ActionLink"}
                className={"text-primaryblue"}
              >
                All categories
              </InteractiveText>
            </Link>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] text-ellipsis overflow-hidden h-[550px] sm:h-auto gap-8 mt-10">
            {Blogs.map((e, index) => {
              return (
                <BlogCard
                  key={index}
                  blogName={e.blogName.split(/\s+/).slice(0, 8).join(" ")}
                  text={e.text}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Relatedblogs;
