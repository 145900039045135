import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const UserDescription = () => {
  return (
    <>
      <SecondaryHeading
        variant={"MainParagraphHeading"}
        className={"flex justify-start text-offblack"}
      >
        About
      </SecondaryHeading>
      <div className="mt-5">
        <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
          Ut molestie elementum dui eget gravida. Etiam mollis neque vel orci
          vehicula fringilla. Praesent interdum sed nulla at consectetur. Nullam
          consectetur sit amet ex id vulputate. Nam at nisl lacinia, placerat
          sapien at, tristique nulla. Phasellus turpis nibh, maximus tincidunt
          nunc non, dapibus auctor libero. Mauris leo leo, pharetra et fringilla
          sed,
        </Paragraph>
      </div>
    </>
  );
};

export default UserDescription;
