import React from "react";
import Heading from "../Typography/Heading";
import SecondaryHeading from "../Typography/SecondaryHeading";
import Paragraph from "../Typography/Paragraph";
import MainButton from "../MainButton/MainButton";
import SpecialText from "../Typography/SpecialText";

const ReviewGuidelines = ({ handleBackClick }) => {
  const data = [
    {
      number: 1,
      heading: "Account Verification or Age Requirement",
      text: "To leave a review, your account must either be verified or have been active for at least two weeks. This helps ensure that reviews are posted by genuine users with real experiences.",
    },
    {
      number: 2,
      heading: "Focus on Your Experience",
      text: "Share your personal interaction with the business, detailing the service received, the outcome, and whether it met your expectations.",
    },
    {
      number: 3,
      heading: "Be Specific and Constructive",
      text: "Provide details about what aspects were satisfactory or where there could be improvements. Specifics help others understand your experience better and offer constructive feedback to the business.",
    },
    {
      number: 4,
      heading: "Stay Relevant",
      text: "Concentrate on the service provided by the business. Avoid discussing unrelated personal experiences or broader issues not directly related to your interaction with the business.",
    },
    {
      number: 5,
      heading: "Respect Privacy",
      text: "Refrain from including personal information about yourself or individuals from the business, such as names, phone numbers, or addresses.",
    },
    {
      number: 6,
      heading: "Use Appropriate Language",
      text: "Ensure your review is written respectfully and politely. Avoid offensive language, personal attacks, or discriminatory remarks.",
    },
    {
      number: 7,
      heading: "Maintain Integrity",
      text: "Your review should be unbiased and genuine. Do not review your own business or a business you have a connection with and avoid unfairly reviewing competitors.",
    },
    {
      number: 8,
      heading: " Limit on Review Frequency",
      text: "Customers can leave a review for the same business once every month. This policy is designed to prevent spamming and ensures that reviews reflect a comprehensive range of experiences over time.",
    },
    {
      number: 9,
      heading: "Reviews are Subject to Moderation",
      text: "Trades Poster reserves the right to moderate, edit, or remove reviews to ensure compliance with our guidelines. Our goal is to maintain the quality and reliability of feedback on our platform.",
    },
    {
      number: 10,
      heading: "Reporting Concerns",
      text: "If you encounter a review that you believe violates our guidelines, please report it. We are committed to maintaining a fair and trustworthy review process.",
    },
  ];
  return (
    <>
    <div className="w-[350px] sm:w-[350px] md:w-[380px] lg:w-[400px] xl:w-[400px] 2xl:w-[400px] mx-auto">
      <Heading variant={"h6"} className={"mt-10 text-offblack mx-auto"}>
        Guidelines for leaving a business Review on Trades Poster
      </Heading>
      <div className="h-[510px] overflow-auto hidden-scrollbar pt-10 pb-20">
        <div className="flex justify-center mx-[5%] md:mx-auto">
          <div className="w-[350px] md:w-[500px] mx-auto">
            <SecondaryHeading variant={"MainParagraphHeading"}>
              Thank you for sharing your experience!
            </SecondaryHeading>

            <div className="mt-5">
              <Paragraph variant={"MainParagraphH"}>
                Your insights help our community make informed decisions and
                support businesses in improving their services. To ensure that
                reviews are beneficial, fair, and appropriate, we ask you to
                adhere to the following guidelines when leaving a review
              </Paragraph>
            </div>
            <div className="mt-10">
              {data.map((item) => {
                return (
                  <>
                    <div className="mb-5">
                      <div className="flex items-center">
                        <Paragraph variant={"MainParagraph"}>
                          {item.number} &nbsp;
                        </Paragraph>
                        <SecondaryHeading variant={"MainParagraphHeading"}>
                          {item.heading}
                        </SecondaryHeading>
                      </div>
                      <div className="mt-2">
                        <Paragraph variant={"MainParagraph"}>
                          {item.text}
                        </Paragraph>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="mt-5">
              <SpecialText variant={"FootNoteDisclaimer"}>
                By adhering to these guidelines, you contribute to a valuable
                resource for the Trades Poster community. We greatly appreciate
                your effort in leaving a respectful and honest review.
              </SpecialText>
            </div>
            <div className="flex justify-end">
            <MainButton
              className={"mt-10"}
              variant={"extrasmall"}
              onClick={handleBackClick}
            >
              Back
            </MainButton>

            </div>
          </div>
        </div>
      </div>

    </div>
    </>
  );
};

export default ReviewGuidelines;
