import React from "react";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import IconBox from "../../components/common/IconBox/IconBox";
import MainButton from "../../components/common/MainButton/MainButton";
// import cctv from "../../assets/img/cctv.svg";
import { businessCategories } from "./BusinessCategories";
import Heading from "../../components/common/Typography/Heading";
const Services = () => {
  // const businessCategories = useSelector(
  //   (category) => category.BusinessCategoryReducer
  // );
  const [LoadMoreImagesIncrement, setLoadMoreImagesIncrement] =
    React.useState(24);
  return (
    <>
      <div className="pt-20 xl:pt-40 2xl:pt-40" id="categorie">
        <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
          <div>
            <Heading variant={"h2"} className={"text-offblack text-start"}>
              Click, Choose, Connect <br />
              Your local trade services, All here
            </Heading>
          </div>
          {/* <div className="flex justify-center w-full "> */}
          <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 w-full sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] gap-y-8 mt-10 lg:mt-10 xl:mt-20 2xl:mt-20">
            {businessCategories
              ?.filter((e) => e.businessCategoryName !== "Others")
              ?.slice(0, LoadMoreImagesIncrement)
              ?.map((e, index) => {
                return (
                  <div key={index}>
                    <Link to={`/services/?id=${e.id}`}>
                      <IconBox
                        id={e?.id}
                        // className={e?.className}
                        imgclassName={
                          "cursor-pointer mx-auto size-[40px] lg:size-[45px] xl:size-[50px]"
                        }
                        // imgclassName={e.imgclassName}
                        // textclassName={e?.textclassName}
                        image={e?.categoryImage}
                        Alt={e?.businessCategoryName + "icon"}
                        // image={cctv}
                        text={
                          e.businessCategoryName.includes("&") ? (
                            <React.Fragment>
                              {e.businessCategoryName.split("&")[0]}
                              <br />& {e.businessCategoryName.split("&")[1]}
                            </React.Fragment>
                          ) : e.businessCategoryName.split(" ").length === 2 &&
                            e.businessCategoryName.split(" ")[0].length > 3 ? (
                            <React.Fragment>
                              {e.businessCategoryName.split(" ")[0]}
                              <br />
                              {e.businessCategoryName.split(" ")[1]}
                            </React.Fragment>
                          ) : (
                            e.businessCategoryName
                          )
                        }
                        // text={e?.businessCategoryName.split('&').join("<br/>")}
                        direction={"horizontal"}
                      />
                    </Link>
                  </div>
                );
              })}
            {businessCategories
              ?.filter((e) => e.businessCategoryName === "Others")
              ?.map((e, index) => {
                let img = JSON.parse(e?.categoryImage);
                return (
                  <IconBox
                  key={index}
                    image={img}
                    text={e?.businessCategoryName}
                    direction={"horizontal"}
                  />
                );
              })}
          </div>

          <div className="flex justify-center">
            {businessCategories?.length !== LoadMoreImagesIncrement && (
              <MainButton
                className={"mt-20"}
                variant={"large"}
                onClick={() => {
                  if (LoadMoreImagesIncrement < businessCategories.length) {
                    setLoadMoreImagesIncrement((value) => {
                      return businessCategories.length;
                    });
                  }
                }}
              >
                Load more
              </MainButton>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
