import React, { useState } from "react";
import InteractiveText from "../../components/common/Typography/InteractiveText";
import Heading from "../../components/common/Typography/Heading";

const FAQs = () => {
  const data = [
    {
      question: "How are the cost estimates determined?",
      answer:
        "Our cost estimates are meticulously calculated based on thorough market research, industry standards, and current local pricing data. We aim to provide you with the most accurate and up-to-date information to assist in your decision-making process.",
    },
    {
      question: "Can I get an exact quote for my mixer repair from this guide?",
      answer:
        "While our guide offers estimates based on average market rates, obtaining an exact quote is best achieved by directly contacting your local business for detailed quote. Their assessment of your specific needs and circumstances will ensure the most precise estimate for your mixer repair.",
    },
    {
      question:
        "How do I know if my mixer repair falls within the low, average, or high-cost range?",
      answer:
        "The cost range provided in our guide offers a general overview of pricing levels for mixer repairs. Low-cost options typically involve basic repairs or part replacements. Average-cost services encompass standard repairs or maintenance tasks. High-cost services may include specialized repairs, premium materials, or urgent service calls.",
    },
  ];
  const [Show, setShow] = useState(-1);
  const toggleHandler = (index) => {
    if (Show === index) {
      return setShow(null);
    } else {
      setShow(index);
    }
  };
  return (
    <>
      <div className="flex text-offblack mt-40 w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] mx-auto">
        <div>
          <Heading variant={"h2"} className={"mb-20 text-offblack"}>
            Frequently Ask Questions
          </Heading>
          {data.map((e, index) => {
            return (
              <>
                <div>
                  <div
                    key={index}
                    className={
                      Show === index
                        ? "bg-brightblue text-white h-[70px] w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] flex items-center rounded-t-xl px-5"
                        : "bg-brightblue text-white h-[100px] w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] flex items-center rounded-xl px-5 mb-8"
                    }
                  >
                    <div>
                      <InteractiveText variant={"FAQHeader"}>
                        {e.question}
                      </InteractiveText>
                    </div>
                    <div className="w-[16px] h-[60px] ml-auto flex items-center justify-center rounded-t-xl">
                      <i
                        className={
                          Show === index
                            ? "fi fi-rs-angle-up text-sm cursor-pointer"
                            : "fi fi-rs-angle-down text-sm cursor-pointer"
                        }
                        onClick={() => {
                          toggleHandler(index);
                        }}
                      ></i>
                    </div>
                  </div>
                  {Show === index && (
                    <div className="bg-brightblue text-white h-auto w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] flex items-center rounded-b-xl px-5 pb-5 mb-8 3xl:-mt-1">
                      <InteractiveText variant={"FAQHeader"}>
                        {e.answer}
                      </InteractiveText>
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FAQs;
