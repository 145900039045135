import React from "react";
import DashboardAdsCard from "../../../../components/common/AdsCard/DashboardAdsCard";
import DefaultImage from "../../../../assets/img/default-image.jpg";

const PostReview = ({ formik, type, data }) => {
  return (
    <>
      <div className="flex items-center justify-center w-fit mx-auto mt-[60px]">
        <DashboardAdsCard
          // key={index}
          // serviceName={formik?.values?.listTitle}
          // serviceDescription={formik?.values?.description}
          // category={formik?.values?.listCategory?.toString() ?? ""}
          type={type}
          // Images={[DefaultImage]}
          // rating={formik.listRating}
          // companyName={data?.companyName ?? ""}
          // companyLogo={data?.companyLogo ?? DefaultImage}
          serviceName={"Engineering Services"}
          serviceDescription={
            "In literary theory, whether this object is a work of literature, a street sign, an arrangement of buildings on a city block, or styles of clothing. It is a coherent set of signs that transmits some kind of informative message."
          }
          category={"Engineering Services"}
          // type={"Featured"}
          Images={[DefaultImage]}
          rating={formik.listRating}
          companyName={"Building Experts"}
          companyLogo={data?.companyLogo ?? DefaultImage}
        />
      </div>
    </>
  );
};

export default PostReview;
