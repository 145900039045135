import React from "react";
import ButtonText from "../Typography/ButtonText";

const BubbleButton = ({
  variant,
  children,
  className,
  onClick,
  type = "",
  disabled,
}) => {
  return (
    <>
      <>
        <button
          type="button"
          className={`cursor-pointer bg-primaryblue text-white px-4 py-2.5 rounded-3xl disabled:bg-white disabled:text-offblack disabled:hover:text-white disabled:hover:bg-primaryblue `}
          onClick={onClick}
          disabled={disabled}
        >
          <ButtonText variant={"SecondaryText"}>{children}</ButtonText>
        </button>
      </>
    </>
  );
};

export default BubbleButton;
