import React from "react";
import Heading from "../../components/common/Typography/Heading";
import BigSlidesSlider from "../SuccessJourneySlider/BigSlidesSlider";

const PopularTypes = () => {
  const data = [
    {
      rareHeading: "Installation",
      subHeading:
        "Professional installation of air conditioning and heating systems to ensure optimal performance and energy efficiency.",
      buttonText: "Discover HVAC Installation Services Near You",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[300px] text-white",
      subClass: "group-hover:w-[305px]",
      link: "",
      navigationText: true,
    },
    {
      rareHeading: "Maintenance",
      subHeading:
        "Regular maintenance services to keep your systems running smoothly and prevent costly breakdowns.",
      buttonText: "Find HVAC Maintenance Services Near You",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[270px] text-white",
      subClass: "group-hover:w-[275px]",
      link: "",
      navigationText: true,
    },
    {
      rareHeading: "Repairs",
      subHeading:
        "Prompt and reliable repair services to fix any issues with your air conditioning or heating systems.",
      buttonText: "Locate HVAC Repair Services Near You",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[245px] text-white",
      subClass: "group-hover:w-[250px]",
      link: "",
      navigationText: true,
    },
    {
      rareHeading: "Duct Cleaning",
      subHeading:
        "Cleaning of air ducts to improve indoor air quality and system efficiency.",
      buttonText: "Find Duct Cleaning Services Near You",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[250px] text-white",
      subClass: "group-hover:w-[255px]",
      link: "",
      navigationText: true,
    },
    {
      rareHeading: "Thermostat Installation",
      subHeading:
        "Installation and programming of modern thermostats to enhance system control and energy savings.",
      buttonText: "Discover Thermostat Installation Services Near You",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[345px] text-white",
      subClass: "group-hover:w-[350px]",
      link: "",
      navigationText: true,
    },
    {
      rareHeading: "Energy Efficiency Upgrades: ",
      subHeading:
        "Services aimed at improving the energy efficiency of your HVAC systems, reducing energy bills.",
      buttonText: "Find Energy Efficiency Upgrade Services Near You",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[330px] text-white",
      subClass: "group-hover:w-[335px]",
      link: "",
      navigationText: true,
    },
  ];
  return (
    <>
      <div className="w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mx-auto mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Comprehensive HVAC Services to Enhance Your Comfort
        </Heading>
      </div>
      <BigSlidesSlider data={data} />
    </>
  );
};

export default PopularTypes;
