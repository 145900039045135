import React from "react";
import AuxiliaryText from "../Typography/AuxiliaryText";
import { Link } from "react-router-dom";

const Pro = ({className}) => {
  return (
    <>
      <Link to={"/pro"}>
        <div className={`px-[3px] pt-[1px] ${className}`}>
          <AuxiliaryText variant={"Protag"}>Pro</AuxiliaryText>
        </div>
      </Link>
    </>
  );
};

export default Pro;
