import React from "react";
import Heading from "../../components/common/Typography/Heading";
import BigSlidesSlider from "../SuccessJourneySlider/BigSlidesSlider";

const SuccessSlider = () => {
  const data = [
    {
      heading: "Discover trusted trade services",
      subHeading: "Home improvement made easy",
      para: "connect with top-rated trades experts dedicated to quality and transparency. No fees, no hassle—just reliable service tailored to your needs.",
      buttonText: "Discover our services",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[140px] sm:w-[150px] lg:w-[165px] xl:w-[165px] 2xl:w-[175px] text-white",
      subClass: "group-hover:w-[145px] sm:group-hover:w-[155px] lg:group-hover:w-[170px] xl:group-hover:w-[170px] 2xl:group-hover:w-[180px]",
      link: "",
      navigationText : true
    },
    {
      heading: "Direct marketplace access",
      subHeading: "Direct access to a thriving marketplace",
      para: "showcase your services on our platform and let customers reach out to you directly. We provide a bridge, not a barrier, to new business opportunities.",
      buttonText: "Start listing today",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[125px] sm:w-[135px] lg:w-[145px] xl:w-[145px] 2xl:w-[155px] text-white",
      subClass: "group-hover:w-[130px] sm:group-hover:w-[140px] lg:group-hover:w-[150px] xl:group-hover:w-[150px] 2xl:group-hover:w-[160px]",
      link: "",
      navigationText : true
    },
    {
      heading: "Quality you can trust",
      subHeading: "Guaranteed satisfaction",
      para: "every expert on our platform is thoroughly vetted, ensuring you receive services from professionals you can trust.",
      buttonText: "View verified professionals",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[175px] sm:w-[185px] lg:w-[195px] xl:w-[195px] 2xl:w-[210px] text-white",
      subClass: "group-hover:w-[180px] sm:group-hover:w-[190px] lg:group-hover:w-[205px] xl:group-hover:w-[205px] 2xl:group-hover:w-[220px]",
      link: "",
      navigationText : true
    },
    {
      heading: "Profile and post analytics",
      subHeading: "Track your engagement",
      para: "gain insights into how your services are performing with detailed analytics on profile and post views, enhancing your business strategy.",
      buttonText: "View your analytics",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[130px] sm:w-[140px] lg:w-[150px] xl:w-[150px] 2xl:w-[160px] text-white",
      subClass: "group-hover:w-[135px] sm:group-hover:w-[145px] lg:group-hover:w-[155px] xl:group-hover:w-[160px] 2xl:group-hover:w-[165px]",
      link: "",
      navigationText : true
    },
    {
      heading: "Unmediated contact",
      subHeading: "Immediate contact options",
      para: "choose how to connect with tradespeople directly through their profiles—whether by phone, email, or onsite inquiries.",
      buttonText: "Connect now",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[60px] sm:w-[70px] lg:w-[75px] xl:w-[75px] 2xl:w-[80px] text-white",
      subClass: "group-hover:w-[65px] sm:group-hover:w-[65px] lg:group-hover:w-[80px] xl:group-hover:w-[80px] 2xl:group-hover:w-[85px]",
      link: "",
      navigationText : true
    },
    {
      heading: "Pricing transparency",
      subHeading: "Clear pricing directly from the source",
      para: "engage directly with tradespeople to discuss and understand pricing—no hidden charges, just clear, straightforward quotes.",
      buttonText: "Explore transparent pricing",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[180px] sm:w-[190px] lg:w-[200px] xl:w-[200px] 2xl:w-[215px] text-white",
      subClass: "group-hover:w-[185px] sm:group-hover:w-[195px] lg:group-hover:w-[205px] xl:group-hover:w-[205px] 2xl:group-hover:w-[220px]",
      link: "",
      navigationText : true
    },
  ];
  
  
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 2k:w-[1600px] 4k:w-[1800px] 3xl:w-[1400px] pt-20 xl:pt-20 2xl:pt-40">
        <Heading variant={"h2"} className={"text-offblack "}>
          Transforming your trade experience <br />
          Connecting customers with local tradespeople
        </Heading>
      </div>
      <BigSlidesSlider data={data} lastButton={true}/>
    </>
  );
};

export default SuccessSlider;
