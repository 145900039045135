import React from "react";
import { useState } from "react";

const MovingLeft = ({ children }) => {
  const [pauseAnimation, setPauseAnimation] = useState(false);

  const handleMouseEnter = () => {
    setPauseAnimation(true);
  };

  const handleMouseLeave = () => {
    setPauseAnimation(false);
  };

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="sliderContainer"
      >
        <div className="sliderWrapper w-[100%] overflow-hidden">
          <section
            className={`sliderSection ${pauseAnimation ? "stopAnimation" : ""}`}
            style={{ "--speed": `2000s` }}
          >
            {children}
          </section>
        </div>
      </div>
    </>
  );
};

export default MovingLeft;
