import React from "react";
// import { Link } from "react-router-dom";
import Paragraph from "../Typography/Paragraph";
const IconBox = ({ image, text, Alt, imgclassName, textclassName }) => {
  const [ImageChange, setImageChange] = React.useState(0);
  return (
    // <Link to={`Services/id=${id}`}>
    <div className={`flex items-center flex-col w-full`}>
      <img
        onMouseOver={() => setImageChange(1)}
        onMouseLeave={() => setImageChange(0)}
        className={`${imgclassName}`}
        src= {image}
        // src={image?.[ImageChange] ?? ""}
        // src={
        //   typeof image === "object"
        //     ? ImgUrl + image?.[ImageChange] ?? ""
        //     : image
        // }
        alt={Alt}
      />
      <div className="mt-3 text-center">
        <Paragraph variant={"MainParagraph"} className={`${textclassName}`}>
          {text}
        </Paragraph>
      </div>
    </div>
  );
};
export default IconBox;
