import React, { useEffect, useState } from "react";
import Popups from "../../../../components/popup/Popups";
import VerificationPopUp from "../../../../sections/VerificationPop/VerificationPopUp";
import MainButton from "../../../../components/common/MainButton/MainButton";
import Paragraph from "../../../../components/common/Typography/Paragraph";

const GeneraComponet = ({ heading, description, image, status }) => {
  const [link, setLink] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    if (link) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [link]);
  return (
    <>
      <div className="w-fit mx-auto">
        <div className="flex justify-center">
          <img
            className="w-[100px] h-[60px] md:w-[120px] md:h-[80px]"
            src={image}
            alt="Missing"
          />
        </div>
        <div className="flex justify-center my-3">
          <Paragraph variant={"MainParagraph"} className={"text-center"}>
            {heading}
          </Paragraph>
        </div>
        <div className="flex justify-center">
          <MainButton
            variant={status === "Remove" ? "outlineextrasmall" : "extrasmall"}
            onClick={() => setLink(true)}
          >
            {status}
          </MainButton>
        </div>
      </div>
      {link && (
        <Popups
          setTrigger={() => setLink(false)}
          ShowCrossButton={ShowCrossButton}
          popupWidth={"w-[350px] h-[500px] sm:w-[600px] sm:h-[500px] 2xl:w-[600px] 2xl:h-[500px] pb-10"}
              crossPosition={
                "flex justify-end w-[16px] absolute left-[320px] sm:left-[565px] top-3"
              }
        >
          <VerificationPopUp
            closePopup={() => setLink(false)}
            resendButton={true}
            firstMainHeading={"Connect your association profile"}
            firstP={
              "Link your association profile to enhance your business's visibility and trustworthiness on our platform."
            }
            label={"Association profile link"}
            placeholder={"Paste the URL of your association profile here."}
            secondMainHeading={"Association profile linked"}
            secondP={
              "Congratulations! Your association profile has been successfully linked to your Trades Poster account. This link will facilitate better engagement with the community and enhance the credibility of your business profile. You can now manage your profile settings and receive updates directly through your linked association."
            }
            smallPara={
              "By linking your association profile, you allow Trades Poster to display your profile on our platform, which may increase customer engagement. We assure you that your information is securely handled in compliance with privacy regulations."
            }
          />
        </Popups>
      )}
    </>
  );
};

export default GeneraComponet;
