import React from "react";
import MovingLeft from "../../components/common/FullLengthSwipers/MovingLeft";
import { businessCategories } from "../Home/BusinessCategories";
import { Link } from "react-router-dom";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";

const FullLengthSlider = () => {
  return (
    <>
      <div className="mt-40 w-[100%]">
        <MovingLeft>
          {businessCategories
            .sort((a, b) =>
              a.businessCategoryName.localeCompare(b.businessCategoryName)
            )
            .map((item, index) => (
              <Link to={`/services/?id=${item.id}`}>
                <div className="sliderImg" key={index}>
                  <div className="w-[200px] sm:w-[250px] xl:w-[250px] 2xl:w-[270px] cursor-pointer mx-4">
                    <img
                      className="w-[200px] h-[250px] sm:w-[250px] sm:h-[320px] xl:w-[250px] xl:h-[320px] 2xl:w-[270px] 2xl:h-[340px] object-cover rounded-xl"
                      src={require("../../assets/img/default-image.jpg")}
                      alt="/"
                    />
                    <SecondaryHeading
                      variant={"MainParagraphHeading"}
                      className={"mt-3 text-offblack"}
                    >
                      {item.businessCategoryName}
                    </SecondaryHeading>
                  </div>
                </div>
              </Link>
            ))}
        </MovingLeft>
      </div>
    </>
  );
};

export default FullLengthSlider;
