import React, { useState, useEffect } from "react";
import "../../../index.css";
import Heading from "../Typography/Heading";
import { useLocation } from "react-router-dom";
import SecondaryHeading from "../Typography/SecondaryHeading";
import SpecialText from "../Typography/SpecialText";
import AuxiliaryText from "../Typography/AuxiliaryText";

export default function RangeSlider({
  units,
  starting,
  Maximum,
  labelClasses,
}) {
  const [blue, setBlue] = useState(starting);
  const location = useLocation();

  useEffect(() => {
    const sliderEl = document.querySelector("#range2");
    const tempSliderValue = blue;
    const progress = (tempSliderValue / sliderEl.max) * 100;
    sliderEl.style.background = `linear-gradient(to right, #2E00FF ${progress}%, #E8F0FF ${progress}%)`;

    sliderEl.addEventListener("input", (event) => {
      const tempSliderValue = event.target.value;
      const progress = (tempSliderValue / sliderEl.max) * 100;

      sliderEl.style.background = `linear-gradient(to right, #2E00FF ${progress}%, #E8F0FF ${progress}%)`;
    });
  }, []);
  return (
    <>
      {location.pathname.includes("SellerDashboard/Posting/CreatePost") ? (
        <label className="value2 mb-1 mt-2 flex justify-center text-center">
          <AuxiliaryText variant={"Placeholder"} className={"text-offblack"}>
            {blue} {units}
          </AuxiliaryText>
        </label>
      ) : (
        <SpecialText variant={"LargeNumber"} className={"flex justify-center text-offblack"}>
          {blue} {units}
        </SpecialText>
      )}
      <div className="range">
        <input
          type="range"
          min={"1"}
          max={Maximum}
          id="range2"
          value={blue}
          onChange={(e) => setBlue(e.target.value)}
        />
      </div>
    </>
  );
}
