import React, { useRef, useState } from "react";
import AuxiliaryText from "../Typography/AuxiliaryText";
import MainButton from "../MainButton/MainButton";
import useClickOutside from "../../../Hooks/useClickOutside";

const SortingButton = () => {
  const [filters, setFilters] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => {
    setFilters(false);
  });
  return (
    <>
      <div className="relative" ref={ref}>
        <MainButton
          variant={filters ? "smallbetween" : "outlinesmallbetween"}
          ref={ref}
          onClick={() => setFilters(!filters)}
          className={"hidden md:block"}
        >
          Sort
          <i className={
                  filters
                    ? "fi fi-rs-angle-up text-sm cursor-pointer"
                    : "fi fi-rs-angle-down text-sm cursor-pointer"
                }></i>
        </MainButton>
        {filters && (
          <>
            <div
              className="absolute z-10 mt-3 w-60 h-[140px] bg-pastalblue text-offblack p-5 shadow-lg md:right-1 ring-black ring-opacity-5 focus:outline-none rounded-xl"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabIndex={-1}
            >
              <button
                className="block px-4 py-2 text-offblack hover:text-primaryblue w-full"
                onClick={() => setFilters(false)}
              >
                <AuxiliaryText variant={"MenuDropdown"}>7 days</AuxiliaryText>
              </button>
              <button
                className="block px-4 py-2 text-offblack hover:text-primaryblue w-full"
                onClick={() => setFilters(false)}
              >
                <AuxiliaryText variant={"MenuDropdown"}>14 days</AuxiliaryText>
              </button>
              <button
                className="block px-4 py-2 text-offblack hover:text-primaryblue w-full"
                onClick={() => setFilters(false)}
              >
                <AuxiliaryText variant={"MenuDropdown"}>28 days</AuxiliaryText>
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SortingButton;
