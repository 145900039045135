import React from "react";
import Heading from "../../components/common/Typography/Heading";
import { Link } from "react-router-dom";
import Paragraph from "../../components/common/Typography/Paragraph";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import SpecialText from "../../components/common/Typography/SpecialText";

const PricingEstimates = () => {
  const headings = ["Sydney", "Melbourne", "Brisbane", "Perth", "Adelaide"];
  const data = [
    {
      heading: "Installation (per unit)",
      cost: [
        "$3,000 - $7,000",
        "$2,800 - $6,500",
        "$2,500 - $6,000",
        "$3,000 - $7,000",
        "$2,800 - $6,500",
      ],
    },
    {
      heading: "Maintenance (per session)",
      cost: [
        "$150 - $300",
        "$140 - $280",
        "$130 - $260",
        "$150 - $300",
        "$140 - $280",
      ],
    },
    {
      heading: "Repairs (per hour)",
      cost: [
        "$100 - $200",
        "$90 - $180",
        "$80 - $160",
        "$100 - $200",
        "$90 - $180",
      ],
    },
    {
      heading: "Duct Cleaning (per system)",
      cost: [
        "$300 - $500",
        "$280 - $480",
        "$260 - $450",
        "$300 - $500",
        "$280 - $480",
      ],
    },
    {
      heading: "Thermostat Installation (per unit)",
      cost: [
        "$200 - $500",
        "$180 - $450",
        "$160 - $400",
        "$200 - $500",
        "$180 - $450",
      ],
    },
    {
      heading: "Energy Efficiency Upgrades",
      cost: [
        "$1,000 - $5,000",
        "$900 - $4,500",
        "$800 - $4,000",
        "$1,000 - $5,000",
        "$900 - $4,500",
      ],
    },
    {
      heading: "Emergency Repairs (per hour)",
      cost: [
        "$150 - $250",
        "$140 - $230",
        "$130 - $220",
        "$150 - $250",
        "$140 - $230",
      ],
    },
    {
      heading: "System Tune-Up (per session)",
      cost: [
        "$100 - $200",
        "$90 - $180",
        "$80 - $160",
        "$100 - $200",
        "$90 - $180",
      ],
    },
    {
      heading: "HVAC Inspection (per inspection)",
      cost: [
        "$200 - $400",
        "$180 - $360",
        "$160 - $320",
        "$200 - $400",
        "$180 - $360",
      ],
    },
    {
      heading: "Heat Pump Installation (per unit)",
      cost: [
        "$3,500 - $8,000",
        "$3,200 - $7,500",
        "$3,000 - $7,000",
        "$3,500 - $8,000",
        "$3,200 - $7,500",
      ],
    },
    {
      heading: "Boiler Installation (per unit)",
      cost: [
        "$4,000 - $10,000",
        "$3,800 - $9,500",
        "$3,500 - $9,000",
        "$4,000 - $10,000",
        "$3,800 - $9,500",
      ],
    },
    {
      heading: "HVAC System Replacement",
      cost: [
        "$5,000 - $15,000",
        "$4,800 - $14,500",
        "$4,500 - $14,000",
        "$5,000 - $15,000",
        "$4,800 - $14,500",
      ],
    },
  ];

  const factors = [
    {
      head: "System Size and Type",
      paragraph: "Larger or more complex systems typically cost more.",
    },
    {
      head: "Efficiency Ratings",
      paragraph:
        "Higher efficiency systems can have higher upfront costs but lower operating costs.",
    },
    {
      head: "Labor and Material Costs",
      paragraph:
        "Vary based on the scope of the project and local market rates.",
    },
  ];
  return (
    <>
      <div className="w-[350px] sm:w-[550px] md:w-[650px] lg:w-[980px] xl:w-[1200px] 2xl:w-[1748px] 3xl:w-[1850px] 2k:w-[2000px] 4k:w-[2150px] h-[1600px] lg:h-[1200px] xl:h-[1300px] 2xl:h-[1400px] 2k:h-[1500px] 4k:h-[1600px] mx-auto relative mt-40 flex items-center justify-center">
        <div className="absolute w-[330px] sm:w-[450px] md:w-[600px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
          <div className="text-white">
            <Link to={"/cost-estimation"}>
              <Heading
                variant={"h2"}
                className={" hover:text-primaryblue w-fit"}
              >
                Cost Guide
              </Heading>
            </Link>
            <Heading variant={"h5"} className={"mt-5"}>
              Understanding the Cost of HVAC Services
            </Heading>
            <div className="mt-5">
              <div className="flex items-center gap-2">
                <Heading variant={"h6"}>General Cost Breakdown</Heading>
              </div>
              <Paragraph variant={"MainParagraph"} className={"mt-3"}>
                Typical costs associated with HVAC services include
                installation, maintenance, repairs, and energy efficiency
                upgrades.
              </Paragraph>
            </div>
            <Heading variant={"h5"} className={"mt-5"}>
              Factors influencing cost
            </Heading>
            {factors.map((e, index) => {
              return (
                <>
                  <div className="mt-3">
                    <div className="flex items-center gap-2">
                      <Heading variant={"h6"}>{e.head}</Heading>
                    </div>
                    <Paragraph variant={"MainParagraph"} className={"mt-2"}>
                      {e.paragraph}
                    </Paragraph>
                  </div>
                </>
              );
            })}
          </div>
          <div className="bg-white p-5 sm:p-10 mt-20 rounded-3xl">
            <div className="grid grid-cols-7 border border-r-0 border-mediumgray w-full">
              <div className="col-span-2 flex items-center justify-center border-r border-r-mediumgray">
                <SecondaryHeading
                  variant={"MainParagraphHeading"}
                  className={"text-offblack"}
                >
                  Services
                </SecondaryHeading>
              </div>
              {headings.map((e, index) => {
                return (
                  <>
                    <div className="col-span-1 flex items-center justify-center border-r border-r-mediumgray py-1">
                      <SecondaryHeading
                        variant={"MainParagraphHeading"}
                        key={index}
                        className={"text-offblack"}
                      >
                        {e}
                      </SecondaryHeading>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="grid grid-cols-7 w-full border border-t-0 border-b-0 border-mediumgray">
              {data.map((e, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="col-span-2 border-b border-b-mediumgray"
                    >
                      <Paragraph
                        variant={"MainParagraph"}
                        className={"text-offblack py-2"}
                      >
                        &nbsp;{e.heading}
                      </Paragraph>
                    </div>
                    {e.cost.map((item, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            className="col-span-1 border-b border-l border-b-mediumgray border-l-mediumgray py-2 flex items-center justify-center"
                          >
                            <Paragraph
                              variant={"MainParagraph"}
                              className={"text-offblack"}
                            >
                              {item}
                            </Paragraph>
                          </div>
                        </>
                      );
                    })}
                  </>
                );
              })}
            </div>
            <SpecialText
              variant={"FootNoteDisclaimer"}
              className={"text-offblack mt-10 max-w-[700px]"}
            >
              <span className="font-semibold">Disclaimer:</span> The prices
              listed above are estimates and can vary based on specific project
              requirements, system types, and other factors. It's recommended to
              obtain a detailed quote from a professional HVAC business to get
              accurate pricing tailored to your project needs.
            </SpecialText>
          </div>
        </div>
        <img
          src={require("../../assets/img/default-image.jpg")}
          alt="nothing found"
          className="w-full h-full rounded-3xl shrink-0 object-cover"
        />
      </div>
    </>
  );
};

export default PricingEstimates;
