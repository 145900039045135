import React from "react";
// import Hero from "../sections/Protag/Hero";
import LeftText from "../sections/Protag/LeftText";
import RighText from "../sections/Protag/RightText";
import SuccessSlider from "../sections/Protag/SuccessSlider";
import PricingGuide from "../sections/Protag/PricingGuide";
import ImageZoomEffect from "../sections/Protag/ZoomContainer";

const ProTags = () => {
  return (
    <>
      <ImageZoomEffect />
      {/* <Hero /> */}
      <LeftText
        heading={"Gain unmatched visibility"}
        text={
          "With the PRO tag, your business gains priority visibility in search results and featured sections, ensuring more eyes on your services. This includes always being at the top of search results, and enhanced exposure to customers."
        }
      />
      <RighText
        heading={"Drive more engagement and build trust"}
        text={
          "Businesses with the PRO tag experience significantly higher engagement rates and build stronger relationships. The PRO tag signals trust and professionalism."
        }
      />
      <SuccessSlider />
      <PricingGuide />
    </>
  );
};

export default ProTags;
