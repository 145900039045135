import React from "react";
import Heading from "../../components/common/Typography/Heading";

const MessagingSystemLayout = () => {
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-20 lg:mt-40 xl:mt-40 2xl:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Manage your business with ease
        </Heading>
      </div>
      <div className="mx-[5%] sm:mx-auto h-auto sm:w-[550px] sm:h-[350px] md:w-[650px] md:h-[400px] lg:w-[950px] lg:h-[700px] xl:w-[1150px] xl:h-[800px] 2xl:w-[1440px] 2xl:h-[900px] 3xl:w-[1600px] 3xl:h-[1000px] 2k:w-[1750px] 2k:h-[1100px] 4k:w-[1900px] 4k:h-[1200px] aspect-[1440/900] mt-10">
        <img
          src={require("../../assets/img/contact-us.jpg")}
          className="w-full h-full rounded-3xl object-cover"
          alt=""
        />
      </div>
    </>
  );
};

export default MessagingSystemLayout;
