import React from "react";

const CircularButton = ({
  variant,
  onClick,
  disabled,
  children,
  titleText,
}) => {
  return (
    <>
      {variant === "prev" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type="button"
          className="relative group overflow-hidden border border-offblack hover:border-primaryblue size-[35px] sm:size-[38px] md:size-[40px] rounded-full flex items-center justify-center"
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-0 flex w-full h-0 mb-0 transition-all duration-300 ease-in-out transform translate-y-0 bg-primaryblue group-hover:h-[120%]"></span>
          <span className="relative group-hover:text-white transition-all duration-300 size-4 flex items-center justify-center">
            <i className="fi fi-rs-angle-left cursor-pointer text-base text-offblack hover:text-white group-hover:text-white mt-[2px]"></i>
          </span>
        </button>
      )}

      {variant === "next" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type="button"
          className="relative group overflow-hidden border border-offblack hover:border-primaryblue size-[35px] sm:size-[38px] md:size-[40px] rounded-full flex items-center justify-center"
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-0 flex w-full h-0 mb-0 transition-all duration-300 ease-in-out transform translate-y-0 bg-primaryblue group-hover:h-[120%]"></span>
          <span className="relative group-hover:text-white transition-all duration-300 size-4 flex items-center justify-center">
            <i className="fi fi-rs-angle-right cursor-pointer text-base text-offblack hover:text-white group-hover:text-white mt-[2px]"></i>
          </span>
        </button>
      )}
      {variant === "prev30" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type="button"
          className="relative group overflow-hidden border border-offblack hover:border-primaryblue size-[25px] sm:size-[28px] md:size-[30px] rounded-full flex items-center justify-center"
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-0 flex w-full h-0 mb-0 transition-all duration-300 ease-in-out transform translate-y-0 bg-primaryblue group-hover:h-[120%]"></span>
          <span className="relative group-hover:text-white transition-all duration-300 size-4 flex items-center justify-center">
            <i className="fi fi-rs-angle-left cursor-pointer text-xs text-offblack hover:text-white group-hover:text-white mt-[2px]"></i>
          </span>
        </button>
      )}

      {variant === "next30" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type="button"
          className="relative group overflow-hidden border border-offblack hover:border-primaryblue size-[25px] sm:size-[28px] md:size-[30px] rounded-full flex items-center justify-center"
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-0 flex w-full h-0 mb-0 transition-all duration-300 ease-in-out transform translate-y-0 bg-primaryblue group-hover:h-[120%]"></span>
          <span className="relative group-hover:text-white transition-all duration-300 size-4 flex items-center justify-center">
            <i className="fi fi-rs-angle-right cursor-pointer text-xs text-offblack hover:text-white group-hover:text-white mt-[2px]"></i>
          </span>
        </button>
      )}
      {variant === "prev30White" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type="button"
          className="relative group overflow-hidden border border-white hover:border-primaryblue size-[25px] sm:size-[28px] md:size-[30px] rounded-full flex items-center justify-center"
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-0 flex w-full h-0 mb-0 transition-all duration-300 ease-in-out transform translate-y-0 bg-primaryblue group-hover:h-[120%]"></span>
          <span className="relative group-hover:text-white transition-all duration-300 size-4 flex items-center justify-center">
            <i className="fi fi-rs-angle-left cursor-pointer text-xs text-white hover:text-white group-hover:text-white mt-[2px]"></i>
          </span>
        </button>
      )}

      {variant === "next30White" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type="button"
          className="relative group overflow-hidden border border-white hover:border-primaryblue size-[25px] sm:size-[28px] md:size-[30px] rounded-full flex items-center justify-center"
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-0 flex w-full h-0 mb-0 transition-all duration-300 ease-in-out transform translate-y-0 bg-primaryblue group-hover:h-[120%]"></span>
          <span className="relative group-hover:text-white transition-all duration-300 size-4 flex items-center justify-center">
            <i className="fi fi-rs-angle-right cursor-pointer text-xs text-white hover:text-white group-hover:text-white mt-[2px]"></i>
          </span>
        </button>
      )}
      {variant === "prev50" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type="button"
          className="relative group overflow-hidden border border-offblack hover:border-primaryblue size-[45px] sm:size-[48px] md:size-[50px] rounded-full flex items-center justify-center"
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-0 flex w-full h-0 mb-0 transition-all duration-300 ease-in-out transform translate-y-0 bg-primaryblue group-hover:h-[120%]"></span>
          <span className="relative group-hover:text-white transition-all duration-300 size-4 flex items-center justify-center">
            <i className="fi fi-rs-angle-left cursor-pointer text-base text-offblack size-4 hover:text-white group-hover:text-white mb-1"></i>
          </span>
        </button>
      )}

      {variant === "next50" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type="button"
          className="relative group overflow-hidden border border-offblack hover:border-primaryblue size-[45px] sm:size-[48px] md:size-[50px] rounded-full flex items-center justify-center"
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-0 flex w-full h-0 mb-0 transition-all duration-300 ease-in-out transform translate-y-0 bg-primaryblue group-hover:h-[120%]"></span>
          <span className="relative group-hover:text-white transition-all duration-300 size-4 flex items-center justify-center">
            <i className="fi fi-rs-angle-right cursor-pointer text-base text-offblack size-4 hover:text-white group-hover:text-white mb-1"></i>
          </span>
        </button>
      )}
      {variant === "cross" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type="button"
          className="relative group overflow-hidden border border-offblack hover:border-primaryblue size-[35px] sm:size-[38px] md:size-[40px] rounded-full flex items-center justify-center"
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-0 flex w-full h-0 mb-0 transition-all duration-300 ease-in-out transform translate-y-0 bg-primaryblue group-hover:h-[120%]"></span>
          <span className="relative group-hover:text-white transition-all duration-300 size-4 flex items-center justify-center">
            <i className="fi fi-rs-cross cursor-pointer text-offblack text-base size-4 hover:text-white group-hover:text-white mb-1"></i>
          </span>
        </button>
      )}
    </>
  );
};
export default CircularButton;
