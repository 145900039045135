import Skid from "../../../assets/img/certifications/latest/skidsteer.svg";
import Excavation from "../../../assets/img/certifications/latest/excavation.svg";
import Demolitions from "../../../assets/img/certifications/latest/demolitions.svg";
import TreeRem from "../../../assets/img/certifications/latest/treeremovalists.svg";
import ConfinedSpace from "../../../assets/img/certifications/latest/confinedspace.svg";
import WTH from "../../../assets/img/certifications/latest/workatheights.svg";
import WWCC from "../../../assets/img/certifications/latest/wwcc.svg";
import WhiteCard from "../../../assets/img/certifications/latest/whitecard.svg";
import FirstAid from "../../../assets/img/certifications/latest/firstaid.svg";
import Asbestos from "../../../assets/img/certifications/latest/asbestos.svg";
import Airconditioning from "../../../assets/img/certifications/latest/airconditioning.svg";
import Refrigeration from "../../../assets/img/certifications/latest/refrigeration.svg";
import Electrician from "../../../assets/img/certifications/latest/electrician.svg";
import Plumber from "../../../assets/img/certifications/latest/plumbing.svg";
import GasFitter from "../../../assets/img/certifications/latest/gasfitter.svg";
import Engineering from "../../../assets/img/certifications/latest/engineering.svg";
import Surveying from "../../../assets/img/certifications/latest/surveying.svg";
import Cleaning from "../../../assets/img/certifications/latest/cleaning.svg";
import Envoirmental from "../../../assets/img/certifications/latest/envoirmental.svg";
import Insurance from "../../../assets/img/certifications/latest/insurance.svg";
import WorkCover from "../../../assets/img/certifications/latest/workcover.svg";
import BuildingContract from "../../../assets/img/certifications/latest/buildcontractor.svg";
import LandScaping from "../../../assets/img/certifications/latest/landscaping.svg";
import FireProtection from "../../../assets/img/certifications/latest/fireprotection.svg";
import TrafficManagement from "../../../assets/img/certifications/latest/trafficmanagement.svg";
import Scaffolding from "../../../assets/img/certifications/latest/scaffolding.svg";
import WaterProofing from "../../../assets/img/certifications/latest/waterproofing.svg";
import Roofing from "../../../assets/img/certifications/latest/roofing.svg";
// import  from "../../../assets/img/certifications/latest/"
// import  from "../../../assets/img/certifications/latest/"
// import  from "../../../assets/img/certifications/latest/"

export const General = [
  {
    heading: "Children Check",
    description:
      "Badge related information Badge related information badge related information",
    image: WWCC,
    status: "Add",
  },
  // {
  //   heading: "Insurance",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Insurance,
  //   status: "Add",
  // },
  // {
  //   heading: "Police check",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: PoliceCheck,
  //   status: "Add",
  // },
  // {
  //   heading: "Carpenter",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Carpenter,
  //   status: "Add",
  // },
  // {
  //   heading: "Waterproofing",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Waterproofing,
  //   status: "Add",
  // },

  // {
  //   heading: "Inspection",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Inspection,
  //   status: "Add",
  // },
  {
    heading: "White Card",
    description:
      "Badge related information Badge related information badge related information",
    image: WhiteCard,
    status: "Add",
  },
  {
    heading: "First Aid",
    description:
      "Badge related information Badge related information badge related information",
    image: FirstAid,
    status: "Remove",
  },
  {
    heading: "Engineering",
    description:
      "Badge related information Badge related information badge related information",
    image: Engineering,
    status: "Add",
  },
  {
    heading: "Surveying",
    description:
      "Badge related information Badge related information badge related information",
    image: Surveying,
    status: "Add",
  },
  {
    heading: "Cleaning",
    description:
      "Badge related information Badge related information badge related information",
    image: Cleaning,
    status: "Add",
  },
  // {
  //   heading: "Health & Safety",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Cleaning,
  //   status: "Add",
  // },
  // {
  //   heading: "Tool Safety",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Cleaning,
  //   status: "Add",
  // },
  // {
  //   heading: "Risk Assessment",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Cleaning,
  //   status: "Add",
  // },
  {
    heading: "Environmental",
    description:
      "Badge related information Badge related information badge related information",
    image: Envoirmental,
    status: "Add",
  },
  // {
  //   heading: "Project Management",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Cleaning,
  //   status: "Add",
  // },
  {
    heading: "Liability Insurance",
    description:
      "Badge related information Badge related information badge related information",
    image: Insurance,
    status: "Add",
  },
  {
    heading: "Work Cover",
    description:
      "Badge related information Badge related information badge related information",
    image: WorkCover,
    status: "Add",
  },
  {
    heading: "Building Contractor",
    description:
      "Badge related information Badge related information badge related information",
    image: BuildingContract,
    status: "Add",
  },
  {
    heading: "Roofing",
    description:
      "Badge related information Badge related information badge related information",
    image: Roofing,
    status: "Add",
  },
  {
    heading: "Waterproofing",
    description:
      "Badge related information Badge related information badge related information",
    image: WaterProofing,
    status: "Add",
  },
  {
    heading: "Landscaping",
    description:
      "Badge related information Badge related information badge related information",
    image: LandScaping,
    status: "Add",
  },
  {
    heading: "Flat Pack Assembly",
    description:
      "Badge related information Badge related information badge related information",
    image: LandScaping,
    status: "Add",
  },
  // {
  //   heading: "Contract Insurance",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Cleaning,
  //   status: "Add",
  // },
  // {
  //   heading: "Warranty Insurance",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Cleaning,
  //   status: "Add",
  // },
];
export const Licensed = [
  {
    heading: "Air Conditioning",
    description:
      "Badge related information Badge related information badge related information",
    image: Airconditioning,
    status: "Remove",
  },
  {
    heading: "Refrigeration",
    description:
      "Badge related information Badge related information badge related information",
    image: Refrigeration,
    status: "Add",
  },

  {
    heading: "Plumber",
    description:
      "Badge related information Badge related information badge related information",
    image: Plumber,
    status: "Add",
  },
  {
    heading: "Gas Fitter",
    description:
      "Badge related information Badge related information badge related information",
    image: GasFitter,
    status: "Add",
  },
  {
    heading: "Electrician",
    description:
      "Badge related information Badge related information badge related information",
    image: Electrician,
    status: "Add",
  },

  {
    heading: "Fire Protection",
    description:
      "Badge related information Badge related information badge related information",
    image: FireProtection,
    status: "Add",
  },
  {
    heading: "Asbestos",
    description:
      "Badge related information Badge related information badge related information",
    image: Asbestos,
    status: "Add",
  },
];
export const Tickets = [
  {
    heading: "Skid Steer",
    description:
      "Badge related information Badge related information badge related information",
    image: Skid,
    status: "Add",
  },
  {
    heading: "Excavation",
    description:
      "Badge related information Badge related information badge related information",
    image: Excavation,
    status: "Add",
  },

  {
    heading: "Tree Removalist",
    description:
      "Badge related information Badge related information badge related information",
    image: TreeRem,
    status: "Add",
  },
  {
    heading: "Confined Space",
    description:
      "Badge related information Badge related information badge related information",
    image: ConfinedSpace,
    status: "Add",
  },
  {
    heading: "Work At Heights",
    description:
      "Badge related information Badge related information badge related information",
    image: WTH,
    status: "Add",
  },
  {
    heading: "Demolitions",
    description:
      "Badge related information Badge related information badge related information",
    image: Demolitions,
    status: "Add",
  },
  {
    heading: "Traffic Management",
    description:
      "Badge related information Badge related information badge related information",
    image: TrafficManagement,
    status: "Add",
  },
  {
    heading: "Scaffolding",
    description:
      "Badge related information Badge related information badge related information",
    image: Scaffolding,
    status: "Add",
  },
  // {
  //   heading: "Crane Operation",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Asbestos,
  //   status: "Add",
  // },
  // {
  //   heading: "Rigging",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Asbestos,
  //   status: "Add",
  // },
  // {
  //   heading: "Welding",
  //   description:
  //     "Badge related information Badge related information badge related information",
  //   image: Asbestos,
  //   status: "Add",
  // },
];
