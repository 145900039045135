import React from "react";
import Heading from "../../components/common/Typography/Heading";
import MainButton from "../../components/common/MainButton/MainButton";
import { useNavigate } from "react-router-dom";

const HelpSupport = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[850px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] mt-40 relative">
        <div className="flex items-center justify-between">
          <Heading variant={"h2"} className={"text-offblack"}>
            Can we help?
          </Heading>
          <MainButton
            variant={"medium"}
            className={"mt-5"}
            type="button"
            onClick={() => navigate("/HelpCentre")}
          >
            Help Centre
          </MainButton>
        </div>
        <div className="sm:h-[300px] md:h-[350px] lg:h-[400px] 2xl:h-[450px] 3xl:h-[500px] 2k:h-[550px] 4k:h-[600px] aspect-[1075/450]">
        <img
          src={require("../../assets/img/handshaking.jpg")}
          className="w-full h-full rounded-3xl object-cover mt-10"
          alt=""
        />

        </div>
      </div>
    </>
  );
};

export default HelpSupport;
