import React from "react";
import PricingSlider from "../../../sections/BusinessDashboard/Subscriptions/PricingPlans/PricingSlider";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";

const PricingPlans = () => {
  // const [RequestStart, setRequetStart] = useState(false);
  return (
    <>
      <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center text-offblack"}>
          Pricing plans
        </Heading>
        <div className="mx-auto mt-[60px] min-h-screen">
          <PricingSlider />
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default PricingPlans;
