import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Heading from "../../../components/common/Typography/Heading";
import InteractiveText from "../../../components/common/Typography/InteractiveText";

const HelpCenterSection = () => {
  const { state = {} } = useLocation();
  const { section } = useParams();
  const navigate = useNavigate();
  const { dropdownTitle } = state;
  return (
    <div className="sm:mx-auto sm:w-[500px] md:w-[550px] lg:w-[600px] xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] px-[30px]">
      {dropdownTitle ? (
        <div className="border-b border-b-mediumgray pb-4 mt-10">
          <button type="butoon" onClick={() => navigate(-1)}>
            <Heading
              variant={"h6"}
              className={"text-offblack hover:text-primaryblue"}
            >
              {dropdownTitle}
            </Heading>
          </button>
        </div>
      ) : (
        <div className="border-b border-b-mediumgray pb-4 mt-10">
          <button type="button" onClick={() => navigate(-1)}>
            <Heading
              variant={"h6"}
              className={"text-offblack hover:text-primaryblue"}
            >
              {state.title}
            </Heading>
          </button>
        </div>
      )}
      {state?.data?.map(
        ({
          heading,
          content,
          list,
          list2,
          list3,
          list4,
          list5,
          ListLabel,
          ListLabel2,
          ListLabel3,
          ListLabel4,
          ListLabel5,
          InlineHeadingList,
          FooterNote,
          FooterNote2,
          extraParagraph,
        }) => (
          <div className="mt-10">
            {heading && (
              <div
                onClick={() => {
                  navigate(`/HelpCentre/article/${section}`, {
                    state: {
                      ...state,
                      displayContent: content,
                      displayTitle: heading,
                      displayDropdowntitle: dropdownTitle,
                      displayList: list,
                      displayList2: list2,
                      displayList3: list3,
                      displayList4: list4,
                      displayList5: list5,
                      displayListLabel: ListLabel,
                      displayListLabel2: ListLabel2,
                      displayListLabel3: ListLabel3,
                      displayListLabel4: ListLabel4,
                      displayListLabel5: ListLabel5,
                      displayInlineHeadingList: InlineHeadingList,
                      displayFooterNote: FooterNote,
                      displayFooterNote2: FooterNote2,
                      displayExtraParagraph: extraParagraph,
                    },
                  });
                }}
              >
                <div className="flex gap-2 cursor-pointer mb-3">
                  <i className="fi fi-ss-bullet text-base text-offblack "></i>
                  <InteractiveText
                    variant={"FAQHeader"}
                    className="text-offblack hover:text-primaryblue cursor-pointer"
                  >
                    {heading}
                  </InteractiveText>
                </div>
              </div>
            )}
            <InteractiveText variant={"FAQAnswer"}>
              {content && content.slice(0, 100).concat("...")}
            </InteractiveText>
          
          </div>
        )
      )}
    </div>
  );
};

export default HelpCenterSection;
