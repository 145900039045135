import React from "react";
import { Link } from "react-router-dom";
import BlogCard from "../../components/common/BlogCard/BlogCard";
import MainButton from "../../components/common/MainButton/MainButton";
import Heading from "../../components/common/Typography/Heading";

const BlogsData = [
  {
    link: "",
    blogName:
      "Blog Name list of service providers and select the best service on the basis of reviews",
    text: "Go select the best service on the basis of reviews. Go through the list of service providers and select the best service on the ",
  },
  {
    link: "",
    blogName:
      "Blog Name list of service and select the best service on the basis of reviews",
    text: "Go through the list of service providers select  of reviews. Go through the list of service providers and select select the best service on the basis of reviews. ",
  },
  {
    link: "",
    blogName:
      "Blog Name list of service and select the best service on the basis of reviews",
    text: "Go through the list of service providers and select of reviews. Go through the list of service ",
  },
  // {
  //   link: "",
  //   blogName:
  //     "Blog Name list of service providers and select the best service on the basis of reviews",
  //   text: "Go through the list of service providers and select the best service on the basis of reviews. select the best service on the basis of reviews. Go through the list of service providers and select the best service on the basis of reviews. select the best service on the basis of reviews.  ",
  // },
];

const Blogs = () => {
  return (
    <>
      <div className="bg-pastalblue pt-20 md:pt-20 lg:pt-20 xl:pt-40">
        <div>
          <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
            <div className="flex justify-between">
              <div>
                <Heading variant={"h2"} className={"text-offblack"}>
                  Explore our blog <br />
                  Articles, and industry updates
                </Heading>
              </div>
              <div className="ml-5 mt-auto">
                <Link to={`/blogs`}>
                  <MainButton variant={"small"}>Our blog</MainButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-fit 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] text-ellipsis overflow-hidden h-[550px] sm:h-auto gap-8 mt-10 xl:mt-20">
            {BlogsData.map((e, index) => {
              return (
                <BlogCard
                  link={e.link}
                  key={index}
                  blogName={e.blogName.split(/\s+/).slice(0, 9).join(" ")}
                  text={e.text}
                  blogTitle={e.blogTitle}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
