import React from 'react'

const Step5 = () => {
  return (
    <>
      <div className="flex items-center justify-center gap-1 mt-20">
            <svg
              width="39"
              height="4"
              viewBox="0 0 39 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="39" height="4" rx="2" fill="#2E00FF" />
            </svg>
            <svg
              width="39"
              height="4"
              viewBox="0 0 39 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="39" height="4" rx="2" fill="#2E00FF" />
            </svg>
            <svg
              width="39"
              height="4"
              viewBox="0 0 39 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="39" height="4" rx="2" fill="#2E00FF" />
            </svg>
            <svg
              width="39"
              height="4"
              viewBox="0 0 39 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="39" height="4" rx="2" fill="#2E00FF" />
            </svg>
            <svg
              width="39"
              height="4"
              viewBox="0 0 39 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="39" height="4" rx="2" fill="#2E00FF" />
            </svg>
          </div>
    </>
  )
}

export default Step5