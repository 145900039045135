import React from "react";
import Deactivation from "../../sections/CustomerDashboard/Dashboard/Deactivation";
import Heading from "../../components/common/Typography/Heading";
import DashboardMainContainer from "../../components/common/Containers/DashboardMainContainer";
const CustDeactivate = () => {
  return (
    <>
    <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center"}>
        Close your customer account
        </Heading>
        <div className="flex justify-center mt-[60px] min-h-screen">
          <Deactivation />
        </div>
      </DashboardMainContainer>
     
    </>
  );
};

export default CustDeactivate;
