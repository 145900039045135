/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import SecondaryHeading from "../Typography/SecondaryHeading";
import MainButton from "../MainButton/MainButton";
import AuxiliaryText from "../Typography/AuxiliaryText";
import SpecialText from "../Typography/SpecialText";
import Pro from "../Tags/Pro";

const SwiperCard = ({ heading, text, Images }) => {
  return (
    <>
      <div className="w-[200px] h-[250px] sm:w-[250px] sm:h-[320px] xl:w-[250px] xl:h-[320px] 2xl:w-[270px] 2xl:h-[340px] rounded-xl group/main relative cursor-pointer m-4">
        <div className="absolute w-full h-full z-10 mt-2">
          <div className="flex items-center justify-end mr-2">
            <div className="bg-primaryblue text-white px-[2px] pt-[1px]">
              <AuxiliaryText variant={"Infographic"}>Featured</AuxiliaryText>
            </div>
          </div>
          <div className="flex gap-x-3 cursor-pointer mt-44 sm:mt-[250px] xl:mt-[250px] 2xl:mt-[260px]">
            <div className="group/profile relative ml-2 shrink-0">
              <img
                className="object-center rounded-full size-6 shrink-0"
                src={require("../../../assets/img/default-image.jpg")}
                alt={"no-image"}
                onError={(e) => {
                  e.target.src = require("../../../assets/img/default-image.jpg");
                }}
              />
              <div className="hidden lg:group-hover/profile:block absolute z-20">
                <div
                  className={`bg-white -ml-2 -mt-[240px] w-[400px] h-[200px] rounded-xl p-5`}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="relative w-[48px] h-[48px]">
                        <img
                          className="object-center rounded-full w-[48px] h-[48px] cursor-pointer"
                          src={require("../../../assets/img/User.png")}
                          // src={
                          //   !companyLogo || companyLogo === "null"
                          //     ? require("../../../assets/img/hero-vector.svg").default
                          //     : companyLogo
                          // }
                          alt={"no-image"}
                          // onError={(e) => {
                          //     e.target.src = require("../../assets/img/default-image.jpg");
                          //   }}
                        />
                        <div className="absolute -mt-3 left-[30%]">
                          <div className="b mt-[1px] mr-2">
                           <Pro className={"bg-primaryblue text-white"}/>
                          </div>
                        </div>
                      </div>
                      <div className="ml-3">
                        <div>
                          <SpecialText variant={"ProfileName"}>
                            Architecture
                            {/* {heading} */}
                          </SpecialText>
                        </div>
                        <div>
                          <SpecialText variant={"OccupationLocation"}>
                            Duffy, ACT, 2611
                          </SpecialText>
                        </div>
                      </div>
                    </div>
                    <div>
                      <MainButton variant={"outlineextrasmall"}>
                        Follow
                      </MainButton>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-5">
                    <div className="w-[109px] h-[82px]">
                      <img
                        src={require("../../../assets/img/default-image.jpg")}
                        className="w-full h-full rounded-xl object-center"
                        alt="portfolio"
                      />
                    </div>
                    <div className="w-[109px] h-[82px]">
                      <img
                        src={require("../../../assets/img/default-image.jpg")}
                        className="w-full h-full rounded-xl object-center"
                        alt="portfolio"
                      />
                    </div>
                    <div className="w-[109px] h-[82px]">
                      <img
                        src={require("../../../assets/img/default-image.jpg")}
                        className="w-full h-full rounded-xl object-center"
                        alt="portfolio"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-0 h-0 border-l-[10px] border-l-transparent border-t-[15px] border-t-white border-r-[10px] border-r-transparent ml-1"></div>
              </div>
            </div>
            <div className="flex flex-col">
              <SecondaryHeading
                variant={"MainParagraphHeading"}
                className={
                  "w-[150px] sm:w-[200px] xl:w-[200px] 2xl:w-[220px] h-[40px] overflow-hidden text-ellipsis text-white"
                }
              >
                {/* {companyName?.split(/\s+/).slice(0, 3).join(" ")} */}
                {/* {companyName?.substring(0, 30)} */} A B C d e A d e f g h j
                k l m K K K l m N o p
              </SecondaryHeading>
            </div>
          </div>
        </div>
        <img
          className="h-full w-full object-cover rounded-xl opacity-90 "
          src={require("../../../assets/img/default-image.jpg")}
          alt="/"
        />
      </div>
    </>
  );
};

export default SwiperCard;
