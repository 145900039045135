import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flip, toast, ToastContainer } from "react-toastify";
import {
  GetPosterPortfolio,
  SavePosterPortfolio,
} from "../../../Api/PosterPortfolio";
import Spinner from "../../../components/common/Spinner";
import useAuth from "../../../Hooks/useAuth";
import DashboardPortfolio from "../../../sections/Portfolio/DashboardPortfolio";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
const Portfolio = () => {
  const navigate = useNavigate();

  const [ImageList, setImageList] = useState([]);
  const { auth } = useAuth();
  const [RequestStart, setRequetStart] = useState({
    isProcessing: false,
    isRequestSuccess: false,
    isRequestError: false,
  });
  const PortfolioSubmitHandler = () => {
    if (ImageList?.length > 0) {
      if (ImageList?.length === 10) {
        const Portfolio = {
          id: 0,
          userId: auth?.user.id,
          images: JSON.stringify(ImageList),
        };
        SavePosterPortfolio(Portfolio)
          .then((response) => {
            if (response.status === 200) {
              toast.success("Portfolio Saved", {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
            }
          })
          .catch((error) => {});
      } else {
        toast.error("Images must be 10 !!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Flip,
        });
      }
    } else {
      toast.error("Please Select Images !!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Flip,
      });
    }
  };
  useEffect(() => {
    setRequetStart({ ...RequestStart, isProcessing: true });
    GetPosterPortfolio(auth.user.id)
      .then((response) => {
        if (response.status === 200) {
          let Images = response.data?.key?.images?.$values ?? [];
          for (let i = 0; i < Images.length; i++) {
            setImageList((pre) => [
              ...pre,
              {
                source: Images?.[i],
              },
            ]);
          }
          setRequetStart({
            ...RequestStart,
            isProcessing: false,
            isRequestSuccess: true,
          });
        }
      })
      .catch((error) => {
        setRequetStart({
          ...RequestStart,
          isProcessing: false,
          isRequestError: true,
        });
        console.log("error", error);
      });
  }, []);
  return (
    <>
      <ToastContainer autoClose={3000} />
      <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center text-offblack"}>
          Portfolio
        </Heading>
        <div className="w-fit mx-auto mt-[60px] min-h-screen">
          {RequestStart.isProcessing && <Spinner />}
          {!RequestStart.isProcessing && (
            <DashboardPortfolio setImageList={setImageList} ImageList={ImageList} />
          )}
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default Portfolio;
