import React from "react";
import Paragraph from "../../common/Typography/Paragraph";
import { Rating } from "primereact/rating";
import SpecialText from "../Typography/SpecialText";

const ProgressBar = ({ percent, number, counter, className }) => {
  return (
    <div className="flex items-center">
      <SpecialText
        variant={"Counter"}
        className={"mr-1"}
      >
        {number}
      </SpecialText>
      <Rating value={5} stars={1} cancel={false} defaultValue={5} />
      <div
        className={` ${className} bg-[#BDBEBF] relative overflow-hidden w-full rounded-2xl ml-2`}
      >
        <div
          className={` bg-[#2E00FF] absolute left-0 h-full`}
          style={{ width: percent + "%" }}
        ></div>
      </div>
      <SpecialText
        variant={"Counter"}
        className={"ml-2 "}
      >
        {counter}
      </SpecialText>
    </div>
  );
};

export default ProgressBar;
