import React from "react";
import Heading from "../../components/common/Typography/Heading";
import MapView from "../../components/common/Map/MapView";
import LoggingSessions from "../../sections/CustomerDashboard/Dashboard/LoggingSessions";
import FollowGraph from "../../sections/CustomerDashboard/Dashboard/Following/FollowGraph";
import Post from "../../sections/CustomerDashboard/Dashboard/Post";
import { Link } from "react-router-dom";
import Reviews from "../../sections/CustomerDashboard/Dashboard/Reviews";
import DashboardMainContainer from "../../components/common/Containers/DashboardMainContainer";
import InteractiveText from "../../components/common/Typography/InteractiveText";
const CustomerDasboard = () => {
  return (
    <>
      {/* Map */}
      <DashboardMainContainer className={"px-5 pt-5 pb-10"}>
        <Heading variant={"h6"} className={"text-offblack"}>
          Map
        </Heading>
        <div className="flex justify-center h-[300px] lg:h-[350px] xl:h-[400px] 3xl:h-[450px] 2k:h-[500px] 4k:h-[550px] mt-5">
          <MapView />
        </div>
      </DashboardMainContainer>
      {/* Quotes and graph */}
      <div className="flex flex-col sm:flex-row items-center gap-8 w-full mt-8">
        <LoggingSessions />
        <FollowGraph />
      </div>
      {/* Post */}
      <div className="bg-white w-full rounded-3xl pt-5 px-5 pb-10 mt-8">
        <div className="flex items-center justify-between">
          <Heading variant={"h6"} className={"text-offblack"}>
            Post
          </Heading>
          <Link to={"/customer-dashboard/posts"}>
            <InteractiveText
              variant={"ActionLink"}
              className={"text-primaryblue"}
            >
              View all
            </InteractiveText>
          </Link>
        </div>
        <Post />
      </div>
      {/* Reviews */}
      <div className="bg-white w-full rounded-3xl pt-5 px-5 pb-10 mt-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Heading variant={"h6"} className={"text-offblack"}>Reviews</Heading>
          </div>
          <Link to={"/customer-dashboard/inbox/reviews"}>
            <InteractiveText
              variant={"ActionLink"}
              className={"text-primaryblue"}
            >
              View all
            </InteractiveText>
          </Link>
        </div>
        <Reviews />
      </div>
    </>
  );
};

export default CustomerDasboard;
