import React from "react";
import { businessCategories } from "../Home/BusinessCategories";
import AdsCard from "../../components/common/AdsCard/AdsCard";
import Heading from "../../components/common/Typography/Heading";
import MovingLeft from "../../components/common/FullLengthSwipers/MovingLeft";
import MovingRight from "../../components/common/FullLengthSwipers/MovingRight";

const DoubleLineSlider = () => {
  return (
    <>
      <div className="mx-auto w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-40 lg:mt-40 xl:mt-40 2xl:mt-40">
        <Heading variant={"h2"} className={"text-left text-offblack"}>
          Recommended <br /> Mixer repair services near you
        </Heading>
      </div>
      <div className="mt-20">
        <MovingLeft>
          {businessCategories
            .sort((a, b) =>
              a.businessCategoryName.localeCompare(b.businessCategoryName)
            )
            .map((item, index) => (
              <div className="sliderImg" key={index}>
                <div className="m-4">
                  <AdsCard
                    key={index}
                    serviceName={item.businessCategoryName}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    Images={require("../../assets/img/default-image.jpg")}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    companyLogo={require("../../assets/img/User.png")}
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </div>
              </div>
            ))}
        </MovingLeft>
      </div>
      <div>
        <MovingRight>
          {businessCategories
            .sort((a, b) =>
              a.businessCategoryName.localeCompare(b.businessCategoryName)
            )
            .map((item, index) => (
              <div className="sliderImg" key={index}>
                <div className="m-4">
                  <AdsCard
                    key={index}
                    serviceName={item.businessCategoryName}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    Images={require("../../assets/img/default-image.jpg")}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    companyLogo={require("../../assets/img/User.png")}
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </div>
              </div>
            ))}
        </MovingRight>
      </div>
    </>
  );
};

export default DoubleLineSlider;
