import React, { useRef } from "react";
import SecondaryHeading from "../Typography/SecondaryHeading";
import { useState } from "react";
import useClickOutside from "../../../Hooks/useClickOutside";
import AuxiliaryText from "../Typography/AuxiliaryText";
import SpecialText from "../Typography/SpecialText";

const Snippet = ({ variant, label, text, snippet, star }) => {
  const [opensnippet, setOpenSnippet] = useState(false);
  const [generalopensnippet, setGeneralOpenSnippet] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => {
    setOpenSnippet(false);
    setGeneralOpenSnippet(false);
  });
  return (
    <>
      {variant === "label" && (
        <div className="flex items-center mb-2">
          <div className="flex">
            <label className={`block mr-1`}>
              <AuxiliaryText variant={"FieldLabel"} className={"text-offblack"}>
                {label}
              </AuxiliaryText>
            </label>
            {star && (
              <i className="fi fi-rs-medical-star text-offblack text-[6px]"></i>
            )}
          </div>
          {snippet && (
            <div className="group relative">
              <div>
                <i
                  className="fi fi-rs-info text-sm text-primaryblue cursor-pointer ml-1"
                  ref={ref}
                  onClick={() => setOpenSnippet(!opensnippet)}
                ></i>
              </div>
              {opensnippet && (
                <div
                  className={`w-[150px] md:w-[200px] bg-offblack text-white absolute z-10 p-3 ml-2 md:ml-5 -mt-28`}
                >
                  <SpecialText variant={"FootNoteDisclaimer"}>
                    {text}
                  </SpecialText>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {variant === "general" && (
        <div className="flex items-center">
          <div className="mr-2">
            <SecondaryHeading variant={"MainParagraphHeading"}>
              {label}
            </SecondaryHeading>
          </div>
          <div className="group relative ">
            <i
              className="fi fi-rs-info text-sm text-primaryblue cursor-pointer"
              ref={ref}
              onClick={() => setGeneralOpenSnippet(!generalopensnippet)}
            ></i>
            {generalopensnippet && (
              <div className="w-[150px] md:w-[200px] bg-offblack text-white absolute z-10 p-3 ml-1 md:ml-5 -mt-28">
                <SpecialText variant={"FootNoteDisclaimer"}>{text}</SpecialText>
              </div>
            )}
          </div>
        </div>
      )}
      {variant === "icon" && (
        <div className="group relative ">
          <i
            className="fi fi-rs-info text-sm text-primaryblue cursor-pointer"
            ref={ref}
            onClick={() => setGeneralOpenSnippet(!generalopensnippet)}
          ></i>
          {generalopensnippet && (
            <div className="w-[150px] md:w-[200px] bg-offblack text-white absolute z-10 p-3 ml-1 md:ml-5 -mt-20">
              <SpecialText variant={"FootNoteDisclaimer"}>{text}</SpecialText>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Snippet;
