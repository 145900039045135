import React from 'react'
import Heading from '../../../components/common/Typography/Heading'
import AuxiliaryText from '../../../components/common/Typography/AuxiliaryText';
import ButtonText from '../../../components/common/Typography/ButtonText';
import { classNames } from "primereact/utils";

const LoggingSessions = () => {
  const data = [
    {
      session: "Current session ",
      sessiontime: "Current time"
   
    },
    {
      session: "Previous session",
      sessiontime: "Last active time"
   
    },
  ];
  return (
    <>
       <div className="rounded-3xl h-[450px] 2xl:h-[500px] 3xl:h-[500px] w-full sm:w-1/2 bg-white pt-5 px-5 pb-10">
        <Heading variant={"h6"} className={"mb-5 text-offblack"}>
          Log sessions
        </Heading>
        {data.map((e, index) => {
          return (
            <>
              <div  className={classNames({ "mt-5 pt-5 border-t border-t-mediumgray": index > 0 })}>
              <div className='px-2.5'>
                <div className="flex items-center justify-between">
                  <AuxiliaryText variant={"Placeholder"}>{e.session}</AuxiliaryText>
                  <div className="flex items-center gap-x-2">
                    <button
                      type="button"
                      className={
                        "text-offblack px-2 py-1 rounded-3xl bg-pastalblue hover:text-primaryblue"
                      }
                    >
                      <ButtonText variant={"SecondaryText"}>Revoke</ButtonText>
                    </button>
                  </div>
                </div>
                <div className={"flex items-center mt-1"}>
                  <AuxiliaryText variant={"Placeholder"}>
                    Sign in time :
                  </AuxiliaryText>
                  <AuxiliaryText variant={"Placeholder"}>
                    &nbsp; 28-05-2024 14:23
                  </AuxiliaryText>
                </div>
                <div className={"flex items-center mt-1"}>
                  <AuxiliaryText variant={"Placeholder"}>
                    {e.sessiontime} :
                  </AuxiliaryText>
                  <AuxiliaryText variant={"Placeholder"}>
                    &nbsp; 28-05-2024 14:23
                  </AuxiliaryText>
                </div>
                <div className={"flex items-center mt-1"}>
                  <AuxiliaryText variant={"Placeholder"}>
                    Session duration :
                  </AuxiliaryText>
                  <AuxiliaryText variant={"Placeholder"}>
                    &nbsp; 36 minutes
                  </AuxiliaryText>
                </div>
                <div className={"flex items-center mt-1"}>
                  <AuxiliaryText variant={"Placeholder"}>
                    Login location :
                  </AuxiliaryText>
                  <AuxiliaryText variant={"Placeholder"}>
                    &nbsp; Melbourne, VIC
                  </AuxiliaryText>
                </div>
                <div className={"flex items-center mt-1"}>
                  <AuxiliaryText variant={"Placeholder"}>
                    IP Address :
                  </AuxiliaryText>
                  <AuxiliaryText variant={"Placeholder"}>
                    &nbsp; 192.168.2.3
                  </AuxiliaryText>
                </div>
                <div className={"flex items-center mt-1"}>
                  <AuxiliaryText variant={"Placeholder"}>
                    Browser :
                  </AuxiliaryText>
                  <AuxiliaryText variant={"Placeholder"}>
                    &nbsp; Chrome
                  </AuxiliaryText>
                </div>
                <div className={"flex items-center mt-1"}>
                  <AuxiliaryText variant={"Placeholder"}>
                    Operating system :
                  </AuxiliaryText>
                  <AuxiliaryText variant={"Placeholder"}>
                    &nbsp; Windows 10
                  </AuxiliaryText>
                </div>

              </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  )
}

export default LoggingSessions