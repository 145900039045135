import React, { useEffect, useState } from "react";
import ServicesMapView from "../sections/CustomerSupport/ServicesMapView";
import WhyTradesposter from "../sections/CustomerSupport/WhyTradesposter";
import SatisfiedClients from "../sections/CustomerSupport/SatisfiedClients";
import Banner from "../components/common/Banners/Banner";
import MainButton from "../components/common/MainButton/MainButton";
import Login from "../sections/Login/Login";
import { SignUp as SignUpRegister } from "../sections/SignUp/SignUp";
import HireWithConf from "../sections/CustomerSupport/HireWithConf";
import HelpSupport from "../sections/CustomerSupport/HelpSupport";
import ImageWithTopText from "../components/common/Generics/ImageWithTopText";
import FullLengthSlider from "../sections/AllServicesSlider/FullLengthSlider";

const CustomerSupport = () => {
  const [SignUp, setSignUp] = useState(false);
  const [SignIn, setSignIn] = useState(false);
  useEffect(() => {
    if (SignUp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [SignUp]);
  return (
    <>
      <Banner
        headingClasses={
          "text-start text-white sm:w-[550px] md:w-[650px] lg:w-[800px] xl:w-[900px] 2xl:w-[1000px] 3xl:w-[1200px] 2k:w-[1400px] 4k:w-[1600px]"
        }
        heading={
          "Get Your Job Done Right with Local Trade Businesses on Trades Poster"
        }
        className={"bg-about"}
      >
        <MainButton
          variant={"large"}
          className={"mt-10 ml-[5%] sm:ml-0"}
          onClick={() => setSignUp(true)}
        >
          Sign Up Now
        </MainButton>
        {SignIn && (
          <Login
            SignIn={SignIn}
            setSignIn={setSignIn}
            SignUpState={SignUp}
            setSignUpState={setSignUp}
          />
        )}
        {SignUp && (
          <SignUpRegister
            SignUpState={SignUp}
            setSignUpState={setSignUp}
            SignIn={SignIn}
            setSignIn={setSignIn}
          />
        )}
      </Banner>
      <WhyTradesposter />
      <ImageWithTopText
        classNames={
          "mx-[5%] sm:mx-auto sm:w-[550px] sm:h-[350px] md:w-[650px] md:h-[400px] lg:w-[900px] lg:h-[500px] xl:w-[1100px] xl:h-[600px] 2xl:w-[1264px] 2xl:h-[700px] 3xl:w-[1400px] 3xl:h-[800px] 2k:w-[1600px] 2k:h-[900px] 4k:w-[1800px] 4k:h-[1000px] aspect-[1264/700]"
        }
        TopText={
          "Start with your profile, step-by-step instructions on creating and verifying a customer profile. Follow our easy guide to get started and ensure your account is secure and ready to use."
        }
      />
      <ImageWithTopText
        classNames={
          "mx-[5%] sm:mx-auto sm:w-[550px] sm:h-[350px] md:w-[650px] md:h-[400px] lg:w-[900px] lg:h-[500px] xl:w-[1100px] xl:h-[600px] 2xl:w-[1264px] 2xl:h-[700px] 3xl:w-[1400px] 3xl:h-[800px] 2k:w-[1600px] 2k:h-[900px] 4k:w-[1800px] 4k:h-[1000px] aspect-[1264/700]"
        }
        TopText={
          "Browse, Connect, and Review, follow these simple steps to find and hire the best services for your needs. Our platform makes it easy to browse available services, connect with businesses directly, and leave reviews after the job is done."
        }
      />
      <ServicesMapView />
      <ImageWithTopText
        classNames={
          "mx-[5%] sm:mx-auto sm:w-[550px] sm:h-[400px] md:w-[650px] md:h-[450px] lg:w-[950px] lg:h-[700px] xl:w-[1150px] xl:h-[800px] 2xl:w-[1440px] 2xl:h-[900px] 3xl:w-[1600px] 3xl:h-[1000px] 2k:w-[1750px] 2k:h-[1100px] 4k:w-[1900px] 4k:h-[1200px] aspect-[1440/900]"
        }
        TopText={
          "Using the messaging system, easily contact businesses directly through our platform. Follow these simple steps to start and manage your conversations efficiently."
        }
      />
      <ImageWithTopText
        classNames={
          "mx-[5%] sm:mx-auto sm:w-[550px] sm:h-[400px] md:w-[650px] md:h-[450px] lg:w-[950px] lg:h-[700px] xl:w-[1150px] xl:h-[800px] 2xl:w-[1440px] 2xl:h-[900px] 3xl:w-[1600px] 3xl:h-[1000px] 2k:w-[1750px] 2k:h-[1100px] 4k:w-[1900px] 4k:h-[1200px] aspect-[1440/900]"
        }
        TopText={
          "Personalized dashboard, manage your saved services and past interactions. Enjoy quick access to messages and reviews."
        }
      />
      <SatisfiedClients />
      <HireWithConf />
      <HelpSupport />
      <FullLengthSlider />
    </>
  );
};

export default CustomerSupport;
