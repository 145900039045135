import React, { useEffect, useState } from "react";
import Heading from "../../components/common/Typography/Heading";

const Percentaging = ({textColor, number, text}) => {
  const [count, setCount] = useState(0);
 

  useEffect(() => {
    const interval1 = setInterval(() => {
      if (document.hasFocus()) {
        setCount((prevCount) => {
          if (prevCount < number) {
            return prevCount + 1;
          } else {
            return setCount(number);
          }
        });
      }
    }, 100);
    return () => {
      clearInterval(interval1);
    };
  }, []);
  return (
    <>
      <div>
        <h1
          className={`font-ptsans font-bold text-[100px] xl:text-[200px] text-center animated-text ${textColor}`}
        >
          {count ? count : 0}%
        </h1>
        <Heading
          variant={"h6"}
          className={"text-center text-offblack mt-3"}
        >
          {text}
        </Heading>
      </div>
    </>
  );
};

export default Percentaging;
