/* eslint-disable default-case */
import React from "react";
import AuxiliaryText from "../../../../components/common/Typography/AuxiliaryText";
const PortfolioUploading = ({ setImageList, ImageList }) => {
  const uploadFile = ({ target: { files } }) => {
    let data = new FormData();

    const filesData = [...files];
    setImageList((state) => {
      return [
        ...state,
        ...filesData.map((file) => ({
          file: file,
          url: URL.createObjectURL(file),
        })),
      ];
    });
  };
  const deletImage = (index) => {
    setImageList((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const completion = 69.45;
  return (
    <>
      <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-5">
        {/* <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="w-full sm:w-[350px] md:w-[380px] lg:w-[380px] xl:w-[420px] 2xl:w-[500px] flex items-center flex-wrap gap-3 md:gap-4">
              {ImageList?.map((image, index) => {
                return (
                  <>
                    <div className="relative" key={index}>
                      <i
                        class="fi fi-sr-cross-circle text-base text-primaryblue flex justify-end items-center right-0 absolute m-1 cursor-pointer"
                        onClick={() => deletImage(index)}
                        type="button"
                      ></i>
                      <img
                        className="w-[75px] h-[75px]  rounded-lg"
                        src={image.url}
                        alt={image.file.name}
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <div>
              
            </div>
          </div>
        </div> */}
        <label className={`block mb-2`}>
          <AuxiliaryText variant={"FieldLabel"}>Post Pictures</AuxiliaryText>
        </label>

        <div className="flex items-center justify-center w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px]">
          <input
            type="file"
            id="imageslist"
            onChange={uploadFile}
            accept="image/*"
            className="cursor-pointer hidden"
            multiple
          />
          <label
            role="button"
            htmlFor="imageslist"
            className="h-[140px] w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] rounded-lg flex justify-center bg-pastalblue"
          >
            <div className="my-auto">
              <div className="flex justify-center">
                <div className="flex justify-center items-center">
                  <i className="fi fi-rs-inbox-out text-base text-offblack"></i>
                </div>
              </div>
              <div className="flex justify-center">
                <AuxiliaryText
                  variant={"Placeholder"}
                  className={"mt-1 text-primaryblue"}
                >
                  Click to upload
                </AuxiliaryText>
              </div>
            </div>
          </label>
        </div>
        <div className="mt-10 w-full">
          <div className="flex gap-3 w-full">
            <i className="fi fi-br-picture text-[50px] text-primaryblue"></i>
            <div className="w-full flex flex-col gap-2 mt-5">
              <div className="flex items-center justify-between w-full">
                <AuxiliaryText variant={"FieldLabel"}>
                  myprofile.png
                </AuxiliaryText>

                <AuxiliaryText variant={"FieldLabel"}>
                  {completion}%
                </AuxiliaryText>
              </div>
              <div className="w-full h-1.5 bg-pastalblue rounded-full">
                <div
                  className={`h-full bg-primaryblue rounded-full`}
                  style={{ width: `${completion}%` }}
                ></div>
              </div>
            </div>
            <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
          </div>
          <div className="flex gap-3 w-full mt-5">
            <i className="fi fi-br-picture text-[50px] text-primaryblue"></i>
            <div className="w-full flex flex-col gap-2 mt-5">
              <div className="flex items-center justify-between w-full">
                <AuxiliaryText variant={"FieldLabel"}>
                  myprofile.png
                </AuxiliaryText>
                <AuxiliaryText variant={"FieldLabel"}>
                  {completion}%
                </AuxiliaryText>
              </div>
              <div className="w-full h-1.5 bg-pastalblue rounded-full">
                <div
                  className={`h-full bg-primaryblue rounded-full`}
                  style={{ width: `${completion}%` }}
                ></div>
              </div>
            </div>
            <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioUploading;

{
  /* <div className="relative h-1.5 bg-lightgray rounded-full">
          <div className="absolute top-0 left-0 h-full bg-primaryblue" ></div>
        </div> */
}
