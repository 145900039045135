// import { Rating } from "primereact/rating";
import React, { useEffect, useState } from "react";
// import { IconContext } from "react-icons";
// import {
//   BsFillHeartFill as Heart,
//   BsFillShareFill as ShareButton,
// } from "react-icons/bs";
import "../../index.css";
import {
  // createSearchParams,
  // Link,
  useLocation,
  useNavigate,
  // useNavigate,
} from "react-router-dom";
import MainButton from "../../components/common/MainButton/MainButton";
// import ProgressBar from "../../components/common/ProgressBar/ProgressBar";
// import RatingStars from "../../components/common/RatingStars/RatingStars";
// import useAuth from "../../Hooks/useAuth";
// import { useServiceReviews } from "../../Hooks/useServices";
import Login from "../Login/Login";
import { SignUp } from "../SignUp/SignUp";
import useClickOutside from "../../Hooks/useClickOutside";
import { useRef } from "react";
import PortfolioBanner from "../Portfolio/PortfolioBanner";
import PosterRanking from "../PosterProfile/PosterRanking";
import PosterReviews from "../PosterProfile/PosterReviews";
import BusinessReport from "../../components/common/ReportForm/BusinessReport";
import Popup from "../../components/popup/Popup";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";
import Heading from "../../components/common/Typography/Heading";
import ReviewSubmissionForm from "../../components/common/ReviewForm/ReviewSubmissionForm";
import Title from "../../components/common/Title/Title";
import ProfileComponent from "../../components/common/Profile/ProfileComponent";
import ButtonText from "../../components/common/Typography/ButtonText";
import SpecialText from "../../components/common/Typography/SpecialText";
import Popups from "../../components/popup/Popups";
import QoutingForms from "./QouteForms/QoutingForms";
import useAuth from "../../Hooks/useAuth";
// import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
const ServiceDetailsContainer = ({
  data,
  // ListingId,
  // AuthnicateData,
  // listRating,
}) => {
  const [report, setReport] = useState(false);
  const [qouting, setQouting] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  const [isReadMore, setIsReadMore] = useState(true);
  // const getReactionInfo = AuthnicateData?.data?.$values.find(
  //   (e) => e.id?.toString() === ListingId.toString()
  // );
  const listingInfo = data;
  const navigate = useNavigate();
  // const [heartClick, setheartClick] = useState(getReactionInfo?.listReaction);
  const { auth } = useAuth();
  const [SignUpState, setSignUpState] = React.useState(false);
  const [SignIn, setSignIn] = React.useState(false);
  // const ListImage = JSON?.parse(listingInfo.pictures);
  const location = useLocation();
  // const Images = ListImage?.map((e) => {
  //   return { original: e, thumbnail: e };
  // });

  // const { data: reviewsData, status } = useServiceReviews(ListingId);
  // const [ReviewsList, setReviewsList] = useState();
  // useEffect(() => {
  //   const sortOrderByDescbyRating = (List) => {
  //     let sortedDsc = List?.sort((a, b) => {
  //       return parseInt(b.starRating) - parseInt(a.starRating);
  //     });
  //     return sortedDsc;
  //   };
  //   setReviewsList(sortOrderByDescbyRating(reviewsData?.data?.key?.$values));
  // }, [ListingId, reviewsData]);

  // const ReviewHandler = () => {
  //   if (!auth?.user) {
  //     setSignIn(true);
  //   } else {
  //     navigate({
  //       pathname: "/WriteReview/",
  //       search: `?${createSearchParams({
  //         id: ListingId,
  //       })}`,
  //     });
  //   }
  // };

  const ReadMore = ({ children }) => {
    const text = children;

    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <>
        <div className="text-inline w-full">
          {isReadMore ? text.split(/\s+/).slice(0, 30).join(" ") : text}
          <p className=" group relative border-b-2 border-lightgray w-[67px] lg:w-[70px] xl:w-[74px] cursor-pointer pb-1 mt-4">
            <span
              onClick={toggleReadMore}
              className="text-primaryblue cursor-pointer"
            >
              {isReadMore ? (
                <ButtonText variant={"SecondaryText"}>Read more</ButtonText>
              ) : (
                <ButtonText variant={"SecondaryText"}>Read less</ButtonText>
              )}
            </span>
            <span class="absolute -bottom-[2px] left-0 w-0 border-b-2 border-primaryblue transition-all duration-100 group-hover:w-[68px] lg:group-hover:w-[70px] xl:group-hover:w-[74px]" />
          </p>
        </div>
      </>
    );
  };
  useEffect(() => {
    if (report || qouting) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [report, qouting]);

  const ref = useRef(null);
  useClickOutside(ref, () => {
    setIsReadMore(true);
  });
  //  const interfaceIcons = [
  //   icon:
  //  ]
  return (
    <>
      {SignIn && (
        <Login
          location={location.pathname + location.search}
          SignIn={SignIn}
          setSignIn={setSignIn}
          SignUpState={SignUpState}
          setSignUpState={setSignUpState}
        />
      )}
      {SignUpState && (
        <SignUp
          location={location.pathname + location.search}
          SignUpState={SignUpState}
          setSignUpState={setSignUpState}
          SignIn={SignIn}
          setSignIn={setSignIn}
        />
      )}
      <>
        <div className="mx-auto w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
          <PortfolioBanner />
        </div>
        <div className="mx-auto w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] flex flex-col items-center justify-center md:flex-row md:items-start md:justify-between -mt-12 px-[30px] xl:px-[30px] 2xl:px-[30px]">
          <div className="md:w-[300px] z-10">
            <ProfileComponent
              hoverView={true}
              actions={true}
              link={`/poster-profile/?id=${listingInfo?.userProfile?.userId}`}
              heading={listingInfo?.userProfile?.company?.companyName
                .split(/\s+/)
                .slice(0, 4)
                .join(" ")}
            />
          </div>
          <div>
            <div className="w-full flex justify-center mt-5 md:mt-[90px]">
              <Heading
                variant={"h5"}
                className={"text-center md:text-start text-offblack"}
              >
                {listingInfo.listingTitle.split(/\s+/).slice(0, 5).join(" ")}
              </Heading>
            </div>
            <div className="flex justify-center md:justify-end mt-4">
              <div>
                <div className="flex items-center justify-center">
                  <MainButton
                    variant={"large"}
                    onClick={() => {
                      auth?.user ? setQouting(true) : navigate("/login");
                    }}
                  >
                    Get in touch
                  </MainButton>
                </div>
                {qouting && (
                  <Popups
                    setTrigger={setQouting}
                    ShowCrossButton={ShowCrossButton}
                    popupWidth={
                      "w-[350px] h-auto sm:w-[550px] sm:h-[650px] xl:w-[550px] xl:h-[600px] 2xl:w-[550px] 2xl:h-[650px] pb-10"
                    }
                    crossPosition={
                      "flex justify-end w-[16px] absolute left-[320px] sm:left-[520px] top-2"
                    }
                  >
                    <QoutingForms closePopup={() => setQouting(false)} />
                  </Popups>
                )}
                <div className="flex items-center justify-center mt-3">
                  <div className="flex items-center">
                    <div className="pr-3">
                      <Title text={"Report"} width={"50"}>
                        <div>
                          <i
                            className="fi fi-rs-flag-alt text-xs cursor-pointer hover:text-alertred"
                            onClick={() => setReport(true)}
                          ></i>
                        </div>
                      </Title>
                    </div>
                    <div>
                      <Title text={"Share"} width={"50"} className={"bg-white"}>
                        <div className="flex items-center px-3 border-l border-l-mediumgray">
                          <div>
                            <i className="fi fi-rs-share text-xs cursor-pointer mr-1 "></i>
                          </div>
                          <SpecialText variant={"Counter"} className={"ml-1"}>
                            1k
                          </SpecialText>
                        </div>
                      </Title>
                    </div>
                    <div>
                      <Title text={"Views"} width={"50"} className={"bg-white"}>
                        <div className="flex items-center border-l border-l-mediumgray border-r border-r-mediumgray  px-3">
                          <div>
                            <i className="fi fi-rs-eye text-xs cursor-pointer mr-1"></i>
                          </div>
                          <SpecialText variant={"Counter"} className={"ml-1"}>
                            1k
                          </SpecialText>
                        </div>
                      </Title>
                    </div>
                    <div className="flex items-center pl-3">
                      <Title text={"Saved"} width={"50"} className={"bg-white"}>
                        <div className="flex items-center h-[16px]">
                          <div>
                            <i className="fi fi-rs-heart text-xs cursor-pointer mr-1 "></i>
                          </div>
                          <SpecialText variant={"Counter"} className={"ml-1"}>
                            1k
                          </SpecialText>
                        </div>
                      </Title>
                    </div>
                  </div>

                  {report && (
                    <Popup
                      setTrigger={setReport}
                      ShowCrossButton={ShowCrossButton}
                    >
                      <BusinessReport closePopup={() => setReport(false)} />
                    </Popup>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] 2xl:pl-[30px]">
          <div className="w-full sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px]">
            <div ref={ref} className="mt-10 md:mt-20">
              <SecondaryHeading variant={"MainParagraphHeading"}>
                Description
              </SecondaryHeading>
              <div className="mt-5">
                <Paragraph
                  variant={"MainParagraph"}
                  className={"text-offblack"}
                >
                  {/* {listingInfo.description.split(/\s+/).slice(0, 300).join(" ")} */}

                  {/* {listingInfo.description.split(/\s+/).slice(0, 10).join(" ")}
                <ReadMore>{listingInfo.description.split(/\s+/).slice(10, 300).join(" ")}</ReadMore> */}
                  <ReadMore>
                    The services that an architect typically provides include
                    concept design development, preparation of construc
                    documents, and construc administration.The services that an
                    architect as typically provides include concept design
                    development, preparation of construction documents, and
                    construction administration. Architects also provide a wide
                    variety of additional services including feasibility
                    studies, architectural programming and project management.
                    The services that an architect typically provides include
                    concept design development, preparation of construction
                    documents, and construction administration.
                  </ReadMore>
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto w-[350px] sm:w-[500px] md:w-[600px] lg:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px]">
          <div>
            <div className="md:hidden flex items-center justify-center h-[135px] bg-white rounded-3xl w-auto mt-10">
              <ReviewSubmissionForm />
            </div>
            <div className="hidden md:block mt-20">
              <PosterRanking />
            </div>
            <div>
              <PosterReviews
                showMeReply={true}
                showMeReport={true}
                DropdownClasses={"bg-white"}
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ServiceDetailsContainer;
