// Latest
import Air from "../../assets/img/services/latest/airconditioner.svg";
import Architect from "../../assets/img/services/latest/architectural.svg";
import Engineering from "../../assets/img/services/latest/engineering.svg";
import BlindsCurtains from "../../assets/img/services/latest/curtains.svg";
import Bricklaying from "../../assets/img/services/latest/bricklaying.svg";
import CabinetMaking from "../../assets/img/services/latest/cabinetmaking.svg";
import Carpentery from "../../assets/img/services/latest/carpenter.svg";
import CarpetFloring from "../../assets/img/services/latest/carpetandflooring.svg";
import Caulking from "../../assets/img/services/latest/chalking.svg";
import SecurityAutomation from "../../assets/img/services/latest/automation.svg";
import CleaningServices from "../../assets/img/services/latest/cleaning.svg";
import Concret from "../../assets/img/services/latest/concrete.svg";
import Conveyancing from "../../assets/img/services/latest/conveyancing.svg";
import Decking from "../../assets/img/services/latest/decking.svg";
import Demolition from "../../assets/img/services/latest/demolitionexcavation.svg";
import Electricals from "../../assets/img/services/latest/electricals.svg";
import Fence from "../../assets/img/services/latest/fenceandgates.svg";
import Glazing from "../../assets/img/services/latest/glazing.svg";
import Handyman from "../../assets/img/services/latest/handyman.svg";
import Inspection from "../../assets/img/services/latest/inspection.svg";
import Insulation from "../../assets/img/services/latest/insulation.svg";
import Interior from "../../assets/img/services/latest/interiordesigns.svg";
import GardenLandscaping from "../../assets/img/services/latest/gardenandlandscaping.svg";
import Locksmith from "../../assets/img/services/latest/locksmith.svg";
import PaintingDecor from "../../assets/img/services/latest/painting.svg";
import PestControl from "../../assets/img/services/latest/pestcontrol.svg";
import Plastering from "../../assets/img/services/latest/plastering.svg";
import Plumbing from "../../assets/img/services/latest/plumbing.svg";
import PoolsSPAS from "../../assets/img/services/latest/pool.svg";
import Rendering from "../../assets/img/services/latest/rendering.svg";
import RenovationRestoration from "../../assets/img/services/latest/renovation.svg";
import Roofing from "../../assets/img/services/latest/roofing.svg";
import Scaffolding from "../../assets/img/services/latest/scafolding.svg";
import Shopfitting from "../../assets/img/services/latest/shopfitting.svg";
import Skipbins from "../../assets/img/services/latest/skipbins.svg";
import SolarEnergy from "../../assets/img/services/latest/solarenergy.svg";
import Staircases from "../../assets/img/services/latest/staircases.svg";
import Stonemasonry from "../../assets/img/services/latest/stonemasonary.svg";
import Tiling from "../../assets/img/services/latest/tiling.svg";
import Waterproofing from "../../assets/img/services/latest/waterproofing.svg";
import MovingStorage from "../../assets/img/services/latest/movingandstorage.svg";
import MoldAsbestos from "../../assets/img/services/latest/moldandasbestos.svg";


export const businessCategories = [
  {
    businessCategoryName: "Air conditioning & Heating",
    categoryImage: Air,
    id: 44,
    imgclassName: "cursor-pointer w-[50px] h-[50px]",
    textclassName: "mt-2 md:mt-3 text-center w-[120px] md:w-[150px]"
  },
  {
    businessCategoryName: "Architectural",
    categoryImage: Architect,
    id: 78,
    imgclassName: "cursor-pointer w-[60px] h-[60px]",
    textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
    businessCategoryName: "Engineering Services",
    categoryImage: Engineering,
    id: 77,
    imgclassName: "cursor-pointer w-[60px] h-[60px]",
    textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Blinds & Curtains",
      categoryImage : BlindsCurtains,
      id: 45,
      imgclassName: "cursor-pointer w-[50px] h-[50px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Bricklaying",
      categoryImage : Bricklaying,
      id: 46,
      imgclassName: "cursor-pointer w-[60px] h-[60px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Cabinet Making",
      categoryImage : CabinetMaking,
      id: 48,
      imgclassName: "cursor-pointer w-[60px] h-[60px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Carpentry",
      categoryImage : Carpentery,
      id: 50,
      imgclassName: "cursor-pointer w-[50px] h-[50px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Carpet & Flooring",
      categoryImage : CarpetFloring,
      id: 51,
      imgclassName: "cursor-pointer w-[60px] h-[60px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Caulking",
      categoryImage : Caulking,
      id: 57,
      imgclassName: "cursor-pointer w-[60px] h-[60px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Security & Automation",
      categoryImage : SecurityAutomation,
      id: 49,
      imgclassName: "cursor-pointer w-[50px] h-[50px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px] md:w-[80px]"
  },
  {
      businessCategoryName : "Cleaning Services",
      categoryImage : CleaningServices,
      id: 42,
      imgclassName: "cursor-pointer w-[60px] h-[60px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Concrete & Paving",
      categoryImage : Concret,
      id: 55,
      imgclassName: "cursor-pointer w-[60px] h-[60px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px] md:w-[90px]"
  },
  {
      businessCategoryName : "Conveyancing",
      categoryImage : Conveyancing,
      id: 79,
      imgclassName: "cursor-pointer w-[80px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Decking & Gazebos",
      categoryImage : Decking,
      id: 52,
      imgclassName: "cursor-pointer w-[80px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px] md:w-[90px]"
  },
  {
      businessCategoryName : "Demolition & Excavation",
      categoryImage : Demolition,
      id: 56,
      imgclassName: "cursor-pointer w-[77.7px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[80px] md:w-[100px]"
  },
  {
      businessCategoryName : "Electrical",
      categoryImage : Electricals,
      id: 58,
      imgclassName: "cursor-pointer w-[66.16px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },

  {
      businessCategoryName : "Fence & Gates",
      categoryImage : Fence,
      id: 54,
      imgclassName: "cursor-pointer w-[85.75px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px] "
  },
  {
      businessCategoryName : "Glazing & Screens",
      categoryImage : Glazing,
      id: 59,
      imgclassName: "cursor-pointer w-[95.34px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px] md:w-[90px]"
  },
  {
      businessCategoryName : "Handyman",
      categoryImage : Handyman,
      id: 76,
      imgclassName: "cursor-pointer w-[82.42px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Inspection & Surveyors",
      categoryImage : Inspection,
      id: 60,
      imgclassName: "cursor-pointer w-[82.13px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[90px] md:w-[100px]"
  },
  {
      businessCategoryName : "Insulation Services",
      categoryImage : Insulation,
      id: 75,
      imgclassName: "cursor-pointer w-[109.40px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Interior Design",
      categoryImage : Interior,
      id: 78,
      imgclassName: "cursor-pointer w-[78px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Garden & Landscaping",
      categoryImage : GardenLandscaping,
      id: 53,
      imgclassName: "cursor-pointer w-[82.39px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Locksmiths",
      categoryImage : Locksmith,
      id: 61,
      imgclassName: "cursor-pointer w-[83.12px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Painting & Decorating",
      categoryImage : PaintingDecor,
      id: 43,
      imgclassName: "cursor-pointer w-[63.89px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px] md:w-[90px]"
  },
 {
      businessCategoryName : "Pest Control",
      categoryImage : PestControl,
      id: 62,
      imgclassName: "cursor-pointer w-[75.45px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Plastering",
      categoryImage : Plastering,
      id: 63,
      imgclassName: "cursor-pointer w-[80px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Plumbing",
      categoryImage : Plumbing,
      id: 65,
      imgclassName: "cursor-pointer w-[84.37px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Pools & SPAs",
      categoryImage : PoolsSPAS,
      id: 73,
      imgclassName: "cursor-pointer w-[82.19px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Rendering",
      categoryImage : Rendering,
      id: 64,
      imgclassName: "cursor-pointer w-[44.90px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Renovation & Restoration",
      categoryImage : RenovationRestoration,
      id: 72,
      imgclassName: "cursor-pointer w-[78.81px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[80px] md:w-[100px]"
  },
  {
      businessCategoryName : "Roofing",
      categoryImage : Roofing,
      id: 66,
      imgclassName: "cursor-pointer w-[96.81px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Scaffolding",
      categoryImage : Scaffolding,
      id: 67,
      imgclassName: "cursor-pointer w-[76.77px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Shopfitting",
      categoryImage : Shopfitting,
      id: 71,
      imgclassName: "cursor-pointer w-[80px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Skip Bins",
      categoryImage : Skipbins,
      id: 68,
      imgclassName: "cursor-pointer w-[103.93px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Solar Energy",
      categoryImage : SolarEnergy,
      id: 69,
      imgclassName: "cursor-pointer w-[80px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Staircases",
      categoryImage : Staircases,
      id: 74,
      imgclassName: "cursor-pointer w-[80px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Stonemasonry",
      categoryImage : Stonemasonry,
      id: 47,
      imgclassName: "cursor-pointer w-[86px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center mr-3 w-[70px]"
  },

  {
      businessCategoryName : "Tiling",
      categoryImage : Tiling,
      id: 70,
      imgclassName: "cursor-pointer w-[80px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
      businessCategoryName : "Waterproofing",
      categoryImage : Waterproofing,
      id: 73,
      imgclassName: "cursor-pointer w-[82.19px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center mr-3 w-[70px]"
  },
  {
      businessCategoryName : "Moving & Storage",
      categoryImage : MovingStorage,
      id: 60,
      imgclassName: "cursor-pointer w-[82.13px] h-[80px]",
      textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
  {
    businessCategoryName: "Mold & Asbestos",
    categoryImage: MoldAsbestos,
    id: 60,
    imgclassName: "cursor-pointer w-[82.13px] h-[80px]",
    textclassName: "mt-2 md:mt-3 text-center w-[70px]"
  },
];
