import useAuth from "../../Hooks/useAuth";

function generateRandomNumber() {
  return Math.random() * 99999;
}
const useCustomerSidebarData = (props) => {
  const { setAuth } = useAuth();
  return [
    {
      icon: (
        <i className="fi fi-rs-dashboard text-base hover:text-primaryblue"></i>
      ),
      iconSelected: (
        <i className="fi fi-rs-dashboard text-base text-primaryblue"></i>
      ),
      linkName: "Dashboard",
      link: "/customer-dashboard/dashboard",
    },
    {
      icon: (
        <i className="fi fi-rs-user text-base hover:text-primaryblue"></i>
      ),
      iconSelected: (
        <i className="fi fi-rs-user text-base text-primaryblue"></i>
      ),
      linkName: "Account",
      link: "/customer-dashboard/account",
    },
    {
      icon: (
        <i className="fi fi-rs-rectangle-list text-base hover:text-primaryblue"></i>
        
      ),
      iconSelected: (
        <i className="fi fi-rs-rectangle-list text-base text-primaryblue"></i>
        
      ),
      linkName: "Posts",
      link: "/customer-dashboard/posts",
    },
    {
      icon: (
        <i className="fi fi-rs-settings text-base hover:text-primaryblue"></i>
        
      ),
      iconSelected: (
        <i className="fi fi-rs-settings text-base text-primaryblue"></i>
        
      ),
      linkName: "Settings",
      subMenu: [
        {
          icon: (
            <i className="fi fi-rs-mobile-button text-base hover:text-primaryblue"></i>
           
          ),
          linkName: "Mobile",
          link: "/customer-dashboard/settings/mobile",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-envelope text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Email",
          link: "/customer-dashboard/settings/email",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-bell text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Notifications",
          link: "/customer-dashboard/settings/notification-preferences",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-key-skeleton-left-right text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Reset Password",
          link: "/customer-dashboard/settings/reset-password",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      icon: (
        <i className="fi fi-rs-comment-alt-dots text-base  hover:text-primaryblue"></i>
      ),
      iconSelected: (
        <i className="fi fi-rs-comment-alt-dots text-base text-primaryblue"></i>
      ),
      linkName: "Inbox",
      subMenu: [
        {
          icon: (
            <i className="fi fi-rs-comment-alt-dots text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Messages",
          link: "/customer-dashboard/inbox/chats",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-bell text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Notifications",
          link: "/customer-dashboard/inbox/notifications",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-star text-base cursor-pointer hover:text-primaryblue"></i>
          ),
          linkName: "Reviews",
          link: "/customer-dashboard/inbox/reviews",
          id: generateRandomNumber(),
        },
      ],
    },
    {
      icon: (
        <i className="fi fi-rs-exclamation text-base hover:text-primaryblue"></i>
      ),
      iconSelected: (
        <i className="fi fi-rs-exclamation text-base text-primaryblue"></i>
      ),
      linkName: "Support",
      subMenu: [
        {
          icon: (
            <i className="fi fi-rs-info text-base hover:primaryblue"></i>
          ),
          linkName: "About",
          link: "/about-us",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-blog-pencil text-base hover:text-primaryblue"></i>
          ),
          linkName: "Blog",
          link: "/Blogs",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-life-ring text-base hover:text-primaryblue"></i>
          ),
          linkName: "Help centre",
          link: "/HelpCentre",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-time-fast text-base hover:text-primaryblue"></i>
          ),
          linkName: "Terms & Conditions",
          link: "/terms-&-conditions",
          id: generateRandomNumber(),
        },
        {
          icon: (
            <i className="fi fi-rs-incognito text-base hover:text-primaryblue"></i>
          ),
          linkName: "Privacy Policy",
          link: "/privacy-policy",
          id: generateRandomNumber(),
        },
      ],
    },

    {
      icon: (
        <i className="fi fi-rs-exit rotate-180 text-base hover:text-primaryblue"></i>
      ),
      linkName: "Log out",
      link: "/",
      onClick: () => {
        localStorage.removeItem("LoginSession");
        if (props?.toggleDrawer) {
          props.toggleDrawer();
        }
        setAuth({
          user: null,
          roles: null,
          accessToken: "",
        });
      },
      id: generateRandomNumber(),
    },
  ];
};

export default useCustomerSidebarData;
