import React from "react";
import Snippet from "../SnippetIcon/Snippet";

const SearchField = ({ label, labeling, ...restProps }) => {
  return (
    <>
      
        <div className="relative w-[400px] h-[48px] sm:w-[480px] sm:h-[52px] md:w-[550px] md:h-[56px] lg:w-[600px] lg:h-[60px]">
          { labeling && <Snippet variant={"label"} label={label} star={false} /> }
          <input
            className={`pl-[60px] bg-white focus:border focus:border-brightblue placeholder-lightgray text-offblack font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-full block p-3`}
            {...restProps}
            name={restProps?.name}
          />
          <div className="absolute left-[25px] -mt-[35px]">
            <i
              className={
                "fi fi-rs-search text-base text-offblack flex items-center"
              }
            />
          </div>
        </div>
     
    </>
  );
};

export default SearchField;
