import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

import P1 from "../../assets/img/payment/master.svg";
import P2 from "../../assets/img/payment/visa.svg";
import P3 from "../../assets/img/payment/banktransfer.svg";
import P4 from "../../assets/img/payment/amex.svg";
import P5 from "../../assets/img/payment/cash.svg";

const Payment = () => {
  const data = [
    {
      heading: "Master",
      pic: P1,
    },
    {
      heading: "Visa",
      pic: P2,
    },
    {
      heading: "Deposit",
      pic: P3,
    },
    {
      heading: "Amex",
      pic: P4,
    },
    {
      heading: "Cash",
      pic: P5,
    },
  ];
  return (
    <>
      <SecondaryHeading
        variant={"MainParagraphHeading"}
        className={"flex justify-center text-offblack mt-10"}
      >
        Payment methods
      </SecondaryHeading>
      <div className="grid grid-cols-3 md:grid-cols-2 gap-y-5 xl:mx-10 mt-5">
        {data.map((e, index) => {
          return (
            <>
              <div key={index} className="flex flex-col items-center ">
                <img
                  className="w-[50px] h-[50px]"
                  src={e.pic}
                  alt={"Master card payment method"}
                />
                <div>
                  <Paragraph
                    variant={"MainParagraph"}
                    className={"text-offblack"}
                  >
                    {e.heading}
                  </Paragraph>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Payment;
