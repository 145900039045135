import React, { useState } from "react";
import Heading from "../../components/common/Typography/Heading";
import TextFiled from "../../components/common/InputField/TextFiled";
import MainButton from "../../components/common/MainButton/MainButton";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import SpecialText from "../../components/common/Typography/SpecialText";
import { Dropdown } from "primereact/dropdown";

const HeroImage = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [step, setStep] = useState(1);
  const services = [
    { name: "Air condition & Heating" },
    { name: "Bricklaying" },
    { name: "Handyman" },
    { name: "Waterproofing" },
    { name: "Plumber" },
    { name: "Electrician" },
    { name: "Carpentry" },
    { name: "Inspection & Surveying" },
    { name: "Air condition & Heating" },
    { name: "Bricklaying" },
    { name: "Handyman" },
    { name: "Waterproofing" },
    { name: "Plumber" },
    { name: "Electrician" },
    { name: "Carpentry" },
    { name: "Inspection & Surveying" },
  ];
  return (
    <>
      <div
        className={`bg-about w-[100%] h-[100vh] relative bg-no-repeat bg-center object-cover bg-cover`}
      >
        <div className="pt-20 lg:pt-20 xl:pt-20 2xl:pt-48">
          <div className="mx-auto w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
            <div className="flex flex-col lg:flex-row lg:items-center 2xl:items-start 2xl:flex-col">
              <div>
                <Heading
                  variant={"h1"}
                  className={"text-white w-auto 2xl:w-[1000px] 3xl:w-[1200px]"}
                >
                  Get Your <br /> Estimate in Minutes
                </Heading>
              </div>
              <div className="w-[350px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] mx-auto">
                <form>
                  <div className="flex justify-center items-center bg-pastalblue p-10 rounded-3xl w-[350px] sm:w-[500px] mt-10">
                    <div className="w-[300px] sm:w-[400px]">
                      <div>
                        <label className={`block mb-2`}>
                          <AuxiliaryText
                            variant={"FieldLabel"}
                            className={"text-offblack"}
                          >
                            Please choose a service from the dropdown
                          </AuxiliaryText>
                        </label>
                        <div className="custom-dropdown">
                          <Dropdown
                            value={selectedRole}
                            onChange={(e) => setSelectedRole(e.value)}
                            options={services}
                            optionLabel="name"
                            placeholder="Select a service"
                            className="bg-white text-offblack flex items-center font-ibmplex font-normal normal-case text-[12px] leading-[16px] tracking-[0.70px] sm:text-[12px] sm:leading-[16px] sm:tracking-[0.70px] md:text-[12px] md:leading-[16px] md:tracking-[0.80px] lg:text-[12px] lg:leading-[16px] lg:tracking-[0.90px] xl:text-[13px] xl:leading-[16px] xl:tracking-[1px] 2xl:text-[13px] 2xl:leading-[16px] 2xl:tracking-[1px] rounded-lg w-full h-[50px] sm:h-[50px] md:h-[54px] lg:h-[54px] xl:h-[56px] 2xl:h-[56px]"
                          />
                          <i className="fi fi-rs-angle-down text-sm text-offblack hover:cursor-pointer custom-dropdown-icon"></i>
                        </div>
                      </div>
                      <div className="mt-5">
                        <TextFiled
                          variant={"general"}
                          label={"Describe what you need in a few words"}
                          placeholder={
                            "e.g., leaking kitchen tap, slow draining sink"
                          }
                        />
                      </div>
                      <div className="mt-5 ">
                        <TextFiled
                          variant={"general"}
                          label={"Where do you need the service?"}
                          placeholder={"Enter suburb or postcode"}
                        />
                      </div>
                      <div className="mt-10 flex justify-end">
                        <MainButton
                          variant={"small"}
                          onClick={() => setStep(2)}
                        >
                          Calculate
                        </MainButton>
                      </div>
                      <div className="mt-10 text-offblack">
                        <SpecialText variant={"FootNoteDisclaimer"}>
                          Additional Note
                        </SpecialText>
                        <SpecialText
                          variant={"FootNoteDisclaimer"}
                          className={"text-offblack mt-3"}
                        >
                          Remember, these estimates are based on average rates
                          and may vary. For updated prices and accurate quotes,
                          please contact businesses directly.
                        </SpecialText>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroImage;
