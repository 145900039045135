import React from "react";
import Heading from "../../../../components/common/Typography/Heading";
import BarChart from "../../../../components/Analytics/BarChart";
import { Link } from "react-router-dom";
import InteractiveText from "../../../../components/common/Typography/InteractiveText";

const AddQuotes = () => {
  const data1 = [250, 200, 130];
  const data2 = [400, 230, 250];
  const labels = ["January", "March", "April"];
  return (
    <>
      <div className="rounded-3xl h-[450px] 2xl:h-[500px] w-full sm:w-1/2 bg-white pt-5 px-5 pb-10">
        <div className="flex items-center justify-between">
          <Heading variant={"h6"} className={"text-offblack"}>Following</Heading>
          <Link to={"/customer-dashboard/following"}>
            <InteractiveText
              variant={"ActionLink"}
              className={"text-primaryblue"}
            >
              View all
            </InteractiveText>
          </Link>
        </div>
        <div className="flex justify-center mx-auto mt-60 xl:mt-[190px] 3xl:mt-40 2k:mt-28">
          <BarChart data1={data1} data2={data2} labels={labels} />
        </div>
      </div>
    </>
  );
};

export default AddQuotes;
