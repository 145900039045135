import React from "react";
import AllPost from "../../sections/CustomerDashboard/Dashboard/AllPost";
import Heading from "../../components/common/Typography/Heading";
import DashboardMainContainer from "../../components/common/Containers/DashboardMainContainer";
const Posts = () => {
  return (
    <>
      <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center"}>
          Posts
        </Heading>
        <div className="mt-[60px] min-h-screen">
          <AllPost />
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default Posts;
