import React from "react";
import Article from "../sections/BlogTemplate/Article";
import Relatedblogs from "../sections/BlogTemplate/Relatedblogs";

const BlogTemplate = () => {
  return (
    <>
      <Article />
      <Relatedblogs />
    </>
  );
};

export default BlogTemplate;
