import React from "react";

const SecondaryHeading = ({ variant, children, className }) => {
  return (
    <>
    
      {/* o	Usage: Subheadings within content sections. */}
      {variant === "MainParagraphHeading" && (
        <p
          className={`
          ${className} font-ptsans font-normal leading-[1.4] tracking-[0.1px] text-[14px] sm:text-[14px] md:text-[14px] lg:text-[15px] xl:text-[15px] 2xl:text-[16px] 3xl:text-[16px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Default style for footer. */}
      {variant === "FooterHeading" && (
        <p
          className={`
          ${className} font-ptsans font-normal capitalize leading-[1.4] tracking-[0.1px] text-[12px] sm:text-[12px] md:text-[13px] lg:text-[13px] xl:text-[13px] 2xl:text-[14px] 3xl:text-[14px]`}
        >
          {children}
        </p>
      )}
    </>
  );
};
export default SecondaryHeading;
