import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";

const Popup = (props) => {
  const navigate = useNavigate();
  const dropIn = {
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.9,
        type: "ease",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
      transition: {
        duration: 0.9,
        type: "ease",
        damping: 25,
        stiffness: 500,
      },
    },
  };
  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center p-3 z-50 bg-pastalblue`}
    >
      <div
        className={`fixed w-screen h-screen shadow-2xl top-0 z-40 ${props.className}`}
      >
        {!props.headingSection && (
          <>
            <div className="grid grid-cols-12">
              <div
                className={
                  "flex items-center justify-between col-span-12 md:col-span-12 lg:col-span-12 mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] h-[80px]"
                }
              >
                <div>
                  <img
                    className="w-[70px] h-[30px] cursor-pointer"
                    src={require("../../assets/img/trades-poster.png")}
                    alt="trade-poster"
                  />
                </div>
                <div
                  onClick={() => {
                    props.setTrigger(false);
                    props.onClose();
                  }}
                >
                  <div
                    className={`${
                      !props?.ShowCrossButton ? "hidden" : "block"
                    }`}
                  >
                    {props.cross ? (
                      <>
                        <i className="fi fi-rs-cross text-base text-offblack cursor-pointer"></i>
                      </>
                    ) : (
                      <i className="fi fi-rs-cross text-base text-offblack cursor-pointer"></i>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div>{props.children}</div>
      </div>
    </motion.div>
  );
};
export default Popup;
