/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSidebarData from "../Sidebar/Data";
// import useSidebarData from "../Sidebar/Data";
import "./header.css";
import { NavHashLink } from "react-router-hash-link";
import ProfileComponent from "../common/Profile/ProfileComponent";
import AuxiliaryText from "../common/Typography/AuxiliaryText";
const SideBarDrawer = ({
  isMobile,
  toggleDrawer,
  setSignUpState,
  SignUpState,
  auth,
  setAuth,
  close,
}) => {
  const [open, setOpen] = useState(false);
  const [SelectedIndex, setSelectedIndex] = useState(null);
  const [ClickIndex, setClickIndex] = useState(null);
  const sidebarLinks = useSidebarData({ toggleDrawer });
  return (
    <>
      {auth?.user && (
        <nav className={`flex flex-col items-center justify-center lg:hidden`}>
          <ProfileComponent />
          <ul className="pt-2 pb-4 space-y-1 w-[200px] mt-5 lg:mt-0">
            <li>
              {sidebarLinks?.map((e, index) => {
                if (e.subMenu) {
                  return (
                    <li>
                      <button
                        type="button"
                        className="flex items-center p-2 space-x-3 my-2 hover:text-primaryblue w-full"
                        aria-controls="dropdown-example"
                        data-collapse-toggle="dropdown-example"
                        onClick={() => {
                          setOpen(!open);
                          setSelectedIndex(index);
                        }}
                      >
                        {/* {e.icon} */}
                        <span
                          className="flex-1 text-left text-offblack whitespace-nowrap"
                          sidebar-toggle-item
                        >
                          <AuxiliaryText variant={"MenuDropdown"}>
                            {e.linkName}
                          </AuxiliaryText>
                        </span>
                        <i className="fi fi-rs-angle-down text-sm text-offblack"></i>
                      </button>
                      {open && index === SelectedIndex && (
                        <ul
                          id="dropdown-example"
                          className="transition duration-150 ease-in-out py-2 space-y-2 flex justify-center flex-col w-full "
                        >
                          {e.subMenu.map((sub) => (
                            <li>
                              <NavHashLink
                                key={sub.id}
                                to={sub.link}
                                onClick={() => {
                                  setClickIndex(sub.id);
                                  close();
                                }}
                                className={`flex items-center p-2 space-x-3 hover:text-primaryblue  text-offblack w-full px-5 ${
                                  sub.id === ClickIndex && "text-primaryblue "
                                }`}
                              >
                                <AuxiliaryText variant={"SubMenuDropdown"}>
                                  {/* {sub.icon} */}
                                  {sub.linkName}
                                </AuxiliaryText>
                              </NavHashLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                } else {
                  return (
                    <Link
                      key={index}
                      to={e.link}
                      onClick={() => {
                        close();
                        setClickIndex(index);
                        if (e.onClick) {
                          e.onClick();
                        }
                      }}
                      className={`flex items-center p-2 space-x-3 hover:text-primaryblue my-2 text-offblack  ${
                        index === ClickIndex && "text-primaryblue"
                      }`}
                    >
                      <AuxiliaryText variant={"MenuDropdown"}>
                        {/* {e.icon} */}
                        {e.linkName}
                      </AuxiliaryText>
                    </Link>
                  );
                }
              })}
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default SideBarDrawer;
// <div className="w-8 h-8 bg-pink rounded-full mr-4 flex justify-center items-end">
//   <IconContext.Provider value={{ className: `text-xl mb-1` }}>
//     <User
//       onClick={() =>
//         setAuth({ user: null, roles: null, accessToken: "" })
//       }
//     />
//   </IconContext.Provider>
// </div>
