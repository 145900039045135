import React, { useEffect, useRef } from "react";

import { useState } from "react";
import SpecailStars from "../RatingStars/SpecailStars";
import Popups from "../../popup/Popups";
import FormDesign from "./FormDesign";
import Heading from "../Typography/Heading";

const ReviewSubmissionForm = () => {
  const [form, setForm] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    if (form) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [form]);

  return (
    <>
      {/* Review Opener */}
      <div className="flex justify-center">
        <div className="flex flex-col w-full md:w-[468px]">
          <Heading
            variant={"h6"}
            className={"flex justify-center text-offblack"}
          >
            Leave a review
          </Heading>
          <div className="flex justify-center mt-3">
            <span onClick={() => setForm(!form)}>
              <SpecailStars rating={5} className={"size-4"} />
            </span>
          </div>
        </div>
      </div>
      {/* Review Form */}
      {form && (
        <Popups
          cross={true}
          setTrigger={setForm}
          ShowCrossButton={ShowCrossButton}
          onClose={() => setForm(false)}
          popupWidth={"w-[350px] sm:w-[550px] md:w-[600px]"}
          crossPosition={"flex justify-end w-4 absolute left-[320px] sm:left-[570px] mt-2"}
        >
          <FormDesign closePopup={() => setForm(false)} />
        </Popups>
      )}
    </>
  );
};

export default ReviewSubmissionForm;
