import React from "react";
import Paragraph from "../../components/common/Typography/Paragraph";
import Heading from "../../components/common/Typography/Heading";

const Paragraphs = () => {
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-20">
        <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
          In the vibrant tapestry of Australia's trade industry, TradesPoster
          emerges as the definitive nexus between ambition and expertise.
          Conceived from the vision of transcending ordinary service encounters
          into extraordinary collaborations, our platform is the crucible where
          projects and professionalism converge. Here, every handshake is the
          beginning of a masterpiece, every contract a covenant of quality. In
          the vibrant tapestry of Australia's trade industry, TradesPoster
          emerges as the definitive nexus between ambition and expertise.
          Conceived from the vision of transcending ordinary service encounters
          into extraordinary collaborations, our platform is the crucible where
          projects and professionalism converge. Here, every handshake is the
          beginning of a masterpiece, every contract a covenant of quality.
        </Paragraph>
      </div>
      <div className="mx-[7%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] mt-20">
        <Heading variant={"h6"} className={"text-offblack text-center"}>
          In the vibrant tapestry of Australia's trade industry, TradesPoster
          emerges as the definitive nexus between ambition and expertise.
          Conceived from the vision of transcending ordinary service encounters
          into extraordinary collaborations, our platform is the crucible where
          projects and professionalism converge. Here, every handshake is the
          beginning of a masterpiece, every contract a covenant of quality. In
          the vibrant tapestry of Australia's trade industry, TradesPoster
          emerges as the definitive nexus between ambition and expertise.
          Conceived from the vision of transcending ordinary service encounters
          into extraordinary collaborations, our platform is the crucible where
          projects and professionalism converge. Here, every handshake is the
          beginning of a masterpiece, every contract a covenant of quality.
        </Heading>
        <div className="flex justify-center mt-5">
          <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
            John vick , Founder
          </Paragraph>
        </div>
      </div>
    </>
  );
};

export default Paragraphs;
