import React from "react";
import Heading from "../components/common/Typography/Heading";
import BlogDesign from "../components/common/Blog/BlogDesign";

const BlogCategories = () => {
  const data = [
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
    {
      title:
        "The services that an architect typically provides include concept",
      text: "The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept The services that an architect typically provides include concept",
      time: "6 May, 2024",
    },
  ];
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] pt-48 bg-pastalblue">
        <Heading variant={"h3"} className={"text-offblack"}>
          Category title
        </Heading>
        {data.map((e, index) => {
          return (
            <>
              <BlogDesign
                key={index}
                heading={e.title}
                para={e.text}
                timing={e.time}
              />
            </>
          );
        })}
      </div>
    </>
  );
};

export default BlogCategories;
