import React from "react";
import PolicyDocs from "../sections/PrivacyPolicy/PolicyDocs";

const PrivacyPolicy = () => {
  return (
    <>
      <PolicyDocs />
    </>
  );
};

export default PrivacyPolicy;
