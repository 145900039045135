import React, { useEffect, useRef, useState } from "react";
import SearchInput from "../../components/common/SearchInput/SearchInput";
import useClickOutside from "../../Hooks/useClickOutside";
import { useSelector } from "react-redux";
import RangeSlider from "../../components/common/InputField/RangeSlider";
import MainButton from "../../components/common/MainButton/MainButton";
import MapView from "../../components/common/Map/MapView";
import TextFiled from "../../components/common/InputField/TextFiled";
import Popup from "../../components/popup/Popup";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
const Filters = () => {
  const [mainFilters, setMainFilters] = useState(false);
  const [mainSorting, setMainSorting] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);
  const [filters, setFilters] = useState(false);
  const [categories, setCategories] = useState(false);
  const [mcategories, setmCategories] = useState(false);
  const [msearchLocation, setmSearchLocation] = useState(false);
  const [mfilters, setmFilters] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  const [value, setValue] = useState("");
  const businessCategories = useSelector(
    (category) => category.BusinessCategoryReducer
  );
  useEffect(() => {
    if (mcategories || msearchLocation || mfilters) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [mcategories, msearchLocation, mfilters]);
  const ref = useRef(null);
  useClickOutside(ref, () => {
    setCategories(false);
    setSearchLocation(false);
    setFilters(false);
    setMainSorting(false);
  });
  return (
    <>
      <div className="flex justify-center h-[500px] lg:h-[700px] xl:h-[800px] 2xl:h-[1000px] 3xl:h-[1100px] 2k:h-[1200px] 4k:h-[1300px]">
        <MapView />
      </div>
      <div className="flex items-center justify-between mx-auto w-full mt-20">
        <div className="flex flex-col relative">
          <MainButton
            variant={"outlinelargebetween"}
            onClick={() => setMainSorting(!mainSorting)}
          >
            Featured
            <i
              className={
                mainSorting
                  ? "fi fi-rs-angle-up text-sm cursor-pointer"
                  : "fi fi-rs-angle-down text-sm cursor-pointer"
              }
            ></i>
          </MainButton>
          {mainSorting && (
            <div>
              <div
                ref={ref}
                className="flex flex-col rounded-xl w-60 py-5 absolute z-30 bg-white text-offblack mt-3"
              >
                <button className="block px-4 py-2 hover:text-primaryblue w-full mt-1">
                  <AuxiliaryText variant={"MenuDropdown"}>
                    Top rated
                  </AuxiliaryText>
                </button>
                <button className="block px-4 py-2  hover:text-primaryblue w-full mt-1">
                  <AuxiliaryText variant={"MenuDropdown"}>
                    Recommended
                  </AuxiliaryText>
                </button>
                <button className="block px-4 py-2  hover:text-primaryblue w-full mt-1">
                  <AuxiliaryText variant={"MenuDropdown"}>
                    Popular
                  </AuxiliaryText>
                </button>
              </div>
            </div>
          )}
        </div>
        <div>
          <MainButton
            variant={"outlinelargebetween"}
            onClick={() => setMainFilters(!mainFilters)}
          >
            <i className={"fi fi-rs-bars-filter text-base "}></i>
            Filters
          </MainButton>
        </div>
      </div>
      {mainFilters && (
        <div className="flex flex-col items-center justify-center mx-auto xl:flex-row w-fit md:w-full mt-10">
          <div className="flex justify-center w-full md:flex-1 md:mx-0">
            <SearchInput />
          </div>
          <div className="hidden md:flex mt-5 md:mt-[23px] md:gap-x-5 flex-nowrap justify-center xl:justify-end w-full mx-auto md:mx-[0px]">
            <div className="relative">
              <MainButton
                variant={"outlinelargebetween"}
                onClick={() => {
                  setCategories(!categories);
                  setFilters(false);
                  setSearchLocation(false);
                }}
              >
                Categories
                <i
                  className={
                    categories
                      ? "fi fi-rs-angle-up text-sm cursor-pointer"
                      : "fi fi-rs-angle-down text-sm cursor-pointer"
                  }
                ></i>
              </MainButton>
              {categories && (
                <div
                  ref={ref}
                  className="p-5 md:pt-10 md:pl-10 md:pr-5 md:pb-10 rounded-xl absolute z-20 bg-white text-offblack mt-3 w-[550px] "
                >
                  <form className="mx-auto">
                    <div className="mb-5">
                      <AuxiliaryText variant={"MenuDropdown"}>
                        All categories
                      </AuxiliaryText>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-2 mx-2 gap-x-5">
                      {React.Children.toArray(
                        businessCategories?.map((e, index) => {
                          return (
                            <div key={index} className="flex items-center mb-2">
                              <label
                                className="relative flex items-center rounded-full cursor-pointer mr-3"
                                htmlFor="link"
                              >
                                <input
                                  type="checkbox"
                                  className="before:content[''] peer relative size-4 cursor-pointer appearance-none rounded-[4px] border border-mediumgray transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primaryblue checked:bg-primaryblue checked:before:bg-primaryblue"
                                  id="link"
                                />
                                <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-[40%] left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                  <i className="fi fi-rs-check text-[10px]"></i>
                                </span>
                              </label>
                              <AuxiliaryText
                                variant={"SubMenuDropdown"}
                                className={"text-offblack"}
                              >
                                {e?.businessCategoryName}
                              </AuxiliaryText>
                            </div>
                          );
                        })
                      )}
                    </div>
                    <div className="flex justify-center mt-10">
                      <MainButton variant={"large"}>Apply</MainButton>
                    </div>
                  </form>
                </div>
              )}
            </div>
            <div className="relative">
              <MainButton
                className={"mx-3 md:mx-0"}
                variant={"outlinelargebetween"}
                onClick={() => {
                  setSearchLocation(!searchLocation);
                  setCategories(false);
                  setFilters(false);
                }}
              >
                Location
                <i
                  className={
                    searchLocation
                      ? "fi fi-rs-angle-up text-sm cursor-pointer"
                      : "fi fi-rs-angle-down text-sm cursor-pointer"
                  }
                ></i>
              </MainButton>
              {searchLocation && (
                <div
                  ref={ref}
                  className="px-6 pt-8 pb-4 rounded-xl w-[350px] xl:w-[370px] h-[408px] absolute z-20 bg-white mt-3"
                >
                  <form className="mx-auto">
                    <div>
                      <TextFiled
                        variant={"general"}
                        label={"Suburb"}
                        placeholder={"Enter a suburb"}
                        className={"bg-pastalblue w-[290px]"}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </div>
                    <div className="my-8">
                      <AuxiliaryText variant={"FieldLabel"}>
                        Distance
                      </AuxiliaryText>
                    </div>
                    <RangeSlider />
                    <div className="flex justify-center mt-[82px]">
                      <MainButton variant={"medium"}>Apply</MainButton>
                    </div>
                  </form>
                </div>
              )}
            </div>
            <div className="relative">
              <MainButton
                variant={"outlinelargebetween"}
                onClick={() => {
                  setFilters(!filters);
                  setSearchLocation(false);
                  setCategories(false);
                }}
              >
                Timeframe
                <i
                  className={
                    filters
                      ? "fi fi-rs-angle-up text-sm cursor-pointer"
                      : "fi fi-rs-angle-down text-sm cursor-pointer"
                  }
                ></i>
              </MainButton>
              {filters && (
                <>
                  <div
                    ref={ref}
                    className="flex flex-col rounded-xl w-60 py-5 absolute right-[5%] 2xl:right-auto z-20 bg-white text-offblack mt-3"
                  >
                    <button
                      onClick={() => setFilters(false)}
                      className="block px-4 py-2 hover:text-primaryblue w-full mt-1"
                    >
                      <AuxiliaryText variant={"MenuDropdown"}>
                        Now
                      </AuxiliaryText>
                    </button>
                    <button
                      onClick={() => setFilters(false)}
                      className="block px-4 py-2 hover:text-primaryblue w-full mt-1"
                    >
                      <AuxiliaryText variant={"MenuDropdown"}>
                        This Week
                      </AuxiliaryText>
                    </button>
                    <button
                      onClick={() => setFilters(false)}
                      className="block px-4 py-2 hover:text-primaryblue w-full mt-1"
                    >
                      <AuxiliaryText variant={"MenuDropdown"}>
                        Past Week
                      </AuxiliaryText>
                    </button>
                    <button
                      onClick={() => setFilters(false)}
                      className="block px-4 py-2 hover:text-primaryblue w-full mt-1"
                    >
                      <AuxiliaryText variant={"MenuDropdown"}>
                        This Month
                      </AuxiliaryText>
                    </button>
                    <button
                      onClick={() => setFilters(false)}
                      className="block px-4 py-2 hover:text-primaryblue w-full mt-1"
                    >
                      <AuxiliaryText variant={"MenuDropdown"}>
                        Past Month
                      </AuxiliaryText>
                    </button>
                    <button
                      onClick={() => setFilters(false)}
                      className="block px-4 py-2 hover:text-primaryblue w-full mt-1"
                    >
                      <AuxiliaryText variant={"MenuDropdown"}>
                        All time
                      </AuxiliaryText>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="md:hidden">
            <div className="flex flex-wrap sm:flex-nowrap gap-5 justify-center sm:gap-0 mt-5">
              <div>
                <MainButton
                  variant={"outlinelargebetween"}
                  onClick={() => {
                    setmCategories(!mcategories);
                  }}
                >
                  Categories
                  <i
                    className={
                      mcategories
                        ? "fi fi-rs-angle-up text-sm cursor-pointer"
                        : "fi fi-rs-angle-down text-sm cursor-pointer"
                    }
                  ></i>
                </MainButton>
              </div>
              <div>
                <MainButton
                  className={"sm:mx-3 md:mx-0"}
                  variant={"outlinelargebetween"}
                  onClick={() => {
                    setmSearchLocation(!msearchLocation);
                  }}
                >
                  Location
                  <i
                    className={
                      msearchLocation
                        ? "fi fi-rs-angle-up text-sm cursor-pointer"
                        : "fi fi-rs-angle-down text-sm cursor-pointer"
                    }
                  ></i>
                </MainButton>
              </div>
              <div>
                <MainButton
                  variant={"outlinelargebetween"}
                  ref={ref}
                  onClick={() => {
                    setmFilters(!mfilters);
                  }}
                >
                  Sort
                  <i
                    className={
                      mfilters
                        ? "fi fi-rs-angle-up text-sm cursor-pointer"
                        : "fi fi-rs-angle-down text-sm cursor-pointer"
                    }
                  ></i>
                </MainButton>
              </div>
            </div>
            <div>
              {mcategories && (
                <>
                  <Popup
                    setTrigger={() => setmCategories(false)}
                    ShowCrossButton={ShowCrossButton}
                    className={"bg-white"}
                  >
                    <>
                      <form className="mt-20 sm:mt-5 mx-[5%] bg-white">
                        <div className="text-center">
                          <AuxiliaryText variant={"MenuDropdown"}>
                            All categories
                          </AuxiliaryText>
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-2 w-full gap-x-5 overflow-auto max-h-[300px] mt-10 sm:mt-5 hidden-scrollbar px-1">
                          {React.Children.toArray(
                            businessCategories?.map((e, index) => {
                              return (
                                <div className="mb-[0.125rem] flex items-center min-h-[1.5rem]">
                                  <label
                                    className="relative flex items-center rounded-full cursor-pointer mr-3"
                                    htmlFor="link"
                                  >
                                    <input
                                      type="checkbox"
                                      className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-[4px] border border-mediumgray transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primaryblue checked:bg-primaryblue checked:before:bg-primaryblue"
                                      id="link"
                                    />
                                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-[40%] left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                      <i className="fi fi-rs-check text-[10px]"></i>
                                    </span>
                                  </label>

                                  <AuxiliaryText variant={"SubMenuDropdown"}>
                                    {e?.businessCategoryName}
                                  </AuxiliaryText>
                                </div>
                              );
                            })
                          )}
                        </div>
                        <div className="flex justify-center mt-10">
                          <MainButton variant={"large"}>Apply</MainButton>
                        </div>
                      </form>
                    </>
                  </Popup>
                </>
              )}
              {msearchLocation && (
                <>
                  <Popup
                    setTrigger={() => setmSearchLocation(false)}
                    ShowCrossButton={ShowCrossButton}
                    className={"bg-white"}
                  >
                    <>
                      <form className="mt-20 sm:mt-5 mx-[5%] bg-white sm:overflow-auto sm:h-[500px] pb-[500px]">
                        <div className="max-w-[350px] sm:max-h-[200px] mx-auto">
                          <div className="relative mt-5">
                            <TextFiled
                              variant={"general"}
                              label={"Suburb"}
                              placeholder={"Enter a suburb"}
                              className={"bg-pastalblue"}
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                            />
                          </div>
                          <div className="my-8">
                            <AuxiliaryText variant={"FieldLabel"}>
                              Distance
                            </AuxiliaryText>
                          </div>
                          <RangeSlider />
                          <div className="flex justify-center mt-[82px]">
                            <MainButton variant={"large"}>Apply</MainButton>
                          </div>
                        </div>
                      </form>
                    </>
                  </Popup>
                </>
              )}
              {mfilters && (
                <>
                  <Popup
                    setTrigger={() => setmFilters(false)}
                    ShowCrossButton={ShowCrossButton}
                    className={"bg-white"}
                  >
                    <>
                      <form className="mt-20 sm:mt-5 mx-[5%] bg-white sm:overflow-auto sm:h-[500px] pb-[500px]">
                        <div className="max-w-[350px] mx-auto">
                          <div className="flex flex-col rounded-xl w-60 py-5 text-offblack mx-auto">
                            <button className="block px-4 py-2 hover:text-primaryblue w-full">
                              <AuxiliaryText variant={"MenuDropdown"}>
                                now
                              </AuxiliaryText>
                            </button>
                            <button className="block px-4 py-2 hover:text-primaryblue w-full mt-1">
                              <AuxiliaryText variant={"MenuDropdown"}>
                                This Week
                              </AuxiliaryText>
                            </button>
                            <button className="block px-4 py-2 hover:text-primaryblue w-full mt-1">
                              <AuxiliaryText variant={"MenuDropdown"}>
                                Past Week
                              </AuxiliaryText>
                            </button>
                            <button className="block px-4 py-2 hover:text-primaryblue w-full mt-1">
                              <AuxiliaryText variant={"MenuDropdown"}>
                                This Month
                              </AuxiliaryText>
                            </button>
                            <button className="block px-4 py-2 hover:text-primaryblue w-full mt-1">
                              <AuxiliaryText variant={"MenuDropdown"}>
                                Past Month
                              </AuxiliaryText>
                            </button>
                            <button className="block px-4 py-2 hover:text-primaryblue w-full mt-1">
                              <AuxiliaryText variant={"MenuDropdown"}>
                                All Time
                              </AuxiliaryText>
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  </Popup>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Filters;
