import React from "react";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";
import { Link } from "react-router-dom";
import SpecialText from "../../components/common/Typography/SpecialText";

const PolicyDocs = () => {
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[800px] xl:w-[900px] 2xl:w-[1000px] 3xl:w-[1200px] 2k:w-[1400px] 4k:w-[1600px]">
        <Heading
          variant={"h1"}
          className={
            "flex justify-center text-center pt-48 text-offblack"
          }
        >
          Privacy Policy
        </Heading>
        <div className="mt-10 text-center">
          <SecondaryHeading variant={"MainParagraphHeading"} className={"text-offblack"}>
            Please read the entire page carefully
          </SecondaryHeading>
        </div>
      </div>

      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[850px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] text-offblack">
        <div className="mt-20">
          <SecondaryHeading variant={"MainParagraphHeading"} className={"text-offblack"}>
            Privacy Policy
          </SecondaryHeading>
        </div>
        <div className="mt-1">
          <SpecialText variant={"DateStamp"} className={"text-offblack"}>
            Last updated: June 18, 2022
          </SpecialText>
        </div>
        <div className="mt-3">
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
            <br />
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </Paragraph>
        </div>
        <div className="mt-10">
          <SecondaryHeading variant={"MainParagraphHeading"}>
            Interpretation and Definitions
          </SecondaryHeading>
        </div>
        <div className="mt-5">
          <SecondaryHeading variant={"MainParagraphHeading"}>
            Interpretation
          </SecondaryHeading>
        </div>
        <div className="mt-3">
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </Paragraph>
        </div>
        <div className="mt-5">
          <SecondaryHeading variant={"MainParagraphHeading"}>
            Definitions
          </SecondaryHeading>
        </div>
        <div className="mt-1">
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            For the purposes of this Privacy Policy:
          </Paragraph>
        </div>
        <div className="mt-3">
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            Account means a unique account created for You to access our Service
            or parts of our Service.
            <br />
            Company (referred to as either "the Company", "We", "Us" or "Our" in
            this Agreement) refers to Trades Poster ABN (35652593087), PO BOX
            204 WERRIBEE, VIC 3030.
            <br />
            Cookies are small files that are placed on Your computer, mobile
            device or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
            <br />
            Country refers to: Victoria, Australia
            <br />
            Device means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
            <br />
            Personal Data is any information that relates to an identified or
            identifiable individual.
            <br />
            Service refers to the Website.
            <br />
            Service Provider means any natural or legal person who processes the
            data on behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analyzing how the
            Service is used.
            <br />
            Third-party Social Media Service refers to any website or any social
            network website through which a User can log in or create an account
            to use the Service.
            <br />
            Usage Data refers to data collected automatically, either generated
            by the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit).
            <br />
            Website refers to Trades Poster, accessible from
            www.tradeposter.com.au
            <br />
            You means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </Paragraph>
        </div>
        <div className="mt-5">
          <SecondaryHeading variant={"MainParagraphHeading"}>
            Collecting and Using Your Personal Data
          </SecondaryHeading>
        </div>
        <div className="mt-3">
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            Types of Data Collected
            <br />
            Personal Data
            <br />
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
            <br />
            Email address
            <br />
            First name and last name
            <br />
            Phone number
            <br />
            Address, State/Postal code/City
            <br />
            Usage Data
            <br />
            Company Name /Address/ABN/Director or Authorized person details for
            Verification perposes.
            <br />
            Usage Data
            <br />
            Usage Data is collected automatically when using the Service.
            <br />
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
            <br />
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
            <br />
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
            <br />
            Information from Third-Party Social Media Services
            <br />
            The Company allows You to create an account and log in to use the
            Service through the following Third-party Social Media Services:
            <br />
            1 Google
            <br />
            2 Facebook
            <br />
            3 Twitter
            <br />
            4 LinkedIn
            <br />
          </Paragraph>
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            If You decide to register through or otherwise grant us access to a
            Third-Party Social Media Service, We may collect Personal data that
            is already associated with Your Third-Party Social Media Service's
            account, such as Your name, Your email address, Your activities or
            Your contact list associated with that account.
            <br />
            You may also have the option of sharing additional information with
            the Company through Your Third-Party Social Media Service's account.
            If You choose to provide such information and Personal Data, during
            registration or otherwise, You are giving the Company permission to
            use, share, and store it in a manner consistent with this Privacy
            Policy.
            <br />
            Tracking Technologies and Cookies
            <br />
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
            <br />
            1 Cookies or Browser Cookies. A cookie is a small file placed on
            Your Device. You can instruct Your browser to refuse all Cookies or
            to indicate when a Cookie is being sent. However, if You do not
            accept Cookies, You may not be able to use some parts of our
            Service. Unless you have adjusted Your browser setting so that it
            will refuse Cookies, our Service may use Cookies.
            <br />2 Flash Cookies. Certain features of our Service may use local
            stored objects (or Flash Cookies) to collect and store information
            about Your preferences or Your activity on our Service. Flash
            Cookies are not managed by the same browser settings as those used
            for Browser Cookies. For more information on how You can delete
            Flash Cookies, please read "Where can I change the settings for
            disabling, or deleting local shared objects?" available at
            <Link
              to={
                "https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
              }
            >
              https://helpx.adobe.com
            </Link>
            <br />
            3 Web Beacons. Certain sections of our Service and our emails may
            contain small electronic files known as web beacons (also referred
            to as clear gifs, pixel tags, and single-pixel gifs) that permit the
            Company, for example, to count users who have visited those pages or
            opened an email and for other related website statistics (for
            example, recording the popularity of a certain section and verifying
            system and server integrity).
            <br />
          </Paragraph>
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. You can learn more about cookies on TermsFeed website
            article.
            <br />
            We use both Session and Persistent Cookies for the purposes set out
            below:
            <br />
            Necessary / Essential Cookies
            <br />
            Type: Session Cookies
            <br />
            Administered by: Us
            <br />
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
            <br />
            Cookies Policy / Notice Acceptance Cookies
            <br />
            Type: Persistent Cookies
            <br />
            Administered by: Us
            <br />
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
            <br />
            Functionality Cookies
            <br />
            Type: Persistent Cookies
            <br />
            Administered by: Us
            <br />
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
            <br />
            Tracking and Performance Cookies
            <br />
            Type: Persistent Cookies
            <br />
            Administered by: Third-Parties
            <br />
          </Paragraph>
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            Purpose: These Cookies are used to track information about traffic
            to the Website and how users use the Website. The information
            gathered via these Cookies may directly or indirectly identify you
            as an individual visitor. This is because the information collected
            is typically linked to a pseudonymous identifier associated with the
            device you use to access the Website. We may also use these Cookies
            to test new pages, features or new functionality of the Website to
            see how our users react to them.
            <br />
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
            <br />
            Use of Your Personal Data
            <br />
            The Company may use Personal Data for the following purposes:
            <br />
            1 To provide and maintain our Service, including to monitor the
            usage of our Service.
            <br />
            2 To manage Your Account: to manage Your registration as a user of
            the Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
            <br />
            3 For the performance of a contract: the development, compliance and
            undertaking of the purchase contract for the products, items or
            services You have purchased or of any other contract with Us through
            the Service.
            <br />
            4 To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
            <br />
            5 To provide You with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
            <br />
            6 To manage Your requests: To attend and manage Your requests to Us.
            <br />
            7 To deliver targeted advertising to You: We may use Your
            information to develop and display content and advertising (and work
            with third-party vendors who do so) tailored to Your interests
            and/or location and to measure its effectiveness.
            <br />
            8 For business transfers: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Data held by Us about our
            Service users is among the assets transferred.
            <br />
            9 For other purposes: We may use Your information for other
            purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
            <br />
          </Paragraph>
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            We may share Your personal information in the following situations:
            <br />
            1 With Service Providers: We may share Your personal information
            with Service Providers to monitor and analyze the use of our
            Service, to advertise on third party websites to You after You
            visited our Service, for payment processing, to contact You.
            <br />
            2 For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
            <br />
            3 With Affiliates: We may share Your information with Our
            affiliates, in which case we will require those affiliates to honor
            this Privacy Policy. Affiliates include Our parent company and any
            other subsidiaries, joint venture partners or other companies that
            We control or that are under common control with Us.
            <br />
            4 With business partners: We may share Your information with Our
            business partners to offer You certain products, services or
            promotions.
            <br />
            5 With other users: when You share personal information or otherwise
            interact in the public areas with other users, such information may
            be viewed by all users and may be publicly distributed outside. If
            You interact with other users or register through a Third-Party
            Social Media Service, Your contacts on the Third-Party Social Media
            Service may see Your name, profile, pictures and description of Your
            activity. Similarly, other users will be able to view descriptions
            of Your activity, communicate with You and view Your profile.
            <br />
            6 With Your consent: We may disclose Your personal information for
            any other purpose with Your consent.
            <br />
            Retention of Your Personal Data
            <br />
          </Paragraph>
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
            <br />
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
            <br />
            Transfer of Your Personal Data
            <br />
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
            <br />
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
            <br />
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
            <br />
          </Paragraph>
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            Disclosure of Your Personal Data
            <br />
            Business Transactions
            <br />
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
            <br />
            Law enforcement
            <br />
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
            <br />
            Other legal requirements
            <br />
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
            <br />
            1 Comply with a legal obligation
            <br />
            2 Protect and defend the rights or property of the Company
            <br />
            3 Prevent or investigate possible wrongdoing in connection with the
            Service
            <br />
            4 Protect the personal safety of Users of the Service or the public
            <br />
            5 Protect against legal liability
            <br />
            Security of Your Personal Data
            <br />
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </Paragraph>
        </div>
        <div className="mt-5">
          <SecondaryHeading variant={"MainParagraphHeading"}>
            Detailed Information on the Processing of Your Personal Data
          </SecondaryHeading>
        </div>
        <div className="mt-3">
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            The Service Providers We use may have access to Your Personal Data.
            These third-party vendors collect, store, use, process and transfer
            information about Your activity on Our Service in accordance with
            their Privacy Policies.
            <br />
            Analytics
            <br />
            We may use third-party Service providers to monitor and analyze the
            use of our Service.
            <br />
            Google Analytics
            <br />
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising
            network.
            <br />
            You can opt-out of having made your activity on the Service
            available to Google Analytics by installing the Google Analytics
            opt-out browser add-on. The add-on prevents the Google Analytics
            JavaScript (ga.js, analytics.js and dc.js) from sharing information
            with Google Analytics about visits activity.
            <br />
            For more information on the privacy practices of Google, please
            visit the Google Privacy & Terms web page:
            https://policies.google.com/privacy
            <br />
            Firebase
            <br />
            Firebase is an analytics service provided by Google Inc.
            <br />
            You may opt-out of certain Firebase features through your mobile
            device settings, such as your device advertising settings or by
            following the instructions provided by Google in their Privacy
            Policy: https://policies.google.com/privacy
            <br />
            We also encourage you to review the Google's policy for safeguarding
            your data: https://support.google.com/analytics/answer/6004245
            <br />
            For more information on what type of information Firebase collects,
            please visit the How Google uses data when you use our partners'
            sites or apps webpage:
            https://policies.google.com/technologies/partner-sites
            <br />
            Matomo
            <br />
            Matomo is a web analytics service. You can visit their Privacy
            Policy page here: https://matomo.org/privacy-policy
            <br />
            Clicky
            <br />
            Clicky is a web analytics service. Read the Privacy Policy for
            Clicky here: https://clicky.com/terms
            <br />
            Statcounter
            <br />
            Statcounter is a web traffic analysis tool. You can read the Privacy
            Policy for Statcounter here: https://statcounter.com/about/legal/
            <br />
            Flurry Analytics
            <br />
            Flurry Analytics service is provided by Yahoo! Inc.
            <br />
            You can opt-out from Flurry Analytics service to prevent Flurry
            Analytics from using and sharing your information by visiting the
            Flurry's Opt-out page:
            https://developer.yahoo.com/flurry/end-user-opt-out/
            <br />
            For more information on the privacy practices and policies of
            Yahoo!, please visit their Privacy Policy page:
            https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm
            <br />
            Mixpanel
            <br />
            Mixpanel is provided by Mixpanel Inc.
            <br />
            You can prevent Mixpanel from using your information for analytics
            purposes by opting-out. To opt-out of Mixpanel service, please visit
            this page: https://mixpanel.com/optout/
            <br />
            For more information on what type of information Mixpanel collects,
            please visit the Terms of Use page of Mixpanel:
            https://mixpanel.com/terms/
            <br />
            Unity Analytics
            <br />
            Unity Analytics is provided by Unity Technologies.
            <br />
            Unity Analytics collects, stores, uses, processes, and transfers
            personal information to provide, operate, and improve Unity
            Technologies services, including personalized advertising services.
            For more information on what type of information Unity Technologies
            collects, please visit their Privacy Policy page:
            https://unity3d.com/legal/privacy-policy.
            <br />
            You can prevent Unity Analytics from using your information by
            opting-out. To opt-out of Unity Analytics, please visit this page:
            https://unity3d.com/legal/privacy-policy
            <br />
            You may opt-out of all personalized advertising by enabling privacy
            features on Your mobile device such as Limit Ad Tracking (iOS) and
            Opt Out of Ads Personalization (Android). See Your mobile device
            Help system for more information.
            <br />
            Payments
            <br />
            We may provide paid products and/or services within the Service. In
            that case, we may use third-party services for payment processing
            (e.g. payment processors).
            <br />
            We will not store or collect Your payment card details. That
            information is provided directly to Our third-party payment
            processors whose use of Your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
            <br />
            Apple Store In-App Payments
            <br />
            Their Privacy Policy can be viewed at
            https://www.apple.com/legal/privacy/en-ww/
            <br />
            Google Play In-App Payments
            <br />
            Their Privacy Policy can be viewed at
            https://www.google.com/policies/privacy/
            <br />
            Stripe
            <br />
            Their Privacy Policy can be viewed at https://stripe.com/us/privacy
            <br />
            Behavioral Remarketing
            <br />
            The Company uses remarketing services to advertise to You after You
            accessed or visited our Service. We and Our third-party vendors use
            cookies and non-cookie technologies to help Us recognize Your Device
            and understand how You use our Service so that We can improve our
            Service to reflect Your interests and serve You advertisements that
            are likely to be of more interest to You.
            <br />
            These third-party vendors collect, store, use, process and transfer
            information about Your activity on Our Service in accordance with
            their Privacy Policies and to enable Us to:
            <br />
            1) Measure and analyze traffic and browsing activity on Our Service
            <br />
            2) Show advertisements for our products and/or services to You on
            third-party websites or apps
            <br />
            3) Measure and analyze the performance of Our advertising campaigns
            <br />
            Some of these third-party vendors may use non-cookie technologies
            that may not be impacted by browser settings that block cookies.
            Your browser may not permit You to block such technologies. You can
            use the following third-party tools to decline the collection and
            use of information for the purpose of serving You interest-based
            advertising:
            <br />
            1) The NAI's opt-out platform:
            http://www.networkadvertising.org/choices/
            <br />
            2) The EDAA's opt-out platform http://www.youronlinechoices.com/
            <br />
            3) The DAA's opt-out platform:
            http://optout.aboutads.info/?c=2&lang=EN
            <br />
            You may opt-out of all personalized advertising by enabling privacy
            features on Your mobile device such as Limit Ad Tracking (iOS) and
            Opt Out of Ads Personalization (Android). See Your mobile device
            Help system for more information.
            <br />
            We may share information, such as hashed email addresses (if
            available) or other online identifiers collected on Our Service with
            these third-party vendors. This allows Our third-party vendors to
            recognize and deliver You ads across devices and browsers. To read
            more about the technologies used by these third-party vendors and
            their cross-device capabilities please refer to the Privacy Policy
            of each vendor listed below.
            <br />
            The third-party vendors We use are:
            <br />
            Google Ads (AdWords)
            <br />
            Google Ads (AdWords) remarketing service is provided by Google Inc.
            <br />
            You can opt-out of Google Analytics for Display Advertising and
            customise the Google Display Network ads by visiting the Google Ads
            Settings page: http://www.google.com/settings/ads
            <br />
            Google also recommends installing the Google Analytics Opt-out
            Browser Add-on - https://tools.google.com/dlpage/gaoptout - for your
            web browser. Google Analytics Opt-out Browser Add-on provides
            visitors with the ability to prevent their data from being collected
            and used by Google Analytics.
            <br />
            For more information on the privacy practices of Google, please
            visit the Google Privacy & Terms web page:
            https://policies.google.com/privacy
            <br />
            Facebook
            <br />
            Facebook remarketing service is provided by Facebook Inc.
            <br />
            You can learn more about interest-based advertising from Facebook by
            visiting this page: https://www.facebook.com/help/516147308587266
            <br />
            To opt-out from Facebook's interest-based ads, follow these
            instructions from Facebook:
            https://www.facebook.com/help/568137493302217
            <br />
            Facebook adheres to the Self-Regulatory Principles for Online
            Behavioural Advertising established by the Digital Advertising
            Alliance. You can also opt-out from Facebook and other participating
            companies through the Digital Advertising Alliance in the USA
            http://www.aboutads.info/choices/, the Digital Advertising Alliance
            of Canada in Canada http://youradchoices.ca/ or the European
            Interactive Digital Advertising Alliance in Europe
            http://www.youronlinechoices.eu/, or opt-out using your mobile
            device settings.
            <br />
            For more information on the privacy practices of Facebook, please
            visit Facebook's Data Policy:
            https://www.facebook.com/privacy/explanation
            <br />
            Pinterest
            <br />
            Pinterest remarketing service is provided by Pinterest Inc.
            <br />
            You can opt-out from Pinterest's interest-based ads by enabling the
            "Do Not Track" functionality of your web browser or by following
            Pinterest instructions:
            http://help.pinterest.com/en/articles/personalization-and-data
            <br />
            You can learn more about the privacy practices and policies of
            Pinterest by visiting their Privacy Policy page:
            https://about.pinterest.com/en/privacy-policy
            <br />
            Usage, Performance and Miscellaneous
            <br />
            We may use third-party Service Providers to provide better
            improvement of our Service.
            <br />
            Invisible reCAPTCHA
            <br />
            We use an invisible captcha service named reCAPTCHA. reCAPTCHA is
            operated by Google.
            <br />
            The reCAPTCHA service may collect information from You and from Your
            Device for security purposes.
            <br />
            The information gathered by reCAPTCHA is held in accordance with the
            Privacy Policy of Google:
            https://www.google.com/intl/en/policies/privacy/
            <br />
            Mouseflow
            <br />
            Mouseflow is a session replay and heatmap tool that shows how
            visitors click, move, scroll, browse, and pay attention on websites.
            The service is operated by ApS.
            <br />
            Mouseflow service may collect information from Your device.
            <br />
            The information gathered by Mouseflow is held in accordance with its
            Privacy Policy: https://mouseflow.com/privacy/
            <br />
            FreshDesk
            <br />
            FreshDesk is a customer support software. The service is operated by
            Freshworks, Inc.
            <br />
            FreshDesk service may collect information from Your Device.
            <br />
            The information gathered by FreshDesk is held in accordance with its
            Privacy Policy: https://www.freshworks.com/privacy/
            <br />
            Google Places
            <br />
            Google Places is a service that returns information about places
            using HTTP requests. It is operated by Google
            <br />
            Google Places service may collect information from You and from Your
            Device for security purposes.
            <br />
            The information gathered by Google Places is held in accordance with
            the Privacy Policy of Google:
            https://www.google.com/intl/en/policies/privacy/
          </Paragraph>
        </div>
        <div className="mt-5">
          <SecondaryHeading variant={"MainParagraphHeading"}>
            Children's Privacy
          </SecondaryHeading>
        </div>
        <div className="mt-3">
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
            <br />
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </Paragraph>
        </div>
        <div className="mt-5">
          <SecondaryHeading variant={"MainParagraphHeading"}>
            Links to Other Websites
          </SecondaryHeading>
        </div>
        <div className="mt-3">
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
            <br />
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </Paragraph>
        </div>
        <div className="mt-5">
          <SecondaryHeading variant={"MainParagraphHeading"}>
            Changes to this Privacy Policy
          </SecondaryHeading>
        </div>
        <div className="mt-3">
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
            <br />
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
            <br />
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </Paragraph>
        </div>
        <div className="mt-10">
          <SecondaryHeading variant={"MainParagraphHeading"}>
            Contact Us
          </SecondaryHeading>
        </div>
        <div className="mt-3">
          <Paragraph variant={"MainParagraph"} className="text-offblack">
            If you have any questions about this Privacy Policy, You can contact
            us:
            <br />
            By email:{" "}
            <span className="text-primaryblue">contact@tradeposter.com.au</span>
          </Paragraph>
        </div>
      </div>
    </>
  );
};

export default PolicyDocs;
