import React from "react";
import { NavHashLink } from "react-router-hash-link";
import ButtonText from "../Typography/ButtonText";
import { Link } from "react-router-dom";

const ReadMore = ({ variant,text, link, mainClass, groupHoverWidthClasses }) => {
  return (
    <>
      {variant === "hashlink" && (
        <div
          class={`group relative border-b-2 border-white cursor-pointer ${mainClass} mt-4 xl:mt-4 2xl:mt-6`
          }
        >
          <NavHashLink to={link} smooth>
            <ButtonText variant={"SecondaryText"} className={"-mb-5"}>
              {text}
            </ButtonText>
          </NavHashLink>
          <span class={`absolute -bottom-[2px] -left-1 w-0 border-b-2 border-primaryblue transition-all duration-100 ${groupHoverWidthClasses}`}/>
            
        </div>
      )}
      {variant === "link" && (
        <div
          class={`group relative border-b-2 border-white cursor-pointer ${mainClass} mt-4 xl:mt-4 2xl:mt-6`
          }
        >
          <Link to={link}>
            <ButtonText variant={"SecondaryText"} className={"-mb-5"}>
              {text}
            </ButtonText>
          </Link>
          <span class={`absolute -bottom-[2px] -left-1 w-0 border-b-2 border-primaryblue transition-all duration-100 ${groupHoverWidthClasses}`}/>
            
        </div>
      )}
     
    </>
  );
};

export default ReadMore;
