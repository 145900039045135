/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Facebook from "../../../assets/img/social/facebook.svg";
import Instagram from "../../../assets/img/social/instagram.svg";
import Linkedin from "../../../assets/img/social/linkedin.svg";
import Tiktok from "../../../assets/img/social/tiktok.svg";
import Youtube from "../../../assets/img/social/youtube.svg";
import Pinterest from "../../../assets/img/social/pinterest.svg";
import Threads from "../../../assets/img/social/threads.svg";
import Popups from "../../../components/popup/Popups";
import VerificationPopUp from "../../VerificationPop/VerificationPopUp";
import { useState } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import Paragraph from "../../../components/common/Typography/Paragraph";
function SocialMedia() {
  const badgeData = [
    {
      heading: "Facebook ",
      description:
        "Badge related information Badge related information badge related information",
      image: Facebook,
      status: "Remove",
    },
    {
      heading: "Instagram ",
      description:
        "Badge related information Badge related information badge related information",
      image: Instagram,
      status: "Add",
    },
    {
      heading: "Linkedin ",
      description:
        "Badge related information Badge related information badge related information",
      image: Linkedin,
      status: "Add",
    },
    {
      heading: "TikTok ",
      description:
        "Badge related information Badge related information badge related information",
      image: Tiktok,
      status: "Add",
    },
    {
      heading: "Youtube ",
      description:
        "Badge related information Badge related information badge related information",
      image: Youtube,
      status: "Add",
    },
    {
      heading: "Twitter ",
      description:
        "Badge related information Badge related information badge related information",
      image: Threads,
      status: "Add",
    },
    {
      heading: "Pinterest ",
      description:
        "Badge related information Badge related information badge related information",
      image: Pinterest,
      status: "Add",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2k:grid-cols-5 4k:grid-cols-6 gap-16 w-[300px] sm:w-[500px] md:w-[600px] lg:w-[600px] xl:w-[680px] 2k:w-[1000px] 4k:w-[1200px] mx-auto">
        {badgeData?.map((item, index) => {
          return (
            <>
              <Social
                key={index}
                heading={item.heading}
                description={item.description}
                image={item.image}
                status={item.status}
              />
            </>
          );
        })}
      </div>
    </>
  );
}

export default SocialMedia;

const Social = ({ heading, description, image, status }) => {
  const [link, setLink] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    if (link) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [link]);
  return (
    <>
      <div>
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <img className="w-[50px] h-[50px]" src={image} alt="badge" />
          </div>
          <div className="mt-1">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              {heading}
            </Paragraph>
          </div>
          <div className="mt-3">
            <MainButton
              variant={status === "Remove" ? "outlineextrasmall" : "extrasmall"}
              onClick={() => {
                setLink(true);
              }}
            >
              {status}
            </MainButton>
          </div>
        </div>
      </div>
      {link && (
        <Popups
          setTrigger={() => setLink(false)}
          ShowCrossButton={ShowCrossButton}
          popupWidth={"w-[350px] h-[500px] sm:w-[600px] sm:h-[500px] 2xl:w-[600px] 2xl:h-[500px] pb-10"}
              crossPosition={
                "flex justify-end w-[16px] absolute left-[320px] sm:left-[565px] top-3"
              }
        >
          <VerificationPopUp
            closePopup={() => setLink(false)}
            firstMainHeading={"Connect your social media profile"}
            firstP={
              "Link your social media profile to enhance your business's visibility and trustworthiness on our platform."
            }
            label={"Social media profile link"}
            placeholder={"Paste the URL of your social media profile here."}
            secondMainHeading={"Social media profile linked"}
            secondP={
              "Congratulations! Your social media profile has been successfully linked to your Trades Poster account. This link will facilitate better engagement with the community and enhance the credibility of your business profile. You can now manage your profile settings and receive updates directly through your linked social media."
            }
            smallPara={
              "By linking your social media profile, you allow Trades Poster to display your profile on our platform, which may increase customer engagement. We assure you that your information is securely handled in compliance with privacy regulations."
            }
          />
        </Popups>
      )}
    </>
  );
};
