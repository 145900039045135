import React from "react";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import SpecialText from "../../components/common/Typography/SpecialText";
import { Link } from "react-router-dom";

const StoriesLayout = () => {
  const data = [
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
    {
      businessname: "Business name",
      businessLocation: "Melbourne, Australia",
      businessDate: "6 Dec, 2024",
    },
  ];
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[980px] xl:w-[1200px] xl:h-[800px] 2xl:w-[1748px] 3xl:w-[1850px] 2k:w-[2000px] 4k:w-[2150px] h-auto sm:h-[600px] lg:h-[700px] 2xl:h-[1000px] 3xl:h-[1100px] 2k:h-[1200px] 4k:h-[1300px] aspect-[1748/1000] bg-about bg-no-repeat bg-center object-cover bg-cover rounded-3xl mt-24">
        <div className="sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mx-auto pt-20 lg:pt-40 2xl:pt-40">
          <Heading variant={"h1"} className="text-white ml-[5%] lg:ml-0">
            Business main page
          </Heading>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[980px] xl:w-[1200px] 2xl:w-[1748px] 3xl:w-[1850px] 2k:w-[2000px] 4k:w-[2150px] mt-20">
        {data.map((e, index) => {
          return (
            <>
              <div key={index}>
              <div className="h-auto sm:h-[320px] md:h-[340px] xl:h-[360px] 2xl:h-[380px] 3xl:h-[400px] 2k:h-[420px] 4k:h-[440px] aspect-[560/380]">
                <Link to={"/business-success-stories"}>
                  <img
                    src={require("../../assets/img/default-image.jpg")}
                    className="w-full h-full rounded-3xl object-cover"
                    alt=""
                  />
                </Link>

              </div>
                <SecondaryHeading
                  variant={"MainParagraphHeading"}
                  className="text-offblack mt-2"
                >
                  {e.businessname}
                </SecondaryHeading>
                <SpecialText
                  variant={"OccupationLocation"}
                  className={"text-offblack mt-5"}
                >
                  {e.businessLocation}
                </SpecialText>
                <SpecialText variant={"Date"} className={"text-offblack mt-1"}>
                  {e.businessDate}
                </SpecialText>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default StoriesLayout;
