import React from "react";
import BlogsLayout from "../sections/Blogs/BlogsLayout";
import Heading from "../components/common/Typography/Heading";

const Blogs = () => {
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] pt-48 sm:pt-48 md:pt-48 lg:pt-48 xl:pt-48 2xl:pt-48">
        <Heading variant={"h1"} className={"text-offblack text-center"}>
          Industry Insights & Trends
        </Heading>
        <BlogsLayout />
      </div>
    </>
  );
};

export default Blogs;
