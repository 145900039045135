import React from "react";
// import { useLocation } from "react-router-dom";
// import useAuth from "../../Hooks/useAuth";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";
import P1 from "../../assets/img/certifications/latest/wwcc.svg";
import P2 from "../../assets/img/certifications/latest/insurance.svg";
import P3 from "../../assets/img/certifications/latest/plumbing.svg";
import P4 from "../../assets/img/certifications/latest/buildcontractor.svg";
import P5 from "../../assets/img/certifications/latest/asbestos.svg";

const Certifications = () => {
  const data = [
    {
      heading: "Children Check",
      pic: P1,
    },
    {
      heading: "Insurance",
      pic: P2,
    },
    {
      heading: "Plumber",
      pic: P3,
    },
    {
      heading: "Building Contractor",
      pic: P4,
    },
    {
      heading: "Asbestos",
      pic: P5,
    },
  ];
  return (
    <>
      <SecondaryHeading
        variant={"MainParagraphHeading"}
        className={"flex justify-center text-offblack"}
      >
        Certifications
      </SecondaryHeading>
      <div className="flex flex-col mt-5 mb-10">
        <div className="grid grid-cols-3 md:grid-cols-2 gap-y-5 xl:mx-10">
          {data.map((e, index) => {
            return (
              <>
                <div key={index} className="flex flex-col items-center">
                  <img className="w-[50px] h-[50px]" src={e.pic} alt={""} />
                  <div className="mt-2 md:mt-3">
                    <Paragraph
                      variant={"MainParagraph"}
                      className={"text-offblack text-center"}
                    >
                      {e.heading}
                    </Paragraph>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Certifications;
