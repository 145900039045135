import React from "react";
import Blinds from "../../assets/img/advance-algorithm.png";
import Heading from "../../components/common/Typography/Heading";
import ImageWithLongCardsColumn from "../../components/common/Generics/ImageWithLongCardsColumn";

const WhyTradesPoster = () => {
  const cardsdata = [
    {
      heading: "Adaptable Plans",
      icon: Blinds,
      description: "Start free, upgrade as you grow.",
    },
    {
      heading: "No Commissions",
      icon: Blinds,
      description: "Keep all earnings.",
    },
 
    {
      heading: "Category Flexibility",
      icon: Blinds,
      description: "List in multiple categories.",
    },
    {
      heading: "Quote Your Way",
      icon: Blinds,
      description: "Send custom quote to customers.",
    },
    {
      heading: "Tailored Profile",
      icon: Blinds,
      description: "Contactable business profiles.",
    },
    {
      heading: "Dashboard",
      icon: Blinds,
      description: "Personalized business dashboard.",
    },
    {
      heading: "Performance",
      icon: Blinds,
      description: "Monitor business metrics and analytics.",
    },
    {
      heading: "Australia Wide",
      icon: Blinds,
      description: "Offer services nationwide.",
    },
    
   
    
   
  ];
  return (
    <>
      <div className="mt-40">
        <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
          <Heading
            variant={"h2"}
            className={
              "flex justify-start text-start text-offblack max-w-[650px] 2xl:max-w-[850px] 3xl:max-w-[1000px]"
            }
          >
            Why Trades Poster stands out Why Trades Poster stands out Why Trades
            Poster stands out Why Trades Poster stands out 
          </Heading>
        </div>
        <ImageWithLongCardsColumn data={cardsdata} />
      </div>
    </>
  );
};

export default WhyTradesPoster;
