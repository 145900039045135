import React from "react";
import MainButton from "../../../../components/common/MainButton/MainButton";
import TextFiled from "../../../../components/common/InputField/TextFiled";
import { useState } from "react";
import Heading from "../../../../components/common/Typography/Heading";
import Paragraph from "../../../../components/common/Typography/Paragraph";

const AddCardForm = ({ secondMainHeading, secondP, closePopup }) => {
  const [step, setStep] = useState(1);
  return (
    <>
      {step === 1 && (
        <div>
          <>
            <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[380px] xl:w-[420px] 2xl:w-[500px] mx-auto">
              <Heading
                variant={"h6"}
                className={"text-center mb-10 mt-[70px] text-offblack"}
              >
                Billing card details
              </Heading>
              <form>
                <div className="mx-auto w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] xl:w-[400px] 2xl:w-[400px] overflow-y-auto hidden-scrollbar pb-5">
                  <div>
                    <TextFiled
                      variant={"general"}
                      label={"Name on card"}
                      placeholder={"Enter card holder name"}
                    />
                  </div>
                  <div className="mt-5">
                    <TextFiled
                      variant={"general"}
                      label={" Card number"}
                      placeholder={"Enter  card number"}
                    />
                  </div>
                  <div className="flex items-center gap-x-2 mt-5">
                    <div>
                      <TextFiled
                        variant={"extrasmall02"}
                        label={"Expiry"}
                        placeholder={"12/2023"}
                      />
                    </div>
                    <div>
                      <TextFiled
                        variant={"extrasmall01"}
                        label={"CVV"}
                        placeholder={"123"}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end mt-10">
                    <MainButton
                      variant={"extrasmall"}
                      onClick={() => {
                        setStep(2);
                        setTimeout(closePopup, 3000);
                      }}
                    >
                      Submit
                    </MainButton>
                  </div>
                </div>
              </form>
            </div>
          </>
        </div>
      )}
      {step === 2 && (
        <>
          <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[380px] xl:w-[420px] 2xl:w-[500px] mx-auto">
            <Heading
              variant={"h6"}
              className={"text-center mb-10 mt-20 text-offblack"}
            >
              {secondMainHeading}
            </Heading>
            <div className="flex">
              <div className="w-[100%]">
                <div className="max-w-[400px] mx-auto">
                  <Paragraph variant={"MainParagraph"} className={"text-start"}>
                    {secondP}
                  </Paragraph>
                </div>
                <div className="flex justify-center my-10">
                  <i className="fi fi-ss-badge-check text-[50px] text-primaryblue"></i>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddCardForm;
