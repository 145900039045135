import React from "react";
import { Link } from "react-router-dom";
import SpecialText from "../../../components/common/Typography/SpecialText";
import AllPostSlider from "./AllPostSlider";
import InteractiveText from "../../../components/common/Typography/InteractiveText";


const AllPost = () => {
  return (
    <>
      <div className="w-[700px] lg:w-[992px] mx-auto hidden sm:block">
        <div class="border-b-2 border-lightgray max-w-fit">
          <ul class="flex flex-wrap -mb-px text-center text-lightgray">
            <li>
              <Link className="flex items-center justify-center gap-2 pb-2 h-5 pr-20 border-b-2 border-transparent rounded-t-lg text-primaryblue hover:text-primaryblue border-b-primaryblue hover:border-primaryblue group">
                {/* <i className="fi fi-rs-heart text-xs cursor-pointer text-primaryblue"></i> */}
                <InteractiveText variant={"FAQHeader"}>All post</InteractiveText>
                <SpecialText
                  variant={"TabsCounter"}
                  className={"flex items-center justify-center"}
                >
                  23
                </SpecialText>
              </Link>
            </li>
            <li>
              <Link className="flex items-center justify-center gap-2 pb-2 h-5 pr-20 border-b-2 border-transparent rounded-t-lg hover:text-primaryblue hover:border-primaryblue group">
                {/* <i className="fi fi-rr-share-alt-square text-xs cursor-pointer text-lightgray group-hover:text-primaryblue"></i> */}
                <InteractiveText variant={"FAQHeader"}>Saved</InteractiveText>
                <SpecialText
                  variant={"TabsCounter"}
                  className={"flex items-center justify-center"}
                >
                  5
                </SpecialText>
              </Link>
            </li>
            <li>
              <Link className="flex items-center justify-center gap-2 pb-2 h-5 pr-20 border-b-2 border-transparent rounded-t-lg hover:text-primaryblue hover:border-primaryblue group">
                {/* <i className="fi fi-rr-share-alt-square text-xs cursor-pointer text-lightgray group-hover:text-primaryblue"></i> */}
                <InteractiveText variant={"FAQHeader"}>Shared</InteractiveText>
                <SpecialText
                  variant={"TabsCounter"}
                  className={"flex items-center justify-center"}
                >
                  8
                </SpecialText>
              </Link>
            </li>
            <li>
              <Link className="flex items-center justify-center gap-2 pb-2 h-5 pr-20 border-b-2 border-transparent rounded-t-lg hover:text-primaryblue hover:border-primaryblue group">
                {/* <i className="fi fi-rr-eye text-xs cursor-pointer text-lightgray group-hover:text-primaryblue"></i> */}
                <InteractiveText variant={"FAQHeader"}>Viewed</InteractiveText>
                <SpecialText
                  variant={"TabsCounter"}
                  className={"flex items-center justify-center"}
                >
                  10
                </SpecialText>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <AllPostSlider />
    </>
  );
};

export default AllPost;
