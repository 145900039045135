import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination"; // Import pagination styles
import Heading from "../../components/common/Typography/Heading";
import ReadMore from "../../components/common/MainButton/ReadMore";
// import CircularButton from "../../components/common/MainButton/CircularButton";

const BigSlidesSlider = ({ data, lastButton }) => {
  const swiperRef = useRef();
  const paginationRef = useRef(null); // Ref for the pagination container

  return (
    <>
      <div className="mx-[5%] lg:mx-0 lg:w-full mt-20">
        <Swiper
          className="w-auto lg:w-full"
          breakpoints={{
            300: {
              initialSlide: 0,
              slidesPerView: 1,
            },
            1024: {
              initialSlide: 1,
              slidesPerView: "auto",
            },
          }}
          // slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={32}
          initialSlide={1}
          speed={1000}
          pagination={{
            el: paginationRef.current, // Set the pagination container here
            clickable: true,
          }}
          // autoplay={{
          //   delay: 5000,
          //   disableOnInteraction: false,
          // }}
          modules={[Pagination, Navigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          <>
            <SwiperSlide className="hidden lg:block w-[350px] xs:w-[390px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] cursor-pointer h-[350px] md:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px] 2k:h-[800px] 4k:h-[900px] xl:mr-8 2xl:mr-8 rounded-3xl bg-pastalblue "></SwiperSlide>
            {data?.map((e, index) => (
              <SwiperSlide
                key={index}
                className="w-full lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] aspect-[1264px/700px] rounded-2xl"
              >
                <div className="w-full lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] cursor-pointer h-auto relative rounded-3xl">
                  <div className="absolute w-full z-10 rounded-3xl">
                    <div className="flex flex-col mt-10 mx-[5%]">
                      <Heading
                        variant={"h6"}
                        className={"text-white max-w-[700px]"}
                      >
                        {e.rareHeading}
                      </Heading>
                      {e.subHeading || e.para ? (
                        <Heading
                          variant={"h6"}
                          className={"text-white max-w-[700px]"}
                        >
                          {e.subHeading}&nbsp;{e.para}
                        </Heading>
                      ) : (
                        ""
                      )}
                      {e.navigationText && (
                        <div>
                          <ReadMore
                            variant={"link"}
                            text={e.buttonText}
                            link={e.link}
                            mainClass={e.class}
                            groupHoverWidthClasses={e.subClass}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <img
                    className="h-full w-full object-cover rounded-3xl opacity-90 aspect-[1264px/700px]"
                    src={require("../../../src/assets/img/demo.jpg")}
                    alt="/"
                  />
                </div>
              </SwiperSlide>
            ))}
          </>
        </Swiper>
      </div>
      <div className="mx-[5%] lg:mx-auto lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
        <div className="flex items-center justify-center w-fit py-2 px-4 mt-10 border border-offblack rounded-[35px]">
          <div
            className="swiper-pagination-container w-full cursor-pointer"
            ref={paginationRef} // Reference for the pagination bullets
          ></div>
          {/* <CircularButton
            variant={"prev"}
            onClick={() => swiperRef.current?.slideNext()}
          ></CircularButton>
          <CircularButton
            variant={"next"}
            onClick={() => swiperRef.current?.slidePrev()}
          ></CircularButton> */}
        </div>
        <div className="flex justify-center mt-5 lg:mt-10">
          {lastButton && (
            <ReadMore
              variant={"link"}
              text={"See how tradesposter works"}
              mainClass={
                "w-[180px] sm:w-[180px] lg:w-[190px] xl:w-[190px] 2xl:w-[205px] text-offblack"
              }
              groupHoverWidthClasses={
                "group-hover:w-[180px] sm:group-hover:w-[185px] lg:group-hover:w-[195px] xl:group-hover:w-[195px] 2xl:group-hover:w-[210px]"
              }
              link={"/how-it-works"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BigSlidesSlider;
