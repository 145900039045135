import React from "react";

const ButtonText = ({ variant, children, className }) => {
  return (
    <>
    {/* o	Usage: Main actions like 'Sign Up', 'Contact Us', 'Buy Now' */}
      {variant === "PrimaryText" && (
        <p
          className={`
          ${className} font-ibmplex font-semibold uppercase leading-[1.4] tracking-[0.75px] text-[12px] sm:text-[12px] md:text-[13px] lg:text-[13px] xl:text-[13px] 2xl:text-[14px] 3xl:text-[14px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Secondary actions like 'Learn More', 'Read More  */}
      {variant === "SecondaryText" && (
        <p
          className={`
          ${className} font-ibmplex font-semibold uppercase leading-[1.4] tracking-[0.5px] text-[11px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px]`}
        >
          {children}
        </p>
      )}
    </>
  );
};
export default ButtonText;
