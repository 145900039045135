import { useQuery } from "react-query";
import {
  GetPopularListingOfRelatedListing,
  GetPosterService,
  GetProductListings,
  GetRelatedListing,
  GetRelatedListingOfSelectedListing,
} from "../Api/Listing";
export function useRelatedListing(Id) {
  return useQuery(["GetRelatedListing", Id], GetRelatedListing);
}
export function useGetUserListings(Id) {
  return useQuery(["GetUserListing", Id], GetProductListings);
}
export function usePopularListingOfRelatedListing(Id, userId) {
  return useQuery(
    ["GetPopularListingOfRelatedListing", Id, userId],
    GetPopularListingOfRelatedListing
  );
}
export function useRelatedListingOfSelectedListing(Id, userId) {
  return useQuery(
    ["GetRelatedListingOfSelectedListing", Id, userId],
    GetRelatedListingOfSelectedListing
  );
}

export function usePosterServices(userId) {
  console.log("userPosterServiceHook",userId)
  return useQuery(
    ["GetPosterService", userId],
    GetPosterService
  );
}