import React from "react";
import SpecialText from "../Typography/SpecialText";
import Paragraph from "../Typography/Paragraph";
import { Rating } from "primereact/rating";

const Card = () => {
  return (
    <>
      <div className="bg-white rounded-xl flex flex-col items-center p-5 border border-mediumgray w-[250px] h-auto sm:w-[400px] sm:h-[200px]">
        <div className="flex w-full ">
          <div className="h-[50px] w-[50px]">
            <img
              className="h-[50px] w-[50px] object-cover rounded-full"
              src={require("../../../assets/img/User.png")}
              alt={"no"}
            />
          </div>
          <div className="flex-1 ml-5">
            <SpecialText variant={"ProfileName"} className={"mt-2"}>
              Jozeph J
            </SpecialText>
            <div className="mt-1">
              <SpecialText variant={"OccupationLocation"}>
                Duffy, ACT, 2611
              </SpecialText>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div>
            <Paragraph
              variant={"MainParagraph"}
              className={
                "h-[54px] sm:h-[62px] md:h-[65px] pb-1 overflow-hidden text-ellipsis text-offblack"
              }
            >
              {/* {reviewData.reviewMessage} */}
              Donec consequat, lacus vel ornare laoreet, nibh lacus blandit est,
              venenatis feugiat augue augue eu ipsum. a b c Vestibulum pulvinar
              eleifend facilisis. Aliquam a tempor orci, vitae ullamcorper
              mauris. Cras sollicitudin turpis quis tempus pretium. Maecenas
              vehicula mauris sagittis justo blandit, sed consequat lacus
              dictum.
            </Paragraph>
          </div>
          <div className="flex items-center justify-between mt-3">
            <div className="flex items-center gap-1">
              <Rating
                value={3}
                stars={1}
                style={{ gap: 5 }}
                cancel={false}
                defaultValue={4}
              />
              <SpecialText variant={"Counter"}>4.9</SpecialText>
            </div>
            <SpecialText variant={"DateStamp"} className={"text-offblack"}>
              1 m ago
            </SpecialText>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
