import React from "react";
import SpecialText from "../../components/common/Typography/SpecialText";
import { Link } from "react-router-dom";
import Paragraph from "../../components/common/Typography/Paragraph";

const StoriesLayout = () => {
  const data = [
    {
      serviceName: "Plumbing in Melbourne",
      companyName: "Waterproofing company LTD",
      companyLoaction: "Duffy, ACT, 2563",
      joining: "June 2020",
    },
    {
      serviceName: "Plumbing in Melbourne",
      companyName: "Waterproofing company LTD",
      companyLoaction: "Duffy, ACT, 2563",
      joining: "June 2020",
    },
    {
      serviceName: "Plumbing in Melbourne",
      companyName: "Waterproofing company LTD",
      companyLoaction: "Duffy, ACT, 2563",
      joining: "June 2020",
    },
    {
      serviceName: "Plumbing in Melbourne",
      companyName: "Waterproofing company LTD",
      companyLoaction: "Duffy, ACT, 2563",
      joining: "June 2020",
    },
  ];
  return (
    <>
      {data.map((e, index) => {
        return (
          <>
            <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-20" key={index}>
              <div className="flex flex-col sm:flex-row gap-10 sm:gap-16">
                <div className="w-full">
                  <div>
                    <Link to={"/business-success-stories"}>
                      <SpecialText
                        variant={"ProfileName"}
                        className={"hover:text-primaryblue"}
                      >
                        {e.serviceName}
                      </SpecialText>
                    </Link>
                  </div>
                 
                  {/* Review text */}
                  <div className="mt-5">
                    <Paragraph
                      variant={"MainParagraph"}
                      className={
                        "h-[60px] 2xl:h-[70px] pb-1 overflow-hidden text-ellipsis text-offblack"
                      }
                    >
                      Donec consequat, lacus vel ornare laoreet, nibh lacus
                      blandit est, venenatis feugiat augue augue eu ipsum. a b c
                      Vestibulum pulvinar eleifend facilisis. Aliquam a tempor
                      orci, vitae ullamcorper
                    </Paragraph>
                    <div className="flex justify-start mt-3">
                      <SpecialText
                        variant={"DateStamp"}
                        className={"text-offblack"}
                      >
                        4 days ago
                      </SpecialText>
                    </div>
                  </div>
                </div>
                <div className="hidden sm:block sm:w-[300px] md:w-[350px] lg:w-[600px] xl:w-[700px] 2xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] h-auto sm:h-[200px] md:h-[250px] lg:h-[400px] xl:h-[450px] 2xl:h-[500px] 3xl:h-[600px] 2k:h-[700px] 4k:h-[800px] aspect-[800/500] shrink-0">
                  <img
                    src={require("../../../src/assets/img/default-image.jpg")}
                    className="rounded-3xl w-full h-full object-cover"
                    alt=""
                  />
                </div>
              </div>
              <div className="sm:hidden sm:w-[300px] md:w-[350px] lg:w-[600px] xl:w-[700px] 2xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] h-auto sm:h-[200px] md:h-[250px] lg:h-[400px] xl:h-[450px] 2xl:h-[500px] 3xl:h-[600px] 2k:h-[700px] 4k:h-[800px] aspect-[800/500] shrink-0 mt-10">
                  <img
                    src={require("../../../src/assets/img/default-image.jpg")}
                    className="rounded-3xl w-full h-full object-cover"
                    alt=""
                  />
                </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default StoriesLayout;
