import React from "react";
import Banner from "../components/common/Banners/Banner";
import AtoZFilters from "../sections/Councils/AtoZFilters";

const CouncilRagulators = () => {
  return (
    <>
      <Banner
        headingClasses={
          "text-start text-white w-[350px] sm:w-[550px] md:w-[650px] lg:w-[850px] xl:w-[900px] 2xl:w-[1000px] 3xl:w-[1200px] 2k:w-[1400px] 4k:w-[1600px]"
        }
        heading={"Australian Councils"}
        className={"bg-about"}
      />
      <AtoZFilters />
    </>
  );
};

export default CouncilRagulators;
