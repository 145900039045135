import React from "react";
import AuxiliaryText from "../../../../components/common/Typography/AuxiliaryText";

const PaymentHistory = () => {
  return (
    <>
      {/* Labels */}
      <div className="grid grid-cols-12 rounded-xl mb-5 py-4 bg-pastalblue text-offblack pl-5 pr-1 mt-20">
        <div className="md:flex items-center col-span-2 xl:col-span-2">
          <AuxiliaryText variant={"FieldLabel"}>Invoice</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center xl:col-span-2">
          <AuxiliaryText variant={"FieldLabel"}>Subscription</AuxiliaryText>
        </div>
        <div className="col-span-3 flex items-center justify-center xl:col-span-3">
          <AuxiliaryText variant={"FieldLabel"}>Status</AuxiliaryText>
        </div>
        <div className="col-span-1 flex items-center justify-center xl:col-span-2">
          <AuxiliaryText variant={"FieldLabel"}>Date</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center xl:col-span-2">
          <AuxiliaryText variant={"FieldLabel"}>Amount</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center xl:col-span-1">
          <AuxiliaryText variant={"FieldLabel"}>Download</AuxiliaryText>
        </div>
      </div>
      {/* Values */}
      <div className="grid grid-cols-12 mb-5 py-4 text-offblack pl-5 pr-1">
        <div className="flex items-center col-span-2 xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>#24855</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center md:gap-1 xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>Premium</AuxiliaryText>
        </div>
        <div className="col-span-3 flex items-center justify-center xl:col-span-3">
          <AuxiliaryText
            variant={"Placeholder"}
            className={"bg-primaryblue text-white rounded-3xl py-[2px] px-2"}
          >
            Paid
          </AuxiliaryText>
        </div>
        <div className="col-span-1 flex items-center justify-center xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>Feb 2</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>$12.00</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center xl:col-span-1">
          <i className="fi fi-rr-cloud-download-alt text-base text-offblack cursor-pointer"></i>
        </div>
      </div>
      {/* 2nd dummy */}
      <div className="grid grid-cols-12 mb-5 py-4 text-offblack pl-5 pr-1">
        <div className="flex items-center col-span-2 xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>#24855</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center md:gap-1 xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>Premium</AuxiliaryText>
        </div>
        <div className="col-span-3 flex items-center justify-center xl:col-span-3">
          <AuxiliaryText
            variant={"Placeholder"}
            className={"bg-primaryblue text-white rounded-3xl py-[2px] px-2"}
          >
            Paid
          </AuxiliaryText>
        </div>
        <div className="col-span-1 flex items-center justify-center xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>Feb 2</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>$12.00</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center xl:col-span-1">
          <i className="fi fi-rr-cloud-download-alt text-base text-offblack cursor-pointer"></i>
        </div>
      </div>
      {/* 3rd dummy */}
      <div className="grid grid-cols-12 mb-5 py-4 text-offblack pl-5 pr-1">
        <div className="flex items-center col-span-2 xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>#24855</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center md:gap-1 xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>Premium</AuxiliaryText>
        </div>
        <div className="col-span-3 flex items-center justify-center xl:col-span-3">
          <AuxiliaryText
            variant={"Placeholder"}
            className={"bg-primaryblue text-white rounded-3xl py-[2px] px-2"}
          >
            Paid
          </AuxiliaryText>
        </div>
        <div className="col-span-1 flex items-center justify-center xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>Feb 2</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center xl:col-span-2">
          <AuxiliaryText variant={"Placeholder"}>$12.00</AuxiliaryText>
        </div>
        <div className="col-span-2 flex items-center justify-center xl:col-span-1">
          <i className="fi fi-rr-cloud-download-alt text-base text-offblack cursor-pointer"></i>
        </div>
      </div>
    </>
  );
};

export default PaymentHistory;
