import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetProductListings } from "../../../Api/Listing";
import Spinner from "../../../components/common/Spinner";
import useAuth from "../../../Hooks/useAuth";
import { useSellerProfileInfo } from "../../../Hooks/useSellerProfileInfo";
import SliderPost from "../../../sections/BusinessDashboard/Post/RecycleBin/SliderPost";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
const RecycleBin = () => {
  const { auth } = useAuth();
  const { isError, isLoading, data } = useSellerProfileInfo(auth?.user?.id);
  const [value1, setValue1] = useState("Draft");
  const [ListData, setData] = useState([]);
  const [filteredItem, setFilteredItem] = useState([]);
  const [RequestStart, setRequetStart] = useState({
    isProcessing: false,
    isRequestSuccess: false,
    isRequestError: false,
  });
  const navigate = useNavigate();

  let ProfileId = data?.data?.key?.id ?? 0;
  function GetListHandler() {
    if (ProfileId !== 0) {
      setRequetStart({ ...RequestStart, isProcessing: true });
      GetProductListings(ProfileId)
        .then((response) => {
          if (response?.status === 200) {
            setData(response?.data?.key?.$values);
            setRequetStart({
              ...RequestStart,
              isProcessing: false,
            });
          }
        })
        .catch((error) => {
          setRequetStart({
            ...RequestStart,
            isProcessing: false,
          });
        });
    }
  }
  useEffect(() => {
    GetListHandler();
  }, [ProfileId]);

  useEffect(() => {
    if (value1 === "Draft") {
      const filteredArray = ListData.filter(
        (e) => e.status?.toUpperCase() === value1?.toUpperCase()
      );
      setFilteredItem(filteredArray);
    } else if (value1 === "Archive") {
      const filteredArray = ListData.filter(
        (e) => e.status?.toUpperCase() === value1?.toUpperCase()
      );
      setFilteredItem(filteredArray);
    } else if (value1 === "Published") {
      const filteredArray = ListData.filter(
        (e) => e.status?.toUpperCase() === value1?.toUpperCase()
      );
      setFilteredItem(filteredArray);
    } else setFilteredItem(ListData);
  }, [value1, ListData]);

  return (
    <>
      <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center"}>
          Recycle bin
        </Heading>
        <div className="flex justify-center mx-auto mt-[60px] min-h-screen">
          {RequestStart.isProcessing && <Spinner />}
          <SliderPost />
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default RecycleBin;
