import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import HelpCenterLink from "./HelpCenterLink";

const HelpCenter = () => {

  const gettingStarted = [
    {
      title: "Quick Setup",
      link: "/HelpCentre/quick-setup",
      data: [
        {
          heading: "How do I sign up for an account on Trades Poster?",
          content: `To sign up, visit the homepage and click "Sign Up." You can register with your email, Facebook, or Google account. Choose "Business" or "Customer," complete the required profile information, review the terms and conditions, and then submit your registration. Check your email for a verification code to activate your account.`,
        },
        {
          heading: "How do I verify my account on Trades Poster?",
          content: `After signing up, check your email for a verification code from Trades Poster. If you don't see it in your inbox, check your spam or junk folder. Enter the verification code on the Trades Poster website to complete the verification. Once verified, your account will be fully active.`,
        },
        {
          heading: "What should I do if I don't receive a verification email?",
          content: `If you don't receive the verification email, check your spam or junk folder. Ensure the email address you provided during registration is correct. Request to resend the email from the verification or sign-in page. Check your email filters or rules to ensure they aren't intercepting emails from Trades Poster. If you still don't receive the email, contact customer support with your account details for assistance.`,
        },
        {
          heading:
            "How can I edit my profile on Trades Poster after initial setup?",
          content: `To edit your profile, log into your Trades Poster account and navigate to your dashboard. Go to "Settings" or "Account" and update your profile details. Verify any critical changes by entering the verification code sent to your new email or mobile number. Click "Save" or "Apply" to finalize your modifications. Trades Poster will notify you of any critical updates.`,
        },
        {
          heading: "How do I delete my Trades Poster account?",
          content: `To delete your account, log in and navigate to your dashboard. Go to "Settings," then "Account," scroll down to the end of the page and click the red "Delete Account" button. Select a reason for deletion, provide feedback if desired, and enter the verification code sent to your email. Submit your final request to complete the process. Your account will be deleted immediately. Trades Poster retains your email address for 60 days to manage any residual issues. You can re-register within 60 days using the same email or register as a new user after 60 days.`,
        },
      ],
    },
    {
      title: "Subscriptions",
      link: "/HelpCentre/subscriptions",
      data: [
        {
          heading: "How do I select a subscription plan on Trades Poster?",
          content: `To select a subscription plan, click on "Pricing Plans" in the Trades Poster website header. Review the available plans and choose one that suits your business needs. Follow the prompts to subscribe and provide payment details for paid plans. Customers can sign up for free and start exploring services immediately. You will receive a confirmation email with your subscription details.`,
        },
        {
          heading: "Can I switch plans anytime on Trades Poster?",
          content: `Yes, you can switch plans anytime. Log into your account and go to your dashboard. Navigate to "Subscription" and then "Pricing Plans." Select a new plan to upgrade or downgrade based on your needs and follow the prompts to confirm your choice and accept the billing terms. Your account will be updated immediately, and the billing cycle will adjust accordingly.`,
        },
        {
          heading:
            "What happens if I cancel my Trades Poster subscription early?",
          content: "If you cancel early:",
          list: [
            {
              listItem: "Subscription services will stop immediately.",
            },
            {
              listItem:
                "No refunds will be provided for any remaining days in the billing cycle.",
            },
            {
              listItem:
                "Your business profile will remain active until the end of the paid period.",
            },
            {
              listItem:
                "To reactivate, you will need to select a new plan and accept current pricing and terms.",
            },
            {
              listItem:
                "You will receive a confirmation notification once your subscription is cancelled.",
            },
          ],
        },
        {
          heading:
            "How are fees billed and what payments are accepted on Trades Poster?",
          content: `Fees are billed monthly on the same day you signed up. All prices include GST. You will receive a tax invoice via email after each payment, and you can download invoices from your dashboard. Trades Poster accepts major debit and credit cards, including Visa and Mastercard. Future payment options may include digital wallets and bank transfers. You can cancel anytime, and your subscription will remain active until the end of the current billing period. You will receive email notifications and receipts for each charge.`,
        },
      ],
    },
    {
      title: "Customer vs Business ",
      link: "/HelpCentre/customer-vs-business",
      data: [
        {
          heading:
            "What differs between customer and business accounts on Trades Poster?",
            content: "There are many differences between Customer & Business",
          ListStartingLabel: "Key differences include:",
          ListLabel: "Customer accounts:",
          list: [
            {
              listItem: "Free account with no charges.",
            },
            {
              listItem: "Browse services.",
            },
            {
              listItem: "Review and rate services.",
            },
            {
              listItem: "Direct communication with businesses.",
            },
            {
              listItem: "Manage interactions from dashboard.",
            },
            {
              listItem: "Follow businesses.",
            },
            {
              listItem: "Receive notifications.",
            },
          ],
          ListLabel2: "Business accounts:",
          list2: [
            { listItem2: "Subscription plans with additional features." },
            { listItem2: "Detailed service listings and profiles." },
            { listItem2: "Customer interaction management." },
            { listItem2: "Analytics tools." },
            { listItem2: "Personalized dashboard." },
            { listItem2: "Multiple contact channels." },
          ],
        },
        {
          heading:
            "How do I switch from a customer to a business account on Trades Poster?",
          content: `To switch from a customer to a business account, you need to set up a separate account. Use a different email address for the business account, sign up with the new email, and choose the business account option. Fill in the required details, select a subscription plan that fits your business needs, and set up your business profile with detailed information. Manually add relevant reviews or history from your customer account. You may consider deactivating your customer account to focus on business operations, though this is optional. Finally, explore the tools and features available for business accounts to familiarize yourself with them.`,
        },
      ],
    },
    {
      title: "Email Support",
      link: "/HelpCentre/email-support",
      data: [
        {
          heading: "How can I reach email support on Trades Poster?",
          content: `To contact support, go to the Trades Poster website and click on "Help Centre." Fill out the contact form with the required details and select the inquiry category. Submit the form, and our support team will respond within 24 to 48 hours. Complex issues may take longer, but we aim to respond promptly. You can continue the conversation through follow-up options if needed.`,
        },
      ],
    },
  ];
  const customers = [
    {
      title: "Profile Setup",
      link: "/HelpCentre/profile-setup",
      data: [
        {
          heading: "How do i create my customer profile on trades poster?",
          content: `To create your customer profile, visit the trades poster homepage and click "sign up." register using your email and password or with google/Facebook, choose the "customer" account option, and fill in your first name (required), last name (optional), mobile number, and address (suburb and postcode). Enter the verification code sent to your email, review and agree to the terms and conditions. Once verified, your account will be active, allowing you to start browsing services, requesting quotes, and interacting with businesses.`,
        },
        {
          heading: "Can i adjust privacy settings during setup?",
          content: `While initial privacy settings during the account creation process are set to default, you can customize your privacy and communication preferences once your trades poster account is verified and active. Log in and navigate to your customer dashboard, then go to the 'settings' menu and select 'notifications.' adjust your notification preferences for activity on your interactions, business communication alerts, new content and services, support notifications, review interactions, saved services updates, personalized service recommendations, trades poster news and updates, and user privacy and settings. Save your changes to update your account settings immediately.`,
        },
      ],
    },
    {
      title: "Exploring Services",
      link: "/HelpCentre/exploring-services",
      data: [
        {
          heading: "How do i search for services on trades poster?",
          content: `To search for services on Trades Poster, start on the homepage. Click on the 'Categories' tab in the header to view different service categories like plumbing, electrical, and landscaping. Select the category you need to see a list of available service providers and a map showing their locations. Use filters to refine your search by adjusting the radius, changing your suburb or postcode, or sorting by top-rated, popular, or recommended businesses. Service cards below the map provide basic information about each business. Click on a card to see more details, including descriptions, services offered, and reviews. To contact the business, click the 'Get in Touch' button to start a chat, request a quote, or use the provided contact details.`,
        },
        {
          heading:
            "Are there specific tools for filtering services on Trades Poster?",
          content: `Yes, Trades Poster is equipped with advanced filtering tools and integrates Google Maps to enhance your search experience. Here’s how you can effectively use these tools to refine your search for services:`,
          FooterNote:
            "By utilizing these filtering tools, you can efficiently find the services that best match your needs on Trades Poster.",
          InlineHeadingList: [
            {
              darkHeading: "Google Maps Integration",
              InlineParagraph:
                "The service listings include a Google Map view, displaying markers for each service provider in your chosen category. This helps you understand the proximity of different services to your location.",
            },
            {
              darkHeading: "Categories",
              InlineParagraph:
                "Quickly filter services by selecting from a variety of categories such as plumbing, electrical, landscaping, and more. This helps you dive directly into the specific services you require.",
            },
            {
              darkHeading: "Location Filters",
              InlineParagraph:
                "Specify your search area by adjusting the location filter. Input a suburb or postcode and define the radius (e.g., 10 km from your location) to narrow down the search to local service providers.",
            },
            {
              darkHeading: "Timeframe Filters",
              InlineParagraph:
                "Use the timeframe sort button to choose when services were added, with options like 'Now', 'This Week', 'This Month', or 'All Time', helping you find the most recent listings.",
            },
            {
              darkHeading: "Sorting Options",
              InlineParagraph:
                "Sort services based on criteria such as 'Top Rated', 'Recommended', 'Popular', or 'Pro'. This helps you prioritize services based on their ratings, recommendations, popularity, or professional status.",
            },
            {
              darkHeading: "Keyword Search",
              InlineParagraph:
                "Use the keyword search bar to type in specific terms related to the services you’re looking for, further refining your search to match precise needs.",
            },
            {
              darkHeading: "Interactive Map Features",
              InlineParagraph:
                "Interact directly with the Google Map. Clicking on a service marker will reveal more details about that business, and adjusting the map view will dynamically update the services listed based on the visible geographical area.",
            },
          ],
        },
      ],
    },
    {
      title: "Business Engagement",
      link: "/HelpCentre/business-engagement",
      data: [
        {
          heading:
            "What should i check before engaging with a business on trades poster?",
          content: `Before engaging with a business on Trades Poster, it's important to review the following:`,
          InlineHeadingList: [
            {
              darkHeading: "Business Profile and Details",
              InlineParagraph:
                "Check the business's experience, qualifications, and necessary licenses, especially for regulated trades like electrical work or plumbing.",
            },
            {
              darkHeading: "Ratings and Reviews",
              InlineParagraph:
                "Read reviews and ratings from other customers to understand the quality of work, professionalism, and reliability. Look for consistent feedback patterns.",
            },
            {
              darkHeading: "Portfolio or Past Work Examples",
              InlineParagraph:
                "Review the business's portfolio to assess their craftsmanship and see if it matches your expectations.",
            },
            {
              darkHeading: "Insurance and Warranty Information",
              InlineParagraph:
                "Ensure the business has appropriate insurance and inquire about warranty offerings on their work.",
            },
            {
              darkHeading: "Quotes",
              InlineParagraph:
                "Obtain and compare detailed quotes from multiple businesses, including a breakdown of services, materials, timelines, and additional charges.",
            },
            {
              darkHeading: "Communication and Professionalism",
              InlineParagraph:
                "Evaluate the business's responsiveness and communication style to gauge how they manage customer relationships and projects.",
            },
            {
              darkHeading: "Terms of Service and Contracts",
              InlineParagraph:
                "Review the terms of service or contracts carefully, understanding the scope of work, payment terms, timelines, and any clauses related to changes or cancellations.",
            },
            {
              darkHeading: "Certifications and Endorsements",
              InlineParagraph:
                "Verify any specific certifications or endorsements required for the trade to ensure compliance and quality standards.",
            },
          ],
        },
        {
          heading: "How do i communicate my requirements?",
          content: `To communicate your requirements, provide a clear and detailed description of the service you need, including any preferences and expected outcomes. Include photos or diagrams if possible. State what aspects are most important, such as timeline, cost, and materials. Share your availability and budget. Engage in a conversation by asking about the service process, materials, and duration. Use Trades Poster's messaging and quote request features for clear communication. Follow up to clarify details and ensure mutual understanding. Finally, get a written agreement outlining the scope, pricing, and timelines to prevent misunderstandings.`,
        },
        {
          heading: "What safety tips should i follow on trades poster?",
          content: `Here are some safety tips to follow when engaging with businesses on trades poster:`,
          InlineHeadingList: [
            {
              darkHeading: "Verify business credentials",
              InlineParagraph:
                "Always ensure that the business is properly licensed and insured, especially for significant or high-risk work. You can often verify these credentials directly on the business's trades poster profile.",
            },
            {
              darkHeading: "Read reviews and ratings",
              InlineParagraph:
                "Review the feedback from previous customers on trades poster to assess the reliability and quality of the business's work. This can give your insight into their professionalism and the satisfaction of past clients.",
            },
            {
              darkHeading: "Meet in person",
              InlineParagraph:
                "If a face-to-face meeting is necessary to discuss project details, ensure that someone else is present, especially if the meeting is at your home. Having someone with you ensures you understand, can ask questions, and feel secure.",
            },
            {
              darkHeading: "Use secure payment methods",
              InlineParagraph:
                "Avoid large upfront payments and use secure payment methods such as bank transfers or card payments, agreed upon between you and the business.",
            },
            {
              darkHeading: "Keep personal information private",
              InlineParagraph:
                "Exercise caution in sharing personal information or bank details. Share only what is necessary and do so in a secure manner.",
            },
            {
              darkHeading: "Communicate through the platform",
              InlineParagraph:
                "Use trades poster’s communication tools for all discussions to keep a record of your interactions. This helps maintain clear communications and assists in dispute resolution if needed.",
            },
            {
              darkHeading: "Follow your instincts",
              InlineParagraph:
                "If something about the interaction feels wrong, trust your instincts and either ask more questions to clarify any doubts or cease further interaction.",
            },
            {
              darkHeading: "Ensure written agreements",
              InlineParagraph:
                "Request detailed written quotes and contracts from the business before starting any work. Ensure all details like scope, costs, timelines, and payment terms are clearly outlined to avoid misunderstandings.",
            },
          ],
        },
        {
          heading: "How do i request a quote on trades poster?",
          content:
            "To request a quote, provide a detailed description of the service you need. Use the 'Get in Touch' button on the business’s profile or service detail page. Include relevant details like the scope of work, materials, and timeframe. Request itemized quotes to compare costs. Review and confirm the quote in writing once you decide to proceed",
        },
      ],
    },
    {
      title: "Privacy Protocols",
      link: "/HelpCentre/privacy-protocols",
      data: [
        {
          heading: "How do i report security issues?",
          content: `Answers are pending `,
        },
        {
          heading: "How can i adjust my privacy settings?",
          content: `Answers are pending `,
        },
      ],
    },
    {
      title: "Feedbacks",
      link: "/HelpCentre/feedbacks",
      data: [
        {
          heading: "What are the best practices for leaving reviews?",
          content: `To leave a helpful review on Trades Poster, provide honest feedback about your experience. Include specific details about what you liked or didn’t like about the service, such as punctuality, quality of work, and problem resolution. Focus on your direct experience and avoid unrelated details or personal opinions. Keep your review respectful and avoid offensive language. Offer constructive criticism by suggesting improvements. Mention any exceptional circumstances that influenced your experience. Update your review if your opinion changes or if your concerns are addressed. Ensure your rating matches your review's content and double-check for accuracy and clarity before submitting. `,
        },
        {
          heading: "How can i leave feedback for trades poster?",
          content: `To leave feedback for Trades Poster, go to the 'Help' or 'Support' section on the website, accessible from the main menu or footer. Fill out the feedback form with your comments and suggestions. If you prefer, you can email Trades Poster directly using the contact email provided in the support section. Be clear and concise in your email. Participate in any surveys sent by Trades Poster for more structured feedback. You can also leave feedback via Trades Poster’s social media platforms through public posts or direct messages. Additionally, use the live chat feature if available for immediate feedback.`,
        },
        {
          heading:
            "What should I do if a service is unsatisfactory and how do I handle disputes?",
          content:
            "If you find a service unsatisfactory, start by contacting the business to discuss your concerns. Review your contract for any relevant clauses and request that the business redo the work or offer a refund. Keep records of all communications and documents related to the issue. If the problem remains unresolved, use Trades Poster's dispute resolution feature in the 'Help' or 'Support' section of your account dashboard, providing a detailed explanation and supporting documents. Leave a review to inform others about your experience. For serious issues, visit the 'Help' section for additional guidance, check FAQs or user guides, and seek legal advice if necessary, consulting the ACCC if needed.",
        },
        {
          heading: "How do I leave a service review on Trades Poster?",
          content:
            "To leave a review on Trades Poster, log in to your account and go to the service post. Click on “Leave a Review,” provide honest and detailed feedback, and then submit your review. Your review may be reviewed by the platform to ensure it adheres to community standards before it goes live.",
        },
      ],
    },
  ];
  const business = [
    {
      title: "Profiles",
      link: "/HelpCentre/profiles",
      data: [
        {
          heading: "How do I create a business profile?",
          content: `To create a business profile on Trades Poster:`,
          extraParagraph:
            "Visit the Trades Poster homepage, click 'Sign Up', and select the business registration option. Enter your business details, including your legal business name, Australian Business Number (ABN), contact information, and services offered. Write a detailed description of your business, highlighting your expertise, years in operation, and specialties. Upload any certifications, licenses, or insurance documents to enhance credibility. Specify the geographic areas where you offer services and ensure your contact details are correct. Add high-quality images of your work, team, or office, and list your services with detailed descriptions. Use relevant keywords to improve your profile's visibility. Submit the required documents for verification if necessary. Finally, review and edit your information for accuracy and professionalism, then publish your profile and start engaging with potential customers.",
        },
        {
          heading: "What features make a profile stand out?",
          content: `To make your business profile stand out on Trades Poster, include professional, high-quality images of your work. Write a detailed and engaging business description that highlights your skills and experience. Clearly define your services with specific descriptions and display relevant certifications and licenses prominently. Encourage satisfied customers to leave reviews. Mention any professional associations or affiliations. Regularly update your profile to reflect new services or changes. Be responsive to inquiries and comments. Use relevant keywords to improve your visibility in search results.`,
        },
        {
          heading: "Can I update my profile information?",
          content: `Yes, you can update your profile information on Trades Poster. Here’s how:`,
          extraParagraph:
            "Log in to your Trades Poster account and navigate to your business dashboard. Access the 'Profile' or 'Settings' section. Here, you can update your business name, description, contact information, services offered, photos and media, certifications and licenses, and operational details like business hours and service areas. After making changes, click 'Save' or 'Update.' Review your profile to ensure all information is correct. Regularly update your profile to keep information relevant and accurate.",
        },
        {
          heading: "How do I apply and benefits for a Pro tag?",
          content: `To apply for a Pro tag, ensure your business profile is fully detailed with your business name, logo, contact information, and a compelling description of your services and expertise. Upload relevant certifications, licenses, and verifications to authenticate your expertise. Provide high-quality images or videos showcasing your work. Encourage satisfied customers to leave positive reviews. Upgrade to the Premium plan, which is required for the Pro tag and includes additional benefits such as unlimited regular posts, 20 featured posts, 10 keywords, and priority visibility in services.
          The benefits of having a Pro tag include unmatched visibility in search results and featured sections, increased engagement rates with more profile visits and messages from customers, enhanced trust signalling professionalism, and the ability to showcase your achievements, making your business more attractive to potential clients.
          `,
        },
      ],
    },
    {
      title: "Post Services",
      link: "/HelpCentre/post-services",
      data: [
        {
          heading: "How do I post a service?",
          content: `To post a service on Trades Poster, log into your account and access your business dashboard. Click on the 'Post' tab and select 'Create Post.' Choose either a 'Regular' or 'Featured' post based on your subscription plan. Select the appropriate category and subcategory for your service. Write a concise and descriptive title with relevant keywords and provide a detailed description of your service. Specify the geographic area by entering specific suburbs or postcodes and adjusting the coverage radius. Upload images that depict your service or workmanship. Review your post for accuracy and click 'Publish' to make your service available on Trades Poster.`,
        },
        {
          heading: "What guidelines apply to postings?",
          content: `When posting a service on Trades Poster, follow these guidelines to ensure your posts are effective and compliant:`,
          InlineHeadingList: [
            {
              darkHeading: "Accuracy of Information",
              InlineParagraph:
                "Ensure all service details are accurate and truthful. Regularly update your posts to reflect any changes in services, pricing, or availability.",
            },
            {
              darkHeading: "Clarity and Professionalism",
              InlineParagraph:
                "Use clear, professional language. Avoid slang or jargon unless widely understood in your trade. Organize your post for easy readability.",
            },
            {
              darkHeading: "Appropriate Content",
              InlineParagraph:
                "Avoid offensive, misleading, or fraudulent content. Do not include personal information or content that infringes on privacy.",
            },
            {
              darkHeading: "Visual Content",
              InlineParagraph:
                "Use high-quality, relevant images. Ensure you own the rights to these images. Avoid using overly edited images that might misrepresent the quality or results of your service.",
            },
            {
              darkHeading: "Compliance with Legal and Ethical Standards",
              InlineParagraph:
                "Ensure compliance with local regulations and laws. Avoid posting illegal services or engaging in discriminatory practices.",
            },
            {
              darkHeading: "Keywords ",
              InlineParagraph:
                "Incorporate relevant keywords naturally to enhance searchability. Avoid keyword stuffing. Select specific keywords relevant to your service and local area.",
            },
            {
              darkHeading: "Response Guidelines",
              InlineParagraph:
                "Respond promptly and professionally to inquiries and feedback. Clearly communicate expected response times and service delivery.",
            },
            {
              darkHeading: "Original Content",
              InlineParagraph:
                "Use your own images and content. Do not copy from other businesses. Only post trade-related pictures and content, as Trades Poster is exclusively for trade businesses.",
            },
            {
              darkHeading: "Conduct and Ethics",
              InlineParagraph:
                "Adhere to professional ethics. Avoid profanity and unethical behavior. Ensure images and content are appropriate and professional. Indecent content is strictly prohibited and may lead to the suspension or banning of your account for platform violations.",
            },
          ],
        },
        {
          heading: "Is there a limit to how many services I can post?",
          content: `Yes, the number of services you can post depends on your subscription plan:`,
          FooterNote:
            "For a detailed comparison of each plan, please visit the Pricing Plans page. Choose a plan that best suits your business needs and the volume of postings you intend to manage to maximize the visibility and engagement of your services on Trades Poster.",
          InlineHeadingList: [
            {
              darkHeading: "Free Plan",
              InlineParagraph:
                "Allows for 1 regular post. No featured posts are included.",
            },
            {
              darkHeading: "Standard Plan",
              InlineParagraph: "Allows for unlimited regular posts.",
            },
            {
              darkHeading: "Premium Plan",
              InlineParagraph:
                "Allows for unlimited regular posts and includes 20 featured posts.",
            },
          ],
        },
        {
          heading: "Difference between Regular and featured post?",
          content: `The difference between a regular post and a featured post primarily lies in the level of visibility and prominence each type of post receives on the platform:`,
          InlineHeadingList: [
            {
              darkHeading: "Regular Post",
              InlineParagraph:
                "Provides basic exposure on the service page. It is listed in the standard search results without special highlighting. This option is suited for businesses looking to maintain a standard presence.",
            },
            {
              darkHeading: "Featured Post",
              InlineParagraph:
                "Grants enhanced placement on the service page. It appears more prominently in search results and may be highlighted or marked to draw attention. Featured posts often appear on the homepage or in special sections that attract more views. This option is ideal for businesses seeking to maximize visibility, reach more potential customers, and increase engagement.",
            },
          ],
        },
      ],
    },
    {
      title: "Memberships",
      link: "/HelpCentre/memberships",
      data: [
        {
          heading: "What membership plans are available?",
          content: `Trades Poster offers three main subscription plans for businesses:`,
          ListLabel: "Free Plan:",
          list: [
            {
              listItem: "Business Description & Logo",
            },
            {
              listItem: "Live Chat",
            },
            {
              listItem: "1 Regular Post",
            },
            {
              listItem: "1 Keyword",
            },
          ],
          ListLabel2: "Standard Plan:",
          list2: [
            {
              listItem2: "All Free Plan Features",
            },
            {
              listItem2: "Portfolio Gallery",
            },
            {
              listItem2: "3 Keywords",
            },
            {
              listItem2: "Unlimited Regular Posts",
            },
          ],
          ListLabel3: "Premium Plan:",
          list3: [
            {
              listItem3: "All Standard Plan Features",
            },
            {
              listItem3: "10 Keywords",
            },
            {
              listItem3: "20 Featured Posts",
            },
            {
              listItem3: "Business Contact Details",
            },
            {
              listItem3: "Trading Hours",
            },
            {
              listItem3: "Post Metrics",
            },
          ],
          FooterNote:
            "Choose the plan that best suits your business needs to optimize visibility and engagement on Trades Poster.",
        },
        {
          heading: "How do I change or cancel a membership?",
          content: "There are two ways to cancel a membership",
          list: [
            {
              listItem:
                "To change your membership, go to the 'Subscription' section in your dashboard, click on 'Pricing Plans', choose a new plan that meets your business needs, and confirm your selection. The change will take effect immediately, updating your account with the new plan's features and billing cycle.",
            },
            {
              listItem:
                "To cancel your membership, you need to delete your account. Navigate to the 'Account' section in your dashboard, select the option to delete your account, and follow the provided prompts to confirm. Deleting your account will cancel your membership, and your account will be deactivated.",
            },
          ],
        },
      ],
    },
    {
      title: "Customer Interaction",
      link: "/HelpCentre/customer-interaction",
      data: [
        {
          heading: "What are best practices for customer interactions?",
          content: `Effective customer interactions are crucial for maintaining and growing a successful business on Trades Poster. Here are some best practices:`,
          extraParagraph:
            "Prompt responses to customer inquiries and messages show that you value their time and business. Communicate clearly and professionally, ensuring your messages are free of jargon and easy to understand. Tailor your interaction to the customer’s needs and previous interactions, as personalized engagement can enhance customer experience and loyalty. Even in difficult situations, maintain a professional demeanor and always be courteous and respectful.",
        },
        {
          heading: "How should I handle complaints?",
          content: `Handling complaints effectively involves several key steps:`,
          extraParagraph:
            "Listen actively to the customer's issue, giving your full attention without interrupting and acknowledging the problem and the inconvenience it has caused. Offer a sincere apology if the complaint is valid, as this can help diffuse tension. Provide clear options for resolving the issue and, if immediate resolution isn’t possible, set a timeline for when they can expect a solution. After resolving the issue, follow up to ensure the customer is satisfied with the solution and see if there is anything more you can do.",
        },
        {
          heading: "What strategies improve customer satisfaction?",
          content: `Improving customer satisfaction involves several strategies:`,
          extraParagraph:
            "Regularly seek feedback on your services through follow-up emails, surveys, or direct messages after completing a job. Use the feedback to make necessary adjustments in your services or interaction strategies, demonstrating responsiveness to customer needs. Go above and beyond what is expected whenever possible, as extra effort in service delivery can turn a satisfied customer into a delighted one. Regularly train your staff on customer service best practices, ensuring they handle interactions efficiently and empathetically. By integrating these practices into your daily operations, you can build stronger relationships with your customers, resolve issues more effectively, and enhance the overall customer experience on Trades Poster.",
        },
      ],
    },
    {
      title: "Certifications",
      link: "/HelpCentre/certifications",
      data: [
        {
          heading: "How do I add certifications to my profile?",
          content: `To add certifications to your Trades Poster profile, log in to your account and navigate to your profile or dashboard. Select "Settings" and find the "Certifications" section. Click "Add" to enter your certification details, including the name of the certification, issuing organization, and date obtained or expiration date. Upload a digital copy or image of the certificate if required. Submit your certifications for verification if necessary. Once added, you can view, update, delete, or modify your certifications as needed to maintain trust and transparency with potential clients.`,
        },
        {
          heading: "What types of certifications are recognized?",
          content: `Trades Poster recognizes a broad range of certifications related to your trade services, including, but not limited to:`,
          InlineHeadingList: [
            {
              InlineParagraph:
                "Trade-specific licenses (e.g., plumbing, electrical, carpentry)",
            },
            {
              InlineParagraph:
                "Safety certifications (e.g., OSHA, WHS, First Aid)",
            },
            {
              InlineParagraph:
                "Specialized skills certifications (e.g., HVAC qualifications, asbestos removal certification)",
            },
            {
              InlineParagraph:
                "Insurance and bonding documents that enhance credibility and trust",
            },
          ],
        },
        {
          heading: "Why should I display certifications?",
          content:
            "Displaying certifications on your profile offers several benefits. It builds trust and credibility, making clients more likely to choose your services. It highlights your expertise and commitment to maintaining high industry standards. A well-documented profile with relevant certifications can attract more customers, as they often look for qualified and accredited professionals. Additionally, displaying certifications ensures compliance and transparency, informing clients of your qualifications as expected in many trades.",
        },
      ],
    },
    {
      title: "Analytics & Dashboard",
      link: "/HelpCentre/analytics-&-dashboard",
      data: [
        {
          heading: "How do I access the analytics dashboard?",
          content: `To access the analytics dashboard on Trades Poster, log into your account with your business credentials. Navigate to your profile or dashboard icon, usually found at the top right corner of the homepage. In the sidebar menu within your dashboard, look for a section titled 'Analytics' or 'Metrics' and click on it to view various analytics related to your business activities. Explore the different metrics such as views, shares, and saves for each of your posts, both featured and regular. You can also use filters to sort this data by time period, post type, or other criteria.`,
        },
        {
          heading: "How can analytics improve my strategy?",
          content: `Analytics on Trades Poster can significantly shape your business strategy by identifying trends and preferences among your target audience through the observation of post engagement. Understanding what works and what doesn’t allow you to tweak your posts or create new ones that better align with audience interests. Tracking when your posts perform best helps you choose optimal times for posting to maximize visibility and engagement. Seeing how different types of content perform lets you focus on creating more of what leads to higher engagement. Long-term trends and data comparisons can guide strategic decisions about business development, marketing focus, and resource allocation. Using analytics effectively enables you to make data-driven decisions that enhance your business presence and customer engagement on Trades Poster.`,
        },
      ],
    },
    {
      title: "Compliance Checklist",
      link: "/HelpCentre/compliance-checklist",
      data: [
        {
          heading: "What compliance must I meet?",
          content: `To operate legally and ethically on Trades Poster, you must meet several compliance requirements:`,
          extraParagraph:
            "Ensure your business has all necessary licenses and permits, including trade licenses, professional certifications, and local business permits. Carry appropriate insurance coverage, such as liability insurance, to protect your business and customers in case of accidents or damages. Comply with data protection laws relevant to your location, like the Australian Privacy Act, to handle customer data responsibly. Adhere to occupational health and safety regulations to ensure a safe working environment for employees and clients, especially on-site. Additionally, ensure your business practices comply with environmental regulations to minimize impacts on the environment, if applicable.",
        },
        {
          heading: "How often are compliance updates made?",
          content: `Compliance updates can occur due to regulatory changes and platform updates. Stay informed about these changes, as they can occur annually or less predictably.`,
        },
        {
          heading: "Where can I find compliance resources?",
          content:
            "The Trades Poster Resource Library offers a variety of resources to help you manage and grow your business:",
          InlineHeadingList: [
            {
              darkHeading: "Blog & Articles",
              InlineParagraph:
                "Informative content on industry trends, best practices, and tips for improving your business.",
            },
            {
              darkHeading: "Services Guide",
              InlineParagraph:
                "Detailed guides on various trades services, helping customers understand and checklist each service.",
            },
            {
              darkHeading: "Service Cost Estimate",
              InlineParagraph:
                "Calculators and articles to help you estimate the cost of services, ensuring competitive and market-appropriate pricing.",
            },
            {
              darkHeading: "Council Regulations",
              InlineParagraph:
                "Information on local council regulations that may impact your business operations, ensuring you stay compliant with local laws.",
            },
          ],
        },
      ],
    },
    {
      title: "Technical Support",
      link: "/HelpCentre/technical-support",
      data: [
        {
          heading: "How do I contact technical support?",
          content: `For addressing technical issues, resolving login problems, and handling unusual account activity on Trades Poster, you can contact technical support through several channels:`,
          InlineHeadingList: [
            {
              darkHeading: "Help Centre",
              InlineParagraph:
                "Access the Trades Poster Help Centre, which likely has a dedicated section for technical support. Here you can find articles, FAQs, and troubleshooting guides.",
            },
            {
              darkHeading: "Contact Form",
              InlineParagraph:
                "Use the contact form available on the website. Provide detailed information about the issue for faster resolution.",
            },
            {
              darkHeading: "Email Support",
              InlineParagraph:
                "Send an email detailing the issue to the technical support team. Include screenshots or other relevant information to clarify the problem.",
            },
          ],
        },
        {
          heading: "How do I resolve login issues?",
          content: `To resolve login issues on Trades Poster:`,
          InlineHeadingList: [
            {
              darkHeading: "Password Reset",
              InlineParagraph:
                "Use the ‘Forgot Password’ feature on the login page to reset your password. Follow the instructions sent to your registered email.",
            },
            {
              darkHeading: "Check Browser Settings",
              InlineParagraph:
                "Ensure your browser accepts cookies and doesn’t have settings that might block the login process.",
            },
            {
              darkHeading: "Update Browser or App",
              InlineParagraph:
                "Make sure you are using the latest version of your web browser or the Trades Poster app.",
            },
          ],
        },
        {
          heading: "What should I do about unusual account activity?",
          content: `To handle unusual account activity on Trades Poster:`,
          FooterNote:
            "By following these steps, you can effectively manage technical issues, resolve login problems, and respond to unusual account activity, ensuring a secure and smooth experience on Trades Poster.",
          InlineHeadingList: [
            {
              darkHeading: "Change Password",
              InlineParagraph:
                "Immediately change your password if you notice any unusual activity in your account.",
            },
            {
              darkHeading: "Review Account Activity",
              InlineParagraph:
                "Check login sessions and recent activity logs if available. Log out from all other sessions if you suspect unauthorized access.",
            },
            {
              darkHeading: "Contact Support",
              InlineParagraph:
                "Report the activity to the Trades Poster support team. Provide them with details about what you observed and any changes you did not authorize.",
            },
            {
              darkHeading: "Monitor Your Account",
              InlineParagraph:
                "Keep an eye on your account for any further unusual activities. Check your associated email and other accounts for security breaches.",
            },
          ],
        },
      ],
    },
  ];
  const aboutTradesposter = [
    {
      LinkingTitle: "Our Story",
      link: "/about-us",
    },

    {
      title: "Legal Hub",
      // link: "/HelpCentre/legal-hub",
      dropdownList: [
        {
          dropdownTitle: "Trades Poster Basics",
          link: "/HelpCentre/legal-hub/trades-poster-basics",
          data: [
            {
              heading: "What are the community standards on Trades Poster?",
              content:
                "Trades Poster expects all users to adhere to the following community standards to ensure a safe and respectful environment:",
              InlineHeadingList: [
                {
                  darkHeading: "Respectful Communication",
                  InlineParagraph:
                    "Always communicate with other users respectfully. Avoid using abusive, harassing, or offensive language.",
                },
                {
                  darkHeading: "No Discrimination",
                  InlineParagraph:
                    "Do not engage in any form of discrimination based on race, ethnicity, gender, religion, nationality, or any other characteristic.",
                },
                {
                  darkHeading: "Authenticity",
                  InlineParagraph:
                    "Provide truthful and accurate information in your profile, posts, and communications. Do not impersonate others or create fake accounts.",
                },
                {
                  darkHeading: "Privacy Respect",
                  InlineParagraph:
                    "Do not share personal information of others without their consent. Respect privacy and confidentiality at all times.",
                },
                {
                  darkHeading: "No Spam",
                  InlineParagraph:
                    "Avoid posting repetitive or irrelevant content. Do not send unsolicited messages or promotions.",
                },
                {
                  darkHeading: "Professional Conduct",
                  InlineParagraph:
                    "Conduct yourself professionally, especially when interacting with potential customers or business partners. Maintain a courteous and ethical demeanour.",
                },
                {
                  darkHeading: "Legal Compliance",
                  InlineParagraph:
                    "Ensure all your activities on the platform comply with local laws and regulations. Do not engage in illegal activities or promote illegal services.",
                },
                {
                  darkHeading: "Content Guidelines",
                  InlineParagraph:
                    "Only post content that is appropriate, relevant, and adheres to Trades Poster’s guidelines. Do not post inappropriate, violent, or explicit content.",
                },
                {
                  darkHeading: "Conflict Resolution",
                  InlineParagraph:
                    "Handle disputes or conflicts calmly and respectfully. Use the platform’s dispute resolution mechanisms if necessary.",
                },
              ],
            },
            {
              heading:
                "What should I do if I encounter abusive or harassing behaviour on Trades Poster?",
              content: "If you encounter abusive or harassing behaviour:",
              InlineHeadingList: [
                {
                  darkHeading: "Report the User",
                  InlineParagraph:
                    "Use the 'Report' feature to flag the user or content that is abusive or harassing.",
                },
                {
                  darkHeading: "Block the User",
                  InlineParagraph:
                    "Utilize the 'Block' option to prevent further interactions with the abusive user.",
                },
                {
                  darkHeading: "Contact Support",
                  InlineParagraph:
                    "Reach out to Trades Poster support via the Help Centre or email to report the incident in detail. Provide any evidence or screenshots that can help support your case.",
                },
              ],
            },
            {
              heading:
                "How does Trades Poster handle reports of inappropriate behaviour?",
              content:
                "Trades Poster has a dedicated team to handle reports of inappropriate behaviour. Upon receiving a report:",
              FooterNote:
                "By following these community standards, all users can contribute to a positive and respectful environment on Trades Poster.",
              InlineHeadingList: [
                {
                  darkHeading: "Investigation",
                  InlineParagraph:
                    "The team will investigate the reported behaviour or content.",
                },
                {
                  darkHeading: "Action",
                  InlineParagraph:
                    "Depending on the findings, appropriate action will be taken, which may include warning the user, suspending their account, or permanently banning them from the platform.",
                },
                {
                  darkHeading: "Notification",
                  InlineParagraph:
                    "You may receive updates or notifications about the status of your report if necessary.",
                },
              ],
            },
          ],
        },
        {
          dropdownTitle: "Account Suspension",
          link: "/HelpCentre/legal-hub/account-suspension",
          data: [
            {
              heading:
                "What should I do if my Trades Poster account is suspended?",
              content: "If your Trades Poster account is suspended:",
              FooterNote:
                "By promptly addressing the issue and cooperating with Trades Poster support, you can work towards resolving the suspension and reactivating your account.",
              InlineHeadingList: [
                {
                  darkHeading: "Review Suspension Notice",
                  InlineParagraph:
                    "Check the email or notification from Trades Poster to understand the reason for the suspension.",
                },
                {
                  darkHeading: "Email Support",
                  InlineParagraph:
                    "Send an email to Trades Poster support detailing your account suspension. Include any relevant information or documentation that might help resolve the issue.",
                },
                {
                  darkHeading: "Help Centre",
                  InlineParagraph:
                    "Visit the Help Centre on Trades Poster and use the contact form to submit a request for more information or to appeal the suspension.",
                },
                {
                  darkHeading: "Provide Necessary Information",
                  InlineParagraph:
                    "Be prepared to provide additional information or clarification about the activities or content that led to the suspension.",
                },
                {
                  darkHeading: "Follow Instructions",
                  InlineParagraph:
                    "Follow any instructions provided by Trades Poster support to rectify the situation. This might include removing prohibited content, verifying your identity, or addressing any policy violations.",
                },
                {
                  darkHeading: "Wait for Resolution",
                  InlineParagraph:
                    "Allow some time for Trades Poster support to review your case and respond. They will notify you of the outcome and any steps needed to restore your account.",
                },
              ],
            },
          ],
        },
        {
          dropdownTitle: "Reporting & Blocking",
          link: "/HelpCentre/legal-hub/reporting-&-blocking",
          data: [
            {
              heading: "How do I report or block a user on Trades Poster?",
              content:
                "To report or block a user on Trades Poster, you can use several methods:",
              FooterNote:
                "By following these steps, you can effectively report inappropriate behaviour or block users to maintain a safe and positive experience on Trades Poster.",
              ListLabel: "Reporting from profile:",
              list: [
                {
                  listItem: "Navigate to the user's profile.",
                },
                {
                  listItem: "Click on the 'Flag' option to report the user.",
                },
                {
                  listItem:
                    "Choose the reason for your report from the provided options and provide any additional information or context.",
                },
                {
                  listItem:
                    "Submit the report for review by the Trades Poster support team.",
                },
              ],
              ListLabel2: "Reporting from post:",
              list2: [
                {
                  listItem2: "Locate the post you want to report.",
                },
                {
                  listItem2: "Click on the 'Flag' option on the post.",
                },
                {
                  listItem2:
                    "Choose the reason for your report and provide details.",
                },
                {
                  listItem2:
                    "Submit the report to be reviewed by the support team.",
                },
              ],
              ListLabel3: "Reporting Help Centre:",
              list3: [
                {
                  listItem3: "Go to the Help Centre on Trades Poster.",
                },
                {
                  listItem3:
                    "Use the contact form to write a detailed report about the issue.",
                },
                {
                  listItem3: "Submit your report for further assistance.",
                },
              ],
              ListLabel4: "Blocking from messages:",
              list4: [
                {
                  listItem4:
                    "Open the message thread with the user you want to block.",
                },
                {
                  listItem4:
                    "Click on the 'Block' option in the interaction menu.",
                },
                {
                  listItem4:
                    "Confirm your decision to block the user, preventing them from contacting you further.",
                },
              ],
              ListLabel5: "Managing Blocked Users:",
              list5: [
                {
                  listItem5:
                    "View and manage your blocked users in the settings or privacy section of your dashboard.",
                },
              ],
            },
          ],
        },
        {
          dropdownTitle: "Content & Copyrights",
          link: "/HelpCentre/legal-hub/content-&-copyrights",
          data: [
            {
              heading:
                "How do I ensure my content complies with copyright laws on Trades Poster?",
              content:
                "To ensure your content complies with copyright laws on Trades Poster:",
              FooterNote:
                "By following these guidelines, you can ensure that your content on Trades Poster complies with copyright laws and maintains the integrity of your business.",
              InlineHeadingList: [
                {
                  darkHeading: "Use Original Content",
                  InlineParagraph:
                    "Always use content that you have created yourself or have explicit permission to use. This includes text, images, videos, and other media.",
                },
                {
                  darkHeading: "Obtain Licenses",
                  InlineParagraph:
                    "If you use third-party content, make sure you have the proper licenses or permissions to use it. This applies to stock images, music, and any other content that is not originally yours.",
                },
                {
                  darkHeading: "Give Credit",
                  InlineParagraph:
                    "Where applicable, give proper credit to the original creators of any content you use, in accordance with their licensing terms.",
                },
                {
                  darkHeading: "Avoid Infringement",
                  InlineParagraph:
                    "Do not use copyrighted material without permission. This includes logos, trademarks, and other protected content from other businesses or individuals.",
                },
                {
                  darkHeading: "Using Other Business Content",
                  InlineParagraph:
                    "Do not use content from other businesses for your posts without their permission. This includes copying service descriptions, images, or any other material.",
                },
                {
                  darkHeading: "Report Violations",
                  InlineParagraph:
                    "If you believe your content has been used without permission on Trades Poster, report the violation to the platform's support team with evidence of your ownership.",
                },
              ],
            },
          ],
        },
        {
          dropdownTitle: "Terms & Conditions",
          link: "/terms-&-conditions",
        },
        {
          dropdownTitle: "Privacy Policy ",
          link: "/privacy-policy",
        },
      ],
    },
    {
      title: "Resource Library",
      dropdownList: [
        {
          dropdownTitle: "Blog & Articles",
          link: "/blogs",
        },
        {
          dropdownTitle: "Services Guide",
          link: "/services-guide",
        },
        {
          dropdownTitle: "Service Cost Estimate",
          link: "/cost-estimation",
        },
        {
          dropdownTitle: "Council Regulations",
          link: "/councils",
        },
      ],
      data: [
        {
          heading: "How can i secure my personal information on trades poster?",
          content: `Answers are pending `,
        },
        {
          heading: "What should i do if my intellectual property is infringed?",
          content: `Answers are pending `,
        },
      ],
    },
  ];

  return (
    <div>
      {/* Help Centre Header */}
      <div className="grid grid-cols-2 gap-16 mt-10 sm:mx-auto sm:w-[550px] md:w-[600px] lg:w-[600px] xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] px-[30px]">
        {/* Column 1 */}
        <div>
          {/* Using Trades Poster */}
          <section>
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className="mb-5 border-b border-b-mediumgray pb-4"
            >
              Getting Started
            </SecondaryHeading>
            {gettingStarted.map((data) => (
              <HelpCenterLink data={data} />
            ))}
          </section>
        </div>
        <div>
          {/* For Customers */}
          <section>
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className="mb-5 border-b border-b-mediumgray pb-4"
            >
              Customers
            </SecondaryHeading>
            {customers.map((data) => (
              <HelpCenterLink data={data} />
            ))}
          </section>
        </div>

        {/* Column 2 */}
        <div>
          {/* For business*/}
          <section>
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className="mb-5 border-b border-b-mediumgray pb-4"
            >
              Business
            </SecondaryHeading>
            {business.map((data) => (
              <HelpCenterLink data={data} />
            ))}
          </section>
        </div>
        <div>
          {/* About Trades Poster */}
          <section>
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className="mb-5 border-b border-b-mediumgray pb-4"
            >
              Trades Poster
            </SecondaryHeading>
            {aboutTradesposter.map((data) => (
              <HelpCenterLink data={data} />
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
