import { IconContext } from "react-icons";
import { BsEyeFill as Eye, BsEyeSlashFill as SlashEye } from "react-icons/bs";

export default function PasswordField({ className, ...restProps }) {
  const _passwordtype =
    restProps?.passwordtype === "true" ? "password" : "text";
  return (
    <div className="relative w-[300px] h-[46px] sm:w-[350px] sm:h-[50px] md:w-[370px] md:h-[54px] lg:w-[370px] lg:h-[56px] ">
      <input
        {...restProps}
        name={restProps?.name}
        type={_passwordtype}
        className={`bg-white focus:border focus:border-brightblue placeholder-lightgray placeholder:font-ptsans placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[11px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-full block p-2.5 ${className}`}
        // className={`border-2 py-2 pl-4 pr-14 rounded-lg w-full outline-none ${className}`}
      />
      <IconContext.Provider
        value={{
          className: `size-4 absolute right-4 top-4 sm:top-4 lg:right-3 xl:right-3 xl:top-5 cursor-pointer`,
        }}
      >
        {restProps.passwordtype === "true" ? (
          <SlashEye
            onClick={() =>
              restProps?.setpasswordtype(
                restProps?.passwordtype === "true" ? "false" : "true"
              )
            }
          />
        ) : (
          <Eye
            onClick={() =>
              restProps?.setpasswordtype(
                restProps?.passwordtype === "false" ? "true" : "false"
              )
            }
          />
        )}
      </IconContext.Provider>
    </div>
  );
}
// className={`bg-pastalblue focus:border focus:border-brightblue placeholder-lightgray text-offblack font-ibmplex font-normal normal-case text-[12px] leading-[16px] tracking-[0.70px] sm:text-[12px] sm:leading-[16px] sm:tracking-[0.70px] md:text-[12px] md:leading-[16px] md:tracking-[0.80px] lg:text-[12px] lg:leading-[16px] lg:tracking-[0.90px] xl:text-[13px] xl:leading-[16px] xl:tracking-[1px] 2xl:text-[13px] 2xl:leading-[16px] 2xl:tracking-[1px] rounded-lg w-full h-[50px] sm:w-[350px] sm:h-[50px] md:w-[380px] md:h-[54px] lg:w-[380px] lg:h-[54px] xl:w-[420px] xl:h-[56px] 2xl:w-[500px] 2xl:h-[56px] block p-2.5 ${className}`}
