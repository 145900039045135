import React from "react";
import TermsDocs from "../sections/TermsConditions/TermsDocs";

const TermsConditions = () => {
  return (
    <>
      <TermsDocs />
    </>
  );
};

export default TermsConditions;
