import React from "react";
import RangeSliderLocation from "../../../../components/common/InputField/RangeSliderLocation";
import TextFiled from "../../../../components/common/InputField/TextFiled";
import Snippet from "../../../../components/common/SnippetIcon/Snippet";
const LocationandPortfolio = () => {
  return (
    <>
      <div className="grid grid-cols-12 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-[60px]">
        <div className="col-span-12">
          <form>
            <div>
              <Snippet
                variant={"label"}
                label={"Customers Within"}
                text={
                  "Determine a radius to target customers from your specified location."
                }
                snippet={true}
              />
            </div>
            <RangeSliderLocation starting={10} Maximum={50} units={"km"} />
            <div className="mt-5">
              <TextFiled
                className={"bg-pastalblue"}
                variant={"large"}
                label={"Post on this Location"}
                placeholder={
                  "Input the specific area, suburb, or postcode for your services"
                }
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LocationandPortfolio;
