import React from "react";
import Paragraph from "../../components/common/Typography/Paragraph";
import Heading from "../../components/common/Typography/Heading";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";

const AtoZFilters = () => {
  const TypeAll = [
    {
      id: 1,
      heading: "A",
      councilsList: [
        {
          subHeading: "Adelaide City Council",
          link: "cityofadelaide.com.au",
        },
        {
          subHeading: "Adelaide Hills Council",
          link: "ahc.sa.gov.au",
        },
        {
          subHeading: "Albany City Council",
          link: "albany.wa.gov.au",
        },
        {
          subHeading: "Albury City Council",
          link: "alburycity.nsw.gov.au",
        },
        {
          subHeading: "Alexandrina Council",
          link: "alexandrina.sa.gov.au",
        },
        {
          subHeading: "Alice Springs Town Council",
          link: "alicesprings.nt.gov.au",
        },
        {
          subHeading: "Armidale Regional Council",
          link: "armidaleregional.nsw.gov.au",
        },
        {
          subHeading: "Alpine Shire Council",
          link: "alpineshire.vic.gov.au",
        },
        {
          subHeading: "Armadale City Council",
          link: "armadale.wa.gov.au",
        },
        {
          subHeading: "Ashburton Shire Council",
          link: "ashburton.wa.gov.au",
        },
        {
          subHeading: "Augusta Margaret River Shire Council",
          link: "amrshire.wa.gov.au",
        },
      ],
    },
    {
      id: 2,
      heading: "B",
      councilsList: [
        {
          subHeading: "Ballarat City Council",
          link: "ballarat.vic.gov.au",
        },
        {
          subHeading: "Balonne Shire Council ",
          link: "balonne.qld.gov.au",
        },
        {
          subHeading: "Ballina Shire Council",
          link: "ballina.nsw.gov.au",
        },
        {
          subHeading: "Balranald Shire Council",
          link: "balranald.nsw.gov.au",
        },
        {
          subHeading: "Banyule City Council",
          link: "banyule.vic.gov.au",
        },
        {
          subHeading: "Barcaldine Regional Council",
          link: "barcaldinerc.qld.gov.au",
        },
        {
          subHeading: "Barossa Council ",
          link: "barossa.sa.gov.au",
        },
        {
          subHeading: "Town of Bassendean",
          link: "bassendean.wa.gov.au",
        },
        {
          subHeading: "Bathurst Regional Council",
          link: "bathurst.nsw.gov.au",
        },
        {
          subHeading: "Bayside City Council ",
          link: "bayside.vic.gov.au",
        },
        {
          subHeading: "Barcoo Shire Council",
          link: "barcoo.qld.gov.au",
        },
        {
          subHeading: "Baw Baw Shire Council",
          link: "bawbawshire.vic.gov.au",
        },
        {
          subHeading: "Bayside City Council",
          link: "bayside.vic.gov.au",
        },
        {
          subHeading: "Bayswater City Council",
          link: "bayswater.wa.gov.au",
        },
        {
          subHeading: "Bega Valley Shire Council ",
          link: "begavalley.nsw.gov.au",
        },
        {
          subHeading: "Bellingen Shire Council",
          link: "bellingen.nsw.gov.au",
        },
        {
          subHeading: "Belmont City Council",
          link: "belmont.wa.gov.au",
        },
        {
          subHeading: "Benalla Rural City Council",
          link: "benalla.vic.gov.au",
        },
        {
          subHeading: "Berri Barmera Council",
          link: "berribarmera.sa.gov.au",
        },
        {
          subHeading: "Shire of Beverley",
          link: "beverley.wa.gov.au",
        },
        {
          subHeading: "Blacktown City Council",
          link: "blacktown.nsw.gov.au",
        },
        {
          subHeading: "Bland Shire Council",
          link: "blandshire.nsw.gov.au",
        },
        {
          subHeading: "Blayney Shire Council",
          link: "blayney.nsw.gov.au",
        },
        {
          subHeading: "Blue Mountains City Council",
          link: "bmcc.nsw.gov.au",
        },
        {
          subHeading: "Bogan Shire Council",
          link: "bogan.nsw.gov.au",
        },
        {
          subHeading: "Boorowa Council",
          link: "boorowa.nsw.gov.au",
        },
        {
          subHeading: "Brisbane City Council",
          link: "brisbane.qld.gov.au",
        },
        {
          subHeading: "Broken Hill City Council",
          link: "brokenhill.nsw.gov.au",
        },
        {
          subHeading: "Burnside City Council",
          link: "burnside.sa.gov.au",
        },
        {
          subHeading: "Burwood Council",
          link: "burwood.nsw.gov.au",
        },
        {
          subHeading: "Shire of Boddington",
          link: "boddington.wa.gov.au",
        },
        {
          subHeading: "Shire of Boyup Brook",
          link: "boyupbrook.wa.gov.au",
        },
        {
          subHeading: "Shire of Bridgetown-Greenbushes",
          link: "bridgetown.wa.gov.au",
        },
        {
          subHeading: "Shire of Brookton ",
          link: "brookton.wa.gov.au ",
        },
        {
          subHeading: "Shire of Broome",
          link: "broome.wa.gov.au",
        },
        {
          subHeading: "Shire of Broomehill-Tambellup",
          link: "shirebt.wa.gov.au",
        },
        {
          subHeading: "Shire of Bruce Rock",
          link: "brucerock.wa.gov.au",
        },
        {
          subHeading: "City of Bunbury",
          link: "bunbury.wa.gov.au",
        },
        {
          subHeading: "City of Busselton",
          link: "busselton.wa.gov.au",
        },
        {
          subHeading: "Byron Shire Council",
          link: "byron.nsw.gov.au",
        },
      ],
    },
    {
      id: 3,
      heading: "C",
      councilsList: [
        {
          subHeading: "Cabonne Shire Council",
          link: "cabonne.nsw.gov.au",
        },
        {
          subHeading: "Cairns Regional Council",
          link: "cairns.qld.gov.au",
        },
        {
          subHeading: "Camden Council",
          link: "camden.nsw.gov.au",
        },
        {
          subHeading: "Campaspe Shire Council",
          link: "campaspe.vic.gov.au",
        },
        {
          subHeading: "Campbelltown City Council (NSW)",
          link: "campbelltown.nsw.gov.au",
        },
        {
          subHeading: "Campbelltown City Council (SA)",
          link: "campbelltown.sa.gov.au",
        },
        {
          subHeading: "Canada Bay City Council",
          link: "canadabay.nsw.gov.au",
        },
        {
          subHeading: "Canterbury-Bankstown Council",
          link: "cbcity.nsw.gov.au",
        },
        {
          subHeading: "Cardinia Shire Council",
          link: "cardinia.vic.gov.au",
        },
        {
          subHeading: "Cardwell Shire Council",
          link: "cassowarycoast.qld.gov.au",
        },
        {
          subHeading: "Central Coast Council (NSW)",
          link: "centralcoast.nsw.gov.au",
        },
        {
          subHeading: "Central Coast Council (TAS)",
          link: "centralcoast.tas.gov.au",
        },
        {
          subHeading: "Central Darling Shire Council",
          link: "centraldarling.nsw.gov.au",
        },
        {
          subHeading: "Central Desert Regional Council",
          link: "centraldesert.nt.gov.au",
        },
        {
          subHeading: "Central Goldfields Shire Council",
          link: "cgoldshire.vic.gov.au",
        },
        {
          subHeading: "Charters Towers Regional Council",
          link: "charterstowers.qld.gov.au",
        },
        {
          subHeading: "Shire of Chapman Valley",
          link: "chapmanvalley.wa.gov.au",
        },
        {
          subHeading: "Shire of Chittering ",
          link: "chittering.wa.gov.au",
        },
        {
          subHeading: "Shire of Christmas Island",
          link: "shire.gov.cx",
        },
        {
          subHeading: "Town of Claremont",
          link: "claremont.wa.gov.au",
        },
        {
          subHeading: "City of Cockburn",
          link: "cockburn.wa.gov.au",
        },
        {
          subHeading: "Town of Cambridge",
          link: "cambridge.wa.gov.au",
        },
        {
          subHeading: "City of Canning",
          link: "canning.wa.gov.au",
        },
        {
          subHeading: "Shire of Capel",
          link: "capel.wa.gov.au",
        },
        {
          subHeading: "Shire of Carnamah",
          link: "carnamah.wa.gov.au",
        },
        {
          subHeading: "Shire of Carnarvon",
          link: "carnarvon.wa.gov.au",
        },
        {
          subHeading: "Cherbourg Aboriginal Shire Council",
          link: "cherbourg.qld.gov.au",
        },
        {
          subHeading: "Cessnock City Council",
          link: "cessnock.nsw.gov.au",
        },
        {
          subHeading: "Charles Sturt City Council",
          link: "charlessturt.sa.gov.au",
        },
        {
          subHeading: "Circular Head Council",
          link: "circularhead.tas.gov.au",
        },
        {
          subHeading: "Clare and Gilbert Valleys Council ",
          link: "cgvc.sa.gov.au",
        },
        {
          subHeading: "Clarence City Council",
          link: "ccc.tas.gov.au",
        },
        {
          subHeading: "Clayton City Council",
          link: "clayton.vic.gov.au",
        },
        {
          subHeading: "Clermont City Council",
          link: "isaac.qld.gov.au",
        },
        {
          subHeading: "Cloncurry Shire Council",
          link: "cloncurry.qld.gov.au",
        },
        {
          subHeading: "Cockburn City Council",
          link: "cockburn.wa.gov.au",
        },
        {
          subHeading: "Coober Pedy Council",
          link: "cooberpedy.sa.gov.au",
        },
        {
          subHeading: "Coffs Harbour City Council",
          link: "coffsharbour.nsw.gov.au",
        },
        {
          subHeading: "Colac Otway Shire Council",
          link: "colacotway.vic.gov.au",
        },
        {
          subHeading: "Collie Shire Council",
          link: "collie.wa.gov.au",
        },
        {
          subHeading: "Cooma-Monaro Shire Council",
          link: "snowymonaro.nsw.gov.au",
        },
        {
          subHeading: "Coolamon Shire Council",
          link: "coolamon.nsw.gov.au",
        },
        {
          subHeading: "Coolgardie Shire Council",
          link: "coolgardie.wa.gov.au",
        },
        {
          subHeading: "Coonamble Shire Council",
          link: "coonambleshire.nsw.gov.au",
        },
        {
          subHeading: "Copper Coast Council",
          link: "coppercoast.sa.gov.au",
        },
        {
          subHeading: "Corangamite Shire Council",
          link: "corangamite.vic.gov.au",
        },
        {
          subHeading: "Cootamundra-Gundagai Regional Council",
          link: "cgrc.nsw.gov.au",
        },
        {
          subHeading: "Coorong District Council",
          link: "coorong.sa.gov.au",
        },
        {
          subHeading: "Corangamite Shire Council",
          link: "corangamite.vic.gov.au",
        },
        {
          subHeading: "Cottesloe Town Council",
          link: "cottesloe.wa.gov.au",
        },
        {
          subHeading: "Cowra Shire Council",
          link: "cowracouncil.com.au",
        },
        {
          subHeading: "Croydon Shire Council",
          link: "croydon.qld.gov.au",
        },
        {
          subHeading: "Cue Shire Council",
          link: "cue.wa.gov.au",
        },
        {
          subHeading: "Cumberland Council",
          link: "cumberland.nsw.gov.au",
        },
        {
          subHeading: "Shire of Cocos (Keeling) Islands",
          link: "shire.cc/en",
        },
        {
          subHeading: "Shire of Collie ",
          link: "collie.wa.gov.au ",
        },
        {
          subHeading: "Shire of Coolgardie",
          link: "coolgardie.wa.gov.au",
        },
        {
          subHeading: "Shire of Coorow",
          link: "coorow.wa.gov.au",
        },
        {
          subHeading: "Shire of Corrigin",
          link: "corrigin.wa.gov.au",
        },
        {
          subHeading: "Town of Cottesloe",
          link: "cottesloe.wa.gov.au",
        },
        {
          subHeading: "Shire of Cranbrook",
          link: "cranbrook.wa.gov.au",
        },
        {
          subHeading: "Shire of Cuballing",
          link: "cuballing.wa.gov.au",
        },
        {
          subHeading: "Shire of Cue",
          link: "cue.wa.gov.au",
        },
        {
          subHeading: "Shire of Cunderdin",
          link: "cunderdin.wa.gov.au",
        },
      ],
    },
    {
      id: 4,
      heading: "D",
      councilsList: [
        {
          subHeading: "Darebin City Council",
          link: "darebin.vic.gov.au",
        },
        {
          subHeading: "Darwin City Council",
          link: "darwin.nt.gov.au",
        },
        {
          subHeading: "Deniliquin Council",
          link: "edwardriver.nsw.gov.au",
        },
        {
          subHeading: "Derwent Valley Council",
          link: "dvc.tas.gov.au",
        },
        {
          subHeading: "Devonport City Council",
          link: "devonport.tas.gov.au",
        },
        {
          subHeading: "Diamond Valley Shire Council",
          link: "nillumbik.vic.gov.au",
        },
        {
          subHeading: "Dungog Shire Council",
          link: "dungog.nsw.gov.au",
        },
        {
          subHeading: "Dubbo Regional Council",
          link: "dubbo.nsw.gov.au",
        },
        {
          subHeading: "Dunedin City Council",
          link: "dcc.govt.nz",
        },
        {
          subHeading: "Dundas Shire Council",
          link: "dundas.wa.gov.au",
        },
        {
          subHeading: "Shire of Dalwallinu",
          link: "dalwallinu.wa.gov.au",
        },
        {
          subHeading: "Shire of Dandaragan",
          link: "dandaragan.wa.gov.au",
        },
        {
          subHeading: "Shire of Dardanup",
          link: "dardanup.wa.gov.au",
        },
        {
          subHeading: "Shire of Denmark",
          link: "denmark.wa.gov.au",
        },
        {
          subHeading: "Shire of Derby-West Kimberley",
          link: "sdwk.wa.gov.au",
        },
        {
          subHeading: "Shire of Donnybrook-Balingup",
          link: "donnybrook-balingup.wa.gov.au",
        },
        {
          subHeading: "Shire of Dowerin",
          link: "dowerin.wa.gov.au",
        },
        {
          subHeading: "Shire of Dumbleyung",
          link: "dumbleyung.wa.gov.au",
        },
        {
          subHeading: "Shire of Dundas",
          link: "dundas.wa.gov.au",
        },
      ],
    },
    {
      id: 5,
      heading: "E",
      councilsList: [
        {
          subHeading: "East Arnhem Regional Council",
          link: "eastarnhem.nt.gov.au",
        },
        {
          subHeading: "East Gippsland Shire Council",
          link: "eastgippsland.vic.gov.au",
        },
        {
          subHeading: "East Pilbara Shire Council",
          link: "eastpilbara.wa.gov.au",
        },
        {
          subHeading: "Eastern Metropolitan Regional Council",
          link: "emrc.org.au",
        },
        {
          subHeading: "Eastern Suburbs Regional Council",
          link: "esrc.gov.au",
        },
        {
          subHeading: "Echuca Shire Council",
          link: "campaspe.vic.gov.au",
        },
        {
          subHeading: "Edward River Council",
          link: "edwardriver.nsw.gov.au",
        },
        {
          subHeading: "Elermore Vale Council",
          link: "newcastle.nsw.gov.au",
        },
        {
          subHeading: "Elliston District Council",
          link: "elliston.sa.gov.au",
        },
        {
          subHeading: "Emerald Shire Council",
          link: "centralhighlands.qld.gov.au",
        },
        {
          subHeading: "Emu Bay Council",
          link: "kangarooisland.sa.gov.au",
        },
        {
          subHeading: "Esperance Shire Council",
          link: "esperance.wa.gov.au",
        },
        {
          subHeading: "Etheridge Shire Council",
          link: "etheridge.qld.gov.au",
        },
        {
          subHeading: "Eurobodalla Shire Council",
          link: "esc.nsw.gov.au",
        },
        {
          subHeading: "Euroa Shire Council",
          link: "strathbogie.vic.gov.au",
        },
        {
          subHeading: "Euston Shire Council",
          link: "balranald.nsw.gov.au",
        },
        {
          subHeading: "Exmouth Shire Council",
          link: "exmouth.wa.gov.au",
        },
        {
          subHeading: "Town of East Fremantle",
          link: "eastfremantle.wa.gov.au",
        },
        {
          subHeading: "Shire of Goomalling",
          link: "goomalling.wa.gov.au",
        },
        {
          subHeading: "Shire of East Pilbara",
          link: "eastpilbara.wa.gov.au",
        },
        {
          subHeading: "Shire of Esperance",
          link: "esperance.wa.gov.au",
        },
        {
          subHeading: "Shire of Exmouth",
          link: "exmouth.wa.gov.au",
        },
      ],
    },
    {
      id: 6,
      heading: "F",
      councilsList: [
        {
          subHeading: "Fairfield City Council",
          link: "fairfieldcity.nsw.gov.au",
        },
        {
          subHeading: "Fairhaven Council",
          link: "surfcoast.vic.gov.au",
        },
        {
          subHeading: "Federation Council",
          link: "federationcouncil.nsw.gov.au",
        },
        {
          subHeading: "Flinders Council",
          link: "flinders.tas.gov.au",
        },
        {
          subHeading: "Flinders Ranges Council",
          link: "frc.sa.gov.au",
        },
        {
          subHeading: "Forbes Shire Council",
          link: "forbes.nsw.gov.au",
        },
        {
          subHeading: "Frankston City Council",
          link: "frankston.vic.gov.au",
        },
        {
          subHeading: "Fremantle City Council",
          link: "fremantle.wa.gov.au",
        },
        {
          subHeading: "French Island and Sandstone Island Shire Council",
          link: "basscoast.vic.gov.au",
        },
        {
          subHeading: "City of Fremantle",
          link: "fremantle.wa.gov.au",
        },
      ],
    },
    {
      id: 7,
      heading: "G",
      councilsList: [
        {
          subHeading: "Gannawarra Shire Council",
          link: "gannawarra.vic.gov.au",
        },
        {
          subHeading: "Gawler Town Council",
          link: "gawler.sa.gov.au",
        },
        {
          subHeading: "Geelong City Council",
          link: "geelongaustralia.com.au",
        },
        {
          subHeading: "Geraldton City Council",
          link: "cgg.wa.gov.au",
        },
        {
          subHeading: "Gilgandra Shire Council",
          link: "gilgandra.nsw.gov.au",
        },
        {
          subHeading: "Gladstone Regional Council",
          link: "gladstone.qld.gov.au",
        },
        {
          subHeading: "Glen Eira City Council",
          link: "gleneira.vic.gov.au",
        },
        {
          subHeading: "Glen Innes Severn Council",
          link: "gisc.nsw.gov.au",
        },
        {
          subHeading: "Glenelg Shire Council",
          link: "glenelg.vic.gov.au",
        },
        {
          subHeading: "Gloucester Shire Council",
          link: "midcoast.nsw.gov.au",
        },
        {
          subHeading: "Gold Coast City Council",
          link: "goldcoast.qld.gov.au",
        },
        {
          subHeading: "Golden Plains Shire Council",
          link: "goldenplains.vic.gov.au",
        },
        {
          subHeading: "Goomalling Shire Council",
          link: "goomalling.wa.gov.au",
        },
        {
          subHeading: "Gosford City Council",
          link: "centralcoast.nsw.gov.au",
        },
        {
          subHeading: "Goulburn Mulwaree Council",
          link: "goulburn.nsw.gov.au",
        },
        {
          subHeading: "Goyder Regional Council",
          link: "goyder.sa.gov.au",
        },
        {
          subHeading: "Grampians Shire Council",
          link: "ngshire.vic.gov.au",
        },
        {
          subHeading: "Greater Dandenong City Council",
          link: "greaterdandenong.vic.gov.au",
        },
        {
          subHeading: "Greater Geraldton City Council",
          link: "cgg.wa.gov.au",
        },
        {
          subHeading: "Greater Hume Shire Council",
          link: "greaterhume.nsw.gov.au",
        },
        {
          subHeading: "Greater Shepparton City Council",
          link: "greatershepparton.com.au",
        },
        {
          subHeading: "Griffith City Council",
          link: "griffith.nsw.gov.au",
        },
        {
          subHeading: "Gunnedah Shire Council",
          link: "gunnedah.nsw.gov.au",
        },
        {
          subHeading: "Gwydir Shire Council",
          link: "gwydir.nsw.gov.au",
        },
        {
          subHeading: "City of Gosnells",
          link: "gosnells.wa.gov.au",
        },
        {
          subHeading: "Shire of Gnowangerup",
          link: "gnowangerup.wa.gov.au",
        },
        {
          subHeading: "Shire of Gingin",
          link: "gingin.wa.gov.au",
        },
        {
          subHeading: "City of Greater Geraldton",
          link: "cgg.wa.gov.au",
        },
      ],
    },
    {
      id: 8,
      heading: "H",
      councilsList: [
        {
          subHeading: "Hawkesbury City Council",
          link: "hawkesbury.nsw.gov.au",
        },
        {
          subHeading: "Hay Shire Council",
          link: "hay.nsw.gov.au",
        },
        {
          subHeading: "Hepburn Shire Council",
          link: "hepburn.vic.gov.au",
        },
        {
          subHeading: "Hilltops Council",
          link: "hilltops.nsw.gov.au",
        },
        {
          subHeading: "Hinchinbrook Shire Council",
          link: "hinchinbrook.qld.gov.au",
        },
        {
          subHeading: "Hobart City Council",
          link: "hobartcity.com.au",
        },
        {
          subHeading: "Holdfast Bay City Council",
          link: "holdfast.sa.gov.au",
        },
        {
          subHeading: "Holroyd City Council",
          link: "cumberland.nsw.gov.au",
        },
        {
          subHeading: "Horsham Rural City Council",
          link: "hrcc.vic.gov.au",
        },
        {
          subHeading: "Hornsby Shire Council",
          link: "hornsby.nsw.gov.au",
        },
        {
          subHeading: "Hume City Council",
          link: "hume.vic.gov.au",
        },
        {
          subHeading: "Hunter's Hill Council",
          link: "huntershill.nsw.gov.au",
        },
        {
          subHeading: "Hurstville City Council",
          link: "georgesriver.nsw.gov.au",
        },
        {
          subHeading: "Shire of Harvey",
          link: "harvey.wa.gov.au",
        },
        {
          subHeading: "Shire of Halls Creek",
          link: "hallscreek.wa.gov.au",
        },
      ],
    },
    {
      id: 9,
      heading: "I",
      councilsList: [
        {
          subHeading: "Ipswich City Council",
          link: "ipswich.qld.gov.au",
        },
        {
          subHeading: "Indigo Shire Council",
          link: "indigoshire.vic.gov.au",
        },
        {
          subHeading: "Inner West Council",
          link: "innerwest.nsw.gov.au",
        },
        {
          subHeading: "Inverell Shire Council",
          link: "inverell.nsw.gov.au",
        },
        {
          subHeading: "Irwin Shire Council",
          link: "irwin.wa.gov.au",
        },
        {
          subHeading: "Isaac Regional Council",
          link: "isaac.qld.gov.au",
        },
      ],
    },
    {
      id: 10,
      heading: "J",
      councilsList: [
        {
          subHeading: "Jabiru Town Council",
          link: "westarnhem.nt.gov.au",
        },
        {
          subHeading: "Jerilderie Shire Council",
          link: "murrayriver.nsw.gov.au",
        },
        {
          subHeading: "Junee Shire Council",
          link: "junee.nsw.gov.au",
        },
        {
          subHeading: "Shire of Jerramungup",
          link: "jerramungup.wa.gov.au",
        },
        {
          subHeading: "City of Joondalup",
          link: "joondalup.wa.gov.au",
        },
      ],
    },
    {
      id: 11,
      heading: "K",
      councilsList: [
        {
          subHeading: "Kalgoorlie-Boulder City Council",
          link: "ckb.wa.gov.au",
        },
        {
          subHeading: "Kangaroo Island Council",
          link: "kicouncil.sa.gov.au",
        },
        {
          subHeading: "Katanning Shire Council",
          link: "katanning.wa.gov.au",
        },
        {
          subHeading: "Kempsey Shire Council",
          link: "kempsey.nsw.gov.au",
        },
        {
          subHeading: "Kent Shire Council",
          link: "kent.wa.gov.au",
        },
        {
          subHeading: "King Island Council",
          link: "kingisland.tas.gov.au",
        },
        {
          subHeading: "Kingborough Council",
          link: "kingborough.tas.gov.au",
        },
        {
          subHeading: "Kingston City Council (VIC)",
          link: "kingston.vic.gov.au",
        },
        {
          subHeading: "Kingston District Council (SA)",
          link: "kingstondc.sa.gov.au",
        },
        {
          subHeading: "Kiama Municipal Council",
          link: "kiama.nsw.gov.au",
        },
        {
          subHeading: "Kwinana City Council",
          link: "kwinana.wa.gov.au",
        },
        {
          subHeading: "Kogarah City Council",
          link: "georgesriver.nsw.gov.au",
        },
        {
          subHeading: "Ku-ring-gai Council",
          link: "kmc.nsw.gov.au",
        },
        {
          subHeading: "Kyogle Council",
          link: "kyogle.nsw.gov.au",
        },
        {
          subHeading: "City of Karratha",
          link: "karratha.wa.gov.au",
        },
        {
          subHeading: "Shire of Kellerberrin",
          link: "kellerberrin.wa.gov.au",
        },
        {
          subHeading: "Shire of Kent",
          link: "kent.wa.gov.au",
        },
        {
          subHeading: "City of Kalgoorlie-Boulder",
          link: "ckb.wa.gov.au",
        },
        {
          subHeading: "Shire of Kojonup",
          link: "kojonup.wa.gov.au",
        },
        {
          subHeading: "City of Kalamunda",
          link: "kalamunda.wa.gov.au",
        },
        {
          subHeading: "Shire of Kondinin",
          link: "kondinin.wa.gov.au",
        },
        {
          subHeading: "Shire of Koorda",
          link: "koorda.wa.gov.au",
        },
        {
          subHeading: "Shire of Kulin",
          link: "kulin.wa.gov.au",
        },
      ],
    },
    {
      id: 12,
      heading: "L",
      councilsList: [
        {
          subHeading: "Lachlan Shire Council",
          link: "lachlan.nsw.gov.au",
        },
        {
          subHeading: "Laidley Shire Council",
          link: "lockyervalley.qld.gov.au",
        },
        {
          subHeading: "Lake Macquarie City Council",
          link: "lakemac.com.au",
        },
        {
          subHeading: "Latrobe City Council",
          link: "latrobe.vic.gov.au",
        },
        {
          subHeading: "Latrobe Council (TAS)",
          link: "latrobe.tas.gov.au",
        },
        {
          subHeading: "Laverton Shire Council",
          link: "laverton.wa.gov.au",
        },
        {
          subHeading: "Leeton Shire Council",
          link: "leeton.nsw.gov.au",
        },
        {
          subHeading: "Lismore City Council",
          link: "lismore.nsw.gov.au",
        },
        {
          subHeading: "Lithgow City Council",
          link: "council.lithgow.com",
        },
        {
          subHeading: "Liverpool City Council",
          link: "liverpool.nsw.gov.au",
        },
        {
          subHeading: "Lockhart Shire Council",
          link: "lockhart.nsw.gov.au",
        },
        {
          subHeading: "Lockyer Valley Regional Council",
          link: "lockyervalley.qld.gov.au",
        },
        {
          subHeading: "Logan City Council",
          link: "logan.qld.gov.au",
        },
        {
          subHeading: "Lord Howe Island Board",
          link: "lhib.nsw.gov.au",
        },
        {
          subHeading: "Lower Eyre Peninsula District Council",
          link: "lower.council.sa.gov.au",
        },
        {
          subHeading: "Loddon Shire Council",
          link: "loddon.vic.gov.au",
        },
        {
          subHeading: "Shire of Lake Grace",
          link: "www.lakegrace.wa.gov.au",
        },
        {
          subHeading: "Shire of Leonora",
          link: "www.leonora.wa.gov.au",
        },
      ],
    },
    {
      id: 13,
      heading: "M",
      councilsList: [
        {
          subHeading: "MacDonnell Regional Council",
          link: "macdonnell.nt.gov.au",
        },
        {
          subHeading: "Mackay Regional Council",
          link: "mackay.qld.gov.au",
        },
        {
          subHeading: "Maitland City Council",
          link: "maitland.nsw.gov.au",
        },
        {
          subHeading: "Malvern Town Council",
          link: "stonnington.vic.gov.au",
        },
        {
          subHeading: "Mandurah City Council",
          link: "mandurah.wa.gov.au",
        },
        {
          subHeading: "Manly Council",
          link: "northernbeaches.nsw.gov.au",
        },
        {
          subHeading: "Manningham City Council",
          link: "manningham.vic.gov.au",
        },
        {
          subHeading: "Manly Council",
          link: "northernbeaches.nsw.gov.au",
        },
        {
          subHeading: "Mansfield Shire Council",
          link: "mansfield.vic.gov.au",
        },
        {
          subHeading: "Maribyrnong City Council",
          link: "maribyrnong.vic.gov.au",
        },
        {
          subHeading: "Marion City Council",
          link: "marion.sa.gov.au",
        },
        {
          subHeading: "Maroondah City Council",
          link: "maroondah.vic.gov.au",
        },
        {
          subHeading: "Marrickville Council",
          link: "innerwest.nsw.gov.au",
        },
        {
          subHeading: "Melton City Council",
          link: "melton.vic.gov.au",
        },
        {
          subHeading: "Melbourne City Council",
          link: "melbourne.vic.gov.au",
        },
        {
          subHeading: "Melville City Council",
          link: "melvillecity.com.au",
        },
        {
          subHeading: "Mid-Coast Council",
          link: "midcoast.nsw.gov.au",
        },
        {
          subHeading: "Mid Murray Council",
          link: "mid-murray.sa.gov.au",
        },
        {
          subHeading: "Mildura Rural City Council",
          link: "mildura.vic.gov.au",
        },
        {
          subHeading: "Mitchell Shire Council",
          link: "mitchellshire.vic.gov.au",
        },
        {
          subHeading: "Moira Shire Council",
          link: "moira.vic.gov.au",
        },
        {
          subHeading: "Monash City Council",
          link: "monash.vic.gov.au",
        },
        {
          subHeading: "Moonee Valley City Council",
          link: "mvcc.vic.gov.au",
        },
        {
          subHeading: "Moreland City Council",
          link: "moreland.vic.gov.au",
        },
        {
          subHeading: "Mornington Peninsula Shire Council",
          link: "mornpen.vic.gov.au",
        },
        {
          subHeading: "Mosman Council",
          link: "mosman.nsw.gov.au",
        },
        {
          subHeading: "Mount Alexander Shire Council",
          link: "mountalexander.vic.gov.au",
        },
        {
          subHeading: "Mount Barker District Council",
          link: "mountbarker.sa.gov.au",
        },
        {
          subHeading: "Mount Gambier City Council",
          link: "mountgambier.sa.gov.au",
        },
        {
          subHeading: "Mount Isa City Council",
          link: "mountisa.qld.gov.au",
        },
        {
          subHeading: "Moyne Shire Council",
          link: "moyne.vic.gov.au",
        },
        {
          subHeading: "Mudgee Council",
          link: "midwestern.nsw.gov.au",
        },
        {
          subHeading: "Murrindindi Shire Council",
          link: "murrindindi.vic.gov.au",
        },
        {
          subHeading: "Murray River Council",
          link: "murrayriver.nsw.gov.au",
        },
        {
          subHeading: "Murrumbidgee Council",
          link: "murrumbidgee.nsw.gov.au",
        },
        {
          subHeading: "Shire of Manjimup",
          link: "manjimup.wa.gov.au",
        },
        {
          subHeading: "Shire of Meekatharra",
          link: "meekashire.wa.gov.au",
        },
        {
          subHeading: "City of Melville",
          link: "melvillecity.wa.gov.au",
        },
        {
          subHeading: "Shire of Menzies",
          link: "menzies.wa.gov.au",
        },
        {
          subHeading: "Shire of Merredin",
          link: "merredin.wa.gov.au",
        },
        {
          subHeading: "Shire of Mingenew",
          link: "mingenew.wa.gov.au",
        },
        {
          subHeading: "Shire of Moora",
          link: "moora.wa.gov.au",
        },
        {
          subHeading: "Shire of Morawa",
          link: "morawa.wa.gov.au",
        },
        {
          subHeading: "Town of Mosman Park",
          link: "mosmanpark.wa.gov.au",
        },
        {
          subHeading: "Shire of Mount Magnet",
          link: "mtmagnet.wa.gov.au",
        },
        {
          subHeading: "Shire of Mount Marshall",
          link: "mtmarshall.wa.gov.au",
        },
        {
          subHeading: "Shire of Mukinbudin",
          link: "mukinbudin.wa.gov.au",
        },
        {
          subHeading: "Shire of Mundaring",
          link: "mundaring.wa.gov.au",
        },
        {
          subHeading: "Shire of Murchison",
          link: "murchison.wa.gov.au",
        },
        {
          subHeading: "Shire of Murray",
          link: "murray.wa.gov.au",
        },
      ],
    },
    {
      id: 14,
      heading: "N",
      councilsList: [
        {
          subHeading: "Nambucca Shire Council",
          link: "nambucca.nsw.gov.au",
        },
        {
          subHeading: "Narrabri Shire Council",
          link: "narrabri.nsw.gov.au",
        },
        {
          subHeading: "Narrogin Town Council",
          link: "narrogin.wa.gov.au",
        },
        {
          subHeading: "Narromine Shire Council",
          link: "narromine.nsw.gov.au",
        },
        {
          subHeading: "Newcastle City Council",
          link: "newcastle.nsw.gov.au",
        },
        {
          subHeading: "Northern Beaches Council",
          link: "northernbeaches.nsw.gov.au",
        },
        {
          subHeading: "North Sydney Council",
          link: "northsydney.nsw.gov.au",
        },
        {
          subHeading: "North Burnett Regional Council",
          link: "northburnett.qld.gov.au",
        },
        {
          subHeading: "Norwood Payneham & St Peters Council",
          link: "npsp.sa.gov.au",
        },
        {
          subHeading: "Nowra Council",
          link: "shoalhaven.nsw.gov.au",
        },
        {
          subHeading: "Nunawading Council",
          link: "whitehorse.vic.gov.au",
        },
        {
          subHeading: "Shire of Nannup",
          link: "nannup.wa.gov.au",
        },
        {
          subHeading: "Shire of Narembeen",
          link: "narembeen.wa.gov.au",
        },
        {
          subHeading: "City of Nedlands",
          link: "nedlands.wa.gov.au",
        },
        {
          subHeading: "Shire of Ngaanyatjarraku",
          link: "ngaanyatjarraku.wa.gov.au",
        },
        {
          subHeading: "Shire of Northam",
          link: "northam.wa.gov.au",
        },
        {
          subHeading: "Shire of Northampton",
          link: "northampton.wa.gov.au",
        },
        {
          subHeading: "Shire of Nungarin",
          link: "nungarin.wa.gov.au",
        },
      ],
    },
    {
      id: 15,
      heading: "O",
      councilsList: [
        {
          subHeading: "Oberon Council",
          link: "oberon.nsw.gov.au",
        },
        {
          subHeading: "Onkaparinga City Council",
          link: "onkaparingacity.com",
        },
        {
          subHeading: "Orange City Council",
          link: "orange.nsw.gov.au",
        },
        {
          subHeading: "Orbost Shire Council",
          link: "egipps.vic.gov.au",
        },
        {
          subHeading: "Orroroo Carrieton District Council",
          link: "orroroo.sa.gov.au",
        },
        {
          subHeading: "Otways Shire Council",
          link: "colacotway.vic.gov.au",
        },
        {
          subHeading: "Oxford Falls Council",
          link: "northernbeaches.nsw.gov.au",
        },
      ],
    },
    {
      id: 16,
      heading: "P",
      councilsList: [
        {
          subHeading: "Parramatta City Council",
          link: "cityofparramatta.nsw.gov.au",
        },
        {
          subHeading: "Penrith City Council",
          link: "penrithcity.nsw.gov.au",
        },
        {
          subHeading: "Perth City Council",
          link: "perth.wa.gov.au",
        },
        {
          subHeading: "Port Adelaide Enfield Council",
          link: "cityofpae.sa.gov.au",
        },
        {
          subHeading: "Port Augusta City Council",
          link: "portaugusta.sa.gov.au",
        },
        {
          subHeading: "Port Hedland Town Council",
          link: "porthedland.wa.gov.au",
        },
        {
          subHeading: "Port Macquarie-Hastings Council",
          link: "pmhc.nsw.gov.au",
        },
        {
          subHeading: "Port Phillip City Council",
          link: "portphillip.vic.gov.au",
        },
        {
          subHeading: "Port Stephens Council",
          link: "portstephens.nsw.gov.au",
        },
        {
          subHeading: "Prospect City Council",
          link: "prospect.sa.gov.au",
        },
        {
          subHeading: "Pyrenees Shire Council",
          link: "pyrenees.vic.gov.au",
        },
        {
          subHeading: "Shire of Peppermint Grove",
          link: "peppermintgrove.wa.gov.au",
        },
        {
          subHeading: "Shire of Perenjori",
          link: "perenjori.wa.gov.au",
        },
        {
          subHeading: "City of Perth",
          link: "perth.wa.gov.au",
        },
        {
          subHeading: "Shire of Pingelly",
          link: "pingelly.wa.gov.au",
        },
        {
          subHeading: "Shire of Plantagenet",
          link: "plantagenet.wa.gov.au",
        },
      ],
    },
    {
      id: 17,
      heading: "Q",
      councilsList: [
        {
          subHeading: "Queanbeyan-Palerang Regional Council",
          link: "qprc.nsw.gov.au",
        },
        {
          subHeading: "Quilpie Shire Council",
          link: "quilpie.qld.gov.au",
        },
        {
          subHeading: "Quirindi Council",
          link: "lpsc.nsw.gov.au",
        },
        {
          subHeading: "Quairading Shire Council",
          link: "quairading.wa.gov.au",
        },
        {
          subHeading: "Shire of Quairading",
          link: "quairading.wa.gov.au",
        },
      ],
    },
    {
      id: 18,
      heading: "R",
      councilsList: [
        {
          subHeading: "Randwick City Council",
          link: "randwick.nsw.gov.au",
        },
        {
          subHeading: "Richmond Valley Council",
          link: "richmondvalley.nsw.gov.au",
        },
        {
          subHeading: "Rockhampton Regional Council",
          link: "rockhamptonregion.qld.gov.au",
        },
        {
          subHeading: "Rockdale City Council",
          link: "bayside.nsw.gov.au",
        },
        {
          subHeading: "Rockingham City Council",
          link: "rockingham.wa.gov.au",
        },
        {
          subHeading: "Ryde City Council",
          link: "ryde.nsw.gov.au",
        },
        {
          subHeading: "Rural City of Murray Bridge",
          link: "murraybridge.sa.gov.au",
        },
        {
          subHeading: "Shire of Ravensthorpe",
          link: "ravensthorpe.wa.gov.au",
        },
        {
          subHeading: "City of Rockingham",
          link: "rockingham.wa.gov.au",
        },
      ],
    },
    {
      id: 19,
      heading: "S",
      councilsList: [
        {
          subHeading: "Salisbury City Council",
          link: "salisbury.sa.gov.au",
        },
        {
          subHeading: "Scenic Rim Regional Council",
          link: "scenicrim.qld.gov.au",
        },
        {
          subHeading: "Scone Shire Council",
          link: "upperhunter.nsw.gov.au",
        },
        {
          subHeading: "Shellharbour City Council",
          link: "shellharbour.nsw.gov.au",
        },
        {
          subHeading: "Shoalhaven City Council",
          link: "shoalhaven.nsw.gov.au",
        },
        {
          subHeading: "Singleton Shire Council",
          link: "singleton.nsw.gov.au",
        },
        {
          subHeading: "Snowy Monaro Regional Council",
          link: "snowymonaro.nsw.gov.au",
        },
        {
          subHeading: "Snowy Valleys Council",
          link: "snowyvalleys.nsw.gov.au",
        },
        {
          subHeading: "Somerset Regional Council",
          link: "somerset.qld.gov.au",
        },
        {
          subHeading: "South Burnett Regional Council",
          link: "southburnett.qld.gov.au",
        },
        {
          subHeading: "South Gippsland Shire Council",
          link: "southgippsland.vic.gov.au",
        },
        {
          subHeading: "Southern Downs Regional Council",
          link: "sdrc.qld.gov.au",
        },
        {
          subHeading: "Southern Grampians Shire Council",
          link: "sthgrampians.vic.gov.au",
        },
        {
          subHeading: "Stonnington City Council",
          link: "stonnington.vic.gov.au",
        },
        {
          subHeading: "Strathbogie Shire Council",
          link: "strathbogie.vic.gov.au",
        },
        {
          subHeading: "Sunshine Coast Council",
          link: "sunshinecoast.qld.gov.au",
        },
        {
          subHeading: "Sutherland Shire Council",
          link: "sutherlandshire.nsw.gov.au",
        },
        {
          subHeading: "Swan City Council",
          link: "swan.wa.gov.au",
        },
        {
          subHeading: "Swan Hill Rural City Council",
          link: "swanhill.vic.gov.au",
        },
        {
          subHeading: "Sydney City Council",
          link: "cityofsydney.nsw.gov.au",
        },
        {
          subHeading: "Shire of Sandstone",
          link: "sandstone.wa.gov.au",
        },
        {
          subHeading: "Shire of Serpentine Jarrahdale",
          link: "sjshire.wa.gov.au",
        },
        {
          subHeading: "Shire of Shark Bay",
          link: "sharkbay.wa.gov.au",
        },
        {
          subHeading: "City of South Perth",
          link: "southperth.wa.gov.au",
        },
        {
          subHeading: "City of Stirling",
          link: "stirling.wa.gov.au",
        },
        {
          subHeading: "City of Subiaco",
          link: "subiaco.wa.gov.au",
        },
        {
          subHeading: "City of Swan",
          link: "swan.wa.gov.au",
        },
      ],
    },
    {
      id: 20,
      heading: "T",
      councilsList: [
        {
          subHeading: "Tamworth Regional Council",
          link: "tamworth.nsw.gov.au",
        },
        {
          subHeading: "Taree Council",
          link: "midcoast.nsw.gov.au",
        },
        {
          subHeading: "Tasman Council",
          link: "tasman.tas.gov.au",
        },
        {
          subHeading: "Tatiara District Council",
          link: "tatiara.sa.gov.au",
        },
        {
          subHeading: "Tea Tree Gully City Council",
          link: "cttg.sa.gov.au",
        },
        {
          subHeading: "Temora Shire Council",
          link: "temora.nsw.gov.au",
        },
        {
          subHeading: "Tenterfield Shire Council",
          link: "tenterfield.nsw.gov.au",
        },
        {
          subHeading: "The Hills Shire Council",
          link: "thehills.nsw.gov.au",
        },
        {
          subHeading: "Toowoomba Regional Council",
          link: "tr.qld.gov.au",
        },
        {
          subHeading: "Town of Cambridge",
          link: "cambridge.wa.gov.au",
        },
        {
          subHeading: "Town of Claremont",
          link: "claremont.wa.gov.au",
        },
        {
          subHeading: "Town of Cottesloe",
          link: "cottesloe.wa.gov.au",
        },
        {
          subHeading: "Town of Gawler",
          link: "gawler.sa.gov.au",
        },
        {
          subHeading: "Town of Mosman Park",
          link: "mosmanpark.wa.gov.au",
        },
        {
          subHeading: "Townsville City Council",
          link: "townsville.qld.gov.au",
        },
        {
          subHeading: "Towong Shire Council",
          link: "towong.vic.gov.au",
        },
        {
          subHeading: "Tumbarumba Shire Council",
          link: "snowyvalleys.nsw.gov.au",
        },
        {
          subHeading: "Tumut Shire Council",
          link: "snowyvalleys.nsw.gov.au",
        },
        {
          subHeading: "Tweed Shire Council",
          link: "tweed.nsw.gov.au",
        },
        {
          subHeading: "Two Wells Council",
          link: "apc.sa.gov.au",
        },
        {
          subHeading: "Shire of Tammin",
          link: "tammin.wa.gov.au",
        },
        {
          subHeading: "Shire of Three Springs",
          link: "threesprings.wa.gov.au",
        },
        {
          subHeading: "Shire of Toodyay",
          link: "toodyay.wa.gov.au",
        },
        {
          subHeading: "Shire of Trayning",
          link: "trayning.wa.gov.au",
        },
      ],
    },
    {
      id: 21,
      heading: "U",
      councilsList: [
        {
          subHeading: "Upper Hunter Shire Council",
          link: "upperhunter.nsw.gov.au",
        },
        {
          subHeading: "Upper Lachlan Shire Council",
          link: "upperlachlan.nsw.gov.au",
        },
        {
          subHeading: "Uralla Shire Council",
          link: "uralla.nsw.gov.au",
        },
        {
          subHeading: "Urana Shire Council",
          link: "federationcouncil.nsw.gov.au",
        },
        {
          subHeading: "Urunga Shire Council",
          link: "urunga.nsw.gov.au",
        },
        {
          subHeading: "Uralba Shire Council",
          link: "uralba.nsw.gov.au",
        },
        {
          subHeading: "Shire of Upper Gascoyne",
          link: "uppergascoyne.wa.gov.au",
        },
      ],
    },
    {
      id: 22,
      heading: "V",
      councilsList: [
        {
          subHeading: "Victoria Daly Regional Council",
          link: "vicdaly.nt.gov.au",
        },
        {
          subHeading: "Victoria Plains Shire Council",
          link: "victoriaplains.wa.gov.au",
        },
        {
          subHeading: "Vincent Town Council",
          link: "vincent.wa.gov.au",
        },
        {
          subHeading: "Vines Shire Council",
          link: "vines.wa.gov.au",
        },
      ],
    },
    {
      id: 23,
      heading: "W",
      councilsList: [
        {
          subHeading: "Wagga Wagga City Council",
          link: "wagga.nsw.gov.au",
        },
        {
          subHeading: "Walcha Council",
          link: "walcha.nsw.gov.au",
        },
        {
          subHeading: "Walkerston Shire Council",
          link: "mackay.qld.gov.au",
        },
        {
          subHeading: "Warringah Council",
          link: "northernbeaches.nsw.gov.au",
        },
        {
          subHeading: "Warwick Shire Council",
          link: "sdrc.qld.gov.au",
        },
        {
          subHeading: "Waverley Council",
          link: "waverley.nsw.gov.au",
        },
        {
          subHeading: "Weipa Town Council",
          link: "weipatownauthority.com.au",
        },
        {
          subHeading: "Wellington Shire Council",
          link: "wellington.vic.gov.au",
        },
        {
          subHeading: "West Arnhem Regional Council",
          link: "westarnhem.nt.gov.au",
        },
        {
          subHeading: "West Daly Regional Council",
          link: "westdaly.nt.gov.au",
        },
        {
          subHeading: "West Tamar Council",
          link: "wtc.tas.gov.au",
        },
        {
          subHeading: "Western Downs Regional Council",
          link: "wdrc.qld.gov.au",
        },
        {
          subHeading: "Western Plains Regional Council",
          link: "westernplains.nsw.gov.au",
        },
        {
          subHeading: "Westonia Shire Council",
          link: "westonia.wa.gov.au",
        },
        {
          subHeading: "Whitsunday Regional Council",
          link: "whitsunday.qld.gov.au",
        },
        {
          subHeading: "Whyalla City Council",
          link: "whyalla.sa.gov.au",
        },
        {
          subHeading: "Wodonga City Council",
          link: "wodonga.vic.gov.au",
        },
        {
          subHeading: "Wollondilly Shire Council",
          link: "wollondilly.nsw.gov.au",
        },
        {
          subHeading: "Wollongong City Council",
          link: "wollongong.nsw.gov.au",
        },
        {
          subHeading: "Wongan-Ballidu Shire Council",
          link: "wongan.wa.gov.au",
        },
        {
          subHeading: "Wyong Shire Council",
          link: "centralcoast.nsw.gov.au",
        },
        {
          subHeading: "Shire of Wagin",
          link: "wagin.wa.gov.au",
        },
        {
          subHeading: "Shire of Wandering",
          link: "wandering.wa.gov.au",
        },
        {
          subHeading: "City of Wanneroo",
          link: "wanneroo.wa.gov.au",
        },
        {
          subHeading: "Shire of Waroona",
          link: "waroona.wa.gov.au",
        },
        {
          subHeading: "Shire of West Arthur",
          link: "westarthur.wa.gov.au",
        },
        {
          subHeading: "Shire of Wickepin",
          link: "wickepin.wa.gov.au",
        },
        {
          subHeading: "Shire of Williams",
          link: "williams.wa.gov.au",
        },
        {
          subHeading: "Shire of Woodanilling",
          link: "woodanilling.wa.gov.au",
        },
        {
          subHeading: "Shire of Wiluna",
          link: "wiluna.wa.gov.au",
        },
        {
          subHeading: "Shire of Wyalkatchem",
          link: "wyalkatchem.wa.gov.au",
        },
        {
          subHeading: "Shire of Wyndham-East Kimberley",
          link: "swek.wa.gov.au",
        },
      ],
    },
    {
      id: 24,
      heading: "X",
    },
    {
      id: 25,
      heading: "Y",
      councilsList: [
        {
          subHeading: "Yarra City Council",
          link: "yarracity.vic.gov.au",
        },
        {
          subHeading: "Yarra Ranges Shire Council",
          link: "yarraranges.vic.gov.au",
        },
        {
          subHeading: "Yarriambiack Shire Council",
          link: "yarriambiack.vic.gov.au",
        },
        {
          subHeading: "Yass Valley Council",
          link: "yassvalley.nsw.gov.au",
        },
        {
          subHeading: "Yilgarn Shire Council",
          link: "yilgarn.wa.gov.au",
        },
        {
          subHeading: "York Shire Council",
          link: "york.wa.gov.au",
        },
        {
          subHeading: "Young Shire Council",
          link: "hilltops.nsw.gov.au",
        },
        {
          subHeading: "Shire of Yalgoo",
          link: "yalgoo.wa.gov.au",
        },
      ],
    },
    {
      id: 26,
      heading: "Z",
    },
  ];
  const handleItemSelect = (id) => {
    const element = document.getElementById(`council-${id}`);

    // Scroll the element into view
    element.scrollIntoView({ block: "start", behavior: "smooth" });

    // Wait for the scrolling to finish (if needed)
    setTimeout(() => {
      // Calculate the margin you want to add
      const offsetMargin = 40; // Adjust this value as needed

      // Get the current scroll position
      const scrollPosition = window.scrollY;

      // Get the top position of the element relative to the viewport
      const elementRect = element.getBoundingClientRect();
      const elementTop = elementRect.top + scrollPosition;

      // Scroll to a position that includes the margin on top of the element
      window.scrollTo({
        top: elementTop - offsetMargin,
        behavior: "smooth",
      });
    }, 300); // Adjust the delay (in milliseconds) as needed to ensure the element has finished scrolling into view
  };
  return (
    <>
      <div className="grid grid-cols-12 gap-1 w-[350px] sm:w-[450px] md:w-[600px] lg:w-[600px] xl:w-[800px] 2xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] mx-auto mt-10 2xl:mt-40">
        {TypeAll.map((e, index) => {
          return (
            <>
              <button
                key={index}
                type="button"
                onClick={() => handleItemSelect(e.id)}
              >
                <div
                  className={
                    "col-span-1 bg-white text-offblack flex items-center justify-center cursor-pointer hover:bg-primaryblue hover:text-white py-1"
                  }
                  key={e.id}
                >
                  <AuxiliaryText variant={"FieldLabel"}>
                    {e.heading}
                  </AuxiliaryText>
                </div>
              </button>
            </>
          );
        })}
      </div>

      {TypeAll.map((e, index) => {
        return (
          <>
            <div
              key={index}
              id={`council-${e.id}`}
              className="w-[350px] sm:w-[500px] md:w-[600px] lg:w-[600px] xl:w-[800px] 2xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] pb-2 border-b border-b-mediumgray mx-auto mt-20"
            >
              <Heading variant={"h6"} className={"text-offblack"}>
                {e.heading}
              </Heading>
            </div>
            {e.councilsList ? (
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-8 w-[350px] sm:w-[500px] md:w-[600px] lg:w-[600px] xl:w-[800px] 2xl:w-[800px] 3xl:w-[950px] 2k:w-[1100px] 4k:w-[1250px] mx-auto mt-5">
                {e.councilsList.map((e, index) => {
                  return (
                    <>
                      <div key={index} className="col-span-1">
                        <a
                          href={"//" + e.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-offblack hover:text-primaryblue"
                        >
                          <Paragraph
                            variant={"MainParagraph"}
                            className={"mb-2 text-offblack"}
                          >
                            {e.subHeading}
                          </Paragraph>
                        </a>
                      </div>
                    </>
                  );
                })}
              </div>
            ) : (
              <>
                <Paragraph
                  variant={"MainParagraph"}
                  className={"text-center text-offblack mt-5"}
                >
                  Sorry, this category councils aren't available.
                </Paragraph>
              </>
            )}
          </>
        );
      })}
    </>
  );
};

export default AtoZFilters;
