import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const TradingHours = () => {
  const data = [
    {
      heading: "Mon - Fri",
      value: "9am - 5pm",
    },
    {
      heading: "Saturday",
      value: "10am - 3pm",
    },
    {
      heading: "Sunday",
      value: "Closed",
    },
    {
      heading: "P. Holiday",
      value: "Closed",
    },
  ];
  return (
    <>
      <SecondaryHeading
        variant={"MainParagraphHeading"}
        className={"flex justify-center text-offblack"}
      >
        Trading hours
      </SecondaryHeading>
      <div className="mt-5 mb-9 flex justify-center">
        <div>
          {data?.map((item) => {
            return (
              <>
                <div className="grid grid-cols-2 gap-7 mb-1">
                  <div className="col-span-1">
                    <Paragraph
                      variant={"MainParagraph"}
                    >
                      {item.heading}
                    </Paragraph>
                  </div>
                  <div className="col-span-1">
                    <Paragraph
                      variant={"MainParagraph"}
                    >
                      {item.value}
                    </Paragraph>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TradingHours;
