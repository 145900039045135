import React from 'react'
import Heading from "../Typography/Heading";

const ImageWithTopText = ({TopText, classNames}) => {
  return (
    <>
     <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px] mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          {TopText}
        </Heading>
      </div>
      <div className={`${classNames} mt-10`}>
      <img
        src={require("../../../assets/img/about-us.jpg")}
        className={`rounded-3xl object-cover w-full h-full`}
        alt=""
      />

      </div>
    </>
  )
}

export default ImageWithTopText