import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import P1 from "../../assets/img/memberships/TypeA/adb.svg"
import P2 from "../../assets/img/memberships/TypeH/hia.svg"
import P3 from "../../assets/img/memberships/TypeE/ea.svg"
import P4 from "../../assets/img/memberships/TypeB/bdaa.svg"



const Associations = () => {
  const data = [
    {
      pic : P1
    },
    {
      pic : P2
    },
    {
      pic : P3
    },
    {
      pic : P4
    },

  ]
  return (
    <>
      <SecondaryHeading
        variant={"MainParagraphHeading"}
        className={"flex justify-center text-offblack"}
      >
        Associations
      </SecondaryHeading>
      <div className="flex flex-col items-center space-y-5 justify-end mt-5 mb-10">
   {
    data.map((e,index)=> {
      return <>
      <div key={index} className="w-[80px]">
          <img
            className="object-center"
            src={e.pic}
            alt=""
          />
        </div>
      </>
    })
   }
      </div>
    </>
  );
};

export default Associations;
