import React from "react";
import Blinds from "../../assets/img/advance-algorithm.png";
import Heading from "../../components/common/Typography/Heading";
import ImageWithLongCardsColumn from "../../components/common/Generics/ImageWithLongCardsColumn";

const WhyTradesposter = () => {
  const cardsdata = [
    {
      heading: "Completely Free",
      icon: Blinds,
      description: "Use your account completely free.",
    },
    {
      heading: "Verified business",
      icon: Blinds,
      description: "Work with verified professionals.",
    },
    {
      heading: "Local Experts",
      icon: Blinds,
      description: "Connect with local trade people.",
    },
    {
      heading: "Licensed & Insured",
      icon: Blinds,
      description: "Hire licensed and insured professionals.",
    },
    {
      heading: "Custom Quotes",
      icon: Blinds,
      description: "Get tailored quotes.",
    },
    {
      heading: "Save Time",
      icon: Blinds,
      description: "Find services quickly.",
    },
    {
      heading: "Contact Directly",
      icon: Blinds,
      description: "Text, chat, or call when you need.",
    },
    {
      heading: "Pay Your Way",
      icon: Blinds,
      description: "Pay businesses on mutual terms.",
    },
  ];
  return (
    <>
      <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] mt-40">
        <Heading
          variant={"h2"}
          className={
            "text-start text-offblack max-w-[700px] 2xl:max-w-[850px] 3xl:max-w-[1000px]"
          }
        >
          Why register for a Customer account at Trades Poster? Why register for
          a Customer account at Trades Poster?
        </Heading>
      </div>

      <ImageWithLongCardsColumn data={cardsdata} />
    </>
  );
};

export default WhyTradesposter;
