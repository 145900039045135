import React from "react";
import PriceCard from "../../components/common/PriceCard/PriceCard";
import { useSelector } from "react-redux";
import Heading from "../../components/common/Typography/Heading";

const BusinesEmpowerment = () => {
  const getPremiumPackageDetail = useSelector((plan) =>
    plan?.PricingPlanReducer.find((item) => item.name === "PREMIUM")
  );
  return (
    <>
      <div className="pt-20 md:pt-20 lg:pt-20 xl:pt-40 2xl:pt-40">
        <div className="mx-[5%] sm:mx-auto sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] 2k:w-[1600px] 4k:w-[1800px]">
          <div className="mb-10">
            <Heading variant={"h2"} className={" text-offblack"}>
              Empower your business <br />
              Get seen, Get hired, Get reviewed
            </Heading>
          </div>
        </div>
      </div>
      <div className="bg-mainBanner bg-center bg-cover h-[900px] xl:h-[1282px] 2xl:h-[1282px] w-[100%]">
        <div className="flex itmes-center mx-auto w-fit sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] h-full">
          <div className="flex justify-center lg:justify-end w-full my-auto ">
            <PriceCard
              bottomLine={"border-b border-b-lightgray pb-5"}
              MainDiveClasses={
                "bg-white text-offblack w-[350px] h-[550px] sm:w-[400px] sm:h-[550px] md:w-[450px] md:h-[600px] xl:w-[450px] xl:h-[600px] 2xl:w-[450px] 2xl:h-[600px]"
              }
              pricingColor={"text-offblack"}
              marginTop={"mt-10"}
              label={"Premium"}
              price={getPremiumPackageDetail?.price ?? ""}
              duration={1}
              description={
                "Ideal for businesses seeking to lead and expand reach. The Premium plan offers complete solutions for dynamic growth."
              }
              buttonText={"Get started"}
              listItems={[
                `Includes all standard plan features`,
                // `Business Trading Hours`,
                `Business contact details`,
                // `highlighted services`,
                // `accepted payment methods`,
                // `social profile link`,
                // `business website link`,
                // `personalized business FAQs`,
                `10 Keywords`,
                `20 Featured posts`,
                // `20 portfolio pictures`,
                // `8 post pictures`,
                `Post metrics`,
                `Unlimited regular posts`,
              ]}
              // listItemsValue={[
              //   `${getPremiumPackageDetail?.regularListing}`,
              //   `${getPremiumPackageDetail?.featureListing}`,
              //   `${getPremiumPackageDetail?.noOfImages}`,
              // ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinesEmpowerment;
