import React, { useState } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import TextFiled from "../../../components/common/InputField/TextFiled";
import Heading from "../../../components/common/Typography/Heading";
import Paragraph from "../../../components/common/Typography/Paragraph";
import useAuth from "../../../Hooks/useAuth";
import Popups from "../../../components/popup/Popups";
import { useEffect } from "react";
import InteractiveText from "../../../components/common/Typography/InteractiveText";

const ChangePassword = () => {
  const [step, setStep] = useState(1);
  const { auth, setAuth } = useAuth();
  const [verify, setVerify] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    if (verify) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [verify]);
  return (
    <>
      <>
        <div className="grid grid-cols-12 w-fit mx-auto">
          <div className="col-span-12">
            <div>
              <TextFiled
                className={"bg-pastalblue"}
                variant={"large"}
                label={"New password"}
                placeholder={"Your new secure password "}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"bg-pastalblue"}
                variant={"large"}
                label={"Confirm password"}
                placeholder={"Re-enter your new password "}
              />
            </div>
            <div className="flex justify-end mt-10">
              <MainButton variant={"small"} onClick={() => setVerify(true)}>
                Save
              </MainButton>
            </div>
          </div>
        </div>
      </>
      {verify && (
        <Popups
          setTrigger={() => {
            setVerify(false);
            setStep(1);
          }}
          ShowCrossButton={ShowCrossButton}
          popupWidth={"w-[350px] h-[400px] sm:w-[600px] sm:h-[500px] 2xl:w-[600px] 2xl:h-[500px] pb-10"}
              crossPosition={
                "flex justify-end w-[16px] absolute left-[320px] sm:left-[565px] top-3"
              }
        >
          {step === 1 && (
            <>
              <Heading variant={"h6"} className={"text-center mb-10 mt-[70px]"}>
                Password reset verification
              </Heading>
              <div className="grid grid-cols-12 w-[300px] sm:w-[400px] 2xl:w-[400px] mx-auto">
                <div className="col-span-12">
                  <div>
                    <Paragraph variant={"MainParagraph"}>
                      To confirm your password reset request, please enter the
                      verification code sent to your registered email address.
                      If you haven't received the code, you can request a new
                      one.
                    </Paragraph>
                  </div>
                  <form>
                    <div className="mt-10">
                      <TextFiled
                        className={"bg-white"}
                        label={"Verification code"}
                        variant={"general"}
                        type="email"
                        name="email"
                        placeholder={"Enter the 5-digit verification code"}
                        // onChange={formik?.handleChange}
                        // value={formik?.values?.email}
                      />
                    </div>

                    <div className="flex items-center justify-between mt-10">
                      <InteractiveText
                        variant={"ActionLink"}
                        className={"text-primaryblue cursor-pointer"}
                      >
                        Resend
                      </InteractiveText>
                      <MainButton
                        variant={"extrasmall"}
                        onClick={() => {
                          setStep(2);
                          setTimeout(() => {
                            setAuth({
                              user: null,
                              roles: null,
                              accessToken: "",
                            });
                            localStorage.removeItem("LoginSession");
                          }, 4000);
                        }}
                      >
                        Verify
                      </MainButton>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <Heading variant={"h6"} className={"text-center mb-10 mt-20 text-offblack"}>
                Password reset successfully
              </Heading>
              <div className="w-[300px] sm:w-[350px] 2xl:w-[400px] mx-auto">
                <Paragraph variant={"MainParagraph"} className={"text-start text-offblack"}>
                  Your password has been updated successfully. For your
                  security, you've been logged out of all other sessions. Please
                  sign in with your new password.
                </Paragraph>
              </div>
            </>
          )}
        </Popups>
      )}
    </>
  );
};
export default ChangePassword;
