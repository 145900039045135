import React from "react";
import ReviewsLayout from "../../../sections/CustomerDashboard/Inbox/Reviews/ReviewsLayout";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";

const CustReviews = () => {
  return (
    <>
      <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
          <Heading variant={"h6"} className={"text-center text-offblack"}>
            Reviews and ratings
          </Heading>
        <div className="flex justify-center mt-[60px] min-h-screen">
          <ReviewsLayout />
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default CustReviews;
