// A type images
import AA from "../../../../assets/img/memberships/TypeA/AA.svg";
import AAA from "../../../../assets/img/memberships/TypeA/AAA.svg";
import AAAC from "../../../../assets/img/memberships/TypeA/AAAC.svg";
import AAC from "../../../../assets/img/memberships/TypeA/AAC.svg";
import AACA from "../../../../assets/img/memberships/TypeA/AACA.svg";
import ABSA from "../../../../assets/img/memberships/TypeA/ABSA.svg";
import ACA1 from "../../../../assets/img/memberships/TypeA/ACA 1.svg";
import ACA from "../../../../assets/img/memberships/TypeA/ACA.svg";
import ACCI from "../../../../assets/img/memberships/TypeA/ACCI.svg";
import ACI from "../../../../assets/img/memberships/TypeA/ACI.svg";
import ACRA from "../../../../assets/img/memberships/TypeA/ACRA.svg";
import AEPMA from "../../../../assets/img/memberships/TypeA/AEPMA.svg";
import AFA from "../../../../assets/img/memberships/TypeA/AFA.svg";
import AFIA from "../../../../assets/img/memberships/TypeA/AFIA.svg";
// import AGWA from "../../../../assets/img/memberships/TypeA/AGWA.svg";
import AIA from "../../../../assets/img/memberships/TypeA/AIA.svg";
import AIB from "../../../../assets/img/memberships/TypeA/AIB.svg";
import AILA from "../../../../assets/img/memberships/TypeA/AILA.svg";
import AIW from "../../../../assets/img/memberships/TypeA/AIW.svg";
import AMCA from "../../../../assets/img/memberships/TypeA/AMCA.svg";
import APB from "../../../../assets/img/memberships/TypeA/APB.svg";
import ARFA from "../../../../assets/img/memberships/TypeA/ARFA.svg";
import ARTA from "../../../../assets/img/memberships/TypeA/ARTA.svg";
import ASBEC from "../../../../assets/img/memberships/TypeA/ASBEC.svg";
import ASCA from "../../../../assets/img/memberships/TypeA/ASCA.svg";
import ASI from "../../../../assets/img/memberships/TypeA/ASI.svg";
import ASSDA from "../../../../assets/img/memberships/TypeA/ASSDA.svg";
import ATFA from "../../../../assets/img/memberships/TypeA/ATFA.svg";
// import ATIF from "../../../../assets/img/memberships/TypeA/ATIF.svg";
import AWCI from "../../../../assets/img/memberships/TypeA/AWCI.svg";
import AIQS from "../../../../assets/img/memberships/TypeA/IQS.svg";
import ACT from "../../../../assets/img/memberships/TypeA/ACT.svg";

// B type images
// import BABI from "../../../../assets/img/memberships/TypeB/babi.svg";
import BDAA from "../../../../assets/img/memberships/TypeB/bdaa.svg";
import BMAA from "../../../../assets/img/memberships/TypeB/bmaa.svg";
import BPIC from "../../../../assets/img/memberships/TypeB/bpic.svg";
import BSCAA from "../../../../assets/img/memberships/TypeB/bscaa.svg";

// C type images
import CCA from "../../../../assets/img/memberships/TypeC/cca.svg";
import CCAA from "../../../../assets/img/memberships/TypeC/ccaa.svg";
import CCF from "../../../../assets/img/memberships/TypeC/ccf.svg";
import CIAL from "../../../../assets/img/memberships/TypeC/cial.svg";
import CICA from "../../../../assets/img/memberships/TypeC/cica.svg";
import CIOF from "../../../../assets/img/memberships/TypeC/ciof.svg";

// D type images
import DMN from "../../../../assets/img/memberships/TypeD/dmn.svg";

// E type images
import EA from "../../../../assets/img/memberships/TypeE/ea.svg";
import EWPAA from "../../../../assets/img/memberships/TypeE/ewpaa.svg";

// F type images
import FIAA from "../../../../assets/img/memberships/TypeF/fiaa.svg";
import FTMA from "../../../../assets/img/memberships/TypeF/ftma.svg";

// G type images
import GAA from "../../../../assets/img/memberships/TypeG/gaa.svg";
import GRA from "../../../../assets/img/memberships/TypeG/gra.svg";

// H type images
import HIA from "../../../../assets/img/memberships/TypeH/hia.svg";

// I type images
import IAQ from "../../../../assets/img/memberships/TypeI/iaq.svg";
import ICAM from "../../../../assets/img/memberships/TypeI/icam.svg";
import IPCA from "../../../../assets/img/memberships/TypeI/ipca.svg";

// M type images
import MBA from "../../../../assets/img/memberships/TypeM/mba.svg";
import MBIAA from "../../../../assets/img/memberships/TypeM/mbiaa.svg";
import MEA from "../../../../assets/img/memberships/TypeM/mea.svg";
import MPA from "../../../../assets/img/memberships/TypeM/mpa.svg";
import MPAN from "../../../../assets/img/memberships/TypeM/mpan.svg";
import MRCAA from "../../../../assets/img/memberships/TypeM/mrcaa.svg";

// N type images
import NASH from "../../../../assets/img/memberships/TypeN/nash.svg";
import NAWIC from "../../../../assets/img/memberships/TypeN/nawic.svg";
import NBLA from "../../../../assets/img/memberships/TypeN/nbla.svg";
import NECA from "../../../../assets/img/memberships/TypeN/neca.svg";
// import NC from "../../../../assets/img/memberships/TypeN/nc.svg";
import NFIA from "../../../../assets/img/memberships/TypeN/nfia.svg";
// import NT from "../../../../assets/img/memberships/TypeN/nt.svg";
import NPCAA from "../../../../assets/img/memberships/TypeN/npcaa.svg";
import NSSA from "../../../../assets/img/memberships/TypeN/nssa.svg";
import NWFA from "../../../../assets/img/memberships/TypeN/nwfa.svg";

// P type images
import PAUS from "../../../../assets/img/memberships/TypeP/paus.svg";
import PFSF from "../../../../assets/img/memberships/TypeP/pfsf.svg";
import PIA from "../../../../assets/img/memberships/TypeP/pia.svg";
import PTIA from "../../../../assets/img/memberships/TypeP/ptia.svg";

// R type images
import RIA from "../../../../assets/img/memberships/TypeR/ria.svg";

// S type images
// import SA from "../../../../assets/img/memberships/TypeS/sa.svg";
import SAQ from "../../../../assets/img/memberships/TypeS/saq.svg";
import SIA from "../../../../assets/img/memberships/TypeS/sia.svg";
import SPASA from "../../../../assets/img/memberships/TypeS/spasa.svg";
import SUCAA from "../../../../assets/img/memberships/TypeS/sucaa.png";

// T type images
// import TASMANIA from "../../../../assets/img/memberships/TypeT/t.svg";
import TDA from "../../../../assets/img/memberships/TypeT/tda.svg";
// import THOB from "../../../../assets/img/memberships/TypeT/thob.svg";
import TTIAA from "../../../../assets/img/memberships/TypeT/ttiaa.svg";

// V type images
import VCA from "../../../../assets/img/memberships/TypeV/vca.svg";
// import VICTORIA from "../../../../assets/img/memberships/TypeV/v.svg";

export const TypeAll = [
  {
    heading: "A",
    associationsList: [
      {
        heading: "Australian Elevator Association Limited (AEA)",
        image: AA,
        status: "Remove",
      },
      {
        heading: "Australian Architecture Association ",
        image: AAA,
        status: "Add",
      },
      {
        heading: "Association of Australasian Acoustical Consultants ",
        image: AAAC,
        status: "Add",
      },
      {
        heading: "Association of Accredited Certifiers ",
        image: AAC,
        status: "Add",
      },
      {
        heading: "Association of Consultants in Access Australia Inc. ACAA ",
        image: AACA,
        status: "Add",
      },
      {
        heading: "Australian Building Sustainability Association (ABSA)",
        image: ABSA,
        status: "Add",
      },
      {
        heading: "Australian Constructors Association ",
        image: ACA1,
        status: "Add",
      },
      {
        heading: "Association of Consulting Architects ",
        image: ACA,
        status: "Add",
      },
      {
        heading: "Australian Timber Importers Federation (ATIF) ",
        image: ACCI,
        status: "Add",
      },
      {
        heading: "Australian Tile Council ",
        image: ACI,
        status: "Add",
      },
      {
        heading: "ACRA ",
        image: ACRA,
        status: "Add",
      },
      {
        heading: "Australian Environmental Pest Managers Association Ltd ",
        image: AEPMA,
        status: "Add",
      },
      {
        heading: "Australian Glass and Window Association ",
        image: AFA,
        status: "Add",
      },
      {
        heading: "Aluminium Foil Insulation Association (AFIA) ",
        image: AFIA,
        status: "Add",
      },
      {
        heading: "Australian Institute of Architects ",
        image: AIA,
        status: "Add",
      },
      {
        heading: "Australian Institute of Building ",
        image: AIB,
        status: "Add",
      },
      {
        heading: "Australian Institute of Landscape Architects ",
        image: AILA,
        status: "Add",
      },
      {
        heading: "Australian Institute of Waterproofing ",
        image: AIW,
        status: "Add",
      },
      {
        heading: "Air Conditioning and Mechanical Contractors’ Association ",
        image: AMCA,
        status: "Add",
      },
      {
        heading: "Association of Professional Builders ",
        image: APB,
        status: "Add",
      },
      {
        heading: "Australian Resilient Flooring Association – ARFA ",
        image: ARFA,
        status: "Add",
      },
      {
        heading: "Australian Roof Tile Association – ARTA ",
        image: ARTA,
        status: "Add",
      },
      {
        heading: "Australian Sustainable Built Environment Council (ASBEC) ",
        image: ASBEC,
        status: "Add",
      },
      {
        heading: "Australian Smart Communities Association (ASCA) ",
        image: ASCA,
        status: "Add",
      },
      {
        heading: "Australian Steel Institute ",
        image: ASI,
        status: "Add",
      },
      {
        heading: "Australian Stainless Steel Development Association (ASSDA) ",
        image: ASSDA,
        status: "Add",
      },
      {
        heading: "Australasian Timber Flooring Association ",
        image: ATFA,
        status: "Add",
      },
      {
        heading:
          "Association of Wall and Ceiling Industries Australia and New Zealand ",
        image: AWCI,
        status: "Add",
      },
      {
        heading: "Australian Institute of Quantity Surveyors ",
        image: AIQS,
        status: "Add",
      },
      {
        heading: "ACT ",
        image: ACT,
        status: "Add",
      },
    ],
  },

  {
    heading: "B",
    associationsList: [
      {
        heading: "BABI",
        // image: BABI,
        status: "Remove",
      },
      {
        heading: "Building Designers Association of Australia ",
        image: BDAA,
        status: "Add",
      },
      {
        heading: "Blind Manufacturers’ Association of Australia ",
        image: BMAA,
        status: "Add",
      },
      {
        heading: "Building Products Industry Council (BPIC) ",
        image: BPIC,
        status: "Add",
      },
      {
        heading:
          "Building Service Contractors of Australia Association Limited ",
        image: BSCAA,
        status: "Add",
      },
    ],
  },
  {
    heading: "C",
    associationsList: [
      {
        heading:
          "Construction Contractors Association of Western Australia – CCA",
        image: CCA,
        status: "Remove",
      },
      {
        heading: "Cement Concrete and Aggregates Australia – CCAA ",
        image: CCAA,
        status: "Add",
      },
      {
        heading: "Civil Contractors Federation ",
        image: CCF,
        status: "Add",
      },
      {
        heading: "Carpet Institute of Australia – CIAL ",
        image: CIAL,
        status: "Add",
      },
      {
        heading: "Crane Industry Council of Australia (CICA)  ",
        image: CICA,
        status: "Add",
      },
      {
        heading: "Concrete Institute of Australia ",
        image: CIOF,
        status: "Add",
      },
    ],
  },
  {
    heading: "D",
    associationsList: [
      {
        heading: "Design Matters National ",
        image: DMN,
        status: "Add",
      },
    ],
  },
  {
    heading: "E",
    associationsList: [
      {
        heading: "Engineers Australia ",
        image: EA,
        status: "Remove",
      },
      {
        heading: "Engineered Wood Products Association of Australasia ",
        image: EWPAA,
        status: "Add",
      },
    ],
  },
  {
    heading: "F",
    associationsList: [
      {
        heading: "Formwork Industry Association ",
        image: FIAA,
        status: "Remove",
      },
      {
        heading: "Frame & Truss Manufacturers Association of Australia Ltd ",
        image: FTMA,
        status: "Add",
      },
    ],
  },
  {
    heading: "G",
    associationsList: [
      {
        heading: "Galvanizers Association of Australia (GAA) ",
        image: GAA,
        status: "Remove",
      },
      {
        heading: "Green Roofs Australasia (GRA) ",
        image: GRA,
        status: "Add",
      },
    ],
  },
  {
    heading: "H",
    associationsList: [
      {
        heading: "Housing Industry Association – HIA ",
        image: HIA,
        status: "Add",
      },
    ],
  },
  {
    heading: "I",
    associationsList: [
      {
        heading: "Infrastructure Association of Queensland",
        image: IAQ,
        status: "Remove",
      },
      {
        heading: "ICAM ",
        image: ICAM,
        status: "Add",
      },
      {
        heading: "Insulated Panel Council Australasia Ltd – IPCA ",
        image: IPCA,
        status: "Add",
      },
    ],
  },
  {
    heading: "M",
    associationsList: [
      {
        heading: "Master Builders Australia",
        image: MBA,
        status: "Remove",
      },
      {
        heading: "Modular Building Industry Association Australia (MBIAA) ",
        image: MBIAA,
        status: "Add",
      },
      {
        heading: "Master Electricians Australia ",
        image: MEA,
        status: "Add",
      },
      {
        heading: "Master Painters Australia ",
        image: MPA,
        status: "Add",
      },
      {
        heading: "Master Plumbers Australia and New Zealand ",
        image: MPAN,
        status: "Add",
      },
      {
        heading: "Metal Roofing and Cladding Association of Australia – MRCAA ",
        image: MRCAA,
        status: "Add",
      },
    ],
  },
  {
    heading: "N",
    associationsList: [
      {
        heading: "National Association of Steel-Framed Housing – NASH ",
        image: NASH,
        status: "Remove",
      },
      {
        heading: "National Association of Women in Construction – NAWIC ",
        image: NAWIC,
        status: "Add",
      },
      {
        heading: "National Bricklayers Association ",
        image: NBLA,
        status: "Add",
      },
      {
        heading: "NC ",
        status: "Add",
      },
      {
        heading: "National Electrical and Communications Association (NECA)  ",
        image: NECA,
        status: "Add",
      },
      {
        heading: "National Fire Industry Association ",
        image: NFIA,
        status: "Add",
      },
      {
        heading: "National Precast ",
        image: NPCAA,
        status: "Add",
      },
      {
        heading: "National Security Screen Association ",
        image: NSSA,
        status: "Add",
      },
      {
        heading: "NT ",
        status: "Add",
      },
      {
        heading: "National Wood Flooring Association of Australia ",
        image: NWFA,
        status: "Add",
      },
    ],
  },
  {
    heading: "P",
    associationsList: [
      {
        heading: "PrefabAUS",
        image: PAUS,
        status: "Remove",
      },
      {
        heading: "Piling and Foundation Specialists Federation",
        image: PFSF,
        status: "Add",
      },
      {
        heading: "Planning Institute of Australia (PIA) ",
        image: PIA,
        status: "Add",
      },
      {
        heading: "Post-Tensioning Institute of Australia – PTIA ",
        image: PTIA,
        status: "Add",
      },
    ],
  },
  {
    heading: "R",
    associationsList: [
      {
        heading: "Roofing Industry Association of NSW ",
        image: RIA,
        status: "Add",
      },
    ],
  },
  {
    heading: "S",
    associationsList: [
      {
        heading: "SA",
        status: "Remove",
      },
      {
        heading: "Scaffolding Association Queensland (SAQ) ",
        image: SAQ,
        status: "Add",
      },
      {
        heading: "Skylight Industry Association ",
        image: SIA,
        status: "Add",
      },
      {
        heading: "Swimming Pool & Spa Association of Australia Ltd ",
        image: SPASA,
        status: "Add",
      },
      {
        heading: "Surface Coatings Association Australia (Inc.) ",
        image: SUCAA,
        status: "Add",
      },
    ],
  },
  {
    heading: "T",
    associationsList: [
      {
        heading: "Tasmania",
        status: "Remove",
      },
      {
        heading: "Timber Development Association ",
        image: TDA,
        status: "Add",
      },
      {
        heading: "THOB ",
        status: "Add",
      },
      {
        heading: "Tiles & Tiling Industry Association Australia ",
        image: TTIAA,
        status: "Add",
      },
    ],
  },
  {
    heading: "V",
    associationsList: [
      {
        heading: "Vinyl Council of Australia ",
        image: VCA,
        status: "Add",
      },
      {
        heading: "Victoria ",
        status: "Remove",
      },
    ],
  },
  {
    heading: "ALL",
    associationsList: [
      {
        heading: "Australian Elevator Association Limited (AEA)",
        image: AA,
        status: "Remove",
      },
      {
        heading: "Australian Architecture Association ",
        image: AAA,
        status: "Add",
      },
      {
        heading: "Association of Australasian Acoustical Consultants ",
        image: AAAC,
        status: "Add",
      },
      {
        heading: "Association of Accredited Certifiers ",
        image: AAC,
        status: "Add",
      },
      {
        heading: "Association of Consultants in Access Australia Inc. ACAA ",
        image: AACA,
        status: "Add",
      },
      {
        heading: "Australian Building Sustainability Association (ABSA)",
        image: ABSA,
        status: "Add",
      },
      {
        heading: "Australian Constructors Association ",
        image: ACA1,
        status: "Add",
      },
      {
        heading: "Association of Consulting Architects ",
        image: ACA,
        status: "Add",
      },
      {
        heading: "Australian Timber Importers Federation (ATIF) ",
        image: ACCI,
        status: "Add",
      },
      {
        heading: "Australian Tile Council ",
        image: ACI,
        status: "Add",
      },
      {
        heading: "ACRA ",
        image: ACRA,
        status: "Add",
      },
      {
        heading: "Australian Environmental Pest Managers Association Ltd ",
        image: AEPMA,
        status: "Add",
      },
      {
        heading: "Australian Glass and Window Association ",
        image: AFA,
        status: "Add",
      },
      {
        heading: "Aluminium Foil Insulation Association (AFIA) ",
        image: AFIA,
        status: "Add",
      },
      {
        heading: "Australian Institute of Architects ",
        image: AIA,
        status: "Add",
      },
      {
        heading: "Australian Institute of Building ",
        image: AIB,
        status: "Add",
      },
      {
        heading: "Australian Institute of Landscape Architects ",
        image: AILA,
        status: "Add",
      },
      {
        heading: "Australian Institute of Waterproofing ",
        image: AIW,
        status: "Add",
      },
      {
        heading: "Air Conditioning and Mechanical Contractors’ Association ",
        image: AMCA,
        status: "Add",
      },
      {
        heading: "Association of Professional Builders ",
        image: APB,
        status: "Add",
      },
      {
        heading: "Australian Resilient Flooring Association – ARFA ",
        image: ARFA,
        status: "Add",
      },
      {
        heading: "Australian Roof Tile Association – ARTA ",
        image: ARTA,
        status: "Add",
      },
      {
        heading: "Australian Sustainable Built Environment Council (ASBEC) ",
        image: ASBEC,
        status: "Add",
      },
      {
        heading: "Australian Smart Communities Association (ASCA) ",
        image: ASCA,
        status: "Add",
      },
      {
        heading: "Australian Steel Institute ",
        image: ASI,
        status: "Add",
      },
      {
        heading: "Australian Stainless Steel Development Association (ASSDA) ",
        image: ASSDA,
        status: "Add",
      },
      {
        heading: "Australasian Timber Flooring Association ",
        image: ATFA,
        status: "Add",
      },
      {
        heading:
          "Association of Wall and Ceiling Industries Australia and New Zealand ",
        image: AWCI,
        status: "Add",
      },
      {
        heading: "Australian Institute of Quantity Surveyors ",
        image: AIQS,
        status: "Add",
      },
      {
        heading: "ACT ",
        image: ACT,
        status: "Add",
      },
      {
        heading: "BABI",
        // image: BABI,
        status: "Remove",
      },
      {
        heading: "Building Designers Association of Australia ",
        image: BDAA,
        status: "Add",
      },
      {
        heading: "Blind Manufacturers’ Association of Australia ",
        image: BMAA,
        status: "Add",
      },
      {
        heading: "Building Products Industry Council (BPIC) ",
        image: BPIC,
        status: "Add",
      },
      {
        heading:
          "Building Service Contractors of Australia Association Limited ",
        image: BSCAA,
        status: "Add",
      },
      {
        heading:
          "Construction Contractors Association of Western Australia – CCA",
        image: CCA,
        status: "Remove",
      },
      {
        heading: "Cement Concrete and Aggregates Australia – CCAA ",
        image: CCAA,
        status: "Add",
      },
      {
        heading: "Civil Contractors Federation ",
        image: CCF,
        status: "Add",
      },
      {
        heading: "Carpet Institute of Australia – CIAL ",
        image: CIAL,
        status: "Add",
      },
      {
        heading: "Crane Industry Council of Australia (CICA)  ",
        image: CICA,
        status: "Add",
      },
      {
        heading: "Concrete Institute of Australia ",
        image: CIOF,
        status: "Add",
      },
      {
        heading: "Design Matters National ",
        image: DMN,
        status: "Add",
      },
      {
        heading: "Engineers Australia ",
        image: EA,
        status: "Remove",
      },
      {
        heading: "Engineered Wood Products Association of Australasia ",
        image: EWPAA,
        status: "Add",
      },
      {
        heading: "Formwork Industry Association ",
        image: FIAA,
        status: "Remove",
      },
      {
        heading: "Frame & Truss Manufacturers Association of Australia Ltd ",
        image: FTMA,
        status: "Add",
      },
      {
        heading: "Galvanizers Association of Australia (GAA) ",
        image: GAA,
        status: "Remove",
      },
      {
        heading: "Green Roofs Australasia (GRA) ",
        image: GRA,
        status: "Add",
      },
      {
        heading: "Housing Industry Association – HIA ",
        image: HIA,
        status: "Add",
      },
      {
        heading: "Infrastructure Association of Queensland",
        image: IAQ,
        status: "Remove",
      },
      {
        heading: "ICAM ",
        image: ICAM,
        status: "Add",
      },
      {
        heading: "Insulated Panel Council Australasia Ltd – IPCA ",
        image: IPCA,
        status: "Add",
      },
      {
        heading: "Master Builders Australia",
        image: MBA,
        status: "Remove",
      },
      {
        heading: "Modular Building Industry Association Australia (MBIAA) ",
        image: MBIAA,
        status: "Add",
      },
      {
        heading: "Master Electricians Australia ",
        image: MEA,
        status: "Add",
      },
      {
        heading: "Master Painters Australia ",
        image: MPA,
        status: "Add",
      },
      {
        heading: "Master Plumbers Australia and New Zealand ",
        image: MPAN,
        status: "Add",
      },
      {
        heading: "Metal Roofing and Cladding Association of Australia – MRCAA ",
        image: MRCAA,
        status: "Add",
      },
      {
        heading: "National Association of Steel-Framed Housing – NASH ",
        image: NASH,
        status: "Remove",
      },
      {
        heading: "National Association of Women in Construction – NAWIC ",
        image: NAWIC,
        status: "Add",
      },
      {
        heading: "National Bricklayers Association ",
        image: NBLA,
        status: "Add",
      },
      {
        heading: "NC ",
        status: "Add",
      },
      {
        heading: "National Electrical and Communications Association (NECA)  ",
        image: NECA,
        status: "Add",
      },
      {
        heading: "National Fire Industry Association ",
        image: NFIA,
        status: "Add",
      },
      {
        heading: "National Precast ",
        image: NPCAA,
        status: "Add",
      },
      {
        heading: "National Security Screen Association ",
        image: NSSA,
        status: "Add",
      },
      {
        heading: "NT ",
        status: "Add",
      },
      {
        heading: "National Wood Flooring Association of Australia ",
        image: NWFA,
        status: "Add",
      },
      {
        heading: "PrefabAUS",
        image: PAUS,
        status: "Remove",
      },
      {
        heading: "Piling and Foundation Specialists Federation",
        image: PFSF,
        status: "Add",
      },
      {
        heading: "Planning Institute of Australia (PIA) ",
        image: PIA,
        status: "Add",
      },
      {
        heading: "Post-Tensioning Institute of Australia – PTIA ",
        image: PTIA,
        status: "Add",
      },
      {
        heading: "Roofing Industry Association of NSW ",
        image: RIA,
        status: "Add",
      },
      {
        heading: "SA",
        status: "Remove",
      },
      {
        heading: "Scaffolding Association Queensland (SAQ) ",
        image: SAQ,
        status: "Add",
      },
      {
        heading: "Skylight Industry Association ",
        image: SIA,
        status: "Add",
      },
      {
        heading: "Swimming Pool & Spa Association of Australia Ltd ",
        image: SPASA,
        status: "Add",
      },
      {
        heading: "Surface Coatings Association Australia (Inc.) ",
        image: SUCAA,
        status: "Add",
      },
      {
        heading: "Tasmania",
        status: "Remove",
      },
      {
        heading: "Timber Development Association ",
        image: TDA,
        status: "Add",
      },
      {
        heading: "THOB ",
        status: "Add",
      },
      {
        heading: "Tiles & Tiling Industry Association Australia ",
        image: TTIAA,
        status: "Add",
      },
      {
        heading: "Vinyl Council of Australia ",
        image: VCA,
        status: "Add",
      },
      {
        heading: "Victoria ",
        status: "Remove",
      },
    ],
  },
];
