import React, { useState } from "react";
import InteractiveText from "../../components/common/Typography/InteractiveText";
import Heading from "../../components/common/Typography/Heading";

const CommonFAQs = () => {
  const data = [
    {
      question:
        "What are the necessary qualifications for a plumber in Australia?",
      answer:
        "In Australia, plumbers must obtain a trade license to legally conduct most plumbing work. The requirements for licensing vary by state, but typically involve completing an apprenticeship or training program and passing a licensing exam. It's essential to check with your local state's regulatory body for specific licensing requirements.",
    },
    {
      question: "How do I verify if a plumber is licensed?",
      answer:
        "You can verify a plumber's license through the website of the relevant building authority in your state or territory. Most regulatory bodies offer an online search tool where you can enter the plumber's name or license number to check their credentials.",
    },
    {
      question: "Why is it important to hire a licensed plumber?",
      answer:
        "Hiring a licensed plumber ensures that the individual has the necessary training and qualifications to perform plumbing work safely and effectively. Licensed plumbers are also required to adhere to national and state plumbing codes, which helps protect your property from damage and ensures the health and safety of your household.",
    },
    {
      question: "What should I do if I experience a plumbing emergency?",
      answer:
        "In the event of a plumbing emergency, such as a major leak, burst pipe, or sewage backup, it's crucial to contact a licensed plumber immediately. Many plumbing companies offer 24/7 emergency services. It’s advisable to shut off the main water supply to your property to minimize damage until the plumber arrives.",
    },
    {
      question: "How often should plumbing systems be inspected?",
      answer:
        "Plumbing systems should be inspected at least once every two years to ensure they are in good condition and to prevent potential issues. However, older systems or those in areas with hard water may require more frequent inspections. Regular maintenance can help extend the life of your plumbing system and prevent costly repairs.",
    },
  ];
  const [Show, setShow] = useState(-1);
  const toggleHandler = (index) => {
    if (Show === index) {
      return setShow(null);
    } else {
      setShow(index);
    }
  };
  return (
    <>
      <div className="flex text-offblack mt-10 xl:mt-40 w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] mx-auto">
        <div className=" w-full">
          <div>
            <Heading variant={"h2"} className={"mb-20 text-offblack"}>
            Common FAQ’s about plumbing services in Australia
            </Heading>
            {data.map((e, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={
                      Show === index
                        ? "bg-brightblue text-white h-[70px] w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] flex items-center rounded-t-xl px-5"
                        : "bg-brightblue text-white h-[100px] w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] flex items-center rounded-xl px-5 mb-8"
                    }
                  >
                    <div>
                      <InteractiveText variant={"FAQHeader"}>
                        {e.question}
                      </InteractiveText>
                    </div>
                    <div className="w-[16px] h-[70px] ml-auto flex items-center justify-center rounded-t-xl">
                      <i
                        className={
                          Show === index
                            ? "fi fi-rs-angle-up text-[16px] cursor-pointer"
                            : "fi fi-rs-angle-down text-[16px] cursor-pointer"
                        }
                        onClick={() => {
                          toggleHandler(index);
                        }}
                      ></i>
                    </div>
                  </div>
                  {Show === index && (
                    <div className="bg-brightblue text-white h-auto w-[350px] sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px] flex items-center rounded-b-xl px-5 pb-5 mb-8">
                      <InteractiveText variant={"FAQAnswer"}>
                        {e.answer}
                      </InteractiveText>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonFAQs;
