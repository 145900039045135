import * as Yup from "yup";
export const InitialValues = {
  username: "",
  email: "",
  password: "",
  // repeatPassword: "",
};
export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is Required")
    .label("Email"),
    password: Yup.string()
    .required("Please enter a password")
    .min(8, "Password too short")
    .test(
      "isValidPass",
      "Must contain at least one lowercase one uppercase one special and a number character",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSymbole = /[!@#%&]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    ),
  // repeatPassword: Yup.string()
  //   .min(6)
  //   .when("password", {
  //     is: (val) => (val && val.length > 0 ? true : false),
  //     then: Yup.string().oneOf(
  //       [Yup.ref("password")],
  //       "Both password need to be the same"
  //     ),
  //   })
  //   .required("Confirm Password Required"),
});



// validationSchema: Yup.object({
//   email: Yup.string()
//       .min(8, 'Must be at least 8 characters')
//       .max(20, 'Must be less  than 20 characters')
//       .required('Email is required')
//       .test('Unique Email', 'Email already in use', // <- key, message
//           function (value) {
//               return new Promise((resolve, reject) => {
//                   axios.get(`http://localhost:8003/api/u/user/${value}/available`)
//                       .then((res) => {
//                           resolve(true)
//                       })
//                       .catch((error) => {
//                           if (error.response.data.content === "The email has already been taken.") {
//                               resolve(false);
//                           }
//                       })
//               })
//           }
//       ),
// }),