import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const FounderMessage = () => {
  return (
    <>
      {/* Founder Message */}
      <div className="flex text-offblack pt-20 xl:pt-32 2xl:pt-40">
        <div className="flex flex-col lg:flex-row lg:items-center sm:gap-x-16 mx-auto w-full md:w-[650px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] 2k:w-[1440px] 4k:w-[1536px]">
          <div className="mx-[5%] sm:mx-auto xl:mx-0 mt-10 xl:mt-0 h-auto sm:w-[550px] md:w-[650px] lg:w-[500px] xl:w-[616px] 2xl:w-[616px] 3xl:w-[700px] 2k:w-[900px] 4k:w-[1100px] sm:h-[400px] md:h-[450px] lg:h-[350px] xl:h-[400px] 2xl:h-[400px] 3xl:h-[450px] 2k:h-[550px] 4k:h-[650px] aspect-[616/400] ">
            <img
              className="w-full h-full rounded-3xl shrink-0"
              src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
              alt="trade-poster"
            />
          </div>
          <div className="lg:my-auto mx-[5%] sm:mx-auto lg:mx-0 sm:w-[550px] md:w-[650px] xl:w-[395px] 2xl:w-[395px] 3xl:w-full">
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className={"text-offblack mt-10 lg:mt-0 "}
            >
              In founding TradesPoster, I envisioned a marketplace where
              integrity and excellence lead. We commit to unparalleled service
              and connections in Australia's trades sector, ensuring quality and
              trust in every interaction.
            </SecondaryHeading>
            <div className="mt-2">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                Saim , Founder
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FounderMessage;
