import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { GetServiceTags } from "../Api/BusinessCategories";
import { GET_SERVICE_TAGS } from "../Redux/Constants";
export function useServiceTags() {
  const dispatch = useDispatch();
  return useQuery(["GetServiceTags"], GetServiceTags, {
    staleTime: 30000,
    onSuccess: (data) => {
      if (data) {
        dispatch({
          type: GET_SERVICE_TAGS,
          payload: data?.data?.key.$values,
        });
      }
    },
  });
}
