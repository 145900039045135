import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const WhyProfessional = () => {
  const paragraphs = [
    {
      heading: "Introduction:",
      paragraph:
        "Hiring a licensed plumber is crucial for several reasons. Licensing ensures that the plumber has undergone the necessary training and assessments to meet industry standards, which are in place to protect public health, safety, and welfare.",
    },
    {
      heading: "Compliance: ",
      paragraph:
        "A licensed plumber is knowledgeable about the local building codes and regulations, ensuring all plumbing work is compliant and up to code. This is particularly important for tasks that involve gas plumbing or sewage services, where improper handling can lead to serious health and safety risks.",
    },
    {
      heading: "Protection and recourse: ",
      paragraph:
        "Furthermore, using a licensed plumber typically provides recourse if the work does not meet the expected standards or if disputes arise. Most licensed plumbers are required to carry insurance, which further protects homeowners in the event of damage or accidents caused by plumbing work.",
    },
    {
      heading: "Conclusion: ",
      paragraph:
        "By prioritizing the hiring of a licensed plumber, you not only safeguard your property but also ensure the reliability and longevity of the plumbing work completed.",
    },
  ];
  return (
    <>
      <div className="mx-auto flex flex-col lg:flex-row items-center justify-center lg:justify-start gap-16 w-[350px] sm:w-[550px] md:w-[650px] xl:w-[1200px] 2xl:w-[1748px] 3xl:w-[1850px] 2k:w-[2000px] 4k:w-[2150px] h-[900px] sm:h-[700px] lg:w-[980px] lg:h-[700px] xl:h-[800px] 2xl:h-[1000px] 3xl:h-[1100px] 2k:h-[1200px] 4k:h-[1300px] mt-40 relative">
        <div className="absolute">
          <div className="w-[300px] sm:w-[450px] lg:w-[616px] p-10 lg:ml-10 text-offblack bg-white rounded-3xl">
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className={"text-offblack"}
            >
              Why hire a licensed plumber?
            </SecondaryHeading>
            {paragraphs.map((e) => {
              return (
                <>
                  <div className="mt-3">
                    <div className="inline">
                      <Paragraph
                        variant={"MainParagraph"}
                        className={"inline text-offblack font-bold"}
                      >
                        {e.heading}&nbsp;
                      </Paragraph>
                      <Paragraph
                        variant={"MainParagraph"}
                        className={"inline text-offblack"}
                      >
                        {e.paragraph}
                      </Paragraph>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <img
          src={require("../../assets/img/default-image.jpg")}
          alt="nothing found"
          className="w-full h-full rounded-3xl shrink-0 object-cover"
        />
      </div>
    </>
  );
};

export default WhyProfessional;
