import React from "react";
import BusinessDeactivation from "../../sections/BusinessDashboard/Dashboard/BusinessDeactivation";
import Heading from "../../components/common/Typography/Heading";
import DashboardMainContainer from "../../components/common/Containers/DashboardMainContainer";

const Deactivation = () => {
  return (
    <>
      <DashboardMainContainer className={"px-5 pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center text-offblack"}>
          Deactivate your business account
        </Heading>
        <div className="flex justify-center mt-[60px] min-h-screen">
          <BusinessDeactivation />
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default Deactivation;
