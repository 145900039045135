import React, { useState, useEffect } from "react";
import "../../../index.css";
import SecondaryHeading from "../Typography/SecondaryHeading";
import AuxiliaryText from "../Typography/AuxiliaryText";

export default function RangeSlider() {
  const [blue, setBlue] = useState(10);

  useEffect(() => {
    const sliderEl = document.querySelector("#range2");
    const tempSliderValue = blue;
    const progress = (tempSliderValue / sliderEl.max) * 100;
    sliderEl.style.background = `linear-gradient(to right, #2E00FF ${progress}%, #E8F0FF ${progress}%)`;

    sliderEl.addEventListener("input", (event) => {
      const tempSliderValue = event.target.value;
      const progress = (tempSliderValue / sliderEl.max) * 100;

      sliderEl.style.background = `linear-gradient(to right, #2E00FF ${progress}%, #E8F0FF ${progress}%)`;
    });
  }, []);
  return (
    <>
      <label className="value2 mb-1 mt-2 flex justify-center text-center">
        <AuxiliaryText variant={"Placeholder"}>{blue} km</AuxiliaryText>
      </label>
      <div className="range">
        <input
          type="range"
          min="1"
          max="50"
          id="range2"
          value={blue}
          onChange={(e) => setBlue(e.target.value)}
        />
      </div>
    </>
  );
}
