import React from "react";
import AuxiliaryText from "../../../../components/common/Typography/AuxiliaryText";

const CardDetails = () => {
  return (
    <>
      <div className="rounded-xl bg-pastalblue text-offblack">
        <div className="grid grid-cols-12 py-3 xl:py-5 px-5">
          <div className="flex items-center col-span-3 sm:col-span-2 xl:col-span-2">
            <img
              className="w-[40px] xl:w-[50px]"
              src={require("../../../../assets/img/payment/master.svg").default}
              alt="card"
            />
          </div>
          <div className="hidden md:flex items-center col-span-3 sm:col-span-3 xl:col-span-3">
            <AuxiliaryText variant={"FieldLabel"} className={"text-offblack"}>Expiry :</AuxiliaryText>
            <AuxiliaryText variant={"FieldLabel"} className={"text-offblack"}>&nbsp; 2/2023</AuxiliaryText>
          </div>
          <div className="flex items-center col-span-8 sm:col-span-8 md:col-span-6 xl:col-span-6">
            <AuxiliaryText variant={"Placeholder"} className={"text-offblack"}>Card number :</AuxiliaryText>
            <AuxiliaryText variant={"FieldLabel"} className={"text-offblack"}>
              &nbsp; 55956594878565
            </AuxiliaryText>
          </div>
          <div className="col-span-1 sm:col-span-1 flex items-center justify-end my-auto">
            <button title="Delete">
              <i className="fi fi-rs-trash text-base text-alertred"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDetails;
