import { useQuery } from "react-query";
import {
  AddRecentViwedService,
  CustomerDashBoardServices,
  GetCustomerReviews,
  GetServiceDetail,
  GetServiceListings,
  GetServiceListingsById,
  GetServiceReviews,
} from "../Api/Services";
export function useServices() {
  return useQuery(["GetServices"], GetServiceListings, {
    staleTime: 30000,
  });
}
export function useServicesById(Id) {
  return useQuery(["GetServicesById", Id], GetServiceListingsById);
}
export function useServicesDetail(Id) {
  return useQuery(["GetServicesDetail", Id], GetServiceDetail, {
    staleTime: 30000,
  });
}
export function useServiceReviews(Id) {
  return useQuery(["GetServiceReviews", Id], GetServiceReviews, {
    staleTime: 2000,
  });
}
export function useAddRecentViwed(userId, listingId) {
  return useQuery(
    ["AddRecentViwedService", userId, listingId],
    AddRecentViwedService,
    {
      staleTime: 30000,
    }
  );
}
export function useCustomerDashboard(userId) {
  return useQuery(
    ["CustomerDashBoardServices", userId],
    CustomerDashBoardServices
  );
}
export function useCustomerReviews(Id) {
  return useQuery(["GetCustomerReviews", Id], GetCustomerReviews);
}
